export const toMap = (
  array: Array<Record<string, any>>,
  field: string
): Record<string, any> =>
  array.reduce((result, item) => {
    result[item[field]] = item;
    return result;
  }, {});

/*
 * groups a file by sub arrays of size <size>
 * groupBySize([1,2,3,4,5], 2) => [[1,2],[3,4],[5]]
 */
export const groupBySize = <T>(
  array: Array<T>,
  size: number
): Array<Array<T>> =>
  array.reduce((acc, item, index) => {
    const quotient = Math.floor(index / size);
    const remainder = index % size;
    if (remainder) {
      acc[quotient].push(item);
    } else {
      acc[quotient] = [item];
    }
    return acc;
  }, [] as Array<Array<T>>);

export const getLast = <T>(array: Array<T>): T | null => {
  if (array.length < 1) {
    return null;
  }
  return array[array.length - 1];
};
