import { apiGet } from 'api/helpers';
import { LOGOUT } from 'api/duck';
import { LapiReviewConfigurationDTO } from 'api/lapiReviewConfiguration/types';
import { ControlConfiguration } from 'api/controlConfiguration/types';
import {
  ParkingConfigurationDTO,
  VehicleCategory,
  SubscriptionConfigurationDTO,
  ObservatoryConfigurationDTO,
  PricingConfiguration,
  FpsConfigurationDTO,
  KpiConfigurationDTO,
  SivConfiguration,
} from '@cvfm-front/tefps-types';
import { ParkingMeterConfiguration } from 'api/documentLibrary/types';
import { RefundConfigurationDTO } from 'api/fpsConfiguration/types';

export const RECEIVE_CONFIG = 'config/RECEIVE_CONFIG';
export const CHANGE_LANG_START = 'config/CHANGE_LANG_START';
export const CHANGE_LANG_END = 'config/CHANGE_LANG_END';
export const CVFM_PARTNER_CLASS =
  'fr.polyconseil.smartcity.cvfm.commons.config.partner.rapo.CvfmRapoPartnerProperties';

export type RegieProperty = {
  postalCodes?: Set<string>;
  planOrDefinitionIds?: Set<string>;
  id: string;
  name: string;
};

export type RegieProperties = {
  properties: Array<RegieProperty>;
  subscriptionProperties: Array<RegieProperty>;
};

export type ModuleConfigurationProperties = {
  enabled: boolean;
  displayed: boolean;
};

export type AvailableModuleConfiguration =
  | 'fps'
  | 'rapo'
  | 'ccsp'
  | 'tv'
  | 'subscribers'
  | 'pv'
  | 'gpv'
  | 'control'
  | 'planner'
  | 'tasks'
  | 'lapiReview'
  | 'controlView'
  | 'parking'
  | 'parkingMeter'
  | 'eligibility'
  | 'tao';

export type ModulesConfiguration = {
  [key in AvailableModuleConfiguration]: ModuleConfigurationProperties;
};

export type CityLocation = {
  longitude: number;
  latitude: number;
};

type ProcessingPrice = {
  correctionCost: number;
  initialCost: number;
};

// Tous les prix sont en centimes d'euro
export type GlobalConfigDTO = {
  id: string;
  apaMailPrice: ProcessingPrice;
  sendingMailCost: number;
  dematerializedApaPrice: ProcessingPrice;
};

export type InitialOrganization = {
  key: string;
  label: string;
};

export type FpsCancelProposalReason = {
  key: string;
  label: string;
};

export type ExemptionReason = {
  key: string;
  label: string;
  icon: string | null | undefined;
  deleted: boolean;
  hiddenLapi: boolean;
};

export type AuthorizedVehicleCategory = {
  vehicleCategory: VehicleCategory;
  priority: number;
};

export type GeocoderConfiguration = {
  parkingSpaceEnabled: boolean;
};

export type Config = {
  cityId: string;
  name: string;
  logo?: string;
  regiesProperties?: RegieProperties;
  timezone: string;
  location: CityLocation;
  activePricing?: string;
  activeZoning?: string;
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
  fpsRefundEnabled: boolean;
  cancelFpsMultipleEnabled: boolean;
  organizationFilterEnabled: boolean;
  organizationRapoAnswerEnabled: boolean;
  organizationRapoChoiceReasonEnabled: boolean;
  combinedNFPSExportEnabled: boolean;
  recourseFullValidation: boolean;
  whiteListEnabled: boolean;
  securityPeriodForRecordingPayment: number; // minutes
  cvfmTvPartner: boolean;
  cvfmFpsPartner: boolean;
  cvfmRapoPartner: boolean;
  frontUrl: string;
  frontSubsUrl: string;
  frontPmrUrl: string;
  modulesConfiguration: ModulesConfiguration;
  globalConfig?: GlobalConfigDTO;
  noticeDownloadEnabled: boolean;
  apaRapoDownloadEnabled: boolean;
  paymentConfirmationEnabled: boolean;
  initialOrganizationsConfigurations?: Array<InitialOrganization>;
  fpsCancelProposalReasons?: Array<FpsCancelProposalReason>;
  exemptionReasonsConfigurations?: Array<ExemptionReason>;
  smsEnabled: boolean;
  trigram: string;
  trigramIdFormatter: boolean;
  hashPartnerPlateEnabled: boolean;
  tReady?: boolean;
  selectedLang: string;
  langOptions: string[];
  customFpsBehaviour: boolean;
  enableParkingMeter: boolean;
  antaiMinorationEnabled: boolean;
  lapiReviewConfigurationDTO: LapiReviewConfigurationDTO;
  refundConfigurationDTO: RefundConfigurationDTO;
  fpsConfigurationDTO: FpsConfigurationDTO;
  controlConfiguration: ControlConfiguration;
  fullControlViewEnabled: boolean;
  sendingRefundLetterEnabled: boolean;
  sendingRecourseLetterEnabled: boolean;
  parkingConfigurationDTO: ParkingConfigurationDTO;
  parkingMeterConfigurationDTO: ParkingMeterConfiguration;
  mailingPartnerProperties: ICvfmMailingPartnerProperties | null;
  subscriptionConfigurationDTO: SubscriptionConfigurationDTO;
  pricingConfiguration: PricingConfiguration;
  doubleCheckPlateReadingEnabled: boolean;
  useCorrectedCoordinates: boolean;
  watermarkText: string | null;
  kpiConfiguration: KpiConfigurationDTO;
  sivConfiguration: SivConfiguration;
  geocoderConfiguration: GeocoderConfiguration;
  observatoryConfigurationDTO: ObservatoryConfigurationDTO;
};

export type ICvfmMailingPartnerProperties = {
  class: string;
};

type ChangeLangStartAction = {
  type: typeof CHANGE_LANG_START;
};

type ChangeLangEndAction = {
  type: typeof CHANGE_LANG_END;
  lang: string;
};

type ReceiveConfigAction = {
  type: typeof RECEIVE_CONFIG | typeof LOGOUT;
  config?: Config;
};

type ConfigAction =
  | ReceiveConfigAction
  | ChangeLangStartAction
  | ChangeLangEndAction;

export default function configReducer(
  state: Config | null = null,
  action: ConfigAction
): Config | null {
  switch (action.type) {
    case RECEIVE_CONFIG:
      return action.config ? action.config : state;
    case LOGOUT:
      return null;
    case CHANGE_LANG_START:
      return state && { ...state, tReady: false };
    case CHANGE_LANG_END:
      return state && { ...state, selectedLang: action.lang, tReady: true };
    default:
      return state;
  }
}

function changeLanguage(
  config: Config,
  dispatch: (action: ConfigAction) => void
) {
  // display loader during language change
  dispatch({
    type: CHANGE_LANG_START,
  });
  window.i18next.changeLanguage(config.selectedLang, () => {
    // remove loader once language has changed
    dispatch({
      type: CHANGE_LANG_END,
      lang: config.selectedLang,
    });
  });
}

export function fetchConfig(): any {
  return async (dispatch: any): Promise<void> => {
    const config = await apiGet<Config>('/api/prv/v0/config');
    const globalConfig = await apiGet<GlobalConfigDTO>(
      '/api/prv/v0/global/config'
    );
    dispatch({
      type: RECEIVE_CONFIG,
      config: { ...config, globalConfig },
    });
    changeLanguage(config, dispatch);
  };
}

export function getConfigState(state: any): Config {
  return state.config;
}
