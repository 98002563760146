import React, { CSSProperties, useEffect, useState } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { fetchOrganizations } from 'api/organizations';
import { CityOrganizationDTO } from 'api/organizations/types';

import ErrorBlock from './ErrorBlock';

const { _t } = window.loadTranslations(__filename);

type Props = {
  organizationId: string;
  onChange: (
    e: React.SyntheticEvent<{}>,
    index: number,
    menuItemValue: any
  ) => void;
};
const DISPLAY_STYLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: 10,
};

const OrganizationSelector = ({ organizationId, onChange }: Props) => {
  const [organizations, setOrganizations] = useState<
    Array<CityOrganizationDTO>
  >([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const loadOrganizations = async () => {
    try {
      const response = await fetchOrganizations(true, 'fps');
      if (response.length === 0) {
        setErrorMessage(_t('feedback.error.noOrganisation'));
        setError(true);
      } else {
        setOrganizations(response);
      }
    } catch (e) {
      setErrorMessage(e.json.message);
      setError(true);
    }
  };

  useEffect(() => {
    loadOrganizations();
  }, []);

  const renderOrganisationList = () =>
    organizations.map(org => (
      <MenuItem
        key={org.organizationId}
        value={org.organizationId}
        primaryText={org.name}
      />
    ));

  if (error) {
    return <ErrorBlock error={{ message: errorMessage }} />;
  }

  return (
    <div style={DISPLAY_STYLE}>
      <SelectField value={organizationId} onChange={onChange}>
        {renderOrganisationList()}
      </SelectField>
    </div>
  );
};

export default OrganizationSelector;
