import React, { CSSProperties } from 'react';
import AutoComplete from 'material-ui/AutoComplete';
import SearchIcon from 'material-ui/svg-icons/action/search';
import Reset from 'material-ui/svg-icons/action/restore';

import { STYLE_TITLE_BORDER } from 'theme';
import { ItemIdName } from 'api/commonTypes';

import {
  STYLE_BLOCK_TITLE,
  STYLE_SELECT_TEXT,
  STYLE_SELECT_TEXT_BLACK,
} from '../theme';

const { _t } = window.loadTranslations(__filename);

type AutocompleteProps = {
  id: string;
  title?: string;
  options: ItemIdName[];
  // Function to apply the search on the filters
  onChange: (id: string, search: string | null | undefined) => void;
  // Function to run autocomplete loading (or just changing options list)
  onAutocomplete: (search: string) => Promise<any>;
  // The search value is given in props to handle the reset of filters
  // The search value is set in fps filters only when pressed "enter" in autocomplete field
  // Or when the user clicks on a value in the autocomplete list
  search: string | null | undefined; // eslint-disable-line react/no-unused-prop-types
  secondaryColor?: boolean;
  blockStyle?: CSSProperties;
};

type AutocompleteState = {
  searchText: string;
};

class Autocomplete extends React.Component<
  AutocompleteProps,
  AutocompleteState
> {
  getDefaultProps() {
    return {
      secondaryColor: false,
    };
  }

  state: AutocompleteState = {
    searchText: '',
  };

  componentDidMount() {
    this.initFromProps(); // init search value
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: AutocompleteProps) {
    const { search } = nextProps;
    if (search !== this.props.search && search === '') {
      this.setState({ searchText: '' });
    }
    if (search === undefined) {
      // reset filters
      this.reset(nextProps);
    }
  }

  onSearchChange = (
    searchText: string,
    dataSource: any,
    options: { source: string }
  ) => {
    const { onAutocomplete } = this.props;
    this.setState({ searchText });
    if (options.source === 'change') {
      onAutocomplete(searchText);
    }
  };

  onSelectList = (search: string, index: number) => {
    const { options, id, onChange } = this.props;
    let filterToApply: string | undefined =
      index !== -1 ? options[index].id : search;

    /* handle the special case of empty string: we really wants null instead */
    if (filterToApply === '') {
      filterToApply = undefined;
    }

    onChange(id, filterToApply);
  };

  onClickSearch = () => {
    this.onSelectList(this.state.searchText, -1);
  };

  onClickReset = () => this.reset(this.props);

  reset = (props: AutocompleteProps) => {
    const { onAutocomplete, onChange, id } = props;
    this.setState({ searchText: '' });
    onAutocomplete('');
    onChange(id, '');
  };

  initFromProps() {
    const { search, onAutocomplete } = this.props;
    this.setState({ searchText: search || '' });
    onAutocomplete(search || '');
  }

  render() {
    const { title, options, secondaryColor, blockStyle } = this.props;
    const { searchText } = this.state;

    return (
      <div style={{ ...blockStyle }}>
        {title && (
          <div style={STYLE_BLOCK_TITLE}>
            {title}
            <hr style={STYLE_TITLE_BORDER} />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ flex: 1 }}>
            <AutoComplete
              menuStyle={{ overflowY: 'auto', maxHeight: 350 }}
              openOnFocus
              fullWidth
              id={this.props.id}
              inputStyle={
                secondaryColor ? STYLE_SELECT_TEXT_BLACK : STYLE_SELECT_TEXT
              }
              // eslint-disable-next-line @typescript-eslint/unbound-method
              filter={AutoComplete.noFilter}
              // eslint-disable-next-line
              // @ts-ignore
              onUpdateInput={this.onSearchChange}
              onNewRequest={this.onSelectList}
              searchText={searchText}
              dataSource={options}
              dataSourceConfig={{ text: 'name', value: 'id' }}
              style={{ width: '100%' }}
              popoverProps={{ style: { width: 'auto', maxWidth: 500 } }}
            />
          </div>
          <div title={_t('element.applyFilter')}>
            <SearchIcon
              style={{
                color: secondaryColor ? 'black' : 'white',
                marginLeft: 10,
                width: 24,
                height: 24,
                cursor: 'pointer',
              }}
              onClick={this.onClickSearch}
            />
          </div>
          <div title={_t('element.resetFilter')}>
            <Reset
              style={{
                color: secondaryColor ? 'black' : 'white',
                marginLeft: 10,
                width: 24,
                height: 24,
                cursor: 'pointer',
              }}
              onClick={this.onClickReset}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Autocomplete;
