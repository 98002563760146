import { ProductPrivateDTO } from '@cvfm-front/tefps-types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useProducts = (): Array<ProductPrivateDTO> | null => {
  return useWatcher(
    services.products.watchProducts,
    services.products.getProducts()
  );
};

export default useProducts;
