import { useEffect, useState } from 'react';

import { WatchFunctionType } from '@cvfm-front/commons-types';

const useWatcher = <T>(
  watchValue: WatchFunctionType<T>,
  defaultValue: T
): T => {
  const [value, setValue] = useState<T>(defaultValue);

  useEffect(() => watchValue(setValue), []);

  return value;
};

export default useWatcher;
