import { ControlDTO } from 'api/control/types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useCurrentControlDTO = (): ControlDTO => {
  return useWatcher(
    services.lapiReview.watchCurrentControlDTO,
    services.lapiReview.getCurrentControlDTO()
  );
};

export default useCurrentControlDTO;
