import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';

import BoButton from 'facade/BoButton';
import { PricingDTO, ProductPrivateDTO } from '@cvfm-front/tefps-types';
import { BKG_PINK, LABEL_STYLE, TXT_RED } from 'theme';
import { STYLE_ADDRESS_TITLE } from 'tefps/Fps/List/AddFPS/common.styles';

import SeparatorWithTitle from '../SeparatorWithTitle';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: TXT_RED,
  color: 'white',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'start',
  alignContent: 'center',
  maxHeight: '50vh',
};

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
};

type RemoveZoneErrorProps = {
  isOpen: boolean;
  close: () => void;
  products: Array<ProductPrivateDTO>;
  pricings: Array<PricingDTO>;
};

export const RemoveZoneError = ({
  isOpen,
  close,
  products,
  pricings,
}: RemoveZoneErrorProps) => {
  const actions = [
    <BoButton
      style={{ marginRight: 10 }}
      key={1}
      label={_tg('action.cancel')}
      onClick={close}
    />,
  ];

  return (
    <Dialog
      open={isOpen}
      actions={actions}
      title={_tg('tefps.pricing.zoning.deleteZone.title')}
      onRequestClose={close}
      titleStyle={STYLE_TITLE}
      autoScrollBodyContent
    >
      <div style={STYLE_CONTENT_WRAPPER}>
        <div style={{ ...STYLE_CONTAINER, justifyContent: undefined }}>
          <SeparatorWithTitle
            style={STYLE_ADDRESS_TITLE}
            title={_tg('tefps.pricing.zoning.deleteZone.using.product.title')}
            color={BKG_PINK}
            titleSize={20}
          />
          <div style={{ ...LABEL_STYLE, width: undefined }}>
            {_tg('tefps.pricing.zoning.deleteZone.using.product.label', {
              numberOfProducts: products.length,
            })}
          </div>
        </div>
        <div style={{ ...STYLE_CONTAINER, alignItems: 'start' }}>
          {products.length > 0 && (
            <div>
              {products.map(product => (
                <p key={product.productId}>{product.name}</p>
              ))}
            </div>
          )}
        </div>
        <div style={STYLE_CONTAINER}>
          <SeparatorWithTitle
            style={STYLE_ADDRESS_TITLE}
            title={_tg('tefps.pricing.zoning.deleteZone.using.pricing.title')}
            color={BKG_PINK}
            titleSize={20}
          />
          <div style={{ ...LABEL_STYLE, width: undefined }}>
            {_tg('tefps.pricing.zoning.deleteZone.using.pricing.label', {
              numberOfPricings: pricings.length,
            })}
          </div>
        </div>
        <div style={{ ...STYLE_CONTAINER, alignItems: 'start' }}>
          {pricings.length > 0 && (
            <div>
              {pricings.map(pricing => (
                <p key={pricing.id}>{pricing.name}</p>
              ))}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};
