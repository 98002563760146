import services from 'commons/services';
import { EvidenceFile } from 'commons/services/EvidenceFilesService';

import useWatcher from './useWatcher';

const useEvidenceFiles = (): [
  Array<EvidenceFile>,
  (arg0: Array<EvidenceFile>) => void
] => {
  return [
    useWatcher(services.evidenceFiles.watchEvidenceFiles, []),
    services.evidenceFiles.setEvidenceFiles,
  ];
};

export default useEvidenceFiles;
