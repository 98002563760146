import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { UploadFile } from 'api/commonTypes';

export type EvidenceFile = {
  id: string;
  file: UploadFile | null | undefined;
};

export interface EvidenceFilesServiceFactory {
  (): EvidenceFilesServiceInterface;
}

export interface EvidenceFilesServiceInterface {
  getEvidenceFiles: () => Array<EvidenceFile>;
  setEvidenceFiles: (files: Array<EvidenceFile>) => void;
  watchEvidenceFiles: WatchFunctionType<Array<EvidenceFile>>;
  getFilesComment: () => string;
  setFilesComment: (comment: string) => void;
  watchFilesComment: WatchFunctionType<string>;
}

const EvidenceFilesService: EvidenceFilesServiceFactory = () => {
  const {
    getValue: getEvidenceFiles,
    setValue: setEvidenceFiles,
    watchValue: watchEvidenceFiles,
  } = Watcher<Array<EvidenceFile>>([]);

  const {
    getValue: getFilesComment,
    setValue: setFilesComment,
    watchValue: watchFilesComment,
  } = Watcher<string>('');

  return {
    getEvidenceFiles,
    setEvidenceFiles,
    watchEvidenceFiles,
    getFilesComment,
    setFilesComment,
    watchFilesComment,
  };
};

export default EvidenceFilesService;
