import { PolygonDTO, ZoningDTO, ZoningType } from '@cvfm-front/tefps-types';
import { MapZoning, PolygonForMap } from 'api/pricing/types';

import { ZoningNames } from './types';

const { _t } = window.loadTranslations(__filename);

const toPolygonDTO = (polygon: google.maps.Polygon): PolygonDTO => ({
  id: polygon.id,
  name: polygon.name,
  points: polygon
    .getPath()
    .getArray()
    .map(p => ({ lat: p.lat(), lon: p.lng() })),
});

export const polygonsToDto = (
  polygons: Array<Record<string, any>>
): Array<PolygonDTO> => {
  return polygons.map((p: google.maps.Polygon) => toPolygonDTO(p));
};

const buildGeoJsonPolygon = (polygon: PolygonForMap) => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        polygon.points.map<[number, number]>(point => [point.lon, point.lat]),
      ],
    },
    properties: {
      id: polygon.id,
      name: polygon.name,
      zoneIds: polygon.zoneIds,
    },
  };
};

export const toZoneforMap = (zoning: ZoningDTO): MapZoning => {
  const { zones } = zoning;
  return {
    polygons: zoning.polygons.map(p => ({
      id: p.id,
      name: p.name,
      points: p.points,
      zoneIds: zones.filter(z => z.polygonIds.includes(p.id)).map(zo => zo.id),
      properties: null,
    })),
    zones: zoning.zones.map(z => ({
      id: z.id,
      name: z.name,
      color: z.color,
      polygonIds: z.polygonIds,
    })),
  };
};

export const exportZoning = (zoningDTO: ZoningDTO) => {
  const zoningGeoJson: {
    type: string;
    features: Array<any>;
  } = {
    type: 'FeatureCollection',
    features: [],
  };

  const zoningMap = toZoneforMap(zoningDTO);

  if (zoningMap) {
    zoningMap.polygons
      .filter(polygon => polygon.zoneIds != null && polygon.zoneIds.length > 0)
      .forEach(polygon =>
        zoningGeoJson.features.push(buildGeoJsonPolygon(polygon))
      );
  }

  return zoningGeoJson;
};

export function computeNaming(zoningType: ZoningType): ZoningNames {
  const namings = new Map();

  namings.set('LAPI', {
    name: _t('naming.LAPI.name'),
    withThe: _t('naming.LAPI.withThe'),
    withThis: _t('naming.LAPI.withThis'),
    withArticle: _t('naming.LAPI.withArticle'),
    withDeterminant: _t('naming.LAPI.withDeterminant'),
    withVirtual: _t('naming.LAPI.withVirtual'),
  });
  namings.set('FPS', {
    name: _t('naming.FPS.name'),
    withThe: _t('naming.FPS.withThe'),
    withThis: _t('naming.FPS.withThis'),
    withArticle: _t('naming.FPS.withArticle'),
    withDeterminant: _t('naming.FPS.withDeterminant'),
    withVirtual: _t('naming.FPS.withVirtual'),
  });
  namings.set('PV', {
    name: _t('naming.PV.name'),
    withThe: _t('naming.PV.withThe'),
    withThis: _t('naming.PV.withThis'),
    withArticle: _t('naming.PV.withArticle'),
    withDeterminant: _t('naming.PV.withDeterminant'),
    withVirtual: _t('naming.PV.withVirtual'),
  });

  return namings.get(zoningType);
}

function drawPolygon(
  data: PolygonDTO,
  color: string,
  zoneIds: Array<string>,
  zIndex: number,
  map: google.maps.Map | null | undefined,
  polygons: Array<google.maps.Polygon>,
  visible: boolean
): void {
  // Polygon Coordinates
  if (data && data.points) {
    const coords = data.points.map(p => new google.maps.LatLng(p.lat, p.lon));
    // Styling & Controls
    const polygon = new google.maps.Polygon({
      id: data.id,
      name: data.name,
      zones: zoneIds,
      paths: coords,
      draggable: false,
      editable: false,
      strokeColor: color,
      strokeOpacity: 0.6,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.25,
      zIndex,
      visible,
    });
    if (map) polygon.setMap(map);
    polygons.push(polygon);
  }
}

export function drawPolygons(
  zoning: MapZoning | null | undefined,
  map: google.maps.Map | null | undefined,
  polygons: Array<google.maps.Polygon>,
  visible: boolean
): void {
  if (!zoning) {
    return;
  }
  const { zones } = zoning;
  zoning.polygons.forEach((polygon: PolygonForMap, i) => {
    const containingZones = zones.filter(z => z.id === polygon.zoneIds[0]);
    let color = '#FFF';
    if (containingZones.length > 0 && containingZones[0].color !== undefined) {
      color = polygon.zoneIds.length > 1 ? '#000' : containingZones[0].color;
    }
    drawPolygon(polygon, color, polygon.zoneIds, i, map, polygons, visible);
  });
}
