import React, {
  CSSProperties,
  useEffect,
  createContext,
  useContext,
  ReactNode,
  useState,
} from 'react';
import Checkbox from 'material-ui/Checkbox';

import {
  ICON_STYLE,
  LABEL_STYLE,
  STYLE_TITLE_BORDER,
  TXT_GREYBLUE,
  FLEX_WIDTH_STYLE,
} from 'theme';
import { KeyBucketDTO } from 'api/commonTypes';

import { STYLE_BLOCK_TITLE } from '../theme';
import { CheckboxOption } from '../types';
import { UpDownArrow } from '../../UpDownArrow';

const STYLE_BLOCK: CSSProperties = {
  marginTop: 20,
  color: TXT_GREYBLUE,
  fontWeight: 'bold',
};

const STYLE_FILTER_BLOCK: CSSProperties = {
  fontSize: 13,
  margin: '2px 0',
  display: 'flex',
  alignItems: 'center',
};

const STYLE_FACET: CSSProperties = { textAlign: 'right', flex: '1 1 20%' };

type CheckboxWrapperProps = {
  option: CheckboxOption;
  checked: boolean;
  onCheck: (value: string, checked: boolean) => void;
  facet: KeyBucketDTO | null | undefined;
};

class CheckboxWrapper extends React.Component<CheckboxWrapperProps> {
  onCheck = (event: any, isInputChecked: boolean) => {
    const {
      onCheck,
      option: { value },
    } = this.props;
    onCheck(value, isInputChecked);
  };

  render(): React.ReactNode {
    const { option, checked, facet } = this.props;
    return (
      <div style={STYLE_FILTER_BLOCK}>
        <Checkbox
          label={option.label}
          iconStyle={ICON_STYLE}
          labelStyle={LABEL_STYLE}
          style={FLEX_WIDTH_STYLE}
          checked={checked}
          onCheck={this.onCheck}
        />
        <span style={STYLE_FACET}>
          {facet ? facet.value.toLocaleString() : ''}
        </span>
      </div>
    );
  }
}

type CheckboxesProps<T extends string> = {
  title?: string;
  id: string;
  options: Array<CheckboxOption>;
  filters: Set<T>;
  faceting?: Array<KeyBucketDTO> | null | undefined;
  onChange: (id: string, filters: Set<T>) => void;
  collapsible?: boolean;
};

interface CheckboxContextType {
  isParentCollapsing?: boolean;
}

export const CheckboxContext = createContext<CheckboxContextType | undefined>(
  undefined
);

const Checkboxes = ({
  title,
  id,
  options,
  filters,
  faceting,
  onChange,
  collapsible,
}: CheckboxesProps<any>): JSX.Element => {
  const context = useContext(CheckboxContext);
  const isParentCollapsing =
    context !== undefined ? context.isParentCollapsing : undefined;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(!!isParentCollapsing);

  useEffect(() => {
    setIsCollapsed(!!isParentCollapsing);
  }, [isParentCollapsing]);

  const onCheck = (value: string, checked: boolean): void => {
    const newFilters = new Set(filters);

    // Add or remove the option value of the filters
    if (checked) newFilters.add(value);
    else newFilters.delete(value);

    // Send the new filters to the parent to apply
    onChange(id, newFilters);
  };

  const filtersSet = Array.isArray(filters) ? new Set(filters) : filters;
  const isCollapsible =
    isParentCollapsing !== undefined || collapsible !== undefined;
  return (
    <div style={STYLE_BLOCK}>
      {title && (
        <div style={STYLE_BLOCK_TITLE}>
          {title}
          <hr style={STYLE_TITLE_BORDER} />
          {isCollapsible && (
            <UpDownArrow
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              size="15px"
            />
          )}
        </div>
      )}
      {(!isCollapsible || isCollapsed) &&
        options.map(option => {
          const facet = faceting && faceting.find(f => f.key === option.value);
          return (
            <CheckboxWrapper
              key={option.value}
              option={option}
              checked={filtersSet.has(option.value)}
              onCheck={onCheck}
              facet={facet}
            />
          );
        })}
    </div>
  );
};

export default Checkboxes;
