import services from 'commons/services';
import { Pager } from 'api/commonTypes';

import useWatcher from './useWatcher';

const useRecoursesPager = (): Pager => {
  return useWatcher(
    services.recourseListService.watchPager,
    services.recourseListService.getPager()
  );
};

export default useRecoursesPager;
