import moment from 'moment';

import { MandateUser } from '@cvfm-front/tefps-types';

const { _t, _tg } = window.loadTranslations(__filename);

const POSTAL_CODE_REGEX = /^[0-9]{5}$/; // TODO to fix: corse ?
const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
const INTEGER_REGEX = /^[0-9]+$/;
const STREET_LOCALITY_REGEX = /^[a-zA-ZÀ-ÿ0-9\s'-]+$/;

export function isStrictlyPositiveInteger(
  number: string | null | undefined
): boolean {
  return !number || (Number(number) > 0 && INTEGER_REGEX.test(number));
}

export function validateFirstName(value: string): string | undefined {
  if (value.length > 80) {
    return _t('feedback.error.firstName');
  }
  return undefined;
}

export function validateLastName(value: string): string | undefined {
  if (value.length > 80) {
    return _t('feedback.error.lastName');
  }
  return undefined;
}

export function validatePostalCode(value: string): string | undefined {
  if (!POSTAL_CODE_REGEX.test(value)) {
    return _t('feedback.error.postCodeFormat');
  }
  return undefined;
}

export function validateFpsPostalCode(value: string): string | undefined {
  if (!/^[A-Za-z0-9]{1,15}$/.exec(value)) {
    return _t('feedback.error.postCodeFormat');
  }
  return undefined;
}

export function validateForeignCountry(value: string): string | undefined {
  if (value.length > 2) {
    return _t('feedback.error.addressCountry');
  }
  return undefined;
}

export function validateEmail(value: string): string | undefined {
  if (!EMAIL_REGEX.test(value)) {
    return _t('feedback.error.emailFormat');
  }
  return undefined;
}

export function validateMandate(
  mandateUser: MandateUser | undefined
): string | undefined {
  if (!mandateUser) {
    return undefined;
  }
  const firstName = mandateUser.mandateFirstName;
  const lastName = mandateUser.mandateLastName;
  if (
    (!firstName?.length && lastName?.length) ||
    (firstName?.length && !lastName?.length)
  ) {
    return _t('feedback.error.mandateField');
  }
  return undefined;
}

export function validateFileSize(file?: File): string | null {
  const maxSize = 10000000;
  if (file && file.size > maxSize) {
    return _tg('feedback.error.evidenceFileSize', { mo: maxSize / 10 ** 6 });
  }
  return null;
}

export function validateStreetNumber(value: string): string | undefined {
  if (!isStrictlyPositiveInteger(value) || value.length > 10) {
    return _t('feedback.error.streetNumberFormat');
  }
  return undefined;
}

export function validateStreetNumberBis(value: string): string | undefined {
  if (value.length > 10) {
    return _t('feedback.error.numberComplementFormat');
  }
  return undefined;
}

export function validateStreetName(value: string): string | undefined {
  if (value == null || value.trim() === '') {
    return _t('feedback.error.streetNameFormat');
  }
  return undefined;
}

export function validateStreetLocality(value: string): string | undefined {
  if (
    value == null ||
    value.trim() === '' ||
    !STREET_LOCALITY_REGEX.test(value)
  ) {
    return _t('feedback.error.streetLocalityFormat');
  }
  return undefined;
}

export const validateMandatoryInput = (
  value: string | null | undefined
): string | undefined => {
  if (!value || value.trim() === '') {
    return _tg('feedback.error.mandatoryField');
  }
  return undefined;
};

export const validateMandatoryInputWithoutSpace = (
  value: string | null | undefined
): string | undefined => {
  if (!value || value.trim() === '') {
    return _tg('feedback.error.mandatoryField');
  }
  return /^[a-zA-Z0-9_-]*$/.test(value)
    ? undefined
    : _tg('feedback.error.incorrectField');
};

export const validateMandatoryArrayInput = (
  value: Array<string> | null | undefined
): string | undefined => {
  if (!value || value.length < 1) {
    return _tg('feedback.error.mandatoryField');
  }
  return undefined;
};

export const validateMandatoryNumberInput = (
  value: number | null | undefined,
  min: number
): string | undefined => {
  if (!value) {
    return _tg('feedback.error.mandatoryField');
  }
  if (value < min) {
    return _t('feedback.error.minValue', { min });
  }
  return undefined;
};

export const validateBeforeToday = (value: Date): string | undefined => {
  if (
    moment()
      .endOf('day')
      .isBefore(value)
  ) {
    return _t('feedback.error.dateBeforeToday');
  }
  return undefined;
};

export const validateTrigramIdCustomPart = (value: string): string | null =>
  /^[a-zA-Z0-9_-]*$/.test(value) ? null : _t('feedback.error.trigramIdFormat');

// We compute the RIB Key, simple computation except for the letters after 'S'
// cf : http://marlot.org/util/calcul-de-la-cle-rib.php
function computeRIBKey(char: string) {
  const upperCasedChar = char.toUpperCase();
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const offset = upperCasedChar >= 'S' ? 2 : 1;
  return String(
    ((upperCasedChar.charCodeAt(0) - alphabet.charCodeAt(0)) % 9) + offset
  );
}

// we can get the last 2 digits with this calc : 97 - [ (89 x Code banque + 15 x Code guichet + 3 x Numéro de compte ) modulo 97]
// to call this function we have to check first that the IBAN is french format and valid
function checkRIBKey(iban: string): string | null | undefined {
  const newIban = iban.replace(/[a-zA-Z]/g, computeRIBKey);

  const bankCode = Number(newIban.substr(4, 5));
  const deskCode = Number(newIban.substr(9, 5));
  const accountNumber = Number(newIban.substr(14, 11));
  const enteredKey = Number(newIban.substr(25, 2));
  const calculatedKey =
    97 - ((89 * bankCode + 15 * deskCode + 3 * accountNumber) % 97);
  if (calculatedKey !== enteredKey) {
    return _t('feedback.error.ibanFormat.generic');
  }
  return null;
}

export function validateFrenchIban(iban: string): string | null | undefined {
  if (!iban || iban.length === 0) {
    // we don't set an error if nobody entered a value in the IBAN field
    return null;
  }
  if (!RegExp(/^FR[\d]{2}[\d]{10}[a-zA-Z0-9]{11}[\d]{2}$/).test(iban)) {
    return _t('feedback.error.ibanFormat.french');
  }
  return checkRIBKey(iban);
}

export function isValidIP(ip: string) {
  const regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return regex.test(ip);
}
