import { ParkingRightSearchResultDTO } from 'api/tickets/types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useParkingRights = (): ParkingRightSearchResultDTO | null => {
  return useWatcher(
    services.parkingRights.watchParkingRights,
    services.parkingRights.getParkingRights()
  );
};

export default useParkingRights;
