import { ZoneDTO } from '@cvfm-front/tefps-types';
import { KeyLabel } from '@cvfm-front/commons-types';

const { _t } = window.loadTranslations(__filename);

export type Zone = KeyLabel;

export const buildZoneSelect = (zones: Array<ZoneDTO>): Array<Zone> =>
  [{ key: '', label: _t('element.allZones') }].concat(
    zones.map(zone => ({
      key: zone.id,
      label: zone.name,
    }))
  );
