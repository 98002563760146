import React, { CSSProperties } from 'react';
import Reset from 'material-ui/svg-icons/action/restore';

import { BKG_CYAN_SELECTED } from 'theme';

const { _t } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
const STYLE_RESET: CSSProperties = {
  color: BKG_CYAN_SELECTED,
  marginLeft: 10,
  width: 30,
  height: 30,
  cursor: 'pointer',
};

type Props = {
  title?: string;
  resetFilters?: (event: React.MouseEvent<SVGElement>) => void;
};

const BarTitle = ({ title, resetFilters }: Props) => (
  <div style={STYLE_TITLE}>
    <span>{title || _t('element.defaultTitle')}</span>
    {resetFilters && (
      <span title={_t('element.resetFilters')}>
        <Reset style={STYLE_RESET} onClick={resetFilters} />
      </span>
    )}
  </div>
);

export default BarTitle;
