type FormatterConfig = {
  base: number;
  units: Array<string>;
};

type FormatterConfigKey = 'number' | 'filesize';

const formatterConfig: { [key in FormatterConfigKey]: FormatterConfig } = {
  number: {
    base: 1000,
    units: ['', ' k', ' M', ' Md'],
  },
  filesize: {
    base: 1024,
    units: [' o', ' Ko', ' Mo', ' Go'],
  },
};

export const formatNumber = (
  value: number,
  configKey?: 'number' | 'filesize'
): string => {
  const config = configKey
    ? formatterConfig[configKey]
    : formatterConfig.number;
  let newValue = value;
  let i = 0;
  if (!config || isNaN(value)) {
    return '';
  }
  while (Math.abs(newValue) >= config.base && i < config.units.length - 1) {
    newValue /= config.base;
    i += 1;
  }

  return `${parseFloat(Number(newValue).toFixed(1))}${config.units[i]}`;
};
