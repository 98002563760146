import { snackbarClose, snackbarSendMessage } from './actions';

export type SnackbarReduxDispatchProps = {
  snackbarSendMessage: (message: string) => void;
};

export const mapSnackbarActionToProps = (
  dispatch: any
): SnackbarReduxDispatchProps => {
  return {
    snackbarSendMessage: (message: string) => {
      dispatch(snackbarClose());
      dispatch(snackbarSendMessage(message));
    },
  };
};

export type SnackbarReduxStateProps = {
  isOpen: boolean;
  message: string;
};

export const mapSnackbarStateToProps = ({
  snackbar,
}: any): SnackbarReduxStateProps => {
  return {
    isOpen: snackbar.isOpen,
    message: snackbar.message,
  };
};
