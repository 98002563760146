import { isEqual } from 'lodash';

import { WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import {
  EsCityParkingSpaceDTO,
  EsCompletionSuggestionResponse,
  ParkingOrientation,
  ParkingSpaceRegime,
} from '@cvfm-front/tefps-types';
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPostFile,
  apiPut,
  downloadFileFromUrl,
} from 'api/helpers';

import services from '..';

export interface ParkingSpaceServiceApiFactory {
  (): ParkingSpaceServiceApiInterface;
}

export interface ParkingSpaceServiceApiInterface {
  fetchOneById: (
    parkingSpaceId: EsCityParkingSpaceDTO['parkingSpaceId']
  ) => Promise<EsCityParkingSpaceDTO>;
  autocompleteParkingSpaceId: (
    search: string
  ) => Promise<EsCompletionSuggestionResponse>;
  fetchInsideBoundingBox: (
    bb: [number, number, number, number]
  ) => Promise<EsCityParkingSpaceDTO[]>;
  addParkingSpace: (request: EsCityParkingSpaceDTO) => Promise<void>;
  updateParkingSpace: (
    request: EsCityParkingSpaceDTO
  ) => Promise<EsCityParkingSpaceDTO>;
  validateVersions: (request: EsCityParkingSpaceDTO) => Promise<void>;
  deleteOneParkingSpace: (
    parkingSpaceId: EsCityParkingSpaceDTO['parkingSpaceId']
  ) => Promise<void>;
  importGson: (file: File) => Promise<void>;
  exportGson: () => Promise<void>;
  recomputeZones: (cityId: string) => Promise<void>;
  recomputeAddresses: (cityId: string) => Promise<void>;
  watchIsUploading: WatchFunctionType<boolean>;
  watchHasUploadError: WatchFunctionType<boolean>;
  toggleOrientationFilter: (orientation: ParkingOrientation) => void;
  toggleRegimeFilter: (regime: ParkingSpaceRegime) => void;
  watchRegimesFilter: WatchFunctionType<Set<ParkingSpaceRegime>>;
  watchOrientationsFilter: WatchFunctionType<Set<ParkingOrientation>>;
}

const ROOT_API = '/api/proxy/geocoder/api/cities/{cityId}/parkingSpace';

const ParkingSpaceApiService: ParkingSpaceServiceApiFactory = () => {
  const { setValue: setIsUploading, watchValue: watchIsUploading } = Watcher(
    false
  );
  const {
    setValue: setHasUploadError,
    watchValue: watchHasUploadError,
  } = Watcher(false);
  const {
    setValue: setRegimesFilter,
    watchValue: watchRegimesFilter,
    getValue: getRegimesFilter,
  } = Watcher<Set<ParkingSpaceRegime>>(new Set());
  const {
    setValue: setOrientationsFilter,
    watchValue: watchOrientationsFilter,
    getValue: getOrientationsFilter,
  } = Watcher<Set<ParkingOrientation>>(new Set());

  const autocompleteParkingSpaceId: ParkingSpaceServiceApiInterface['autocompleteParkingSpaceId'] = search => {
    return apiGet<EsCompletionSuggestionResponse>(
      `${ROOT_API}/autocomplete?search=${search}`
    );
  };

  const fetchOneById: ParkingSpaceServiceApiInterface['fetchOneById'] = parkingSpaceId => {
    return apiGet<EsCityParkingSpaceDTO>(`${ROOT_API}/${parkingSpaceId}`);
  };

  const fetchInsideBoundingBox: ParkingSpaceServiceApiInterface['fetchInsideBoundingBox'] = boundingBox => {
    const params = new URLSearchParams({
      top: boundingBox[0].toString(),
      left: boundingBox[1].toString(),
      bottom: boundingBox[2].toString(),
      right: boundingBox[3].toString(),
      regimes: Array.from(getRegimesFilter()).join(','),
      orientations: Array.from(getOrientationsFilter()).join(','),
    });
    return apiGet<EsCityParkingSpaceDTO[]>(
      `${ROOT_API}/insideBoundingBox?${params}`
    );
  };

  const addParkingSpace: ParkingSpaceServiceApiInterface['addParkingSpace'] = request => {
    request.geometry.coordinates.forEach(coordinate => {
      const firstCoords = coordinate[0];
      const lastCoords = coordinate[coordinate.length - 1];
      if (!isEqual(firstCoords, lastCoords)) {
        coordinate.push(firstCoords);
      }
    });
    return apiPost<void>(`${ROOT_API}/${request.parkingSpaceId}`, request);
  };

  const validateVersions: ParkingSpaceServiceApiInterface['validateVersions'] = request => {
    return apiPost<void>(`${ROOT_API}/validate`, request);
  };

  const updateParkingSpace: ParkingSpaceServiceApiInterface['updateParkingSpace'] = request => {
    const coordinates = request.geometry.coordinates[0];
    const firstCoords = coordinates[0];
    const lastCoords = coordinates[coordinates.length - 1];
    if (!isEqual(firstCoords, lastCoords)) {
      coordinates.push(firstCoords);
    }
    [request.latLong.longitude, request.latLong.latitude] = firstCoords;
    return apiPut<EsCityParkingSpaceDTO>(
      `${ROOT_API}/${request.parkingSpaceId}`,
      request
    );
  };

  const deleteOneParkingSpace: ParkingSpaceServiceApiInterface['deleteOneParkingSpace'] = (
    parkingSpaceId: EsCityParkingSpaceDTO['parkingSpaceId']
  ) => {
    return apiDelete<void>(`${ROOT_API}/${parkingSpaceId}`);
  };

  const importGson: ParkingSpaceServiceApiInterface['importGson'] = async file => {
    try {
      setIsUploading(true);
      setHasUploadError(false);
      const res = await apiPostFile(`${ROOT_API}/import`, file);
      if (res.status !== 200) {
        setHasUploadError(true);
        console.error(res.statusText);
      }
    } catch (e) {
      console.error(e);
      setHasUploadError(true);
    } finally {
      setIsUploading(false);
    }
  };

  const exportGson: ParkingSpaceServiceApiInterface['exportGson'] = () => {
    const filename = `export-emprises-${
      new Date().toISOString().split('T')[0]
    }.geojson`;
    return downloadFileFromUrl(`${ROOT_API}/export`, filename);
  };

  const toggleRegimeFilter: ParkingSpaceServiceApiInterface['toggleRegimeFilter'] = regime => {
    const originalSet = getRegimesFilter();
    const newSet = new Set([...originalSet]);
    if (newSet.has(regime)) {
      newSet.delete(regime);
    } else {
      newSet.add(regime);
    }
    setRegimesFilter(newSet);
  };

  const recomputeZones: ParkingSpaceServiceApiInterface['recomputeZones'] = (
    cityId: string
  ) => {
    return apiGet<void>(`${ROOT_API}/updateZones/${cityId}`);
  };

  const recomputeAddresses: ParkingSpaceServiceApiInterface['recomputeAddresses'] = (
    cityId: string
  ) => {
    return apiGet<void>(`${ROOT_API}/recomputeAddresses/${cityId}`);
  };

  const toggleOrientationFilter: ParkingSpaceServiceApiInterface['toggleOrientationFilter'] = orientation => {
    const originalSet = getOrientationsFilter();
    const newSet = new Set([...originalSet]);
    if (newSet.has(orientation)) {
      newSet.delete(orientation);
    } else {
      newSet.add(orientation);
    }
    setOrientationsFilter(newSet);
  };

  return {
    addParkingSpace,
    autocompleteParkingSpaceId,
    deleteOneParkingSpace,
    exportGson,
    fetchInsideBoundingBox,
    fetchOneById,
    importGson,
    recomputeZones,
    recomputeAddresses,
    toggleOrientationFilter,
    toggleRegimeFilter,
    updateParkingSpace,
    validateVersions,
    watchHasUploadError,
    watchIsUploading,
    watchOrientationsFilter,
    watchRegimesFilter,
  };
};

export default ParkingSpaceApiService;
