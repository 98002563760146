import React from 'react';
import HardwareKeyboardArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import HardwareKeyboardArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';

type Props = {
  colNumber: number;
  onSort: Function | null | undefined;
  style?: any;
  sorted: boolean;
  sortOrder: boolean | null | undefined;
  sortId?: string;
};

class Sort extends React.Component<Props> {
  updateFilter = () => {
    const { onSort } = this.props;
    if (onSort) {
      onSort(this.props.colNumber, !this.props.sortOrder, this.props.sortId);
    }
  };

  render() {
    const { colNumber, style, sortOrder, sorted } = this.props;
    const ArrowSort =
      sorted && sortOrder ? HardwareKeyboardArrowUp : HardwareKeyboardArrowDown;
    return (
      <ArrowSort
        data-col-number={colNumber}
        onClick={this.updateFilter}
        style={style}
      />
    );
  }
}

export default Sort;
