import React, { CSSProperties } from 'react';
import IconButton from 'material-ui/IconButton';
import PDFIcon from 'material-ui/svg-icons/image/picture-as-pdf';

import { ICON_RED } from 'theme';

const { _t } = window.loadTranslations(__filename);

const STYLE_ICON_BUTTON: CSSProperties = {
  height: 36,
  width: 36,
};

const STYLE_BUTTON: CSSProperties = {
  width: 72,
  height: 72,
  padding: 16,
};

type Props = {
  openPdfUrl: () => void;
};

const PdfButton = ({ openPdfUrl }: Props) => {
  return (
    <IconButton
      tooltip={_t('element.button.tooltip')}
      tooltipPosition="bottom-left"
      iconStyle={STYLE_ICON_BUTTON}
      style={STYLE_BUTTON}
      onClick={openPdfUrl}
      target="_blank"
    >
      <PDFIcon color={ICON_RED} />
    </IconButton>
  );
};

export default PdfButton;
