import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';

export type FormatOptions = 'datetime' | 'date';
type FormatLocales = 'fr-FR' | 'en-US';
const defaultLocale: FormatLocales = 'fr-FR';

const localeMap = {
  'en-US': enLocale,
  fr: frLocale,
};

const momentFormats: {
  [locale in FormatLocales]: { [format in FormatOptions]: string };
} = {
  'fr-FR': {
    datetime: 'd/MM/yyyy [-] HH:mm',
    date: 'd/MM/yyyy',
  },
  'en-US': {
    datetime: 'MM/dd/yyyy [-] HH:mm',
    date: 'MM/dd/yyyy',
  },
};

export const getLocale = () => localeMap[window.i18next.language];

/**
 * returns the format for datefns corresponding to the formatOption and the current language
 */
export const getDateFormat = (formatOption: FormatOptions): string => {
  return (momentFormats[window.i18next.language] ||
    momentFormats[defaultLocale])[formatOption];
};
