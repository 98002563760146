import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { FpsServiceInterface } from 'commons/services/FpsService';
import {
  computeAmountToPay,
  PAYMENT_ORIGIN_LABEL,
  PaymentDTO,
} from 'tefps/Fps/Detail/helpers';
import { FpsCaseDTO } from 'api/fps/types';
import { Payment } from 'api/commonTypes';
import { translatePaymentMode } from 'commons/Utils/paymentUtil';

export interface FpsPaymentsServiceFactory {
  (fpsService: FpsServiceInterface): FpsPaymentsServiceInterface;
}

export interface FpsPaymentsServiceInterface {
  init: () => void;
  getFpsPayments: () => PaymentDTO[];
  watchFpsPayments: WatchFunctionType<PaymentDTO[]>;
}

const FpsPaymentsService: FpsPaymentsServiceFactory = fpsService => {
  const {
    getValue: getFpsPayments,
    setValue: setFpsPayments,
    watchValue: watchFpsPayments,
  } = Watcher<PaymentDTO[]>([]);

  const buildPayment = (fps: FpsCaseDTO, payment: Payment): PaymentDTO => {
    return {
      datetime: payment.paymentDatetime,
      receptionDatetime: payment.receptionDatetime,
      amount: payment.paymentAmount,
      amountToPay: computeAmountToPay(fps, payment.paymentDatetime),
      origin: PAYMENT_ORIGIN_LABEL[payment.paymentChannel],
      mode: translatePaymentMode()[payment.paymentMode],
      agent: payment.agent || undefined,
    };
  };

  const init = (): void => {
    const latestFps = fpsService.getFps();
    if (latestFps) {
      const payments: PaymentDTO[] = [];
      latestFps.versions.forEach(version => {
        version.payments.forEach(payment => {
          payments.push(buildPayment(latestFps, payment));
        });
      });
      setFpsPayments(payments);
    }
  };

  return {
    init,
    getFpsPayments,
    watchFpsPayments,
  };
};

export default FpsPaymentsService;
