import services from 'commons/services';

import useWatcher from './useWatcher';

const useFilesComment = (): [string, (arg0: string) => void] => {
  return [
    useWatcher(services.evidenceFiles.watchFilesComment, ''),
    services.evidenceFiles.setFilesComment,
  ];
};

export default useFilesComment;
