import * as React from 'react';
import { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';

import attachToForm from './FormComponent/attachToForm';

import TextFieldProps = __MaterialUI.TextFieldProps;

type NumberFieldState = {
  valueStr: string;
};

type NumberFieldProps =
  | {
      value: number | null | undefined;
      min?: number;
      max?: number;
      onChange: (newValue: number | null | undefined) => any;
      style?: CSSProperties;
      name?: string;
      disabled?: boolean;
    }
  | TextFieldProps;

const toStringOrEmpty = (value: number | null | undefined) =>
  typeof value === 'number' ? value.toString() : '';

class NumberField extends React.Component<NumberFieldProps, NumberFieldState> {
  constructor(props: NumberFieldProps) {
    super(props);

    this.state = {
      valueStr: toStringOrEmpty(props.value as number | null | undefined),
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: NumberFieldProps) {
    if (newProps.value !== parseFloat(this.state.valueStr)) {
      this.setState({
        valueStr: toStringOrEmpty(newProps.value as number | null | undefined),
      });
    }
  }

  handleChange = (e: any, newValue: string) => {
    const { onChange, value, max, min } = this.props;

    if (newValue === '') {
      // eslint-disable-next-line
      // @ts-ignore
      onChange(null);
      this.setState({ valueStr: '' });
      return;
    }

    const valueInt = Number(newValue);

    if (isNaN(valueInt) || !/^[0-9]+(\.([0-9]{1,2})?)?$/.test(newValue)) {
      return;
    }

    if (
      (typeof max === 'number' && valueInt > max) ||
      (typeof min === 'number' && valueInt < min)
    ) {
      // eslint-disable-next-line
      // @ts-ignore
      onChange(value);
      // eslint-disable-next-line
      // @ts-ignore
      this.setState({ valueStr: toStringOrEmpty(value) });
      return;
    }

    // eslint-disable-next-line
    // @ts-ignore
    onChange(valueInt);
    this.setState({ valueStr: newValue });
  };

  render() {
    const { valueStr } = this.state;

    return (
      <TextField
        {...this.props}
        value={valueStr}
        onChange={this.handleChange}
      />
    );
  }
}
export default NumberField;
