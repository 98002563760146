import { PaymentChannel, PaymentMode, PaymentOrigin } from 'api/commonTypes';

const { _t, _tg } = window.loadTranslations(__filename);

export function convertToCents(value: string | number): number {
  return Math.round(Number(value) * 100);
}

/**
 * We use en-EN because the result of this function is used by material TextFields
 * which can't compute numbers with commas instead of dots
 */
export function formatCentsToEuro(value: number): number {
  return value / 100;
}

export function formatCentsToCurrency(price: number, currency: string): string {
  const formatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency,
  };
  return (price / 100.0).toLocaleString(undefined, formatOptions);
}

/**
 * Returns a positive price (greater than min with a maximum 2 digits for the decimal part
 */
export const positivePrice = (price?: string, min = 0): number => {
  const numberPrice = Math.abs(Number(price));
  return isNaN(numberPrice)
    ? min
    : Math.max(min, Math.round(numberPrice * 100) / 100);
};

/**
 * Returns a nullable price with max two decimals. If the input is not a number, the previous value is kept
 */
export const twoDecimalPrice = (
  previousPrice?: number,
  price?: string
): number | undefined => {
  if (price === undefined || price == null || !price.length) {
    return NaN;
  }
  const numberPrice = Number(price);
  return isNaN(numberPrice)
    ? previousPrice
    : Math.round(numberPrice * 100) / 100;
};

type PaymentModeKey = string | PaymentMode;
export const translatePaymentMode = (): { [key in PaymentModeKey]: string } => {
  return {
    CARD: _t('paymentMode.CARD'),
    CARD_CONTACTLESS: _t('paymentMode.CARD_CONTACTLESS'),
    CHECK: _t('paymentMode.CHECK'),
    CASH: _t('paymentMode.CASH'),
    ANTAI: _t('paymentMode.ANTAI'),
    TRANSFERT: _t('paymentMode.TRANSFERT'),
    RECURRING_DEBIT: _t('paymentMode.RECURRING_DEBIT'),
    DIRECT_DEBIT: _t('paymentMode.DIRECT_DEBIT'),
  };
};

export const translatePaymentChannel = (): {
  [key in PaymentChannel]: string;
} => {
  return {
    INTERNET: _tg('field.payment.origins.INTERNET'),
    DESK: _tg('field.payment.origins.DESK'),
    MAIL: _tg('field.payment.origins.MAIL'),
    DGFIP: '',
    PARKMETER: '',
    MOBILE: '',
    VP: '',
    ANTAI: _t('paymentOriginAntai.ANTAI'),
    COMMUNITY: _t('refundOrigin.COMMUNITY'),
  };
};

export const translatePaymentOrigin = (): Array<PaymentOrigin> => [
  {
    key: 'INTERNET',
    label: _tg('field.payment.origins.INTERNET'),
    paymentModes: ['TRANSFERT'],
  },
  {
    key: 'DESK',
    label: _tg('field.payment.origins.DESK'),
    paymentModes: ['CARD', 'CHECK', 'CASH', 'TRANSFERT'],
  },
  {
    key: 'MAIL',
    label: _tg('field.payment.origins.MAIL'),
    paymentModes: ['CHECK', 'CASH', 'TRANSFERT'],
  },
];

export const translateRefundOrigin = (): Array<PaymentOrigin> => [
  ...translatePaymentOrigin(),
  {
    key: 'COMMUNITY',
    label: _t('refundOrigin.COMMUNITY'),
    paymentModes: ['TRANSFERT', 'CHECK', 'CASH'],
  },
];

export const translatePaymentOriginAntai = (): Array<PaymentOrigin> => [
  {
    key: 'DGFIP',
    label: _t('paymentOriginAntai.ANTAI'),
    paymentModes: ['TRANSFERT', 'CARD', 'CHECK', 'CASH'],
  },
];
