export const Base64 = {
  decode: (str: string) => {
    // To handle UTF-8, need to escape
    return decodeURIComponent(escape(window.atob(str)));
  },
  encode: (str: string) => {
    // To handle UTF-8, need to unescape
    return window.btoa(unescape(encodeURIComponent(str)));
  },
};
