import { cloneDeep } from 'lodash';

import { EsCityParkingSpaceDTO } from '@cvfm-front/tefps-types';

export interface ParkingSpaceModificationServiceFactory {
  (): ParkingSpaceModificationServiceInterface;
}

export interface ParkingSpaceModificationServiceInterface {
  addVersion: (parkingSpace: EsCityParkingSpaceDTO) => EsCityParkingSpaceDTO;
}

const ParkingSpaceModificationService: ParkingSpaceModificationServiceFactory = () => {
  return {
    addVersion: (parkingSpace: EsCityParkingSpaceDTO) => {
      const newParkingSpace = cloneDeep(parkingSpace);
      const numberOfVersion = newParkingSpace.versions.length;
      const lastVersion = newParkingSpace.versions[numberOfVersion - 1];
      const newVersion = cloneDeep(lastVersion);
      newParkingSpace.versions.push(newVersion);
      return newParkingSpace;
    },
  };
};

export default ParkingSpaceModificationService;
