import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { CoreAddress, ScoredZone, ZoningDTO } from '@cvfm-front/tefps-types';
import { fetchZoning } from 'api/pricing';
import { FETCH_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { apiGet } from 'api/helpers';

import { DrawingType, MapId, PolygonWrapper } from './MapService';

import services from '.';

export interface ZoningServiceFactory {
  (): ZoningServiceInterface;
}

export interface ZoningServiceInterface {
  init: () => Promise<void>;
  getZoning: () => ZoningDTO | null;
  watchZoning: WatchFunctionType<ZoningDTO | null>;
  applyZoningChanges: () => void;
  getZoneNameFromId: (zoneId: string) => string;
  drawZonesOnMap: (mapId: MapId) => void;
  clearZonesOnMap: (mapId: MapId) => void;
  findZone: (lat: number, lon: number) => Promise<ScoredZone[]>;
  getZoneByAddress: (
    addr: CoreAddress | null | undefined
  ) => Promise<string | null>;
}

const ZoningService: ZoningServiceFactory = () => {
  const {
    getValue: getZoning,
    setValue: setZoning,
    watchValue: watchZoning,
  } = Watcher<ZoningDTO | null>(null);

  const init = async (): Promise<void> => {
    const zoning = await fetchZoning(FETCH_ZONING_CONFIG);
    setZoning(zoning);
  };

  const applyZoningChanges = (): void => {
    const zoningDetails = getZoning();
    if (zoningDetails) {
      setZoning(zoningDetails);
    }
  };

  const getZoneNameFromId = (zoneId: string): string => {
    const zoning = getZoning();
    return zoning?.zones.find(z => z.id === zoneId)?.name || zoneId;
  };

  const drawZonesOnMapHelper = (mapId: MapId) => {
    // here zoning should not be null
    const zoning = getZoning();
    if (zoning) {
      const polygonColor = new Map<string, string>();
      zoning?.zones.forEach(zone => {
        zone.polygonIds.forEach(zonePolygon => {
          polygonColor.set(zonePolygon, zone.color);
        });
      });
      const zoningsPolygons: Array<PolygonWrapper> = zoning
        ? zoning.polygons.map(polygon => {
            return {
              id: polygon.id,
              type: DrawingType.ZONE,
              name: polygon.name,
              points: polygon.points.map(pointDTO => {
                return {
                  latitude: pointDTO.lat,
                  longitude: pointDTO.lon,
                };
              }),
              color: polygonColor.get(polygon.id) ?? '#ff0000',
              zIndex: 1,
              visible: true,
              editable: false,
              canBeEdited: false,
            };
          })
        : [];
      services.mapService.get(mapId)?.clear?.drawingType(DrawingType.ZONE);
      services.mapService.get(mapId)?.polygons?.add(zoningsPolygons);
    }
  };

  const drawZonesOnMap = (mapId: MapId) => {
    if (getZoning() === null) {
      void init().then(() => drawZonesOnMapHelper(mapId));
    } else {
      drawZonesOnMapHelper(mapId);
    }
  };

  const clearZonesOnMap = (mapId: MapId) => {
    services.mapService.get(mapId)?.clear?.drawingType(DrawingType.ZONE);
  };

  const getZoneByAddress = async (
    addr: CoreAddress | null
  ): Promise<string | null> => {
    if (!addr) {
      return null;
    }
    const location = await services.geocodingApi.geocode(addr);
    const zones = await services.zoning.findZone(
      location.latitude,
      location.longitude
    );
    return zones[0]?.zoneId ?? null;
  };

  return {
    init,
    getZoning,
    watchZoning,
    applyZoningChanges,
    getZoneNameFromId,
    drawZonesOnMap,
    clearZonesOnMap,
    findZone: (lat, lon) => {
      return apiGet<ScoredZone[]>(
        `/api/proxy/pricing/api/v0/cities/{cityId}/zonings?lat=${lat}&lon=${lon}&withPolygons=true`
      );
    },
    getZoneByAddress,
  };
};

export default ZoningService;
