import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';

import FlexCenter from 'commons/FlexCenter';
import { Config, fetchConfig, getConfigState } from 'config/duck';

type Props = {
  children: any;
};

type ReduxStateProps = {
  config: Config | null | undefined;
};

type ReduxDispatchProps = {
  loadConfig: () => void;
};

const STYLE: CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
};

class ConfigLoader extends React.Component<
  Props & ReduxStateProps & ReduxDispatchProps
> {
  componentDidMount() {
    // Load config
    if (!this.props.config) {
      this.loadConfig();
    }
  }

  loadConfig = () => {
    const { config, loadConfig } = this.props;
    if (!config) {
      loadConfig();
    }
  };

  render() {
    const { config, children } = this.props;

    if (!config?.tReady) {
      return (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      );
    }

    return <div style={STYLE}>{children}</div>;
  }
}

export default connect(
  (state): ReduxStateProps => {
    const config = getConfigState(state);
    return {
      config,
    };
  },
  (dispatch): ReduxDispatchProps => ({
    loadConfig: () => dispatch(fetchConfig()),
  })
)(ConfigLoader);
