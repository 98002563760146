import React, { CSSProperties } from 'react';

import SimpleDateFilter from 'commons/SimpleDateFilter';
import { BKG_GREYBLUE, STYLE_TITLE_BORDER } from 'theme';

import { STYLE_BLOCK_TITLE } from '../theme';

require('intl/locale-data/jsonp/fr');

const STYLE_BLOCK: CSSProperties = {
  marginTop: 20,
};

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  marginRight: 10,
};

const STYLE_INPUT: CSSProperties = {
  ...STYLE_TEXT,
  color: 'white',
  textAlign: 'center',
  marginRight: 0,
};

const STYLE_TEXT_FIELD: CSSProperties = {
  width: '100%',
  height: 27,
  backgroundColor: BKG_GREYBLUE,
};

type Props = {
  id: string;
  title: string;
  onChange: (id: string, date: Date | null | undefined) => void;
  date: Date | undefined;
};

class SimpleDate extends React.Component<Props> {
  onChange = (date: Date | null | undefined) => {
    const { id, onChange } = this.props;
    onChange(id, date);
  };

  render() {
    const { title, date } = this.props;

    return (
      <div style={STYLE_BLOCK}>
        <div style={STYLE_BLOCK_TITLE}>
          {title}
          <hr style={STYLE_TITLE_BORDER} />
        </div>
        <SimpleDateFilter
          styleInput={STYLE_INPUT}
          styleTextField={STYLE_TEXT_FIELD}
          colorResetButton="white"
          date={date}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default SimpleDate;
