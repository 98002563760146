import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import Snackbar from 'material-ui/Snackbar';

import BoButton from 'facade/BoButton';
import { BKG_LIGHT_BLUE, STYLE_ERROR_WRAPPER } from 'theme';
import ErrorBlock from 'commons/ErrorBlock';
import { validateMandatoryInput } from 'commons/Validators';
import { ZoningDTO } from '@cvfm-front/tefps-types';

import { PolygonMetadata, ZoningNames } from './types';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: 'white',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  width: '100%',
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_LIGHT_BLUE,
  color: BKG_LIGHT_BLUE,
};

type AddPolygonProps = {
  isOpen: number; // vaut 1 si création, 2 si édition
  zoning: ZoningDTO;
  message: string | null | undefined; // eslint-disable-line react/no-unused-prop-types
  selectedPolygonId: string | null | undefined; // eslint-disable-line react/no-unused-prop-types
  save: (o: Record<string, any>) => any;
  edit: (o: Record<string, any>) => any;
  close: () => any;
  naming: ZoningNames;
};

type AddPolygonState = {
  error: string | null | undefined;
  errors: {
    [key: string]: string | null | undefined;
  };
  existingPolygon: PolygonMetadata | null | undefined;
  id: string;
  name: string;
  zoneIds: Array<string>;
  message: string | null | undefined;
};

const INITIAL_STATE = {
  message: null,
  error: null,
  existingPolygon: null,
  name: '',
  zoneIds: [],
  errors: {},
};

class AddPolygon extends React.Component<AddPolygonProps, AddPolygonState> {
  state: AddPolygonState = { ...INITIAL_STATE, id: Date.now().toString() };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: AddPolygonProps) {
    const { zoning, selectedPolygonId, isOpen, message } = newProps;
    const existingPolygon =
      isOpen === 2 && selectedPolygonId
        ? zoning.polygons.filter(p => p.id === selectedPolygonId)[0]
        : {
            id: Date.now().toString(),
            name: '',
            zoneIds: [],
          };
    let zoneIds: Array<string> = [];
    if (isOpen === 2 && selectedPolygonId) {
      zoneIds = zoning.zones
        .filter(z => z.polygonIds.includes(selectedPolygonId))
        .map(zo => zo.id);
    }
    this.setState({
      error: null,
      errors: {},
      id: existingPolygon.id,
      name: existingPolygon.name,
      zoneIds,
      message,
    });
  }

  onClose = () => {
    this.props.close();
    this.setState({ ...INITIAL_STATE });
  };

  onClickValidate = () => {
    const { save, edit, isOpen } = this.props;
    const { id, name, zoneIds } = this.state;

    const body = {
      name,
      zoneIds,
      id,
    };
    const errors = {
      id: validateMandatoryInput(id),
      name: validateMandatoryInput(name),
      zoneIds:
        zoneIds.length === 0 ? _tg('feedback.error.mandatoryField') : undefined,
    };

    if (!Object.values(errors).some(v => !!v)) {
      try {
        if (isOpen === 1) save(body);
        else edit(body);
        this.setState({ error: null, errors: {}, zoneIds: [] });
      } catch (error) {
        this.setState({ error: _tg('feedback.error.generic') });
      }
    }
    this.setState({ errors });
  };

  changeName = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    this.setState({ id });
  };

  changeId = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    this.setState({ name });
  };

  handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    zoneIds: Array<string>
  ) => this.setState({ zoneIds });

  render() {
    const { isOpen, zoning, naming } = this.props;
    const { error, name, id, zoneIds, message } = this.state;

    const actions = [
      <BoButton
        style={{ marginRight: 10 }}
        key={1}
        label={_tg('action.cancel')}
        onClick={this.onClose}
      />,
      <BoButton
        key={2}
        label={isOpen === 1 ? _tg('action.create') : _tg('action.edit')}
        primary
        keyboardFocused
        onClick={this.onClickValidate}
      />,
    ];

    return (
      <span>
        <Dialog
          actions={actions}
          title={
            isOpen === 1
              ? _t('element.dialog.title.open')
              : _t('element.dialog.title.notOpen')
          }
          open={!!isOpen}
          onRequestClose={this.onClose}
          titleStyle={STYLE_TITLE}
        >
          <div style={STYLE_CONTENT_WRAPPER}>
            <TextField
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              value={id}
              onChange={this.changeName}
              disabled={isOpen === 2}
              floatingLabelText={_t('element.id.floatingLabelText')}
              errorText={this.state.errors.id}
            />
            <TextField
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              value={name}
              onChange={this.changeId}
              floatingLabelText={_t('element.name.floatingLabelText')}
              errorText={this.state.errors.name}
            />
          </div>
          <SelectField
            value={zoneIds}
            onChange={this.handleChange}
            errorText={this.state.errors.zoneIds}
            style={{ width: '100%' }}
            multiple
            floatingLabelText={_t('element.selectZones.floatingLabelText', {
              name: naming.name,
            })}
          >
            {zoning.zones.map(zone => (
              <MenuItem
                key={zone.id}
                value={zone.id}
                primaryText={zone.name}
                checked={zoneIds && zoneIds.includes(zone.id)}
                insetChildren
              />
            ))}
          </SelectField>
          <br />
          <br />
          {isOpen === 1 && _t('element.polygonCreationInstruction')}
          {error && (
            <div style={STYLE_ERROR_WRAPPER}>
              <ErrorBlock error={{ message: error }} />
            </div>
          )}
        </Dialog>
        <Snackbar
          open={!!message}
          message={message || ''}
          autoHideDuration={4000}
          onRequestClose={this.onClose}
        />
      </span>
    );
  }
}

export default AddPolygon;
