import { CoreAddress, FnmsLocation } from '@cvfm-front/tefps-types';
import { apiPost } from 'api/helpers';
import { LocalizedAddressDTO } from 'api/searchingCompletion/types';

export interface GeocodingApiServiceFactory {
  (): GeocodingApiServiceInterface;
}

export interface GeocodingApiServiceInterface {
  geocode: (coreAddress: CoreAddress) => Promise<FnmsLocation>;
  reverseGeocode: (
    fnmsLocation: FnmsLocation,
    cityId: string
  ) => Promise<LocalizedAddressDTO>;
}

const GeocodingApiService: GeocodingApiServiceFactory = () => {
  return {
    geocode: address =>
      apiPost<FnmsLocation>('/api/proxy/geocoder/api/v0/geocoding', address),
    reverseGeocode: (fnmsLocation, cityId) =>
      apiPost<LocalizedAddressDTO>(
        `/api/proxy/geocoder/api/v0/cities/${cityId}/geocoding/reverse`,
        fnmsLocation
      ),
  };
};

export default GeocodingApiService;
