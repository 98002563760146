/**
 * Simple button component to avoid Material UI library
 */
import React from 'react';

import { BKG_PINK, BKG_CYAN } from 'theme';

type SimpleButtonProps = {
  id?: string;
  className?: string;
  title: string;
  onClick?: React.MouseEventHandler<{}>;
  secondary?: boolean;
};

const SimpleButton = ({
  id,
  className,
  title,
  onClick,
  secondary,
}: SimpleButtonProps): JSX.Element => {
  const BUTTON_STYLE = {
    border: '10px none',
    display: 'inline-block',
    height: '36px',
    lineHeight: '36px',
    marginLeft: '10px',
    marginRight: '10px',
    cursor: 'pointer',
    borderRadius: '2px',
    backgroundColor: secondary ? BKG_PINK : BKG_CYAN,
    textAlign: 'center',
  } as React.CSSProperties;

  const BUTTON_LABEL = {
    fontSize: '14px',
    letterSpacing: '0px',
    textTransform: 'uppercase',
    fontWeight: 500,
    margin: '0px',
    paddingLeft: '16px',
    paddingRight: '16px',
    color: 'rgb(255, 255, 255)',
  } as React.CSSProperties;

  return (
    <button
      id={id}
      className={className}
      type="button"
      aria-label={title}
      title={title}
      onClick={onClick}
      style={BUTTON_STYLE}
    >
      <span style={BUTTON_LABEL}>{title}</span>
    </button>
  );
};

export default SimpleButton;
