import services from 'commons/services';
import { RapoChoiceReasonList } from 'api/rapo-choice-reason/types';

import useWatcher from './useWatcher';

const useRecourseReasons = (): RapoChoiceReasonList | null => {
  return useWatcher(
    services.recourseReasons.watchRecourseReasons,
    services.recourseReasons.getRecourseReasons()
  );
};

export default useRecourseReasons;
