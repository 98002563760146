import { WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import {
  ProductPrivateDTO,
  ProductSearchQueryDTO,
} from '@cvfm-front/tefps-types';
import { searchProducts } from 'api/cvfm-core-subscription/product';

export interface ProductServiceFactory {
  (): ProductServiceInterface;
}

export interface ProductServiceInterface {
  init: (filters: ProductSearchQueryDTO) => Promise<void>;
  getProducts: () => Array<ProductPrivateDTO> | null;
  watchProducts: WatchFunctionType<Array<ProductPrivateDTO> | null>;
  applyProductsChanges: (products: Array<ProductPrivateDTO>) => void;
}

const ProductService: ProductServiceFactory = () => {
  const {
    getValue: getProducts,
    setValue: setProducts,
    watchValue: watchProducts,
  } = Watcher<Array<ProductPrivateDTO> | null>(null);

  const init = async (filters: ProductSearchQueryDTO): Promise<void> => {
    const newProducts = await searchProducts(filters);
    setProducts(newProducts);
  };

  const applyProductsChanges = (products: Array<ProductPrivateDTO>): void => {
    setProducts(products);
  };

  return {
    init,
    getProducts,
    watchProducts,
    applyProductsChanges,
  };
};

export default ProductService;
