import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import MultiIcon from 'material-ui/svg-icons/action/view-headline';

import BoButton from 'facade/BoButton';
import { BKG_GREYBLUE } from 'theme';

import { STYLE_BLOCK_TITLE } from '../theme';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_BLOCK: CSSProperties = {
  marginTop: 20,
};

const STYLE_INPUT_WRAPPER: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  color: 'white',
  fontFamily: 'Roboto',
  marginLeft: 5,
};

const STYLE_FIELD: CSSProperties = {
  backgroundColor: BKG_GREYBLUE,
  height: 27,
  lineHeight: '22px',
  marginTop: 5,
  paddingRight: 10,
  width: '100%',
  flex: '1',
};

const STYLE_BUTTON: CSSProperties = {
  width: 40,
  height: 40,
  padding: 0,
  marginTop: 5,
};

type MultiSearchProps = {
  id: string;
  title: string;
  placeholder: string;
  modalTitle: string;
  modalFloatingLabelText: string;
  value: Set<string>;
  onChange: (id: string, value: Set<string>) => void;
};

type MultiSearchState = {
  open: boolean;
  modalFieldValue: string;
  fieldValue: string;
};

const computeFieldValue = (value: Set<string>) => {
  if (value.size === 0) {
    return '';
  }
  if (value.size > 1) {
    return _t('element.multisearch.text');
  }
  return Array.from(value)[0];
};

class MultiSearch extends React.Component<MultiSearchProps, MultiSearchState> {
  constructor(props: MultiSearchProps) {
    super(props);

    this.state = {
      open: false,
      modalFieldValue: '',
      fieldValue: computeFieldValue(props.value),
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: MultiSearchProps) {
    const { value } = newProps;

    this.setState({ fieldValue: computeFieldValue(value) });
  }

  openModal = () =>
    this.setState({
      open: true,
      modalFieldValue: Array.from(this.props.value).join('\n'),
    });

  closeModal = () => this.setState({ open: false });

  handleValidate = () => {
    const { id, onChange } = this.props;
    const { modalFieldValue } = this.state;
    onChange(id, new Set(modalFieldValue.split('\n')));
    this.closeModal();
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const { id, onChange } = this.props;
    const trimmedValue = value.trim();
    if (trimmedValue.split(' ').length > 1) {
      this.setState({
        // Replace all white spaces with line breaks (how we split in handleValidate)
        modalFieldValue: trimmedValue.replace(/\s/g, '\n'),
        open: true,
      });
    } else {
      onChange(id, new Set([trimmedValue]));
    }
  };

  handleChangeModal = (
    e: React.ChangeEvent<HTMLInputElement>,
    modalFieldValue: string
  ) => this.setState({ modalFieldValue });

  handlePaste = (e: React.ClipboardEvent<any>) => {
    const modalFieldValue = e.clipboardData.getData('text');
    if (modalFieldValue.trim().split('\n').length > 1) {
      this.setState({ modalFieldValue, open: true });
      e.preventDefault();
    }
  };

  handleFocus = () => {
    const { fieldValue } = this.state;
    if (fieldValue === _t('element.multisearch.text')) {
      this.setState({ fieldValue: '' });
    }
  };

  render() {
    const {
      id,
      title,
      placeholder,
      modalTitle,
      modalFloatingLabelText,
    } = this.props;
    const { open, modalFieldValue, fieldValue } = this.state;
    const actions = [
      <BoButton label={_tg('action.back')} onClick={this.closeModal} />,
      <BoButton
        label={_tg('action.validate')}
        onClick={this.handleValidate}
        style={{ marginLeft: 10 }}
        primary
      />,
    ];

    return (
      <div style={STYLE_BLOCK}>
        <div style={STYLE_BLOCK_TITLE}>{title}</div>
        <div style={STYLE_INPUT_WRAPPER}>
          <TextField
            name={placeholder}
            underlineShow={false}
            inputStyle={STYLE_TEXT}
            value={fieldValue}
            onChange={this.handleChange}
            style={STYLE_FIELD}
            onFocus={this.handleFocus}
          />
          <IconButton
            tooltip={_t('element.multisearch.tooltip')}
            onClick={this.openModal}
            style={STYLE_BUTTON}
            iconStyle={{ width: 40, height: 40 }}
          >
            <MultiIcon color="white" />
          </IconButton>
        </div>
        <Dialog
          title={modalTitle}
          open={open}
          actions={actions}
          onRequestClose={this.closeModal}
        >
          <TextField
            name={`${id}/modal`}
            floatingLabelText={modalFloatingLabelText}
            multiLine
            rows={5}
            rowsMax={15}
            value={modalFieldValue}
            onChange={this.handleChangeModal}
            style={{ width: '100%' }}
          />
        </Dialog>
      </div>
    );
  }
}

export default MultiSearch;
