import services from 'commons/services';
import { RecourseProposalWrapperDTO } from 'api/recourse/types';

import useWatcher from './useWatcher';

const useCurrentProposal = (): RecourseProposalWrapperDTO | null => {
  return useWatcher(services.proposal.watchCurrentProposal, null);
};

export default useCurrentProposal;
