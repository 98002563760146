import services from 'commons/services';
import { MailDTO } from 'api/mail/type';

import useWatcher from './useWatcher';

type OptionalMailTemplate = MailDTO | null;

const useMailTemplate = (): [OptionalMailTemplate, (arg0: MailDTO) => void] => {
  return [
    useWatcher(services.mailTemplate.watchMailTemplate, null),
    services.mailTemplate.setMailTemplate,
  ];
};

export default useMailTemplate;
