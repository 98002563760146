import React, { CSSProperties, useState } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import SuccessIcon from 'material-ui/svg-icons/navigation/check';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

import { BKG_GREEN } from 'theme';
import CsvImportDropZone from 'commons/CsvImportDropZone';
import { importParkingMeter } from 'api/parkingMeter';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_LOADING: CSSProperties = {
  height: 150,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const STYLE_SUCESS: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const STYLE_ERROR: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: 10,
};

type Props = {
  detectImportParkingMeters: Function;
};

function ImportParkingMeter({ detectImportParkingMeters }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(Array<string>());

  function handleClose() {
    setErrors([]);
    setOpenModal(false);
  }

  async function submit(file: File) {
    setLoading(true);
    setOpenModal(true);
    setSuccess(false);
    const result = await importParkingMeter(file);
    if (result.success) {
      setSuccess(true);
    }
    setErrors(result.errors);
    setLoading(false);
    detectImportParkingMeters();
  }

  function onDrop(files: Array<File>) {
    if (files.length) {
      submit(files[0]);
    } else {
      setLoading(false);
      setOpenModal(true);
      setErrors([_tg('feedback.error.CSVFileFormat')]);
    }
  }

  function renderError() {
    return (
      <div style={STYLE_ERROR}>
        <ul style={{ marginTop: 20 }}>
          {errors.map(error => (
            <li key={error} style={{ marginBottom: 2 }}>
              {_tg('characters.dot')} {error}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  function renderLoading() {
    return (
      <div style={STYLE_LOADING}>
        <CircularProgress />
      </div>
    );
  }

  function renderSuccess() {
    return (
      <div style={STYLE_SUCESS}>
        <SuccessIcon color={BKG_GREEN} style={{ width: 100, height: 100 }} />
        <div>{_t('feedback.success.parkingMeterImported')}</div>
        <div>{_t('feedback.warning.pageRefresh')}</div>
      </div>
    );
  }

  function renderContent() {
    if (loading) {
      return renderLoading();
    }
    if (success) {
      return renderSuccess();
    }
    return renderError();
  }

  return (
    <div>
      <CsvImportDropZone
        onDrop={onDrop}
        buttonLabel={_t('element.importParkingMeter.buttonLabel')}
        buttonStyle={{ width: '100%' }}
      />

      <Dialog
        title={_t('element.dialog.title')}
        onRequestClose={handleClose}
        modal
        open={openModal}
        autoScrollBodyContent
        actions={[
          <FlatButton
            label={_tg('action.close')}
            onClick={handleClose}
            disabled={loading}
          />,
        ]}
      >
        {renderContent()}
      </Dialog>
    </div>
  );
}
export default ImportParkingMeter;
