import { Cluster, Renderer } from '@googlemaps/markerclusterer';

import { formatNumber } from './numberUtil';

export const CLUSTER_IMAGES = [
  {
    url: '/static/img/map/clusters/cluster_1.png',
    width: 40,
    height: 40,
  },
  {
    url: '/static/img/map/clusters/cluster_2.png',
    width: 56,
    height: 56,
  },
  {
    url: '/static/img/map/clusters/cluster_3.png',
    width: 56,
    height: 56,
  },
  {
    url: '/static/img/map/clusters/cluster_4.png',
    width: 70,
    height: 70,
  },
  {
    url: '/static/img/map/clusters/cluster_5.png',
    width: 70,
    height: 70,
  },
];

export const computeIndex = (count: number): number =>
  Math.max(
    0,
    Math.min(CLUSTER_IMAGES.length - 1, Math.floor(Math.log10(count)))
  );

export const rendererFactory = (
  titleFormatter: (count: number) => string
): Renderer => {
  return {
    render: ({ position, markers }: Cluster): google.maps.Marker => {
      const realCount =
        markers?.reduce(function getSum(acc, marker) {
          return acc + (marker as google.maps.Marker).count;
        }, 0) || 1;
      const index = computeIndex(realCount);
      // adjust zIndex to be above other markers
      const zIndex: number = Number(google.maps.Marker.MAX_ZINDEX) + realCount;
      const clusterOptions: google.maps.MarkerOptions = {
        position,
        zIndex,
        title: titleFormatter(realCount),
        label: {
          color: 'white',
          fontSize: '14',
          text: formatNumber(realCount),
        },
        icon: CLUSTER_IMAGES[index],
      };
      return new google.maps.Marker(clusterOptions);
    },
  };
};
