import React, { CSSProperties } from 'react';
import Visible from 'material-ui/svg-icons/action/visibility';
import Hidden from 'material-ui/svg-icons/action/visibility-off';
import Edit from 'material-ui/svg-icons/editor/mode-edit';
import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import MenuItem from 'material-ui/MenuItem';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

import ConfirmAction from 'commons/ConfirmAction';
import { ZoneDTO } from '@cvfm-front/tefps-types';

import { ZoningNames } from './types';

const { _t, _tg } = window.loadTranslations(__filename);

type ZoneItemProps = {
  zone: ZoneDTO;
  canWrite: boolean;
  menuOpen: boolean;
  hidden: boolean;
  hide: (id: string) => any;
  show: (id: string) => any;
  notifyDeleteMenuOpen: (id: string | null | undefined) => void;
  deleteZone: (id: string, deletePolygons: boolean) => any;
  edit: (zone: ZoneDTO) => any;
  naming: ZoningNames;
};

type ZoneItemState = {
  isOpen: boolean;
  deleteAllPolygons: boolean;
};

const STYLE_VISIBILITY: CSSProperties = {
  margin: '0px 8px 0px 0px',
  width: 16,
  height: 16,
  cursor: 'pointer',
  paddingBottom: 2,
};
const STYLE_ACTIONS: CSSProperties = {
  margin: '0px 0px 0px 15px',
  width: 20,
  height: 20,
  cursor: 'pointer',
  paddingBottom: 2,
};
const STYLE_NAME: CSSProperties = {
  whiteSpace: 'nowrap',
  width: '59%',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  overflow: 'hidden',
  paddingBottom: 2,
};

export default class ZoneItem extends React.Component<
  ZoneItemProps,
  ZoneItemState
> {
  state: ZoneItemState = {
    deleteAllPolygons: false,
    isOpen: false,
  };

  onConfirmDelete = () => {
    const { zone, deleteZone } = this.props;
    deleteZone(zone.id, this.state.deleteAllPolygons);
    this.setState({ isOpen: false, deleteAllPolygons: false });
  };

  onRequestChange = (open: boolean, reason: string) => {
    if (reason === 'clickAway' || reason === 'escape')
      this.props.notifyDeleteMenuOpen(null);
  };

  onConfirmDeleteCancel = () => {
    this.props.notifyDeleteMenuOpen(null);
    this.setState({ isOpen: false });
  };

  onDeleteZone = () =>
    this.setState({ isOpen: true, deleteAllPolygons: false });

  onDeleteZoneAndPolygons = () =>
    this.setState({ isOpen: true, deleteAllPolygons: true });

  hideZone = (id: string) => {
    this.props.hide(id);
  };
  openMenu = () => {
    const { zone, notifyDeleteMenuOpen } = this.props;
    notifyDeleteMenuOpen(zone.id);
  };

  showZone = (id: string) => {
    this.props.show(id);
  };

  editZone = () => {
    const { zone, edit } = this.props;
    edit(zone);
  };

  render() {
    const { zone, canWrite, menuOpen, naming, hidden } = this.props;
    const { isOpen, deleteAllPolygons } = this.state;
    const icon = hidden ? (
      <span title={_tg('action.display')}>
        <Hidden
          style={{ color: zone.color, ...STYLE_VISIBILITY }}
          onClick={() => this.showZone(zone.id)}
        />
      </span>
    ) : (
      <span title={_tg('action.hide')}>
        <Visible
          style={{ color: zone.color, ...STYLE_VISIBILITY }}
          onClick={() => this.hideZone(zone.id)}
        />
      </span>
    );

    return (
      <div style={{ height: '30px' }}>
        {icon}
        <span style={STYLE_NAME}>{zone.name}</span>
        {canWrite && (
          <span title={_tg('action.modify')}>
            <Edit
              style={{ color: '#ddd', ...STYLE_ACTIONS }}
              onClick={this.editZone}
            />
          </span>
        )}
        {canWrite && (
          <span>
            <ConfirmAction
              action={this.onConfirmDelete}
              isOpen={isOpen}
              onClose={this.onConfirmDeleteCancel}
              message={
                deleteAllPolygons
                  ? _t('element.confirmAction.deleteAllPolygon', {
                      withThis: naming.withThis,
                    })
                  : _t('element.confirmAction.deleteOnlyZones', {
                      withThis: naming.withThis,
                    })
              }
              enabled
            >
              <IconMenu
                open={menuOpen && !isOpen}
                onRequestChange={this.onRequestChange}
                onClick={this.openMenu}
                iconStyle={{ color: '#aaa', width: 20, height: 20 }}
                style={{ ...STYLE_ACTIONS }}
                menuStyle={{ borderLeft: `8px solid ${zone.color}` }}
                iconButtonElement={
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                }
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
              >
                <MenuItem
                  primaryText={_t('element.deleteZoneMenu.primaryText', {
                    withThe: naming.withThe,
                  })}
                  onClick={this.onDeleteZone}
                />
                <MenuItem
                  style={{ fontWeight: 'bold' }}
                  primaryText={_t(
                    'element.deleteZoneAndPolygonsMenu.primaryText',
                    { withThe: naming.withThe }
                  )}
                  onClick={this.onDeleteZoneAndPolygons}
                />
              </IconMenu>
            </ConfirmAction>
          </span>
        )}
      </div>
    );
  }
}
