import services from 'commons/services';

import { ComplementRequest } from '../../tefps/RecoursesV2/pages/Detail/ComplementRequest/types';

import useWatcher from './useWatcher';

const useComplementRequest = (): [
  ComplementRequest,
  (arg0: ComplementRequest) => void
] => {
  return [
    useWatcher(services.complementRequest.watchComplementRequest, {
      files: [],
      complementInfo: '',
    }),
    services.complementRequest.setComplementRequest,
  ];
};

export default useComplementRequest;
