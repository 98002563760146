import React, { CSSProperties } from 'react';
import {
  AutoSizer,
  Index,
  IndexRange,
  InfiniteLoader,
  List,
} from 'react-virtualized';

import SimpleTableRow from './SimpleTableRow';

type SimpleTableProps = {
  superCols?: Array<{
    label?: string;
    width: number;
    grow?: number;
    style?: CSSProperties;
    headerStyle?: CSSProperties;
    onSort?: boolean;
    sortId?: string;
  }>;
  cols: Array<{
    label?: string;
    width: number;
    grow?: number;
    style?: CSSProperties;
    headerStyle?: CSSProperties;
    onSort?: boolean;
  }>;
  footer?: Array<{
    label?: string;
    width: number;
    grow?: number;
    style?: CSSProperties;
    headerStyle?: CSSProperties;
    onSort?: boolean;
  }>;
  header: boolean;
  itemsRenderer: (value: any, index: number) => Array<any>;
  items: Array<any>;
  rowHeight: number;
  maxHeight?: number;
  style?: CSSProperties;
  onSort?: Function;
  loadMoreRows?: (range: IndexRange) => Promise<void>;
  remoteRowCount: number;
  colSorted?: number;
  sortOrder?: boolean;
  onRowClick?: (value: any) => void | null | undefined;
};

type SimpleTableState = {
  headerWidth: number | null | undefined;
  headerHeight: number | null | undefined;
};

type RowRendererArgs = {
  index: number;
  key: string;
  style: CSSProperties;
};

const STYLE_SUPER_HEADER: CSSProperties = {
  fontWeight: 'bold',
  width: '100%',
  textAlign: 'center',
  fontSize: '20px',
};

const STYLE_HEADER: CSSProperties = {
  fontWeight: 'bold',
  borderBottom: '2px solid rgb(238, 238, 238)',
  width: '100%',
  paddingBottom: 20,
};

const STYLE_FOOTER: CSSProperties = {
  ...STYLE_HEADER,
  paddingBottom: 4,
  paddingTop: 20,
  borderTop: '1px solid rgb(238, 238, 238)',
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
async function noop() {}

class SimpleTable extends React.Component<SimpleTableProps, SimpleTableState> {
  header: React.RefObject<HTMLDivElement> | null | undefined = null;

  // TODO: A bit risky to resolve this warning
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    cols: [],
    header: true,
    itemsRenderer: (x: number, i: number) => [x + i],
    rowHeight: 25,
    remoteRowCount: 0,
  };

  state: SimpleTableState = {
    headerWidth: null,
    headerHeight: null,
  };

  componentDidMount() {
    this.refreshHeaderWidth();
  }

  componentDidUpdate() {
    this.refreshHeaderWidth();
  }

  // HACK: the List width will match the width of the header
  // however, it might hide the vertical scroll when the window is too small
  // best case scenario: Use two Grid with SyncScroll and MeasureCell (on the header)
  refreshHeaderWidth = () => {
    if (!this.header) {
      return;
    }
    const { headerWidth, headerHeight } = this.state;
    const nextHeaderWidth = this.header.current?.scrollWidth;
    const nextHeaderHeight = this.header.current?.clientHeight;
    if (nextHeaderWidth !== headerWidth || nextHeaderHeight !== headerHeight) {
      this.setState({
        headerWidth: nextHeaderWidth,
        headerHeight: nextHeaderHeight,
      });
    }
  };

  attachHeader = (node: any) => {
    this.header = node;
  };

  rowRenderer = ({ index, key, style }: RowRendererArgs) => {
    const { cols, itemsRenderer, items, onRowClick } = this.props; // eslint-disable-line react/prop-types
    const item = items[index];

    return (
      <SimpleTableRow
        key={key}
        index={index}
        cols={cols}
        cells={itemsRenderer(item, index)}
        value={item}
        onRowClick={onRowClick}
        hoverable
        style={
          item.cancelDatetime || item.cancellationDatetime
            ? { ...style, color: '#969696' }
            : style
        }
      />
    );
  };

  isRowLoaded = ({ index }: Index) => {
    return !!this.props.items[index];
  };

  render() {
    const {
      cols,
      superCols,
      header,
      footer,
      items,
      rowHeight,
      maxHeight,
      style,
      onSort,
      loadMoreRows,
      remoteRowCount,
      colSorted,
      sortOrder,
    } = this.props; // eslint-disable-line react/prop-types
    const { headerHeight, headerWidth } = this.state;

    return (
      <AutoSizer disableHeight={!!maxHeight} onResize={this.refreshHeaderWidth}>
        {({ width, height }) => {
          const currentHeaderHeight = headerHeight || rowHeight;
          let actualContainerHeight = maxHeight
            ? Math.min(
                rowHeight * items.length + currentHeaderHeight,
                maxHeight
              )
            : height;
          actualContainerHeight = actualContainerHeight || 500; // for testing with jest/enzyme (height = 0 otherwise)

          const horizontalBarHeight =
            !!headerWidth && headerWidth > width ? 20 : 0;
          const actualListHeight =
            actualContainerHeight - currentHeaderHeight - horizontalBarHeight;

          const verticalScrollbarDisplayed =
            actualListHeight < rowHeight * items.length;

          return (
            <div
              style={{
                position: 'relative',
                fontFamily: 'Roboto',
                ...style,
                width,
                overflowX: 'auto',
              }}
            >
              {superCols && (
                <div ref={this.attachHeader}>
                  <SimpleTableRow
                    index={0}
                    cols={superCols}
                    cells={superCols.map(c => c.label)}
                    hoverable={false}
                    header
                    style={{
                      ...STYLE_SUPER_HEADER,
                      marginRight: verticalScrollbarDisplayed ? 20 : 0,
                    }}
                  />
                </div>
              )}

              {header && (
                <div ref={this.attachHeader}>
                  <SimpleTableRow
                    index={0}
                    cols={cols}
                    cells={cols.map(c => c.label)}
                    hoverable={false}
                    header
                    style={{
                      ...STYLE_HEADER,
                      marginRight: verticalScrollbarDisplayed ? 20 : 0,
                      paddingBottom: 20,
                    }}
                    onSort={onSort}
                    colSorted={colSorted}
                    sortOrder={sortOrder}
                  />
                </div>
              )}

              <InfiniteLoader
                isRowLoaded={this.isRowLoaded}
                loadMoreRows={loadMoreRows || noop}
                rowCount={remoteRowCount}
              >
                {({ onRowsRendered, registerChild }) => (
                  <List
                    width={headerWidth || width || 1200} // || 1200 for testing with jest/enzyme (would be 0 otherwise)
                    height={actualListHeight}
                    rowCount={items.length}
                    rowHeight={rowHeight}
                    onRowsRendered={onRowsRendered}
                    ref={registerChild}
                    rowRenderer={this.rowRenderer}
                    style={{ outline: 'none' }}
                  />
                )}
              </InfiniteLoader>
              {footer && (
                <SimpleTableRow
                  index={0}
                  cols={footer}
                  cells={footer.map(c => c.label)}
                  hoverable={false}
                  style={{
                    ...STYLE_FOOTER,
                    marginRight: verticalScrollbarDisplayed ? 20 : 0,
                  }}
                />
              )}
            </div>
          );
        }}
      </AutoSizer>
    );
  }
}

export default SimpleTable;
