import { PatrolZoneDTO } from '@cvfm-front/tefps-types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const usePatrolZoneToEdit = (): PatrolZoneDTO | null => {
  return useWatcher(
    services.patrolZoneService.watchPatrolZoneToEdit,
    services.patrolZoneService.getPatrolZoneToEdit()
  );
};

export default usePatrolZoneToEdit;
