import services from 'commons/services';

import useWatcher from './useWatcher';

const usePatrolZoneError = (): string | null => {
  return useWatcher(
    services.patrolZoneService.watchError,
    services.patrolZoneService.getError()
  );
};

export default usePatrolZoneError;
