import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { fetchTags, TagsType } from 'api/tags';

import { UserInfoServiceInterface } from './UserInfoService';

export interface TagsServiceFactory {
  (userInfoService: UserInfoServiceInterface): TagsServiceInterface;
}

export interface TagsServiceInterface {
  init: (type: TagsType) => Promise<void>;
  getTags: () => Array<string>;
  watchTags: WatchFunctionType<Array<string>>;
  getMandatoryTags: () => Array<string>;
  watchMandatoryTags: WatchFunctionType<Array<string>>;
  getNewTags: () => Array<string>;
  setNewTags: (newTags: Array<string>) => void;
  watchNewTags: WatchFunctionType<Array<string>>;
}

const TagsService: TagsServiceFactory = userInfoService => {
  const {
    getValue: getTags,
    setValue: setTags,
    watchValue: watchTags,
  } = Watcher<Array<string>>([]);

  const {
    getValue: getMandatoryTags,
    setValue: setMandatoryTags,
    watchValue: watchMandatoryTags,
  } = Watcher<Array<string>>([]);

  const {
    getValue: getNewTags,
    setValue: setNewTags,
    watchValue: watchNewTags,
  } = Watcher<Array<string>>([]);

  const init = async (type: TagsType): Promise<void> => {
    const userInfo = userInfoService.getUserInfo();
    const orgId = userInfo?.worksFor ? userInfo.worksFor.organizationId : null;
    const fetchedTags = await fetchTags(type, orgId, false);
    setTags(fetchedTags);
    const fetchedMandatoryTags = await fetchTags(type, orgId, true);
    setMandatoryTags(fetchedMandatoryTags);
  };

  return {
    init,
    getTags,
    watchTags,
    getMandatoryTags,
    watchMandatoryTags,
    getNewTags,
    setNewTags,
    watchNewTags,
  };
};

export default TagsService;
