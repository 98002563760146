import React, { CSSProperties } from 'react';

import { Gender, RecourseUser } from 'api/recourse/types';

const { _t, Trans } = window.loadTranslations(__filename);

const MOCK_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

const STYLE_DPI: CSSProperties = {
  height: '10000cm',
  left: '-100%',
  position: 'absolute',
  top: '-100%',
  width: '10000cm',
};

const STYLE_OUT_OF_DISPLAY: CSSProperties = {
  left: '-100%',
  position: 'absolute',
  top: '-100%',
};

const STYLE_BASE: CSSProperties = {
  fontFamily: 'Arial, Noto Sans, Hevletica, Sans-serif, serif',
  fontSize: '14px',
  color: 'black',
  lineHeight: '16px',
};

const STYLE_HEADER: CSSProperties = {
  height: '4cm',
  width: '17.55cm',
};

const STYLE_HEADER_WRAPPER: CSSProperties = {
  textAlign: 'center',
};

const STYLE_ADDRESS: CSSProperties = {
  ...STYLE_BASE,
  marginTop: 0,
  marginLeft: '11cm',
};

const STYLE_DATE_PLACE: CSSProperties = {
  ...STYLE_BASE,
  marginTop: '2.5cm',
  marginLeft: '11cm',
};

const STYLE_OBJECT: CSSProperties = {
  ...STYLE_BASE,
  marginTop: '1.5cm',
};

const STYLE_MESSAGE_BODY: CSSProperties = {
  marginTop: '1cm',
};

const STYLE_LINE: CSSProperties = {
  fontSize: 14,
  margin: '0.37cm 0px',
  whiteSpace: 'pre-wrap',
  textAlign: 'justify',
};

const MAX_HEIGHT_WITHOUT_FOOTER = 22.42; // cm

const DEFAULT_DPI = 37.795276; // Pour un écran de 96 DPI (standard), 1cm vaut environ 37,8px
const px2cm = (dpi: number, px: number): number => px / dpi;

const formatAddress = (user: RecourseUser) =>
  `${user.addressStreetNumber}
  ${user.addressStreetNumberBis ? ` ${user.addressStreetNumberBis}` : ''}
   ${user.addressStreetType} ${user.addressStreetName}`.toUpperCase();

const toGender = (gender: Gender) => {
  if (gender === 'MALE') {
    return _t('gender.MALE');
  }
  if (gender === 'FEMALE') {
    return _t('gender.FEMALE');
  }
  return '';
};

type SimpleProps = {
  message: string;
  user: RecourseUser;
  cityName: string;
};

type StateChecker = {
  dpiY: number;
};

/**
 * Affichage de la réponse afin de calculer la taille du message (en cm)
 * et s'assurer qu'elle ne dépasse pas une page.
 */
export class RapoMessageLengthChecker extends React.Component<
  SimpleProps,
  StateChecker
> {
  messageWithoutFooter:
    | React.RefObject<HTMLDivElement>
    | null
    | undefined = null;
  state: StateChecker = {
    dpiY: 0,
  };

  componentDidMount() {
    this.computeDpi();
  }

  computeDpi = () => {
    const d = document.getElementById('dpiDiv');
    if (d) {
      const dpiY = d.offsetWidth / 10000;
      this.setState({ dpiY });
    } else {
      this.setState({ dpiY: DEFAULT_DPI });
    }
  };

  isExceedingOnePage = () => {
    const { dpiY } = this.state;
    if (
      this.messageWithoutFooter &&
      dpiY &&
      this.messageWithoutFooter.current
    ) {
      return (
        px2cm(dpiY, this.messageWithoutFooter.current.clientHeight) >
        MAX_HEIGHT_WITHOUT_FOOTER
      );
    }
    return undefined;
  };

  isExceedingTwoPages = () => {
    const { dpiY } = this.state;
    if (
      this.messageWithoutFooter &&
      dpiY &&
      this.messageWithoutFooter.current
    ) {
      return (
        px2cm(dpiY, this.messageWithoutFooter.current.clientHeight) >
        MAX_HEIGHT_WITHOUT_FOOTER * 2
      );
    }
    return undefined;
  };

  messageWithoutFooterRef = (elem: any) => {
    this.messageWithoutFooter = elem;
  };

  render() {
    const { dpiY } = this.state;
    const { message, user, cityName } = this.props;
    const { addressLocality, addressComplement } = user;
    const fullName = `${toGender(user.gender)} ${user.firstName} ${
      user.lastName
    }`;
    const addressStreet = formatAddress(user);

    if (!dpiY) {
      return (
        <div style={STYLE_OUT_OF_DISPLAY}>
          {!dpiY && <div id="dpiDiv" style={STYLE_DPI} />}
        </div>
      );
    }

    return (
      <div style={STYLE_OUT_OF_DISPLAY} ref={this.messageWithoutFooterRef}>
        <div style={{ width: '17.55cm' }} ref={this.messageWithoutFooterRef}>
          <div style={STYLE_HEADER_WRAPPER}>
            <img src={MOCK_IMAGE} style={STYLE_HEADER} />
          </div>
          <div style={STYLE_ADDRESS}>
            <span>{fullName}</span>
            <br />
            <span>{addressStreet}</span>
            <br />
            {addressComplement && (
              <span>
                {addressComplement}
                <br />
              </span>
            )}
            <span>{addressLocality}</span>
          </div>
          <div style={STYLE_DATE_PLACE}>
            {_t('element.datePlace', { cityName })}
          </div>
          <div style={STYLE_OBJECT}>
            <Trans
              i18nKey="element.object"
              components={[<span style={{ fontWeight: 'bold' }} />]}
            />
          </div>
          <div style={{ ...STYLE_BASE, ...STYLE_MESSAGE_BODY }}>
            {message.split('\n').map((text, i) => (
              <p key={i} style={STYLE_LINE}>
                {text}
              </p>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
