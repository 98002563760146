import * as React from 'react';
import IconButton from 'material-ui/IconButton';
import ContentCreate from 'material-ui/svg-icons/content/create';

import { TXT_BLACK } from 'theme';

type Props = {
  title: string;
  onClick: () => void;
  color?: string;
};

class Button extends React.Component<Props> {
  onClick = (): void => {
    const { onClick } = this.props;
    onClick();
  };

  render(): React.ReactNode {
    const { color, title } = this.props;
    return (
      <IconButton tooltip={title} onClick={this.onClick}>
        <ContentCreate color={color || TXT_BLACK} />
      </IconButton>
    );
  }
}

export default Button;
