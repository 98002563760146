import { RapoDetailDTO } from 'api/recourse/types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useRecourse = (): RapoDetailDTO | null => {
  return useWatcher(
    services.recourse.watchRecourse,
    services.recourse.getRecourse()
  );
};

export default useRecourse;
