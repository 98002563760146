import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { fetchMailTemplate } from 'api/mail';
import { MailDTO } from 'api/mail/type';
import { MailTypeRAPO } from '@cvfm-front/tefps-types';

import { UserInfoServiceInterface } from './UserInfoService';
import { RecourseServiceInterface } from './RecourseService';

export interface MailTemplateServiceFactory {
  (
    userInfoService: UserInfoServiceInterface,
    recourseService: RecourseServiceInterface
  ): MailTemplateServiceInterface;
}

export interface MailTemplateServiceInterface {
  init: () => Promise<void>;
  getMailTemplate: () => MailDTO | null;
  setMailTemplate: (mail: MailDTO) => void;
  watchMailTemplate: WatchFunctionType<MailDTO | null>;
}

const MailTemplateService: MailTemplateServiceFactory = (
  userInfoService,
  recourseService
) => {
  const {
    getValue: getMailTemplate,
    setValue: setMailTemplate,
    watchValue: watchMailTemplate,
  } = Watcher<MailDTO | null>(null);

  const init = async (): Promise<void> => {
    const userInfo = userInfoService.getUserInfo();

    const response = await fetchMailTemplate({
      type: MailTypeRAPO.RAPO_COMPLEMENT,
      organizationId: userInfo?.worksFor
        ? userInfo.worksFor.organizationId
        : undefined,
      resourceId: undefined,
    });

    setMailTemplate({
      mailConfiguration: response.mailConfiguration,
      mail: response.mail,
      mailType: response.type,
    });
  };

  return {
    init,
    getMailTemplate,
    setMailTemplate,
    watchMailTemplate,
  };
};

export default MailTemplateService;
