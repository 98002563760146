import React from 'react';
import moment from 'moment';

import { iAmWatching } from 'api/watchings';
import { WatchingDTO } from 'api/watchings/types';

import './index.css';

const { _t } = window.loadTranslations(__filename);

type Props = {
  resourceType: string;
  resourceId: string;
};

type State = {
  watchings: Array<WatchingDTO>;
  isHeartbeatActive: boolean;
};

const HEARTBEAT_PERIOD = 15 * 1000; // 15 seconds
const HEARTBEAT_REACTIVATION_DELAY = 10 * 1000; // 10 seconds

class Watchings extends React.Component<Props, State> {
  timeoutId: NodeJS.Timeout | number | null | undefined = null;

  state: State = {
    watchings: [],
    isHeartbeatActive: true,
  };

  componentDidMount() {
    this.heartbeat();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.resourceType !== nextProps.resourceType ||
      this.props.resourceId !== nextProps.resourceId
    ) {
      this.setState({ watchings: [] });
    }
  }

  componentWillUnmount() {
    // Clear the timeout when the component is unmounted
    this.stopHeartbeat();
  }

  stopHeartbeat = () => {
    this.setState({ isHeartbeatActive: false });

    if (this.timeoutId) {
      clearTimeout(this.timeoutId as NodeJS.Timeout);
    }

    // timeout pour réactiver le "heartbeat" après 10 secondes
    setTimeout(() => {
      this.setState({ isHeartbeatActive: true });
      this.heartbeat();
    }, HEARTBEAT_REACTIVATION_DELAY);
  };

  heartbeat = async () => {
    const { resourceType, resourceId } = this.props;
    const { isHeartbeatActive } = this.state;

    if (!isHeartbeatActive) {
      return;
    }

    const { watchings: newWatchings } = await iAmWatching(
      resourceType,
      resourceId
    );
    this.setState({ watchings: newWatchings });

    if (this.timeoutId) {
      clearTimeout(this.timeoutId as NodeJS.Timeout);
    }

    this.timeoutId = setTimeout(() => {
      this.heartbeat();
    }, HEARTBEAT_PERIOD);
  };

  render() {
    const { watchings, isHeartbeatActive } = this.state;
    if (!isHeartbeatActive || watchings.length === 0) {
      return null;
    }

    return (
      <div onClick={() => this.stopHeartbeat()} className="watchings">
        <div className="watchings-content">
          <p>{_t('element.currentlyWatchedBy')}</p>
          <ul style={{ fontSize: 12, marginTop: 4 }}>
            {watchings.map(watching => (
              <li
                className="watching-content__item"
                title={moment(watching.instant).fromNow()}
              >
                – {watching.agentDisplayName} –
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Watchings;
