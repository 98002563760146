import { CSSProperties } from 'react';

import { BKG_GREYBLUE, TXT_GREY } from 'theme';

export const STYLE_BLOCK_TITLE: CSSProperties = {
  fontSize: 15,
  fontWeight: 'bold',
  display: 'flex',
  color: TXT_GREY,
};

export const STYLE_SELECT_TEXT: CSSProperties = {
  color: 'white',
  fontSize: 14,
  fontFamily: 'Roboto',
  marginLeft: 5,
  height: 40,
};

export const STYLE_SELECT_TEXT_BLACK: CSSProperties = {
  color: 'black',
  fontSize: 14,
  fontFamily: 'Roboto',
  marginLeft: 5,
  height: 40,
};

export const STYLE_INPUT_TEXT: CSSProperties = {
  fontSize: 12,
  fontWeight: 'bold',
  marginRight: 10,
  color: 'white',
  textAlign: 'center',
};

export const INLINE_TEXT_FIELD_STYLE: CSSProperties = {
  backgroundColor: BKG_GREYBLUE,
  height: 27,
  lineHeight: '22px',
  marginTop: 5,
  width: '100%',
};
