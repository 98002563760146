import services from 'commons/services';
import { EligibilitySearchCriteria } from 'tefps/Eligibility/List/types';
import { initialFilters } from 'tefps/Eligibility/List/duck';

import useWatcher from './useWatcher';

const useEligibilityRightsFilters = (): EligibilitySearchCriteria => {
  return useWatcher(services.eligibilityRights.watchFilter, initialFilters());
};

export default useEligibilityRightsFilters;
