import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from 'material-ui/styles';

import { MapZoning, PolygonForMap } from 'api/pricing/types';
import { ParkingMeterDTO } from 'api/parkingMeter/type';
import { signGoogleMapUrl, BASE_GOOGLE_MAPS_URL } from 'api/url-signer';
import { PointDTO, PolygonDTO } from '@cvfm-front/tefps-types';

import ParkingMeterInfoWindow from './ParkingMeters/ParkingMeterInfoWindow';
import { polygonsToDto } from './helpers';
import { IParkingMeterPatchObject } from './types';

const { _t, _tg } = window.loadTranslations(__filename);

const getPolygonCenter = (polygon: google.maps.Polygon) => {
  const vertices = polygon.getPath();
  const bounds = new google.maps.LatLngBounds();

  vertices.getArray().forEach(x => bounds.extend(x));
  return bounds.getCenter();
};

type Position = {
  lat: number;
  lng: number;
};

type MapProps = {
  style: CSSProperties;
  center: Position;
  zoning: MapZoning | null | undefined;
  selectedPolygonId: string | null | undefined;
  selectedParkingMeterNumber: string | null | undefined;
  hiddenZones: Set<string>;
  onChange: () => void;
  selectPolygon: (id: string) => void;
  selectParkingMeterNumber: (number: string) => void;
  addPolygon: (
    id: string,
    name: string,
    zoneIds: Array<string>,
    points: Array<PointDTO>
  ) => void;
  enableParkingMeter: boolean;
  canShowParkingMeter: boolean;
  parkingMeters: Array<ParkingMeterDTO> | null;
  deleteParkingMeter: (number: string) => void;
  editParkingMeter: (
    number: string,
    updates: IParkingMeterPatchObject[]
  ) => void;
};

class ZoningMap extends React.Component<MapProps> {
  hiddenZoneIds: Set<string> = new Set();
  selectedPolygonId: string | null | undefined = null;
  selectedParkingMeterNumber: string | null | undefined = null;
  map: google.maps.Map | undefined = undefined;
  polygons: Array<google.maps.Polygon> = [];
  parkingMeters: Array<ParkingMeterDTO> = [];
  currentInfoWindow: google.maps.InfoWindow | null | undefined = null;
  refMap: HTMLDivElement | undefined = undefined;
  markers: Set<google.maps.Marker> = new Set();

  async componentDidMount(): Promise<void> {
    // Connect the initMap() function within this class to the global window context,
    // so Google Maps can invoke it
    window.initMap = this.initMap;

    // Asynchronously load the Google Maps script, passing in the callback reference
    await this.loadJS(
      `${BASE_GOOGLE_MAPS_URL}/maps/api/js?callback=initMap&libraries=geometry`
    );
    return Promise.resolve();
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(nextProps: MapProps): Promise<void> {
    const {
      hiddenZones,
      selectedPolygonId,
      selectedParkingMeterNumber,
      parkingMeters,
    } = nextProps;
    if (this.hiddenZoneIds !== hiddenZones) {
      this.hiddenZoneIds = hiddenZones;
      this.handleHiddenZones();
    }
    if (this.selectedPolygonId !== selectedPolygonId) {
      this.selectedPolygonId = selectedPolygonId;
      const polygon = this.polygons.find(p => p.id === selectedPolygonId);
      if (polygon) this.makePolygonEditable(polygon);
    }

    if (
      this.selectedParkingMeterNumber !== selectedParkingMeterNumber &&
      parkingMeters != null
    ) {
      this.selectedParkingMeterNumber = selectedParkingMeterNumber;
      const parkingMeter = parkingMeters.find(
        pm => pm.number === selectedParkingMeterNumber
      );
      const marker = Array.from(this.markers).find(
        m => m.getTitle() === selectedParkingMeterNumber
      );
      if (marker && parkingMeter) {
        const infoWindow = await this.createInfoWindow(marker, parkingMeter);
        if (infoWindow) {
          this.openInfoWindow(infoWindow);
        }
      }
    }
  }

  shouldComponentUpdate(): boolean {
    return false;
  }

  closeInfoWindow = (): void => {
    if (this.currentInfoWindow) {
      this.currentInfoWindow.close();
    }
    const { selectParkingMeterNumber } = this.props;
    selectParkingMeterNumber('');
    this.currentInfoWindow = null;
  };

  openInfoWindow = (infoWindow: google.maps.InfoWindow): void => {
    this.closeInfoWindow(); // Close previous window
    this.currentInfoWindow = infoWindow;
    if (!this.map) {
      return;
    }
    this.currentInfoWindow.open(this.map);
  };

  createInfoWindow = async (
    marker: google.maps.Marker,
    parkingMeter: ParkingMeterDTO
  ): Promise<google.maps.InfoWindow> => {
    /* ======= InfoWindow Workaround
      Component InfoWindow from Google does not support event handlers.
      This workaround is based on the solution described in the link below:
      https://stackoverflow.com/questions/53615413/how-to-add-a-button-in-infowindow-with-google-maps-react
     */
    const { zoning } = this.props;
    return new Promise(res => {
      const infoWindowRef = React.createRef<HTMLDivElement>();
      ReactDOM.render(
        <MuiThemeProvider>
          <ParkingMeterInfoWindow
            ref={infoWindowRef}
            zones={zoning?.zones ?? []}
            parkingMeter={parkingMeter}
            deleteParkingMeter={() =>
              this.handleDeleteParkingMeter(parkingMeter.number, marker)
            }
            editParkingMeter={this.handleEditParkingMeter}
          />
        </MuiThemeProvider>,
        document.createElement('div'),
        () => {
          const position = {
            lat: parkingMeter.latitude,
            lng: parkingMeter.longitude,
          };
          res(
            new google.maps.InfoWindow({
              content: infoWindowRef.current ?? '<div />',
              position,
            })
          );
        }
      );
    });
  };

  removeAllMarkers = (): void => {
    this.markers.forEach(marker => {
      marker.setMap(null);
    });
    this.markers.clear();
  };

  addMarkers = (parkingMeter: ParkingMeterDTO): void => {
    const position = {
      lat: parkingMeter.latitude,
      lng: parkingMeter.longitude,
    };

    const marker = new google.maps.Marker({
      position,
      title: parkingMeter.number,
      icon: {
        url:
          parkingMeter.type === 'VIRTUAL'
            ? '/static/img/map/parking/parking-meter_virtual.png'
            : '/static/img/map/parking/parking-meter_physical.png',
        anchor: new google.maps.Point(13, 13), // {x: 13, y: 13}, // Anchor set at the center of the icon (image: 25px*25px)
      },
      map: this.map,
    });

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    google.maps.event.addListener(marker, 'click', () => {
      const { selectParkingMeterNumber } = this.props;
      selectParkingMeterNumber(parkingMeter.number);
    });
    this.markers.add(marker);
  };

  handleDeleteParkingMeter = (
    number: string,
    marker: google.maps.Marker
  ): void => {
    const { deleteParkingMeter } = this.props;

    deleteParkingMeter(number);
    this.markers.delete(marker);
    marker.setMap(null);
    this.closeInfoWindow();
  };

  handleEditParkingMeter = (
    number: string,
    updates: IParkingMeterPatchObject[]
  ): void => {
    const { editParkingMeter } = this.props;
    editParkingMeter(number, updates);
    this.closeInfoWindow();
  };

  drawParkingMeters = (): void => {
    const { parkingMeters } = this.props;
    if (parkingMeters) {
      parkingMeters.forEach(parkingMeter => {
        this.addMarkers(parkingMeter);
        this.parkingMeters.push(parkingMeter);
      });
    }
  };

  handleHideParkingMeters = (): void => {
    const { canShowParkingMeter } = this.props;
    this.markers.forEach(marker => {
      if (canShowParkingMeter) {
        marker.setMap(null);
      } else if (this.map !== undefined) {
        marker.setMap(this.map);
      }
    });
  };

  initMap = (): void => {
    const { center, zoning, enableParkingMeter } = this.props;
    const { refMap } = this;
    if (!refMap) return;
    this.map = new google.maps.Map(refMap, {
      zoom: 13,
      center,
      clickableIcons: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
      ],
    });
    this.drawPolygons(zoning);
    if (enableParkingMeter) {
      this.drawParkingMeters();
      google.maps.event.addListener(this.map, 'click', this.closeInfoWindow);
    }
  };

  loadJS = async (src: string): Promise<void> => {
    const urlSigned = await signGoogleMapUrl(src);
    const script = window.document.createElement('script');
    script.src = BASE_GOOGLE_MAPS_URL + urlSigned.url;
    script.async = true;
    window.document.body.appendChild(script);
  };

  saveMap = (): PolygonDTO[] => {
    return polygonsToDto(this.polygons);
  };

  createRefMap = (node: HTMLDivElement): void => {
    this.refMap = node;
  };

  redraw = (zoning: MapZoning): void => {
    this.removeAllPolygons();
    this.drawPolygons(zoning);
  };

  buildMenu = (polygon: google.maps.Polygon): HTMLElement => {
    const content = document.createElement('div');
    ReactDOM.render(
      <span>
        {_t('element.polygonName', { name: polygon.name })}
        <br />
        {_t('element.vertexCount', {
          count: polygon.getPath().getArray().length,
        })}
      </span>,
      content
    );
    return content;
  };

  buildDeleteMenu = (
    vertex: number,
    path: google.maps.MVCArray<google.maps.LatLng>
  ): HTMLElement => {
    const content = document.createElement('div');
    ReactDOM.render(
      <input
        value={_tg('action.delete').toLowerCase()}
        type="button"
        onClick={() => path.removeAt(vertex)}
      />,
      content
    );
    return content;
  };

  deletePolygon = (polygon: google.maps.Polygon): void => {
    polygon.setMap(null);
    this.polygons.splice(this.polygons.indexOf(polygon), 1);
  };

  deletePolygonBydId = (id: string): void => {
    const polygon = this.polygons.filter(p => p.id === id)[0];
    this.deletePolygon(polygon);
  };

  polygonMenu = (
    event: { vertex: number; latLng: google.maps.LatLng },
    polygon: google.maps.Polygon
  ): void => {
    if (this.currentInfoWindow) {
      this.currentInfoWindow.close();
    }
    this.currentInfoWindow = new google.maps.InfoWindow({
      content:
        event.vertex && polygon.getPath()
          ? this.buildDeleteMenu(event.vertex, polygon.getPath())
          : this.buildMenu(polygon),
      position: event.latLng,
    });
    const { map } = this;
    if (map) this.currentInfoWindow.open(map, polygon);
  };

  makePolygonEditable = (
    polygon: google.maps.Polygon,
    fillOpacity = 0.75
  ): void => {
    if (polygon.getVisible()) {
      polygon.setEditable(true);
      polygon.setOptions({ strokeOpacity: 0.9, fillOpacity });
      const { selectPolygon } = this.props;
      selectPolygon(polygon.id);
      const { map } = this;
      if (map) map.setCenter(getPolygonCenter(polygon));
    }
    this.polygons.forEach(p => {
      if (p.id !== polygon.id && p.getVisible()) {
        p.setEditable(false);
        p.setOptions({ strokeOpacity: 0.6, fillOpacity: 0.25 });
      }
    });
  };

  handleHiddenZones = (): void => {
    this.polygons.forEach((p: google.maps.Polygon) => {
      if (p.zones.every(zId => this.hiddenZoneIds.has(zId))) {
        p.setEditable(false);
        p.setVisible(false);
      } else {
        p.setVisible(true);
      }
    });
  };

  showZone = (zoneId: string): void => {
    this.polygons
      .filter(p => p.zones.includes(zoneId))
      .forEach(p => {
        p.setVisible(true);
      });
  };

  changePolygonZones = (polygonId: string, newZones: Array<string>): void => {
    this.polygons.filter(p => p.id === polygonId)[0].zoneIds = newZones;
  };

  createPolygon = (id: string, name: string, zoneIds: Array<string>): void => {
    if (!this.map) return;
    google.maps.event.addListenerOnce(
      this.map,
      'dblclick',
      (event: { latLng: { lat: () => number; lng: () => number } }) => {
        const clicked = { lat: event.latLng.lat(), lon: event.latLng.lng() };
        const points = [
          { lat: clicked.lat + 0.001, lon: clicked.lon + 0.001 },
          { lat: clicked.lat - 0.001, lon: clicked.lon + 0.001 },
          { lat: clicked.lat - 0.001, lon: clicked.lon - 0.001 },
        ];
        const { addPolygon } = this.props;
        addPolygon(id, name, zoneIds, points);
      }
    );
  };

  drawPolygon = (
    data: PolygonDTO,
    color: string,
    zoneIds: Array<string>,
    zIndex: number,
    visible: boolean
  ): void => {
    const { onChange } = this.props;
    // Polygon Coordinates
    if (data && data.points) {
      const coords = data.points.map(p => new google.maps.LatLng(p.lat, p.lon));
      // Styling & Controls
      const isSelected = data.id === this.selectedPolygonId;
      const polygon = new google.maps.Polygon({
        id: data.id,
        name: data.name,
        zones: zoneIds,
        paths: coords,
        draggable: false,
        editable: isSelected,
        strokeColor: color,
        strokeOpacity: isSelected ? 0.9 : 0.6,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: isSelected ? 0.75 : 0.25,
        zIndex,
        visible,
      });
      if (this.map) polygon.setMap(this.map);
      this.polygons.push(polygon);

      // Add action onClick on the polygon
      google.maps.event.addListener(
        polygon,
        'rightclick',
        (event: { vertex: number; latLng: google.maps.LatLng }) =>
          this.polygonMenu(event, polygon)
      );
      google.maps.event.addListener(polygon, 'click', () =>
        this.makePolygonEditable(polygon)
      );
      google.maps.event.addListener(polygon.getPath(), 'insert_at', () =>
        onChange()
      );
      google.maps.event.addListener(polygon.getPath(), 'set_at', () =>
        onChange()
      );
      google.maps.event.addListener(polygon.getPath(), 'remove_at', () => {
        if (this.currentInfoWindow) {
          this.currentInfoWindow.close();
        }
        onChange();
      });
    }
  };

  drawPolygons = (zoning: MapZoning | null | undefined): void => {
    if (!zoning) {
      return;
    }
    const { zones } = zoning;
    zoning.polygons.forEach((polygon: PolygonForMap, i) => {
      const containingZones = zones.filter(z => z.id === polygon.zoneIds[0]);
      let color = '#FFF';
      if (
        containingZones.length > 0 &&
        containingZones[0].color !== undefined
      ) {
        color = polygon.zoneIds.length > 1 ? '#000' : containingZones[0].color;
      }
      this.drawPolygon(
        polygon,
        color,
        polygon.zoneIds,
        i,
        !polygon.zoneIds.some(zId => this.hiddenZoneIds.has(zId))
      );
    });
  };

  removeAllPolygons(): void {
    this.polygons.map(p => p.setMap(null));
    this.polygons = [];
  }

  render(): JSX.Element {
    const { style } = this.props;
    return (
      <span>
        <div ref={this.createRefMap} style={style} />
      </span>
    );
  }
}

export default ZoningMap;
