import { cloneDeep, isEqual } from 'lodash';

import { Setter, WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import { EsCityParkingSpaceDTO, Point } from '@cvfm-front/tefps-types';
import { computeParkingSpaceCurrentVersion } from 'tefps/ParkingSpace/utils';
import { getLast } from 'commons/Utils/arrayUtils';

import {
  DrawingType,
  MapId,
  MapServiceInterface,
  PolygonWrapper,
} from '../MapService';
import services from '..';

import { ParkingSpaceServiceApiInterface } from './ParkingSpaceApiService';
import { ParkingSpaceChangeRegimeServiceInterface } from './ParkingSpaceChangeRegimeService';

function getColorByRegime(esCityParkingSpaceDTO: EsCityParkingSpaceDTO) {
  if (esCityParkingSpaceDTO.versions.length === 0) {
    return '#000000';
  }

  const lastVersion = computeParkingSpaceCurrentVersion(esCityParkingSpaceDTO);

  switch (lastVersion?.parkingRegime) {
    case 'MIXED_MARKET':
      return '#ff8000';
    case 'MIXED':
      return '#bfff00';
    case 'ROTARY':
      return '#00ffbf';
    case 'MARKET':
      return '#00bfff';
    case 'ROTARY_MARKET':
      return '#0040ff';
    case 'WORKS':
      return '#bf00ff';
    case 'PMR':
      return '#ff00ff';
    case 'DELIVERY':
      return '#8000ff';
    case 'PAID':
      return '#0066ff';
    case 'NEUTRALIZED':
      return '#000';
    case 'UNKNOWN':
    default:
      return '#666666';
  }
}

export enum InteractiveMode {
  NONE,
  EDIT,
  SELECT,
}
export interface ParkingSpaceServiceMapFactory {
  (
    parkingSpaceApiService: ParkingSpaceServiceApiInterface,
    parkingspaceNeutralization: ParkingSpaceChangeRegimeServiceInterface,
    mapService: MapServiceInterface
  ): ParkingSpaceServiceMapInterface;
}

export interface ParkingSpaceServiceMapInterface {
  setupAutoRefresh: (mapId: MapId) => void;
  cancelAutoRefresh: (mapId: MapId) => void;
  setSelectedId: (
    mapId: MapId,
    id: string,
    updateZoomAndCenter?: boolean
  ) => Promise<EsCityParkingSpaceDTO>;
  selectParkingSpace: (
    mapId: MapId,
    parkingSpace: EsCityParkingSpaceDTO
  ) => EsCityParkingSpaceDTO;
  watchSeletected: WatchFunctionType<null | EsCityParkingSpaceDTO>;
  closeSelection: (mapId: MapId) => void;
  refresh: (mapId: MapId) => Promise<void>;
  setDisplayZone: (mapId: MapId, newValue: boolean) => void;
  watchDisplayZone: WatchFunctionType<null | boolean>;
  setInteractiveMode: (newInteractiveMode: InteractiveMode) => void;
  watchIsEditingPolygon: WatchFunctionType<boolean>;
  setIsEditingPolygon: Setter<boolean>;
  watchParkingSpaceWithNewPolygons: WatchFunctionType<EsCityParkingSpaceDTO | null>;
  setParkingSpaceWithNewPolygons: Setter<EsCityParkingSpaceDTO | null>;
}

const ParkingSpaceMapService: ParkingSpaceServiceMapFactory = (
  parkingSpaceApiService,
  parkingspaceNeutralization,
  mapService
) => {
  let currentCenter = '';
  let currentInterval: NodeJS.Timeout | null = null;
  let displayedParkings: EsCityParkingSpaceDTO[] = [];
  let selectedId = '';
  let interactiveMode = InteractiveMode.NONE;
  let currentZoom = 0;
  const {
    watchValue: watchSeletected,
    setValue: setSelectedParkingSpace,
    getValue: getSelectedParkingSpace,
  } = Watcher<null | EsCityParkingSpaceDTO>(null);

  const { watchValue: watchDisplayZone, setValue: setDisplayZone } = Watcher<
    boolean
  >(false);

  const {
    watchValue: watchIsEditingPolygon,
    setValue: setIsEditingPolygon,
    getValue: getIsEditingPolygon,
  } = Watcher<boolean>(false);

  const {
    watchValue: watchParkingSpaceWithNewPolygons,
    setValue: setParkingSpaceWithNewPolygons,
  } = Watcher<EsCityParkingSpaceDTO | null>(null);

  const selectParkingSpace = (
    mapId: MapId,
    esCityParkingSpaceDTO: EsCityParkingSpaceDTO
  ) => {
    selectedId = esCityParkingSpaceDTO.parkingSpaceId;
    setSelectedParkingSpace(esCityParkingSpaceDTO);
    const points: Point[] = esCityParkingSpaceDTO.geometry.coordinates[0].map(
      c => ({
        latitude: c[1],
        longitude: c[0],
      })
    );
    mapService.get(mapId)?.center?.set(points[0]);
    mapService.get(mapId)?.zoom?.set(20);
    return esCityParkingSpaceDTO;
  };

  const convertToPolygon = (
    mapId: MapId,
    esCityParkingSpaceDTO: EsCityParkingSpaceDTO,
    hasModification?: boolean
  ): PolygonWrapper => {
    const points: Point[] = esCityParkingSpaceDTO.geometry.coordinates[0].map(
      e => {
        return { latitude: e[1], longitude: e[0] };
      }
    );
    // we probably have a closed polygon [A, B, C, D, A] but google awaits an open one [A, B, C, D]
    // therefore, we will pop the last one
    if (isEqual(points[0], getLast(points))) {
      points.pop();
    }

    const isEditable =
      (selectedId === esCityParkingSpaceDTO.parkingSpaceId &&
        interactiveMode === InteractiveMode.EDIT) ??
      false;

    const polygonListeners = new Map();
    polygonListeners.set('click', (polygon: { id: string }) => {
      if (interactiveMode === InteractiveMode.EDIT) {
        // Focus
        mapService.get(mapId)?.center?.set(points[0]);
        mapService.get(mapId)?.zoom?.set(20);

        // Handle multi select
        if (parkingspaceNeutralization.getIsSelecting()) {
          parkingspaceNeutralization.addToSelection(esCityParkingSpaceDTO);
          void services.parkingSpaceMap.refresh(MapId.PARKING_SPACE);
          return;
        }
      }

      const shouldSelectParkingSpace =
        interactiveMode === InteractiveMode.EDIT ||
        interactiveMode === InteractiveMode.SELECT;
      if (shouldSelectParkingSpace) {
        // Handle simple select
        selectedId = polygon.id;
        selectParkingSpace(mapId, esCityParkingSpaceDTO);
      }
    });

    const pathListeners = new Map();
    ['insert_at', 'set_at', 'remove_at'].forEach(eventName => {
      pathListeners.set(eventName, (polygon: google.maps.Polygon) => {
        if (interactiveMode === InteractiveMode.EDIT) {
          setIsEditingPolygon(true);
          esCityParkingSpaceDTO.geometry.coordinates[0] = polygon
            .getPath()
            .getArray()
            .map(point => [point.lng(), point.lat()]);
          setParkingSpaceWithNewPolygons(cloneDeep(esCityParkingSpaceDTO));
        }
      });
    });

    return {
      id: esCityParkingSpaceDTO.parkingSpaceId,
      name: esCityParkingSpaceDTO.parkingSpaceId,
      type: DrawingType.PARKING_SPACE,
      points,
      color: !hasModification
        ? getColorByRegime(esCityParkingSpaceDTO)
        : '#009933',
      zIndex: 1000,
      visible: true,
      editable: isEditable,
      canBeEdited: interactiveMode === InteractiveMode.EDIT,
      polygonListeners,
      pathListeners,
    };
  };

  const drawEsParkingSpaces = (mapId: MapId) => {
    const neutralizedSelection = parkingspaceNeutralization.getSelection();
    const allPolygons = displayedParkings.map(parking =>
      convertToPolygon(mapId, parking, false)
    );
    const basePolygonColorized = allPolygons.map(polygon => {
      if (
        selectedId === polygon.id ||
        neutralizedSelection.some(e => e.parkingSpaceId === polygon.id)
      ) {
        polygon.color = '#00FF00';
      }
      return polygon;
    });

    mapService.get(mapId)?.clear?.drawingType(DrawingType.PARKING_SPACE);
    mapService.get(mapId)?.polygons?.add([...basePolygonColorized]);
  };

  const setupAutoRefresh = (mapId: MapId) => {
    if (currentInterval !== null) {
      return;
    }

    currentInterval = setInterval(() => {
      if (getIsEditingPolygon()) {
        return;
      }
      const map = mapService.get(mapId);
      if (map === undefined) {
        return;
      }
      const mapCenter = map.center.get();
      const newCenter = JSON.stringify(mapCenter);
      const mapZoom = map.zoom.get();
      // center is [0, 0] if the map is not loaded
      if (
        (mapCenter.latitude === 0 && mapCenter.longitude === 0) ||
        (newCenter === currentCenter && mapZoom === currentZoom)
      ) {
        return;
      }
      currentCenter = newCenter;
      currentZoom = mapZoom;

      const boundingBox = map.viewPort.get();
      void parkingSpaceApiService
        .fetchInsideBoundingBox(boundingBox)
        .then(response => {
          displayedParkings = response;
          drawEsParkingSpaces(mapId);
        });
    }, 1000);
  };

  const cancelAutoRefresh = () => {
    if (currentInterval !== null) {
      clearInterval(currentInterval);
      currentInterval = null;
      currentCenter = '';
    }
  };

  const setSelectedId = async (
    mapId: MapId,
    newSelectedId: string,
    updateZoomAndCenter?: boolean
  ) => {
    selectedId = newSelectedId;
    const esCityParkingSpaceDTO = await parkingSpaceApiService.fetchOneById(
      newSelectedId
    );
    setSelectedParkingSpace(esCityParkingSpaceDTO);
    const points: Point[] = esCityParkingSpaceDTO.geometry.coordinates[0].map(
      c => ({
        latitude: c[1],
        longitude: c[0],
      })
    );
    if (updateZoomAndCenter) {
      mapService.get(mapId)?.center?.set(points[0]);
      mapService.get(mapId)?.zoom?.set(20);
    }
    return esCityParkingSpaceDTO;
  };

  const refresh = async (mapId: MapId) => {
    const map = mapService.get(mapId);
    if (map === undefined) {
      return;
    }
    const boundingBox = map.viewPort.get();
    displayedParkings = await parkingSpaceApiService.fetchInsideBoundingBox(
      boundingBox
    );

    const selectParkingSpace = getSelectedParkingSpace();
    if (selectParkingSpace !== null) {
      setSelectedParkingSpace(cloneDeep(selectParkingSpace));
    }

    drawEsParkingSpaces(mapId);
  };

  const setInteractiveMode = (newInteractiveMode: InteractiveMode) => {
    interactiveMode = newInteractiveMode;
  };

  const setDisplayZoneWrapper = (mapId: MapId, newValue: boolean) => {
    setDisplayZone(newValue);
    if (newValue) {
      services.zoning.drawZonesOnMap(mapId);
    } else {
      services.zoning.clearZonesOnMap(mapId);
    }
  };

  return {
    watchDisplayZone,
    setDisplayZone: setDisplayZoneWrapper,
    setupAutoRefresh,
    cancelAutoRefresh,
    setSelectedId,
    watchSeletected,
    refresh,
    selectParkingSpace,
    closeSelection: (mapId: MapId) => {
      selectedId = '';
      setSelectedParkingSpace(null);
      drawEsParkingSpaces(mapId);
    },
    setInteractiveMode,
    watchIsEditingPolygon,
    setIsEditingPolygon,
    watchParkingSpaceWithNewPolygons,
    setParkingSpaceWithNewPolygons,
  };
};

export default ParkingSpaceMapService;
