import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { InternalAgent } from 'api/auth/types';

export interface UserInfoServiceFactory {
  (): UserInfoServiceInterface;
}

export interface UserInfoServiceInterface {
  init: (userInfo: InternalAgent) => void;
  getUserInfo: () => InternalAgent | null;
  watchUserInfo: WatchFunctionType<InternalAgent | null>;
}

const UserInfoService: UserInfoServiceFactory = () => {
  const {
    getValue: getUserInfo,
    setValue: setUserInfo,
    watchValue: watchUserInfo,
  } = Watcher<null | InternalAgent>(null);

  const init = (userInfo: InternalAgent): void => {
    setUserInfo(userInfo);
  };

  return {
    init,
    getUserInfo,
    watchUserInfo,
  };
};

export default UserInfoService;
