import { cloneDeep } from 'lodash';

import { Getter, Setter, WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import {
  EsCityParkingSpaceDTO,
  ParkingSpaceRegime,
} from '@cvfm-front/tefps-types';

import { ParkingSpaceServiceApiInterface } from './ParkingSpaceApiService';

export interface ParkingSpaceChangeRegimeServiceFactory {
  (
    parkingSpaceApi: ParkingSpaceServiceApiInterface
  ): ParkingSpaceChangeRegimeServiceInterface;
}

export interface ParkingSpaceChangeRegimeServiceInterface {
  addToSelection: (id: EsCityParkingSpaceDTO) => void;
  reset: () => void;
  setIsSelecting: Setter<boolean>;
  getIsSelecting: Getter<boolean>;
  watchIsSelecting: WatchFunctionType<boolean>;
  saveToAPI: () => Promise<void>;
  watchSelection: WatchFunctionType<EsCityParkingSpaceDTO[]>;
  getSelection: Getter<EsCityParkingSpaceDTO[]>;
  watchIsChangingRegime: WatchFunctionType<boolean>;
  setIsChangingRegime: Setter<boolean>;
  getIsChangingRegime: Getter<boolean>;
  watchRequest: WatchFunctionType<ChangeRegime>;
  setRequest: Setter<ChangeRegime>;
}

export interface ChangeRegime {
  startDatetime: string;
  endDatetime: string | null;
  parkingRegime: ParkingSpaceRegime;
}

export function ChangeRegimeFactory(): ChangeRegime {
  return {
    startDatetime: new Date().toISOString(),
    endDatetime: null,
    parkingRegime: ParkingSpaceRegime.PAID,
  };
}

const ParkingSpaceChangeRegimeService: ParkingSpaceChangeRegimeServiceFactory = parkingSpaceApi => {
  const {
    watchValue: watchIsSelecting,
    setValue: setIsSelecting,
    getValue: getIsSelecting,
  } = Watcher<boolean>(false);
  const {
    watchValue: watchIsChangingRegime,
    setValue: setIsChangingRegime,
    getValue: getIsChangingRegime,
  } = Watcher<boolean>(false);
  const {
    watchValue: watchSelection,
    setValue: setSelection,
    getValue: getSelection,
  } = Watcher<EsCityParkingSpaceDTO[]>([]);
  const {
    watchValue: watchRequest,
    setValue: setRequest,
    getValue: getRequest,
  } = Watcher<ChangeRegime>(ChangeRegimeFactory());

  const reset = () => {
    setSelection([]);
    setIsSelecting(false);
    setIsChangingRegime(false);
    setRequest(ChangeRegimeFactory());
  };

  const saveToAPI = async () => {
    const parkingSpacesIds = getSelection();
    const promises: Promise<unknown>[] = [];
    const form = getRequest();
    parkingSpacesIds.forEach(parkingSpace => {
      const numberOfVersions = parkingSpace.versions.length;
      const lastVersion = parkingSpace.versions[numberOfVersions - 1];
      const newVersion = cloneDeep(lastVersion);
      newVersion.startDatetime = form.startDatetime;
      newVersion.endDatetime = form.endDatetime;
      newVersion.parkingRegime = form.parkingRegime;
      parkingSpace.versions.push(newVersion);
      promises.push(parkingSpaceApi.updateParkingSpace(parkingSpace));
    });
    await Promise.all(promises);
    reset();
  };

  const addToSelection = (parkingSpace: EsCityParkingSpaceDTO) => {
    const selection = getSelection();
    const isAlreadySelected = selection.some(ps => ps.id === parkingSpace.id);
    if (isAlreadySelected) {
      setSelection(selection.filter(ps => ps.id !== parkingSpace.id));
    } else {
      setSelection([...getSelection(), parkingSpace]);
    }
  };

  return {
    addToSelection,
    getIsChangingRegime,
    getIsSelecting,
    getSelection,
    reset,
    saveToAPI,
    setIsChangingRegime,
    setIsSelecting,
    watchIsChangingRegime,
    watchIsSelecting,
    watchSelection,
    watchRequest,
    setRequest,
  };
};

export default ParkingSpaceChangeRegimeService;
