import React, { CSSProperties } from 'react';
import Checkbox from 'material-ui/Checkbox';
import ArrowDropRight from 'material-ui/svg-icons/navigation-arrow-drop-right';
import ArrowDropDown from 'material-ui/svg-icons/navigation/arrow-drop-down';

import { BKG_CYAN } from 'theme';
import { ItemIdName } from 'api/commonTypes';

import RemovableCriteria from './RemovableCriteria';

const BASE_STYLE: CSSProperties = {
  borderRadius: 5,
  padding: 8,
  backgroundColor: 'white',
  fontFamily: 'Roboto',
  color: 'black',
  textAlign: 'center',
};

const STYLE_CONTAINER: CSSProperties = {
  ...BASE_STYLE,
  border: `1px solid ${BKG_CYAN}`,
};

const STYLE_CONTAINER_FOLDED_UP: CSSProperties = {
  ...STYLE_CONTAINER,
  cursor: 'pointer',
  display: 'flex',
};

const STYLE_TITLE: CSSProperties = {
  ...BASE_STYLE,
  cursor: 'pointer',
  display: 'flex',
};

const initializeState = (
  propsCheckedIds: Array<any>,
  items: Array<ItemIdName>
) => {
  const checkedIds = new Set(propsCheckedIds);
  const allchecked = checkedIds.size === items.length;
  return { expanded: false, checkedIds, allchecked };
};

const updateState = (propsCheckedIds: Array<any>, items: Array<ItemIdName>) => {
  const checkedIds = new Set(propsCheckedIds);
  const allchecked = checkedIds.size === items.length;
  return { checkedIds, allchecked };
};

type Props = {
  items: Array<ItemIdName>;
  checkedIds: Array<any>;
  title: string;
  itemNameSingular: string;
  itemNamePlural: string;
  save: Function;
  remove?: (event: any) => void;
  disabled: boolean;
};

type State = {
  checkedIds: Set<string>;
  expanded: boolean;
  allchecked: boolean;
};

class MultiSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initializeState(props.checkedIds, props.items);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.checkedIds !== nextProps.checkedIds) {
      this.setState(updateState(nextProps.checkedIds, nextProps.items));
    }
  }

  onCheck = (
    { currentTarget }: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => {
    if (currentTarget.dataset.itemId) {
      const newCheckedIds = new Set(this.state.checkedIds);
      if (isInputChecked) {
        newCheckedIds.add(currentTarget.dataset.itemId);
      } else {
        newCheckedIds.delete(currentTarget.dataset.itemId);
      }
      const allchecked = newCheckedIds.size === this.props.items.length;
      this.setState({ checkedIds: newCheckedIds, allchecked });
    }
  };

  checkAll = (
    event: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => {
    const { items } = this.props;
    const newCheckedIds = isInputChecked
      ? new Set<string>(items.map(i => i.id))
      : new Set<string>();
    this.setState({ checkedIds: newCheckedIds, allchecked: isInputChecked });
  };

  expand = () => {
    this.setState({ expanded: true });
  };

  saveChecked = () => {
    const { disabled, save } = this.props;
    const { checkedIds } = this.state;
    if (!disabled) {
      save([...checkedIds]);
    }
  };

  collapse = () => {
    this.setState({ expanded: false });
  };

  render() {
    const {
      items,
      title,
      itemNameSingular,
      itemNamePlural,
      remove,
      disabled,
    } = this.props;
    const { checkedIds, expanded, allchecked } = this.state;

    const arrow = expanded ? (
      <ArrowDropDown
        style={{
          verticalAlign: 'middle',
          marginTop: '-4px',
          display: 'inline-block',
          left: '3px',
        }}
      />
    ) : (
      <ArrowDropRight
        style={{
          verticalAlign: 'middle',
          marginTop: '-4px',
          display: 'inline-block',
          left: '3px',
        }}
      />
    );

    const plural = checkedIds.size <= 1 ? itemNameSingular : itemNamePlural;

    if (expanded) {
      return (
        <div style={STYLE_CONTAINER} onMouseLeave={this.saveChecked}>
          <div style={STYLE_TITLE} onClick={this.collapse}>
            <span>{arrow}</span>
            <span style={{ flex: 1 }}>{`${checkedIds.size} ${plural}`}</span>
          </div>
          <br />
          <div>
            <Checkbox
              onCheck={this.checkAll}
              checked={allchecked}
              disabled={disabled}
              label={title}
              labelPosition="left"
              labelStyle={{ fontStyle: 'bold' }}
            />
          </div>
          <hr />
          <div>
            {items.map(item => (
              <Checkbox
                key={item.id}
                onCheck={this.onCheck}
                disabled={disabled}
                checked={checkedIds.has(item.id)}
                data-item-id={item.id}
                label={item.name}
                labelPosition="left"
              />
            ))}
          </div>
        </div>
      );
    }

    if (!disabled && remove) {
      return (
        <div style={STYLE_CONTAINER_FOLDED_UP} onClick={this.expand}>
          <RemovableCriteria deleteFunc={remove}>
            <div>{arrow}</div>
            <div style={{ flex: 1 }}>{`${checkedIds.size} ${plural}`}</div>
          </RemovableCriteria>
        </div>
      );
    }

    return (
      <div style={STYLE_CONTAINER_FOLDED_UP} onClick={this.expand}>
        <span>{arrow}</span>
        <span style={{ flex: 1 }}>{`${checkedIds.size} ${plural}`}</span>
      </div>
    );
  }
}

export default MultiSelect;
