import React from 'react';
import Dialog from 'material-ui/Dialog';
import { CircularProgress } from 'material-ui';

import FlexCenter from 'commons/FlexCenter';

type Props = {
  open: boolean;
  message: string;
  title: string;
};

const OnUploadMoal = ({ open, message, title }: Props) => {
  return (
    <Dialog title={title} modal open={open}>
      <span>{message}</span>
      <FlexCenter>
        <CircularProgress />
      </FlexCenter>
    </Dialog>
  );
};

export default OnUploadMoal;
