import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { ColorResult, HuePicker } from 'react-color';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import BoButton from 'facade/BoButton';
import { TrigramReduxProps } from 'tefps/Trigram/types';
import { SubscriptionConfigurationDTO, ZoneDTO } from '@cvfm-front/tefps-types';
import ErrorBlock from 'commons/ErrorBlock';
import { getConfigState } from 'config/duck';
import { BKG_LIGHT_BLUE, STYLE_ERROR_WRAPPER } from 'theme';
import { generateTrigramId } from 'api/pricing';
import { fetchOrganizations } from 'api/organizations';
import {
  validateMandatoryInput,
  validateMandatoryInputWithoutSpace,
  validateTrigramIdCustomPart,
} from 'commons/Validators';

import { ZoningNames } from './types';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: 'white',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_LIGHT_BLUE,
  color: BKG_LIGHT_BLUE,
};

type AddZoneProps = TrigramReduxProps & {
  isOpen: number;
  existingZone: ZoneDTO | null | undefined;
  close: () => any;
  save: Function;
  naming: ZoningNames;
  organizationFilterEnabled?: boolean;
  subscriptionConfigurationDTO: SubscriptionConfigurationDTO; // from store redux connect
};

type AddZoneState = {
  error: string | null | undefined;
  name: string;
  color: string;
  secondaryColor: string;
  id: string;
  virtual: boolean;
  alias: string;
  errors: {
    [key: string]: string | null | undefined;
  };
  organizations: Array<{ id: string; name: string }>;
  organizationId: string | null;
};

const INITIAL_STATE = {
  error: null,
  virtual: false,
  name: '',
  color: '#ff0000',
  secondaryColor: '#ff0000',
  errors: {},
};

const initStateFromProps = ({ existingZone }: AddZoneProps): AddZoneState => {
  return {
    error: null,
    virtual: existingZone ? existingZone.virtual : false,
    name: existingZone ? existingZone.name : '',
    color: existingZone ? existingZone.color : '#ff0000',
    secondaryColor:
      existingZone && existingZone.secondaryColor
        ? existingZone.secondaryColor
        : '#ff0000',
    id: existingZone ? existingZone.id : '',
    errors: {},
    alias:
      existingZone && existingZone.alias ? existingZone.alias.join(',') : '',
    organizations: [],
    organizationId: existingZone ? existingZone.organizationId : null,
  };
};

class AddZone extends React.Component<AddZoneProps, AddZoneState> {
  constructor(props: AddZoneProps) {
    super(props);
    this.state = initStateFromProps(props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: AddZoneProps) {
    this.setState(initStateFromProps(newProps));
    if (newProps.organizationFilterEnabled) {
      this.fetchOrganizations();
    }
  }

  fetchOrganizations = async () => {
    try {
      const orgs = await fetchOrganizations(false, 'fps');
      this.setState({
        organizations: orgs.map(o => ({
          id: o.organizationId,
          name: o.name,
        })),
      });
    } catch (e) {
      this.setState({
        error: e,
      });
    }
  };

  onChangeOrganization = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    organizationId: string
  ) => {
    this.setState({ organizationId });
  };

  onClose = () => {
    this.props.close();
    this.setState({ ...INITIAL_STATE });
  };

  onClickValidate = async () => {
    const { save, trigram, trigramIdFormatter, isOpen } = this.props;
    const {
      name,
      color,
      secondaryColor,
      id,
      alias,
      virtual,
      organizationId,
    } = this.state;
    const body: {
      name: string;
      color: string;
      secondaryColor: string;
      id: string;
      virtual: boolean;
      lastModified: null;
      polygonIds: null;
      alias: Array<string>;
      organizationId: string | null;
    } = {
      name,
      color,
      secondaryColor,
      id,
      virtual,
      lastModified: null,
      polygonIds: null,
      alias: [],
      organizationId,
    };

    const errors: {
      id: string | null | undefined;
      name: string | null | undefined;
      alias: string | undefined;
    } = {
      id: trigramIdFormatter
        ? validateTrigramIdCustomPart(id)
        : validateMandatoryInputWithoutSpace(id),
      name: validateMandatoryInput(name),
      alias: undefined,
    };

    // We only want to generate trigram Id on creation
    if (isOpen === 1 && trigramIdFormatter) {
      if (id !== '') {
        body.id = `Z_${trigram}_${id}`;
      } else if (id === '' && name !== '') {
        const { trigramId } = await generateTrigramId(name);
        body.id = trigramId;
      }
    }

    try {
      body.alias = alias
        .split(',')
        .map(a => a.trim())
        .filter(a => a !== '');
    } catch (e) {
      errors.alias = _t('feedback.error.badAliasFormat');
    }

    if (!Object.values(errors).some(v => !!v)) {
      try {
        save(body);
        this.setState({ error: null, errors: {} });
      } catch (error) {
        this.setState({ error: _tg('feedback.error.generic') });
      }
    }
    this.setState({ errors });
  };

  onChangeName = (e: React.ChangeEvent<HTMLInputElement>, name: string) =>
    this.setState({ name });
  onChangeAlias = (e: React.ChangeEvent<HTMLInputElement>, alias: string) =>
    this.setState({ alias });
  onCheck = (e: React.MouseEvent<{}>, virtual: boolean) => {
    this.setState({ virtual });
  };

  changeId = (e: React.ChangeEvent<HTMLInputElement>, id: string) =>
    this.setState({ id });

  handleChangeColor = (color: ColorResult) => {
    this.setState({ color: color.hex });
  };

  handleChangeSecondaryColor = (secondaryColor: ColorResult) => {
    this.setState({ secondaryColor: secondaryColor.hex });
  };

  render() {
    const {
      isOpen,
      trigram,
      trigramIdFormatter,
      naming,
      organizationFilterEnabled,
    } = this.props;
    const {
      error,
      name,
      color,
      secondaryColor,
      id,
      alias,
      virtual,
      organizations,
      organizationId,
    } = this.state;

    const actions = [
      <BoButton
        style={{ marginRight: 10 }}
        key={1}
        label={_tg('action.cancel')}
        onClick={this.onClose}
      />,
      <BoButton
        key={2}
        label={isOpen === 2 ? _tg('action.edit') : _tg('action.add')}
        primary
        keyboardFocused
        onClick={this.onClickValidate}
      />,
    ];

    return (
      <Dialog
        actions={actions}
        title={
          isOpen === 1
            ? _t('element.dialog.title_open', {
                withArticle: naming.withArticle,
              })
            : _t('element.dialog.title', { withArticle: naming.withArticle })
        }
        open={isOpen === 1 || isOpen === 2}
        onRequestClose={this.onClose}
        titleStyle={STYLE_TITLE}
      >
        <div style={STYLE_CONTENT_WRAPPER}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isOpen !== 2 && trigramIdFormatter && (
              <span
                style={{ marginTop: 32, marginRight: 10 }}
              >{`Z_${trigram}_`}</span>
            )}
            <TextField
              underlineFocusStyle={STYLE_INPUTS}
              floatingLabelFocusStyle={STYLE_INPUTS}
              value={id}
              disabled={isOpen === 2}
              onChange={this.changeId}
              floatingLabelText={_t('element.zoneId.floatingLabelText', {
                withDeterminant: naming.withDeterminant,
              })}
              errorText={this.state.errors.id}
            />
          </div>
          <TextField
            underlineFocusStyle={STYLE_INPUTS}
            floatingLabelFocusStyle={STYLE_INPUTS}
            value={name}
            onChange={this.onChangeName}
            floatingLabelText={_t('element.zoneName.floatingLabelText', {
              withDeterminant: naming.withDeterminant,
            })}
            errorText={this.state.errors.name}
          />
        </div>
        <div style={{ marginTop: 14 }}>
          {_t('element.zoneColor', { withDeterminant: naming.withDeterminant })}
          <br />
          <br />
          <HuePicker
            color={color}
            width="100%"
            onChangeComplete={this.handleChangeColor}
          />
        </div>
        {this.props.subscriptionConfigurationDTO &&
          this.props.subscriptionConfigurationDTO.adV3Enabled && (
            <div style={{ marginTop: 14 }}>
              {_t('element.zoneSecondaryColor', {
                withDeterminant: naming.withDeterminant,
              })}
              <br />
              <br />
              <HuePicker
                color={secondaryColor}
                width="100%"
                onChangeComplete={this.handleChangeSecondaryColor}
              />
            </div>
          )}
        <div style={{ marginTop: 14 }}>
          <Checkbox
            style={{ width: '100%' }}
            onCheck={this.onCheck}
            checked={virtual}
            label={_t('element.virtual.label', {
              withVirtual: naming.withVirtual,
            })}
          />
        </div>
        <div>
          <TextField
            underlineFocusStyle={STYLE_INPUTS}
            floatingLabelFocusStyle={STYLE_INPUTS}
            value={alias}
            onChange={this.onChangeAlias}
            floatingLabelText={_t('element.alias.floatingLabelText', {
              withDeterminant: naming.withDeterminant,
            })}
            errorText={this.state.errors.alias}
            fullWidth
          />
        </div>
        {organizationFilterEnabled && (
          <div>
            <SelectField
              floatingLabelFixed
              floatingLabelText={_tg('field.organisation.organisation')}
              hintText={_tg('field.organisation.name')}
              value={organizationId}
              onChange={this.onChangeOrganization}
              fullWidth
            >
              {organizations.map(org => (
                <MenuItem key={org.id} value={org.id} primaryText={org.name} />
              ))}
            </SelectField>
          </div>
        )}
        {error && (
          <div style={STYLE_ERROR_WRAPPER}>
            <ErrorBlock error={{ message: error }} />
          </div>
        )}
      </Dialog>
    );
  }
}

export default connect(state => {
  const {
    trigram,
    trigramIdFormatter,
    subscriptionConfigurationDTO,
  } = getConfigState(state);
  return { trigram, trigramIdFormatter, subscriptionConfigurationDTO };
})(AddZone);
