import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';

import { BKG_LIGHT_BLUE } from 'theme';
import { CcspRecourseDTO } from 'api/ccsp/types';

import {
  validateCcspNumber,
  validateFpsId,
} from '../../../tefps/CcspRecourses/List/AddCcsp/helper';
import { patchCcspRecourse } from '../../../api/ccsp';

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: '#ffffff',
  fontWeight: 'bold',
};

interface ICcspErrors {
  ccspNumber?: string;
  fpsId?: string;
}

type ModalProps<T> = {
  isOpen: boolean;
  onClose: () => void;
  recourse: T;
  applyChanges: (recourse: T) => void;
  showMessage: (message: string) => void;
};

type ModalState = {
  ccspNumber: string;
  fpsId: string;
  errors: ICcspErrors;
};

class Modal extends React.Component<ModalProps<CcspRecourseDTO>, ModalState> {
  constructor(props: ModalProps<CcspRecourseDTO>) {
    super(props);

    this.state = {
      ccspNumber: props.recourse.ccspNumber,
      fpsId: props.recourse.fpsId,
      errors: {} as ICcspErrors,
    };
  }

  onChangeCcspNumber = (
    event: React.MouseEvent<any>,
    ccspNumber: string
  ): void => this.setState({ ccspNumber });

  onChangeFpsId = (event: React.MouseEvent<any>, fpsId: string): void =>
    this.setState({ fpsId });

  onSave = async (): Promise<void> => {
    const { showMessage, applyChanges, onClose, recourse } = this.props;
    const { ccspNumber, fpsId } = this.state;
    const errors = {
      ccspNumber: validateCcspNumber(ccspNumber),
      fpsId: validateFpsId(fpsId, false),
    };

    if (errors.ccspNumber || errors.fpsId) {
      this.setState({ errors });
    } else {
      const patch = [
        {
          path: '/ccspNumber',
          op: 'replace',
          value: ccspNumber,
        },
        {
          path: '/fpsId',
          op: 'replace',
          value: fpsId,
        },
      ];

      try {
        const updatedRecourse = await patchCcspRecourse(recourse.id, patch);
        applyChanges(updatedRecourse);
        onClose();
        showMessage('Modifications enregistrées');
      } catch (e) {
        if (e.message) {
          showMessage(e.message);
        } else {
          showMessage(
            "Une erreur s'est produite pendant la modification des informations"
          );
        }
      }
    }
  };

  render(): React.ReactNode {
    const { isOpen, onClose } = this.props;
    const { ccspNumber, fpsId, errors } = this.state;
    return (
      <Dialog
        title="Modifier les informations de la saisine"
        open={isOpen}
        titleStyle={STYLE_TITLE}
        modal
        autoScrollBodyContent
        actions={[
          <FlatButton label="Annuler" onClick={onClose} />,
          <FlatButton label="Enregistrer" primary onClick={this.onSave} />,
        ]}
      >
        <div>
          <TextField
            title="8 chiffres"
            floatingLabelText="n° de la saisine"
            value={ccspNumber}
            onChange={this.onChangeCcspNumber}
            errorText={errors.ccspNumber}
          />
        </div>
        <div>
          <TextField
            title="26 chiffres"
            floatingLabelText="n° du fps lié"
            value={fpsId}
            onChange={this.onChangeFpsId}
            errorText={errors.fpsId}
          />
        </div>
      </Dialog>
    );
  }
}

export default Modal;
