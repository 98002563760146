import React, { CSSProperties } from 'react';

import { BKG_PINK } from 'theme';

const STYLE_CONTAINER: CSSProperties = {
  width: 80,
  display: 'flex',
};

const STYLE_CONTAINER_BAR: CSSProperties = {
  width: 30,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const STYLE_BASE_BAR: CSSProperties = {
  width: 1,
  backgroundColor: '#CACACA',
};

const STYLE_BASE_TOP: CSSProperties = {
  ...STYLE_BASE_BAR,
  flex: 1,
};

const STYLE_BASE_TOP_FIRST: CSSProperties = {
  ...STYLE_BASE_BAR,
  flex: 1,
  width: 0,
};

const STYLE_BASE_BOTTOM: CSSProperties = {
  ...STYLE_BASE_BAR,
  flex: 1,
};

const STYLE_BASE_BOTTOM_LAST: CSSProperties = {
  ...STYLE_BASE_BOTTOM,
  width: 0,
};

const STYLE_TIMELINE_POINT: CSSProperties = {
  width: 20,
  height: 20,
  borderRadius: '50%',
  backgroundColor: BKG_PINK,
};

type Props = {
  isFirst?: boolean;
  isLast?: boolean;
  filler?: boolean;
  style?: Record<string, any>;
};

const TimelinePoint = ({ isFirst, isLast, filler, style }: Props) => {
  const topBarStyle =
    isFirst || (isLast && filler) ? STYLE_BASE_TOP_FIRST : STYLE_BASE_TOP;
  const bottomBarStyle = isLast ? STYLE_BASE_BOTTOM_LAST : STYLE_BASE_BOTTOM;

  return (
    <div style={{ ...STYLE_CONTAINER, ...style }}>
      <div style={STYLE_CONTAINER_BAR}>
        <div style={topBarStyle} />
        {!filler && <div style={STYLE_TIMELINE_POINT} />}
        <div style={bottomBarStyle} />
      </div>
    </div>
  );
};

export default TimelinePoint;
