import * as React from 'react';

import { KeyBucketDTO } from 'api/commonTypes';

import Checkboxes from './Checkboxes';

type Props = {
  id: string;
  title: string;
  filters: Set<string>;
  faceting?: Array<KeyBucketDTO> | null | undefined;
  onChange: (id: string, filters: Set<string>) => void;
};

const DynamicCheckboxes = ({ faceting, ...otherProps }: Props) => {
  if (!faceting) return null;

  // Build options from faceting
  const options = faceting.map(f => ({ value: f.key, label: f.key }));

  return <Checkboxes {...otherProps} faceting={faceting} options={options} />;
};

export default DynamicCheckboxes;
