import React, { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

import { STYLE_TITLE_BORDER } from 'theme';
import { twoDecimalPrice } from 'commons/Utils/paymentUtil';

import {
  INLINE_TEXT_FIELD_STYLE,
  STYLE_BLOCK_TITLE,
  STYLE_INPUT_TEXT,
} from '../theme';

const { _t } = window.loadTranslations(__filename);

const STYLE_BLOCK: CSSProperties = {
  marginTop: 20,
};

export type PriceRangeFilter = {
  from: number | undefined;
  to: number | undefined;
};

type PriceRangeProps = {
  id: string;
  title: string;
  price: PriceRangeFilter;
  onChange: (id: string, price: PriceRangeFilter) => void;
};

class PriceRange extends React.Component<PriceRangeProps> {
  onFromChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    amount: string
  ) => {
    const { id, onChange, price } = this.props;
    onChange(id, { from: twoDecimalPrice(price.from, amount), to: price.to });
  };

  onToChange = (event: React.ChangeEvent<HTMLInputElement>, amount: string) => {
    const { id, onChange, price } = this.props;
    onChange(id, { from: price.from, to: twoDecimalPrice(price.to, amount) });
  };

  clearPriceFilter = () => {
    const { id, onChange } = this.props;
    onChange(id, { to: NaN, from: NaN });
  };

  render() {
    const { title, price } = this.props;

    return (
      <div style={STYLE_BLOCK}>
        <div style={STYLE_BLOCK_TITLE}>
          {title}
          <hr style={STYLE_TITLE_BORDER} />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <span style={STYLE_INPUT_TEXT}>{_t('element.from')}</span>
          <div style={{ flex: 1 }}>
            <TextField
              id="fromAmount"
              type="number"
              step={0.01}
              underlineShow={false}
              inputStyle={STYLE_INPUT_TEXT}
              value={price.from}
              onChange={this.onFromChange}
              style={INLINE_TEXT_FIELD_STYLE}
            />
          </div>
          <span style={{ ...STYLE_INPUT_TEXT, marginLeft: 2 }}>
            <span style={{ ...STYLE_INPUT_TEXT, marginRight: 8 }}>
              {_t('element.currency')}
            </span>
            {_t('element.to')}
          </span>
          <div style={{ flex: 1 }}>
            <TextField
              id="toAmount"
              type="number"
              step={0.01}
              underlineShow={false}
              inputStyle={STYLE_INPUT_TEXT}
              value={price.to}
              onChange={this.onToChange}
              style={INLINE_TEXT_FIELD_STYLE}
            />
          </div>
          <span style={{ ...STYLE_INPUT_TEXT, marginLeft: 2 }}>
            {_t('element.currency')}
          </span>
          <DeleteIcon
            style={{
              color: 'white',
              marginLeft: 10,
              width: 24,
              height: 24,
              cursor: 'pointer',
            }}
            onClick={this.clearPriceFilter}
          />
        </div>
      </div>
    );
  }
}

export default PriceRange;
