import React, { CSSProperties } from 'react';

import { BRD_GREY } from 'theme';

const STYLE_SEPARATOR: CSSProperties = {
  height: 1,
  backgroundColor: BRD_GREY,
  margin: '25px 0px',
  width: '90%',
};

type Props = {
  style?: Record<string, any>;
};

const SeparatorHorizontal = ({ style }: Props): JSX.Element => (
  <div style={{ ...STYLE_SEPARATOR, ...style }} />
);

export default SeparatorHorizontal;
