import React, { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';
import getCaretCoordinates from 'textarea-caret';

const UP_KEY_CODE = 38;
const DOWN_KEY_CODE = 40;
const ENTER_KEY_CODE = 13;

const TRIGGER_CHARACTER = '{';
const END_CHARACTER = '}';
const TOKEN_REGEX = new RegExp(`(?:${TRIGGER_CHARACTER})([a-z_0-9]*)$`);

const STYLE_SUGGESTIONS: CSSProperties = {
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  position: 'fixed',
  color: '#FFF',
  display: 'block',
  zIndex: 10,
  marginTop: '1em',
  padding: 2,
};

const STYLE_SUGGESTED_FIELD: CSSProperties = {
  cursor: 'pointer',
  padding: 2,
};

const STYLE_SELECTED_FIELD: CSSProperties = {
  ...STYLE_SUGGESTED_FIELD,
  backgroundColor: 'rgba(180, 180, 180, 0.5)',
  fontWeight: 'bold',
};

const STYLE_CONTAINER: CSSProperties = {
  width: '100%',
  marginLeft: 20,
  marginRight: 30,
};

type Field = { field: string; legend: string | null | undefined };

type ItemProps = {
  idx: number;
  field: string;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseOver: (event: React.MouseEvent<HTMLDivElement>) => void;
  highlight: boolean;
};

type SuggestionsListProps = {
  top: number;
  left: number;
  autocompleteList: Array<Field>;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseOver: (event: React.MouseEvent<HTMLDivElement>) => void;
  selectedId: number;
};

const Item = ({ idx, field, onClick, onMouseOver, highlight }: ItemProps) => (
  <div
    data-idx={idx}
    onMouseOver={onMouseOver}
    style={highlight ? STYLE_SELECTED_FIELD : STYLE_SUGGESTED_FIELD}
    data-field={field}
    onClick={onClick}
  >
    {TRIGGER_CHARACTER + field + END_CHARACTER}
  </div>
);

const SuggestionsList = ({
  top,
  left,
  autocompleteList,
  onClick,
  onMouseOver,
  selectedId,
}: SuggestionsListProps): React.ReactElement<any> =>
  autocompleteList.length > 0 ? (
    <div style={{ ...STYLE_SUGGESTIONS, top, left }}>
      {autocompleteList.map(({ field }, i) => (
        <Item
          key={field}
          idx={i}
          field={field}
          highlight={selectedId === i}
          onMouseOver={onMouseOver}
          onClick={onClick}
        />
      ))}
    </div>
  ) : (
    <span />
  );

type State = {
  top: number;
  left: number;
  specialField: string | null | undefined;
  autocompleteList: Array<Field>;
  selected: number;
};

type Props = {
  hints: Array<Field>;
  rows: number;
  fullWidth?: boolean;
  multiLine?: boolean;
  errorText?: string | null | undefined;
  hintText?: string | null | undefined;
  floatingLabelText?: string | null | undefined;
  value: string;
  disabled?: boolean;
  spellCheck?: boolean;
  onChange: (value: string | null | undefined) => void;
};

class TextAreaWithHints extends React.Component<Props, State> {
  textareaRef: HTMLInputElement | undefined = undefined;
  state: State = {
    top: 0,
    left: 0,
    specialField: null,
    autocompleteList: [],
    selected: 0,
  };

  onChangeText = ({
    currentTarget,
  }: React.ChangeEvent<HTMLTextAreaElement> & {
    currentTarget: HTMLInputElement;
  }) => {
    const { value } = currentTarget;
    const { onChange, hints } = this.props;
    if (this.textareaRef) {
      const position = this.textareaRef.selectionEnd as number;
      // position du curseur dans le textArea
      const { top: carretTop, left: carretLeft } = getCaretCoordinates(
        currentTarget,
        position
      );
      // position du textArea dans la fenêtre
      const {
        top: textAreaTop,
        left: textAreaLeft,
      } = currentTarget.getBoundingClientRect();
      const tokenMatch = TOKEN_REGEX.exec(value.slice(0, position));
      const lastToken = tokenMatch && tokenMatch[0];
      const inputField = tokenMatch && lastToken ? tokenMatch[1] : null;

      this.setState({
        specialField: inputField,
        autocompleteList: inputField
          ? hints.filter(({ field }) => field.includes(inputField))
          : [],
        // position du curseur en pixel :
        top: carretTop + textAreaTop,
        left: carretLeft + textAreaLeft,
      });
    }
    onChange(value);
  };

  autocomplete = ({ currentTarget }: React.MouseEvent<any>) => {
    this.replaceText(currentTarget.dataset.field);
  };

  replaceText = (newText: string) => {
    const { value } = this.props;
    const { specialField } = this.state;
    if (!specialField || !this.textareaRef) {
      return;
    }
    const position = this.textareaRef.selectionEnd as number;
    const replacementText = newText + END_CHARACTER;
    const messagePriorCursor = value.substring(
      0,
      position - specialField.length
    );
    const messagePostCursor = value.substring(position, value.length);
    const newValue = messagePriorCursor + replacementText + messagePostCursor;
    this.setState(
      {
        specialField: null,
        selected: 0,
      },
      () =>
        this.moveCursor(position + replacementText.length - specialField.length)
    );
    this.props.onChange(newValue);
  };

  moveCursor = (newPosition: number) => {
    if (this.textareaRef) {
      this.textareaRef.focus();
      this.textareaRef.selectionEnd = newPosition;
    }
  };

  handleKeyDown = (event: React.KeyboardEvent<any>) => {
    const { specialField, selected, autocompleteList } = this.state;
    if (!specialField || !this.textareaRef) {
      return;
    }
    switch (event.keyCode) {
      case ENTER_KEY_CODE: {
        const newText = autocompleteList[selected];
        if (newText) {
          this.replaceText(newText.field);
        }
        break;
      }
      case DOWN_KEY_CODE: {
        const i = selected < autocompleteList.length - 1 ? selected + 1 : 0;
        this.setState({ selected: i });
        break;
      }
      case UP_KEY_CODE: {
        const j = selected > 0 ? selected - 1 : autocompleteList.length - 1;
        this.setState({ selected: j });
        break;
      }
      default:
        return;
    }
    event.preventDefault();
  };

  hoverSuggestion = ({ currentTarget }: React.MouseEvent<any>) => {
    this.setState({ selected: Number(currentTarget.dataset.idx) });
  };

  attachRef = (node: any) => {
    this.textareaRef = node;
  };

  render() {
    const { top, left, specialField, autocompleteList, selected } = this.state;

    return (
      <div onKeyDown={this.handleKeyDown} style={STYLE_CONTAINER}>
        {specialField && (
          <SuggestionsList
            top={top}
            left={left}
            autocompleteList={autocompleteList}
            selectedId={selected}
            onMouseOver={this.hoverSuggestion}
            onClick={this.autocomplete}
          />
        )}
        <TextField
          {...this.props}
          onChange={this.onChangeText}
          ref={this.attachRef}
        />
      </div>
    );
  }
}

export default TextAreaWithHints;
