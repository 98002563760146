import Hidden from 'material-ui/svg-icons/action/visibility-off';
import Visible from 'material-ui/svg-icons/action/visibility';
import React, { CSSProperties, useState } from 'react';
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

import { ICON_RED, STYLE_TITLE_BORDER, TXT_GREY } from 'theme';

import ConfirmAction from '../../ConfirmAction';

const { _t } = window.loadTranslations(__filename);

const STYLE_VISIBILITY: CSSProperties = {
  margin: '0px 8px 0px 0px',
  width: 16,
  height: 16,
  cursor: 'pointer',
  paddingBottom: 2,
};

const STYLE_NAME: CSSProperties = {
  whiteSpace: 'nowrap',
  width: '59%',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  overflow: 'hidden',
  paddingBottom: 2,
};

const STYLE_BLOCK_TITLE: CSSProperties = {
  fontSize: 15,
  marginBottom: '20px',
  marginTop: '8px',
  fontWeight: 'bold',
  display: 'flex',
  color: TXT_GREY,
};

const STYLE_ACTIONS: CSSProperties = {
  margin: '0px 0px 0px 15px',
  width: 20,
  height: 20,
  cursor: 'pointer',
  paddingBottom: 2,
  color: '#aaa',
};

type Props = {
  canShowParkingMeter: boolean;
  changeParkingMeterVisibility: Function;
  canDeleteParkingMeters: boolean;
  deleteCityParkingMeters: Function;
};

function ShowParkingMeter({
  canShowParkingMeter,
  changeParkingMeterVisibility,
  canDeleteParkingMeters,
  deleteCityParkingMeters,
}: Props): JSX.Element {
  const [hidden, setHidden] = useState(!canShowParkingMeter);
  const [isOpen, setIsOpen] = useState(false);

  function handleHide() {
    setHidden(canShowParkingMeter);
    changeParkingMeterVisibility();
  }

  function onDeleteRequest() {
    setIsOpen(true);
  }

  function onConfirmDeleteCancel() {
    setIsOpen(false);
  }

  function onConfirmDelete() {
    deleteCityParkingMeters();
    setIsOpen(false);
  }

  return (
    <>
      <div style={STYLE_BLOCK_TITLE}>
        {_t('element.parkingMeterManagement.titleBar')}
        <hr style={STYLE_TITLE_BORDER} />
      </div>
      {hidden ? (
        <span title={_t('element.parkingMeterManagement.show')}>
          <Hidden
            style={{ color: ICON_RED, ...STYLE_VISIBILITY }}
            onClick={() => handleHide()}
          />
        </span>
      ) : (
        <span title={_t('element.parkingMeterManagement.hide')}>
          <Visible
            style={{ color: ICON_RED, ...STYLE_VISIBILITY }}
            onClick={() => handleHide()}
          />
        </span>
      )}
      <span style={STYLE_NAME}>
        {_t('element.parkingMeterManagement.showHideTitle')}
      </span>
      <span />
      <span>
        {canDeleteParkingMeters && (
          <ConfirmAction
            action={onConfirmDelete}
            isOpen={isOpen}
            onClose={onConfirmDeleteCancel}
            message={_t('element.parkingMeterManagement.delete')}
            enabled
          >
            <IconButton onClick={onDeleteRequest} iconStyle={STYLE_ACTIONS}>
              <DeleteIcon />
            </IconButton>
          </ConfirmAction>
        )}
      </span>
    </>
  );
}

export default ShowParkingMeter;
