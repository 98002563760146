import { ZoningDTO } from '@cvfm-front/tefps-types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useZoning = (): ZoningDTO | null => {
  return useWatcher(services.zoning.watchZoning, services.zoning.getZoning());
};

export default useZoning;
