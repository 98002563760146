import { ControlDTO } from 'api/control/types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useControl = (): ControlDTO | null => {
  return useWatcher(
    services.control.watchControl,
    services.control.getControl()
  );
};

export default useControl;
