import moment from 'moment';

const { _t, _tg } = window.loadTranslations(__filename);

export const toMinutes = (minutes: string | null | undefined): number => {
  const min = Math.abs(Number(minutes));
  if (!min || isNaN(min)) {
    return 0;
  }
  return Math.max(0, Math.min(Math.round(min), 59));
};

export const toHours = (hours: string | null | undefined): number => {
  const h = Math.abs(Number(hours));
  if (!hours || isNaN(h)) {
    return 0;
  }
  return Math.max(0, Math.min(Math.round(h), 23));
};

export const formatDate = (date: Date): string =>
  moment(date).format(_t('dateFormat'));

export const formatDateFromString = (date: string): string =>
  moment(date).format(_t('dateFormat'));

// Ex: 27 sept. 2023 14:08
export const formatDatetimeFromString = (date: string): string => {
  return moment(date).format('lll');
};

export function isDatePast(date: string | number | null | undefined): boolean {
  if (date == null || undefined) {
    return true;
  }
  return moment().isAfter(date);
}

export function firstDateOfCurrentMonth(): Date {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  return new Date(y, m, 1);
}

export function addDayLabelToString(value: string): string {
  if (value.trim() === '') {
    return value;
  }
  return `${value} ${_tg('field.date.day_shortened')}`;
}
