import React, { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';
import Reset from 'material-ui/svg-icons/action/restore';

import { FilterTime } from 'tefps/Tickets/List/types';
import { STYLE_TITLE_BORDER } from 'theme';

import {
  INLINE_TEXT_FIELD_STYLE,
  STYLE_BLOCK_TITLE,
  STYLE_INPUT_TEXT,
} from '../theme';

const { _t } = window.loadTranslations(__filename);

require('intl/locale-data/jsonp/fr');

const STYLE_BLOCK: CSSProperties = {
  marginTop: 20,
};

type Props = {
  id: string;
  title: string;
  onChange: (id: string, times: FilterTime) => void | null | undefined;
  times: FilterTime | null | undefined;
};

class Times extends React.Component<Props> {
  onFromChange = (event: any, newValue: string) => {
    const { id, onChange, times } = this.props;
    if (!onChange) return;
    onChange(id, { ...times, from: newValue });
  };

  onToChange = (event: any, newValue: string) => {
    const { id, onChange, times } = this.props;
    if (!onChange) return;
    onChange(id, { ...times, to: newValue });
  };

  resetDate = () => {
    const { id, onChange } = this.props;
    if (!onChange) return;
    onChange(id, { to: undefined, from: undefined });
  };

  render() {
    const { times, title } = this.props;
    return (
      <div style={STYLE_BLOCK}>
        <div style={STYLE_BLOCK_TITLE}>
          {title}
          <hr style={STYLE_TITLE_BORDER} />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <span style={STYLE_INPUT_TEXT}>{_t('element.from')}</span>
          <div style={{ flex: 1 }} title={_t('element.timeFormat')}>
            <TextField
              name="from"
              underlineShow={false}
              inputStyle={STYLE_INPUT_TEXT}
              value={times && times.from ? times.from : ''}
              onChange={this.onFromChange}
              type="time"
              style={INLINE_TEXT_FIELD_STYLE}
            />
          </div>
          <span style={{ ...STYLE_INPUT_TEXT, marginLeft: 6 }}>
            {_t('element.to')}
          </span>
          <div style={{ flex: 1 }} title={_t('element.timeFormat')}>
            <TextField
              name="to"
              underlineShow={false}
              inputStyle={STYLE_INPUT_TEXT}
              value={times && times.to ? times.to : ''}
              onChange={this.onToChange}
              type="time"
              style={INLINE_TEXT_FIELD_STYLE}
            />
          </div>
          <Reset
            style={{
              color: 'white',
              marginLeft: 10,
              width: 30,
              height: 30,
              cursor: 'pointer',
            }}
            onClick={this.resetDate}
          />
        </div>
      </div>
    );
  }
}

export default Times;
