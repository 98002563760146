import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { Config } from 'config/duck';

export interface CityConfigServiceFactory {
  (): CityConfigServiceInterface;
}

export interface CityConfigServiceInterface {
  init: (city: Config) => void;
  getCityConfig: () => Config | null;
  watchCityConfig: WatchFunctionType<Config | null>;
}

const CityConfigService: CityConfigServiceFactory = () => {
  const {
    getValue: getCityConfig,
    setValue: setCityConfig,
    watchValue: watchCityConfig,
  } = Watcher<null | Config>(null);

  const init = (city: Config): void => {
    setCityConfig(city);
  };

  return {
    init,
    getCityConfig,
    watchCityConfig,
  };
};

export default CityConfigService;
