import React, { CSSProperties, ReactElement } from 'react';

import { STYLE_TITLE_BORDER } from 'theme';

import { STYLE_BLOCK_TITLE, STYLE_INPUT_TEXT } from '../theme';

import Duration from './Duration';

const { _t } = window.loadTranslations(__filename);

const STYLE_BLOCK: CSSProperties = {
  marginTop: 20,
};

export type DurationRangeFilter = {
  from: number | undefined;
  to: number | undefined;
};

type DurationRangeProps = {
  id: string;
  title: string;
  durations: DurationRangeFilter;
  defaultDurations?: DurationRangeFilter;
  onChange: (id: string, duration: DurationRangeFilter) => void;
};

class DurationRange extends React.Component<DurationRangeProps> {
  constructor(props: DurationRangeProps) {
    super(props);
    const { durations, defaultDurations } = this.props;
    if (defaultDurations !== undefined) {
      if (durations.from === undefined) {
        durations.from = defaultDurations.from;
      }
      if (durations.to === undefined) {
        durations.to = defaultDurations.to;
      }
    }
  }

  changeDuration = (index: string, newValue: number | undefined): void => {
    const { id, onChange, durations } = this.props;
    if (index === 'from') {
      onChange(id, { ...durations, from: newValue });
    } else {
      onChange(id, { ...durations, to: newValue });
    }
  };

  clearDurationFilter = (index: string): void => {
    const { id, onChange, durations } = this.props;
    if (index === 'from') {
      onChange(id, { ...durations, from: NaN });
    } else {
      onChange(id, { ...durations, to: NaN });
    }
  };

  render(): ReactElement {
    const { title, durations } = this.props;

    return (
      <div style={STYLE_BLOCK}>
        <div style={STYLE_BLOCK_TITLE}>
          {title}
          <hr style={STYLE_TITLE_BORDER} />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10,
              flexDirection: 'row',
            }}
          >
            <span style={{ ...STYLE_INPUT_TEXT, marginTop: 5 }}>
              {_t('element.from')}
            </span>
            <Duration
              id="from"
              initialTotalMinutes={durations.from}
              changeDuration={this.changeDuration}
              clearDuration={this.clearDurationFilter}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10,
              flexDirection: 'row',
            }}
          >
            <span style={{ ...STYLE_INPUT_TEXT, marginTop: 5 }}>
              {_t('element.to')}
            </span>
            <Duration
              id="to"
              initialTotalMinutes={durations.to}
              changeDuration={this.changeDuration}
              clearDuration={this.clearDurationFilter}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DurationRange;
