import { ValidatorResult, ValidatorType } from '@cvfm-front/commons-types';

const { _t } = window.loadTranslations(__filename);

const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

export function validateFirstName(value: string | undefined): ValidatorResult {
  if (!value) {
    return {
      type: ValidatorType.ERROR,
      message: _t('feedback.error.firstName'),
    };
  }
  return { type: ValidatorType.SUCCESS };
}

export function validateLastName(value: string | undefined): ValidatorResult {
  if (!value) {
    return {
      type: ValidatorType.ERROR,
      message: _t('feedback.error.lastName'),
    };
  }
  return { type: ValidatorType.SUCCESS };
}

export function validateEmail(value: string | undefined): ValidatorResult {
  if (!value || !EMAIL_REGEX.test(value)) {
    return {
      type: ValidatorType.ERROR,
      message: _t('feedback.error.emailFormat'),
    };
  }
  return { type: ValidatorType.SUCCESS };
}
