import services from 'commons/services';

import useWatcher from './useWatcher';

const useMandatoryTags = (): Array<string> => {
  return useWatcher(
    services.tags.watchMandatoryTags,
    services.tags.getMandatoryTags()
  );
};

export default useMandatoryTags;
