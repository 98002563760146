import _debounce from 'lodash.debounce';

import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { Pager, SortParameters } from 'api/commonTypes';
import { RecourseSearchResultDTO } from 'api/recourse/types';
import { fetchRecourseFilteredList } from 'api/recourse';
import { MAX_RECORDS } from 'commons/const';
import {
  filtersToRequest,
  initialFilters,
  RecourseSearchCriterias,
} from 'tefps/RecoursesV2/utils/recourseUtils';

import loadQueryParamFilters from '../helpers/loadQueryParamFilters';

export interface RecourseListServiceFactory {
  (): RecourseListServiceInterface;
}

export interface RecourseListServiceInterface {
  init: (locationSearch: string | null) => Promise<void>;
  getRecourseFilters: () => RecourseSearchCriterias;
  updateFilters: (filters: RecourseSearchCriterias) => Promise<void>;
  updatePlateFilter: (plate: string) => void;
  watchRecourseFilters: WatchFunctionType<RecourseSearchCriterias>;
  getRecourseSort: () => SortParameters;
  setRecourseSort: (sort: SortParameters) => void;
  watchRecourseSort: WatchFunctionType<SortParameters>;
  getPager: () => Pager;
  watchPager: WatchFunctionType<Pager>;
  getError: () => Error | null | undefined;
  watchError: WatchFunctionType<Error | null | undefined>;
  getRecourseSearchResult: () => RecourseSearchResultDTO | null;
  watchRecourseSearchResult: WatchFunctionType<RecourseSearchResultDTO | null>;
  fetchRecourseList: () => Promise<void>;
  loadMoreRows: ({ startIndex }: { startIndex: number }) => Promise<void>;
  isLoading: () => boolean;
  watchLoading: WatchFunctionType<boolean>;
}

const RecourseListService: RecourseListServiceFactory = () => {
  const {
    getValue: getRecourseFilters,
    setValue: setRecourseFilters,
    watchValue: watchRecourseFilters,
  } = Watcher<RecourseSearchCriterias>(initialFilters());

  const {
    getValue: getRecourseSort,
    setValue: setRecourseSort,
    watchValue: watchRecourseSort,
  } = Watcher<SortParameters>({
    sortField: 2,
    increasingOrder: true,
  });

  const {
    getValue: getPager,
    setValue: setPager,
    watchValue: watchPager,
  } = Watcher<Pager>({
    page: 0,
    maxRecords: MAX_RECORDS,
  });

  const {
    getValue: getError,
    setValue: setError,
    watchValue: watchError,
  } = Watcher<Error | null>(null);

  const {
    getValue: getRecourseSearchResult,
    setValue: setRecourseSearchResult,
    watchValue: watchRecourseSearchResult,
  } = Watcher<RecourseSearchResultDTO | null>(null);

  const {
    getValue: isLoading,
    setValue: setLoading,
    watchValue: watchLoading,
  } = Watcher<boolean>(false);

  const fetchRecourseList = async (): Promise<void> => {
    setLoading(true);
    const initialPager = { page: 0, maxRecords: MAX_RECORDS };
    const recourseSearchResult = getRecourseSearchResult();

    setRecourseSearchResult(
      recourseSearchResult
        ? {
            ...recourseSearchResult,
            recourses: [],
          }
        : null
    );
    setError(null);
    setPager(initialPager);
    try {
      const recourseSearchResultFetched = await fetchRecourseFilteredList(
        filtersToRequest(getRecourseFilters(), getRecourseSort(), initialPager)
      );
      setRecourseSearchResult(recourseSearchResultFetched);
      setError(null);
    } catch (e) {
      setError(e as Error);
      setRecourseSearchResult(null);
    }
    setLoading(false);
  };

  const debouceFetch = _debounce(fetchRecourseList, 1000);

  const loadMoreRows = async ({
    startIndex,
  }: {
    startIndex: number;
  }): Promise<void> => {
    setLoading(true);
    const recourseSearchResult = getRecourseSearchResult();
    const filters = getRecourseFilters();
    const sort = getRecourseSort();
    const rowsPager = {
      page: startIndex / MAX_RECORDS,
      maxRecords: MAX_RECORDS,
    };

    try {
      const recourseAppendSearchResult = await fetchRecourseFilteredList(
        filtersToRequest(filters, sort, rowsPager)
      );

      if (recourseSearchResult) {
        const newRecourseList = recourseSearchResult.recourses.concat(
          recourseAppendSearchResult.recourses
        );
        setRecourseSearchResult({
          ...recourseSearchResult,
          recourses: newRecourseList,
        });
      }
    } catch (_e) {
      // As for FPS list, this error shouldn't block displaying
      // this.setState({ error, recourseSearchResult: null, pager });
    }
    setLoading(false);
  };

  const updatePlateFilter = async (plate: string): Promise<void> => {
    setRecourseFilters(initialFilters(plate));

    await debouceFetch();
  };

  const updateFilters = async (
    filters: RecourseSearchCriterias
  ): Promise<void> => {
    setRecourseFilters(filters);

    await debouceFetch();
  };

  const init = async (locationSearch: string | null): Promise<void> => {
    // Si présence d'une plaque dans les param on réécrit les filters
    if (locationSearch) {
      loadQueryParamFilters(locationSearch, ({ plate }) =>
        setRecourseFilters(initialFilters(plate))
      );
    }

    await fetchRecourseList();
  };

  return {
    getRecourseFilters,
    updateFilters,
    updatePlateFilter,
    watchRecourseFilters,
    getRecourseSort,
    setRecourseSort,
    watchRecourseSort,
    getPager,
    watchPager,
    getError,
    watchError,
    getRecourseSearchResult,
    watchRecourseSearchResult,
    fetchRecourseList,
    loadMoreRows,
    isLoading,
    watchLoading,
    init,
  };
};

export default RecourseListService;
