import { SnackbarCloseAction, SnackbarSendMessageAction } from './types';

export const snackbarSendMessage = (
  message: string
): SnackbarSendMessageAction => {
  return {
    type: 'SNACKBAR_SEND_MESSAGE',
    payload: message,
  };
};

export const snackbarClose = (): SnackbarCloseAction => {
  return { type: 'SNACKBAR_CLOSE' };
};
