/**
 * Simple dialog component to avoid Material UI library
 */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { DialogProps } from 'material-ui';

type SimpleDialogProps = DialogProps;

const CustomDialog = ({
  actions,
  title,
  children,
}: SimpleDialogProps): JSX.Element | null => {
  const DIALOG_WRAPPER = {
    position: 'fixed',
    zIndex: 1500,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: 'left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    paddingTop: 'calc(16px + 36vh)',
  } as React.CSSProperties;

  const DIALOG_CONTAINER = {
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    position: 'relative',
    width: '75%',
    maxWidth: 768,
    margin: '0 auto',
    zIndex: 1500,
    opacity: 1,
    transform: 'translate(0, 64)',
  } as React.CSSProperties;

  const DIALOG_CONTAINER_2 = {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'rgb(255, 255, 255)',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxShadow:
      'rgba(0, 0, 0, 0.25) 0px 14px 45px, rgba(0, 0, 0, 0.22) 0px 10px 18px',
    borderRadius: 2,
  } as React.CSSProperties;

  const DIALOG_OVERLAY = {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    opacity: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    willChange: 'opacity',
    transform: 'translateZ(0)',
    overflowX: 'hidden',
    transition:
      'left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    zIndex: 1400,
  } as React.CSSProperties;

  const DIALOG_TITLE = {
    fontFamily: 'Roboto, sans-serif',
    margin: 0,
    padding: '24px 24px 20px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '22px',
    lineHeight: '32px',
    fontWeight: 400,
  } as React.CSSProperties;

  const DIALOG_CONTENT = {
    fontSize: '16px',
    fontFamily: 'Roboto, sans-serif',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 24px 24px',
    overflowY: 'hidden',
    borderTop: 'medium none',
    borderBottom: 'medium none',
    maxHeight: '710px',
  } as React.CSSProperties;

  const BUTTON_CONTAINER = {
    padding: '8px',
    textAlign: 'right',
    marginTop: 0,
  } as React.CSSProperties;

  const el = document.createElement('div');

  const uniqueId = (prefix = 'dialog-') =>
    prefix +
    Math.random()
      .toString(16)
      .slice(-4);

  useEffect(() => {
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
  }, []);

  const content = (
    <div key={uniqueId()} style={DIALOG_WRAPPER}>
      <div key="dialog">
        <div key="dialog-container" style={DIALOG_CONTAINER}>
          <div key="dialog-container-2" style={DIALOG_CONTAINER_2}>
            <h3 key="dialog-title" style={DIALOG_TITLE}>
              {title}
            </h3>
            <div key="dialog-content" style={DIALOG_CONTENT}>
              {children}
            </div>
            <div key="button-container" style={BUTTON_CONTAINER}>
              {actions}
            </div>
          </div>
        </div>
      </div>
      <div style={DIALOG_OVERLAY} />
    </div>
  );

  return ReactDOM.createPortal(content, el, uniqueId());
};

/** We use a wrapper component to mount and unmount the dialog
 *
 * @param props
 * @constructor
 */
const SimpleDialog = (props: SimpleDialogProps): JSX.Element | null => {
  const { open } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return open ? <CustomDialog {...props} /> : null;
};

export default SimpleDialog;
