import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { RecourseServiceInterface } from 'commons/services/RecourseService';

export interface DecisionPriceServiceFactory {
  (recourseService: RecourseServiceInterface): DecisionPriceServiceInterface;
}

export interface DecisionPriceServiceInterface {
  init: () => void;
  getInitialPrice: () => number | null;
  watchInitialPrice: WatchFunctionType<number>;
  getInitialReducedPrice: () => number | null;
  watchInitialReducedPrice: WatchFunctionType<number | null>;
  getNewPrice: () => number | null;
  setNewPrice: (newPrice: number | null) => void;
  watchNewPrice: WatchFunctionType<number | null>;
  getNewReducedPrice: () => number | null;
  setNewReducedPrice: (newReducedPrice: number | null) => void;
  watchNewReducedPrice: WatchFunctionType<number | null>;
}

const DecisionPriceService: DecisionPriceServiceFactory = recourseService => {
  const {
    getValue: getInitialPrice,
    setValue: setInitialPrice,
    watchValue: watchInitialPrice,
  } = Watcher<number>(0);

  const {
    getValue: getInitialReducedPrice,
    setValue: setInitialReducedPrice,
    watchValue: watchInitialReducedPrice,
  } = Watcher<number | null>(null);

  const {
    getValue: getNewPrice,
    setValue: setNewPrice,
    watchValue: watchNewPrice,
  } = Watcher<number | null>(null);

  const {
    getValue: getNewReducedPrice,
    setValue: setNewReducedPrice,
    watchValue: watchNewReducedPrice,
  } = Watcher<number | null>(null);

  const init = (): void => {
    const recourse = recourseService.getRecourse();

    // Get last proposal for recourse
    const currentProposal =
      recourse?.recourse.proposals[recourse.recourse.proposals.length - 1];
    const initialFpsPrice = (recourse?.fps.finePrice ?? 0) / 100;
    const initialFpsReducedPrice =
      recourse?.fps.responsibleOfReducedPeriod !== 'ANTAI' &&
      recourse?.fps.reducedFinePrice != null
        ? recourse?.fps.reducedFinePrice / 100
        : null;

    setInitialPrice(initialFpsPrice);
    setInitialReducedPrice(initialFpsReducedPrice);
    setNewPrice(
      currentProposal &&
        currentProposal.proposal.status !== 'IS_WAITING' &&
        !currentProposal.decision
        ? Math.min(currentProposal.proposal.newFpsPrice / 100, initialFpsPrice)
        : 0
    );
    setNewReducedPrice(
      initialFpsReducedPrice != null &&
        currentProposal &&
        currentProposal.proposal.newFpsReducedPrice != null
        ? Math.min(
            currentProposal.proposal.newFpsReducedPrice / 100,
            initialFpsReducedPrice
          )
        : null
    );
  };

  return {
    init,
    getInitialPrice,
    watchInitialPrice,
    getInitialReducedPrice,
    watchInitialReducedPrice,
    getNewPrice,
    setNewPrice,
    watchNewPrice,
    getNewReducedPrice,
    setNewReducedPrice,
    watchNewReducedPrice,
  };
};

export default DecisionPriceService;
