import services from 'commons/services';

import useWatcher from './useWatcher';

const useComment = (): [string, (arg0: string) => void] => {
  return [
    useWatcher(services.comment.watchComment, ''),
    services.comment.setComment,
  ];
};

export default useComment;
