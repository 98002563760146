import { Config } from 'config/duck';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useCityConfig = (): Config | null => {
  return useWatcher(
    services.cityConfig.watchCityConfig,
    services.cityConfig.getCityConfig()
  );
};

export default useCityConfig;
