import { MutableRefObject, RefObject } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import _debounce from 'lodash.debounce';
import { isEqual } from 'lodash';
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import { IconLayer, Layer, LineLayer, ScatterplotLayer } from 'deck.gl';

import { BASE_GOOGLE_MAPS_URL, signGoogleMapUrl } from 'api/url-signer';
import { Point } from '@cvfm-front/tefps-types';
import { Config } from 'config/duck';
import {
  CLUSTER_IMAGES,
  computeIndex,
  rendererFactory,
} from 'commons/Utils/clustererUtils';
import { formatNumber } from 'commons/Utils/numberUtil';
import { getLast } from 'commons/Utils/arrayUtils';
import { UrlDTO } from 'api/url-signer/types';

// what the polygon/marker/... represents
export enum DrawingType {
  PARKING_SPACE,
  ZONE,
  PATROL_ZONE,
  CONTROL,
  CONTROL_DETAIL,
  CONTROL_STRATEGY,
  FPS_STRATEGY,
  ADDRESS,
  PING,
  HISTORY_PING,
}

// which of the maps we are using
export enum MapId {
  CONTROL_DETAIL,
  CONTROL_STRATEGY,
  FPS_STRATEGY,
  LAPI_REVIEW_CHANGE_ADDRESS,
  PARKING_SPACE,
  PATROL_ZONE,
  PING_MAP,
}

export interface MapServiceFactory {
  (): MapServiceInterface;
}

export interface MapServiceInterface {
  init: (
    mapId: MapId,
    config: Config,
    refMap: RefObject<HTMLDivElement>
  ) => MapWrapperInterface; // inits and resets
  get: (mapId: MapId) => MapWrapperInterface | undefined;
  free: (mapId: MapId) => void;
}

export interface MapWrapperInterface {
  markers: {
    add: (newMarkers: Array<MarkerWrapper>) => void;
    addClusterer: (clustererWrapper: ClustererWrapper) => void;
    removeClustererOnDrawingType: (drawingType: string | DrawingType) => void;
  };
  deckGlArrows: {
    set: (newArrows: DeckGLMultipleArrowsWrapper) => void;
  };
  icons: {
    set: (newicons: MultipleIconsWrapper) => void;
  };
  circles: {
    set: (newicons: MultipleCirclesWrapper) => void;
  };
  arrows: {
    add: (newArrows: Array<ArrowWrapper>) => void;
  };
  polygons: {
    add: (newPolygons: Array<PolygonWrapper>) => void;
    get: () => Array<Polygon>;
    setVisibility: (polygonIds: Array<string>, visibility: boolean) => void;
    setCanBeEdited: (polygonIds: Array<string>, canBeEdited: boolean) => void;
  };
  infoWindows: {
    add: (newInfoWindows: Array<InfoWindowWrapper>) => void;
  };
  center: {
    set: (point: Point) => void;
    get: () => Point;
  };
  zoom: {
    set: (level: number) => void;
    get: () => number;
  };
  viewPort: { get: () => [number, number, number, number] };
  mapListener: {
    add: (eventName: string, cb: () => void) => void;
    clear: (eventName: string) => void;
  };
  clear: {
    map: () => void;
    drawingType: (drawingType: string | DrawingType) => void;
    otherThanDrawingTypes: (keepDrawingTypes: Array<DrawingType>) => void;
  };
}

interface MapClassInterface {
  init: () => void; // inits and resets
  addMarkers: (newMarkers: Array<MarkerWrapper>) => void;
  setCircles: (newIcons: MultipleCirclesWrapper) => void;
  setDeckGlArrows: (newArrows: DeckGLMultipleArrowsWrapper) => void;
  setIcons: (newIcons: MultipleIconsWrapper) => void;
  addArrows: (newArrows: Array<ArrowWrapper>) => void;
  addInfoWindows: (newInfoWindows: Array<InfoWindowWrapper>) => void;
  addPolygons: (newPolygons: Array<PolygonWrapper>) => void;
  addMarkerClusterer: (clustererWrapper: ClustererWrapper) => void;
  removeMarkerClustererOnDrawingType: (
    drawingType: string | DrawingType
  ) => void;
  setPolygonsVisibility: (
    polygonIds: Array<string>,
    visibility: boolean
  ) => void;
  setPolygonsCanBeEdited: (
    polygonIds: Array<string>,
    canBeEdited: boolean
  ) => void;
  getPolygons: () => Array<Polygon>;
  setCenter: (point: Point) => void;
  setZoom: (level: number) => void;
  getZoom: () => number;
  getViewPort: () => [number, number, number, number];
  getCenter: () => Point;
  addMapListener: (eventName: string, cb: () => void) => void;
  clearMapListener: (eventName: string) => void;
  clearMap: () => void;
  clearDrawingType: (drawingType: string | DrawingType) => void;
  clearOtherThanDrawingTypes: (keepDrawingTypes: Array<DrawingType>) => void;
}

export type Polygon = {
  id: string;
  type: string | DrawingType;
  name: string;
  points: Array<Point>;
};

export type PolygonWrapper = Polygon & {
  color: string;
  zIndex: number;
  visible: boolean;
  editable: boolean; // if we are currently editing it, it shows the vertices
  canBeEdited: boolean; // if we can click on it to start editing it
  onRemove?: () => void;
  polygonListeners?: Map<string, (...args: unknown[]) => void>;
  pathListeners?: Map<string, (...args: unknown[]) => void>;
};

export type MarkerWrapper = {
  id: string; // the unique id
  type: string | DrawingType;
  point: Point;
  title: string;
  symbol: string | google.maps.Symbol;
  count?: number; // used for the clusterer, 1 if nullish
  listeners?: Map<string, (...args: unknown[]) => void>;
};

// single Icons at once
export type IconWrapper = {
  point: Point;
  title: string;
  symbol: string;
  height: number;
  width: number;
};

// multiple Icons at once
export type MultipleIconsWrapper = {
  id: string; // the unique id
  type: string | DrawingType;
  icons: Array<IconWrapper>;
};

// single Circle at once
export type CircleWrapper = {
  id: string; // id of the ping
  point: Point;
  title: string;
  radius: number;
  color: [number, number, number];
};

// multiple Icons at once
export type MultipleCirclesWrapper = {
  id: string; // the unique id of the layer
  type: string | DrawingType;
  circles: Array<CircleWrapper>;
  infoWindowContentGenerator?: (c: CircleWrapper) => Promise<string>;
};

// single arrow
export type DeckGLArrowWrapper = {
  start: Point;
  end: Point;
  title?: string;
  width: number;
  color: [number, number, number];
};

// multiple arrows at once
export type DeckGLMultipleArrowsWrapper = {
  id: string; // the unique id
  type: string | DrawingType;
  arrows: Array<DeckGLArrowWrapper>;
  minDistanceForArrow?: number; // default 0, in meters
  maxNumberOfLinesBeforeArrow?: number; // default 10
};

export type ArrowWrapper = {
  id: string;
  type: string | DrawingType;
  start: Point;
  end: Point;
  color: string;
};

export type InfoWindowWrapper = {
  markerId: string;
  content: string | (() => Promise<string>);
};

export type ClustererWrapper = {
  type: string | DrawingType;
  titleFormatter: (count: number) => string;
};

type GoogleMapItemHolder<T, W> = {
  id: string;
  type: string | DrawingType; // the id of the group for this marker. Could be a controlId, "patrolZone", ...
  item: T;
  wrapper: W;
  listeners: Array<google.maps.MapsEventListener>;
};

// for now we only need the wrapper for the polygon
type PolygonHolder = GoogleMapItemHolder<google.maps.Polygon, PolygonWrapper>;

type MarkerHolder = GoogleMapItemHolder<google.maps.Marker, MarkerWrapper>;

type ArrowHolder = GoogleMapItemHolder<google.maps.Polyline, void>;

type ClustererHolder = GoogleMapItemHolder<MarkerClusterer, ClustererWrapper>;

type DeckGlLayerHolder<W> = {
  id: string; // the layer id
  // drawingType: string | DrawingType;
  layerType: DeckGlLayerType;
  layer: Layer;
  wrapper: W;
};

enum DeckGlLayerType {
  ARROWS,
  ICONS,
  MARKERS,
}

type DeckGlIconsLayerHolder = DeckGlLayerHolder<MultipleIconsWrapper>;
type DeckGlCirclesLayerHolder = DeckGlLayerHolder<MultipleCirclesWrapper>;
type DeckGlArrowsLayerHolder = DeckGlLayerHolder<DeckGLMultipleArrowsWrapper>;

class MapClass implements MapClassInterface {
  // set at construction
  mapId: MapId;
  cityConfig: Config;
  refMap: RefObject<HTMLDivElement>;
  initialized = false;
  // set at init
  map: google.maps.Map | null = null;
  readyCallbacks: Array<() => void> = [];

  // list of objects
  polygonHolders = new Map<string, PolygonHolder>();
  markerHolders = new Map<string, MarkerHolder>();
  scatterplotMarkerHolders = new Map<string, MarkerHolder>();
  clustererHolders = new Map<string | DrawingType, ClustererHolder>();
  arrowHolders = new Map<string, ArrowHolder>();
  mapListenerHolders = new Map<string, google.maps.MapsEventListener>();
  currentOpenInfoWindow: google.maps.InfoWindow | null = null;

  // deck.gl
  // layerHolders.icons = one layer for the controls, one for the pings, ...
  layerHolders: {
    arrows: Map<string | DrawingType, DeckGlArrowsLayerHolder>;
    circles: Map<string | DrawingType, DeckGlCirclesLayerHolder>;
    icons: Map<string | DrawingType, DeckGlIconsLayerHolder>;
  } = {
    arrows: new Map<string | DrawingType, DeckGlArrowsLayerHolder>(),
    circles: new Map<string | DrawingType, DeckGlCirclesLayerHolder>(),
    icons: new Map<string | DrawingType, DeckGlIconsLayerHolder>(),
  };
  overlay: GoogleMapsOverlay;

  constructor(
    mapId: MapId,
    config: Config,
    mapReference: MutableRefObject<HTMLDivElement>
  ) {
    this.mapId = mapId;
    this.cityConfig = config;
    this.refMap = mapReference;
  }

  public shouldBeInitialized = (): boolean => {
    return this.refMap?.current !== null && this.initialized === false;
  };

  public init = (): void => {
    // shouldBeInitialized, but we need typescript to know that refMap.current is not null
    if (!this.refMap?.current || this.initialized === true) {
      return;
    }
    this.initialized = true;
    const { location } = this.cityConfig;

    this.map = new google.maps.Map(this.refMap.current, {
      zoom: 13,
      center: { lat: location?.latitude || 0, lng: location?.longitude || 0 },
      clickableIcons: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
      ],
    });
    this.overlay = new GoogleMapsOverlay({
      getTooltip: ({ object }): string | null => {
        if (object) {
          const objectTyped = object as {
            title?: string;
          };
          return objectTyped?.title ?? null;
        }
        return null;
      },
      style: { zIndex: '100000' },
    });
    this.refreshLayers();
    this.overlay.setMap(this.map);
    this.readyCallbacks.forEach(cb => cb());
  };

  private addOrRunReadyCallback(cb: () => void): void {
    if (this.map) {
      cb();
    } else {
      this.readyCallbacks.push(cb);
    }
  }

  public getViewPort = (): [number, number, number, number] => {
    const north =
      this.map
        ?.getBounds()
        ?.getNorthEast()
        .lat() ?? 0;
    const east =
      this.map
        ?.getBounds()
        ?.getNorthEast()
        .lng() ?? 0;
    const south =
      this.map
        ?.getBounds()
        ?.getSouthWest()
        .lat() ?? 0;
    const west =
      this.map
        ?.getBounds()
        ?.getSouthWest()
        .lng() ?? 0;
    return [north, west, south, east];
  };

  public editPolygon = (polygon: google.maps.Polygon) => {
    this.polygonHolders.forEach(p => p.item.setEditable(false));
    polygon.setEditable(true);
  };

  private setupPolygonListeners(polygonHolder: PolygonHolder) {
    if (this.map === null) {
      return;
    }
    const polygon = polygonHolder.item;
    const polygonWrapper = polygonHolder.wrapper;

    polygonHolder.listeners.forEach(listener =>
      google.maps.event.removeListener(listener)
    );

    polygonHolder.listeners.length = 0;

    if (polygonHolder.wrapper.canBeEdited) {
      polygonHolder.listeners.push(
        polygon.addListener('click', () => {
          this.editPolygon(polygon);
        })
      );
      polygonHolder.listeners.push(
        polygon.addListener(
          'rightclick',
          (event: { vertex: number | undefined }) => {
            if (
              event.vertex !== undefined &&
              polygon.getPath().getLength() > 3
            ) {
              polygon.getPath().removeAt(event.vertex);
            } else if (
              event.vertex !== undefined &&
              polygon.getPath().getLength() === 3 &&
              polygonWrapper.onRemove
            ) {
              polygonWrapper.onRemove();
            }
          }
        )
      );
    }
    polygonWrapper.polygonListeners?.forEach((listener, event) => {
      polygonHolder.listeners.push(
        polygon.addListener(event, () => listener(polygon))
      );
    });
    polygonWrapper.pathListeners?.forEach((listener, event) => {
      polygonHolder.listeners.push(
        polygon.getPath().addListener(event, () => listener(polygon))
      );
    });
  }

  public addPolygon = (polygonWrapper: PolygonWrapper) => {
    // Polygon Coordinates
    if (polygonWrapper.points) {
      const callback = () => {
        const coords = polygonWrapper.points.map(
          p => new google.maps.LatLng(p.latitude, p.longitude)
        );
        // Google awaits an open polygon, therefore, we will remove the last one if it's closed
        while (coords.length >= 3 && isEqual(coords[0], getLast(coords))) {
          coords.pop();
        }
        // if coords is of length 2 or less, it means it isn't a real polygon => ignored
        if (coords.length <= 2) {
          return;
        }
        // Styling & Controls
        const polygon = new google.maps.Polygon({
          id: polygonWrapper.id,
          name: polygonWrapper.name,
          paths: coords,
          draggable: false,
          strokeColor: polygonWrapper.color,
          strokeWeight: 2,
          fillColor: polygonWrapper.color,
          zIndex: polygonWrapper.zIndex,
          editable: polygonWrapper.editable,
          visible: polygonWrapper.visible,
          clickable:
            polygonWrapper.canBeEdited ||
            !!polygonWrapper.pathListeners?.size ||
            !!polygonWrapper.polygonListeners?.size,
          map: this.map,
        });
        const polygonHolder: PolygonHolder = {
          id: polygonWrapper.id,
          type: polygonWrapper.type,
          item: polygon,
          wrapper: polygonWrapper,
          listeners: [],
        };
        const oldPolygonHolder = this.polygonHolders.get(polygonWrapper.id);
        if (oldPolygonHolder) {
          oldPolygonHolder.item.setMap(null);
          this.polygonHolders.delete(polygonWrapper.id);
        }
        this.polygonHolders.set(polygonWrapper.id, polygonHolder);
        this.setupPolygonListeners(polygonHolder);
      };
      this.addOrRunReadyCallback(callback);
    }
  };

  public setCenter = (point: Point) => {
    this.addOrRunReadyCallback(() =>
      this.map?.setCenter({ lat: point.latitude, lng: point.longitude })
    );
  };

  public getCenter = () => {
    if (!this.map) {
      return { latitude: 0, longitude: 0 };
    }

    const lat = this.map.getCenter()?.lat() || 0;
    const lng = this.map.getCenter()?.lng() || 0;
    return { latitude: lat, longitude: lng };
  };

  public setZoom = (level: number) => {
    this.addOrRunReadyCallback(() => this.map?.setZoom(level));
  };

  public getZoom = () => {
    return this.map?.getZoom() || 13;
  };

  public addPolygons = (newPolygons: Array<PolygonWrapper>) => {
    newPolygons.forEach(p => this.addPolygon(p));
  };

  public getPolygons = () => {
    return [...this.polygonHolders.values()].map((polygon: PolygonHolder) => ({
      id: polygon.id,
      name: polygon.item.name,
      type: polygon.type,
      points: polygon.item
        .getPath()
        .getArray()
        .map(p => ({ latitude: p.lat(), longitude: p.lng() })),
    }));
  };

  public setPolygonsVisibility = (
    polygonIds: Array<string>,
    visibility: boolean
  ) => {
    const callback = () => {
      this.polygonHolders.forEach((polygonHolder, polygonHolderId) => {
        polygonIds.forEach(polygonId => {
          if (polygonHolderId.startsWith(polygonId)) {
            polygonHolder.item.setVisible(visibility);
          }
        });
      });
    };
    this.addOrRunReadyCallback(callback);
  };

  public setPolygonsCanBeEdited = (
    polygonIds: Array<string>,
    canBeEdited: boolean
  ) => {
    const callback = () => {
      this.polygonHolders.forEach((polygonHolder, polygonHolderId) => {
        let reset = false;
        polygonIds.forEach(polygonId => {
          if (polygonHolderId.startsWith(polygonId)) {
            polygonHolder.wrapper.canBeEdited = canBeEdited;
            reset = true;
          }
        });
        if (reset) {
          this.setupPolygonListeners(polygonHolder);
        }
      });
    };
    this.addOrRunReadyCallback(callback);
  };

  private setupMarkerListeners(markerHolder: MarkerHolder) {
    const marker = markerHolder.item;
    const markerWrapper = markerHolder.wrapper;

    markerHolder.listeners.forEach(listener =>
      google.maps.event.removeListener(listener)
    );

    markerHolder.listeners.length = 0;

    if ((markerHolder.wrapper.count || 1) > 1) {
      markerHolder.listeners.push(
        google.maps.event.addListener(marker, 'click', () => {
          // TODO move in a "zoomOnPosition" function?
          const [north, west, south, east] = this.getViewPort();
          const northSouthSize = north - south;
          const eastWestSize = east - west;
          const center = markerHolder.item.getPosition();
          const centerLat = center?.lat() || 0;
          const centerLng = center?.lng() || 0;
          const northEast = {
            lat: centerLat + northSouthSize / 10,
            lng: centerLng + eastWestSize / 10,
          };
          const southWest = {
            lat: centerLat - northSouthSize / 10,
            lng: centerLng - eastWestSize / 10,
          };
          const bounds = new google.maps.LatLngBounds(center)
            .extend(northEast)
            .extend(southWest);
          this.map?.fitBounds(bounds);
        })
      );
    }
    markerWrapper.listeners?.forEach((listener, event) => {
      markerHolder.listeners.push(
        google.maps.event.addListener(marker, event, () => listener(marker))
      );
    });
  }

  public addMarkers = (markerWrappers: Array<MarkerWrapper>) => {
    const callback = () => {
      const clusterersToRender: Set<MarkerClusterer> = new Set();

      markerWrappers.forEach(markerWrapper => {
        const position = new google.maps.LatLng(
          markerWrapper.point.latitude,
          markerWrapper.point.longitude
        );
        let clusterOptions = {};
        // TODO refacto with AdvancedMarker ? But no easy way to use imgs
        const realCount = markerWrapper.count || 1;

        // if realCount > 1, then it means that this marker represents a backmade cluster
        // so we will render it as a frontmade cluster
        // therefore custom icon and text
        if (realCount > 1) {
          const index = computeIndex(realCount);
          // following trick might not be needed
          const zIndex: number = Number(google.maps.Marker.MAX_ZINDEX) + 1;
          clusterOptions = {
            zIndex,
            label: {
              color: 'white',
              fontSize: '14',
              text: formatNumber(realCount),
            },
            icon: CLUSTER_IMAGES[index],
          };
        }
        const marker = new google.maps.Marker({
          position,
          title: markerWrapper.title,
          icon: markerWrapper.symbol,
          map: this.map,
          count: markerWrapper.count || 1,
          ...clusterOptions,
        });
        const oldMarker = this.markerHolders.get(markerWrapper.id);
        if (oldMarker) {
          this.markerHolders.delete(markerWrapper.id);
          oldMarker.item.setMap(null);
        }
        const markerHolder = {
          id: markerWrapper.id,
          type: markerWrapper.type,
          item: marker,
          wrapper: markerWrapper,
          listeners: [],
        };
        this.setupMarkerListeners(markerHolder);
        this.markerHolders.set(markerWrapper.id, markerHolder);
        const potentialClusterer = this.clustererHolders.get(
          markerWrapper.type
        );
        if (potentialClusterer) {
          potentialClusterer.item.addMarker(marker, true);
          clusterersToRender.add(potentialClusterer.item);
        }
      });
      clusterersToRender.forEach(c => c.render());
    };
    this.addOrRunReadyCallback(callback);
  };

  public addMarkerClusterer = (clustererWrapper: ClustererWrapper) => {
    const callback = () => {
      const potentialClusterer = this.clustererHolders.get(
        clustererWrapper.type
      );
      if (potentialClusterer) {
        // if already exists, no need to create a new one
        return;
      }
      const markers = Array.from(this.markerHolders.values())
        .filter(marker => marker.type === clustererWrapper.type)
        .map(marker => marker.item);
      const clusterer = new MarkerClusterer({
        markers,
        map: this.map,
        renderer: rendererFactory(clustererWrapper.titleFormatter),
      });
      this.clustererHolders.set(clustererWrapper.type, {
        id: clustererWrapper.type.toString(), // not used
        type: clustererWrapper.type,
        item: clusterer,
        wrapper: clustererWrapper,
        listeners: [],
      });
    };
    this.addOrRunReadyCallback(callback);
  };

  public removeMarkerClustererOnDrawingType = (
    drawingType: string | DrawingType
  ) => {
    const callback = () => {
      const potentialClusterer = this.clustererHolders.get(drawingType);
      if (!potentialClusterer) {
        return;
      }
      potentialClusterer.item.clearMarkers();
      this.clustererHolders.delete(drawingType);
    };
    this.addOrRunReadyCallback(callback);
  };

  private refreshLayers = () =>
    this.overlay.setProps({
      layers: [
        ...Object.values(this.layerHolders).reduce(
          (previousValue, currentValue) =>
            previousValue.concat([...currentValue.values()]),
          [] as Array<DeckGlLayerHolder<unknown>>
        ),
      ].map(l => l.layer),
    });

  public setDeckGlArrows = (newArrows: DeckGLMultipleArrowsWrapper) => {
    const callback = () => {
      const { id } = newArrows;
      // because we are on a sphere, 1°lat = more distance than 1°lon
      const lonMultiplier = Math.cos(
        (this.cityConfig.location.latitude * Math.PI) / 180
      );
      const angleDelta = Math.PI / 4;
      const maxNumberOfLinesBeforeArrow =
        newArrows.maxNumberOfLinesBeforeArrow ?? 1;
      // convert from meters to lat degree
      const minDistanceForArrow =
        ((newArrows.minDistanceForArrow ?? 0) * 360) / 40_000_000;
      let lastIndexWithArrow = -(newArrows.maxNumberOfLinesBeforeArrow ?? 0);
      const data = ([] as Array<DeckGLArrowWrapper>).concat(
        ...newArrows.arrows.map((p, index) => {
          const result: Array<DeckGLArrowWrapper> = [p];
          // lat and lon being converted to planar coordinates
          // 1 lat = 40_000_000 / 360 meters
          const lat = p.end.latitude - p.start.latitude;
          // we are on a sphere => represents a smaller distance
          // 1 lon <= 1 lat (== on equator, == 0 on the pole)
          const lon = (p.end.longitude - p.start.longitude) * lonMultiplier;
          const length = Math.sqrt(lat ** 2 + lon ** 2);
          if (
            length > 0 &&
            (length >= minDistanceForArrow ||
              lastIndexWithArrow + maxNumberOfLinesBeforeArrow <= index)
          ) {
            lastIndexWithArrow = index;
            const angle = Math.atan2(lat, lon);
            const smallLength = length / 10;
            const smallLengthMultiplied = smallLength / lonMultiplier;
            const angleLeft = angle + angleDelta;
            const angleRight = angle - angleDelta;
            const middle = {
              latitude: (p.start.latitude + 3 * p.end.latitude) / 4,
              longitude: (p.start.longitude + 3 * p.end.longitude) / 4,
            };
            const left = {
              latitude: middle.latitude - smallLength * Math.sin(angleLeft),
              longitude:
                middle.longitude - smallLengthMultiplied * Math.cos(angleLeft),
            };
            const right = {
              latitude: middle.latitude - smallLength * Math.sin(angleRight),
              longitude:
                middle.longitude - smallLengthMultiplied * Math.cos(angleRight),
            };

            result.push({
              ...p,
              start: left,
              end: middle,
            });
            result.push({
              ...p,
              start: right,
              end: middle,
            });
            result.push({
              ...p,
              start: left,
              end: right,
            });
          }
          return result;
        })
      );
      const newLayer = new LineLayer<{
        start: Point;
        end: Point;
      }>({
        id,
        data,
        getSourcePosition: (d: DeckGLArrowWrapper) => [
          d.start.longitude,
          d.start.latitude,
        ],
        getTargetPosition: (d: DeckGLArrowWrapper) => [
          d.end.longitude,
          d.end.latitude,
        ],
        getColor: (d: DeckGLArrowWrapper) => d.color,
        getWidth: (d: DeckGLArrowWrapper) => d.width,
        widthUnits: 'pixels',
        pickable: true,
      });
      this.layerHolders.arrows.set(newArrows.id, {
        id,
        layer: newLayer,
        layerType: DeckGlLayerType.ARROWS,
        wrapper: newArrows,
      });
      this.refreshLayers();
    };
    this.addOrRunReadyCallback(callback);
  };

  public setCircles = (newCircles: MultipleCirclesWrapper) => {
    const callback = () => {
      const { id, infoWindowContentGenerator } = newCircles;
      const data = newCircles.circles;
      const newLayer = new ScatterplotLayer<CircleWrapper>({
        id,
        data,
        getPosition: (d: CircleWrapper) => [
          d.point.longitude,
          d.point.latitude,
          // 1000000,
        ],
        getFillColor: (d: CircleWrapper) => d.color,
        getRadius: (d: CircleWrapper) => d.radius,
        radiusUnits: 'pixels',
        getLineWidth: 0,
        onClick: infoWindowContentGenerator
          ? ({ object }) => {
              if (object) {
                const dataObject = object as CircleWrapper;
                void infoWindowContentGenerator(dataObject).then(value =>
                  this.openInfoWindow(value, dataObject.point)
                );
              }
            }
          : undefined,
        pickable: true,
      });
      this.layerHolders.circles.set(newCircles.id, {
        id,
        layer: newLayer,
        layerType: DeckGlLayerType.MARKERS,
        wrapper: newCircles,
      });
      this.refreshLayers();
    };
    this.addOrRunReadyCallback(callback);
  };

  public setIcons = (newIcons: MultipleIconsWrapper) => {
    const callback = () => {
      const { id } = newIcons;
      const data = newIcons.icons;
      const newLayer = new IconLayer<IconWrapper>({
        id,
        data,
        getIcon: (d: IconWrapper) => ({
          url: d.symbol,
          width: d.width,
          height: d.height,
        }),
        getPosition: (d: IconWrapper) => [d.point.longitude, d.point.latitude],
        getSize: (d: IconWrapper) => (d.height + d.width) / 2,
        pickable: true,
      });
      this.layerHolders.icons.set(newIcons.id, {
        id,
        layer: newLayer,
        layerType: DeckGlLayerType.MARKERS,
        wrapper: newIcons,
      });
      this.refreshLayers();
    };
    this.addOrRunReadyCallback(callback);
  };

  public addArrows = (newArrows: Array<ArrowWrapper>) => {
    const callback = () => {
      newArrows.forEach(newArrow => {
        const arrow = new google.maps.Polyline({
          path: [
            {
              lat: newArrow.start.latitude,
              lng: newArrow.start.longitude,
            },
            {
              lat: newArrow.end.latitude,
              lng: newArrow.end.longitude,
            },
          ],
          draggable: false,
          strokeColor: newArrow.color,
          strokeOpacity: 0.9,
          strokeWeight: 2,
          icons: [
            {
              icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
            },
          ],
          map: this.map,
        });
        const oldArrowMarker = this.arrowHolders.get(newArrow.id);
        if (oldArrowMarker) {
          this.arrowHolders.delete(newArrow.id);
          oldArrowMarker.item.setMap(null);
        }
        this.arrowHolders.set(newArrow.id, {
          id: newArrow.id,
          type: newArrow.type,
          item: arrow,
          wrapper: undefined,
          listeners: [],
        });
      });
    };
    this.addOrRunReadyCallback(callback);
  };

  private openInfoWindow = (content: string, anchor: Point): void => {
    const infoWindow = new google.maps.InfoWindow();
    infoWindow.setContent(content);
    if (this.currentOpenInfoWindow) {
      this.currentOpenInfoWindow.close();
    }
    infoWindow.setPosition(
      new google.maps.LatLng(anchor.latitude, anchor.longitude)
    );
    infoWindow.open(this.map);
    this.currentOpenInfoWindow = infoWindow;
  };

  public addInfoWindows = (newInfoWindows: Array<InfoWindowWrapper>) => {
    const callback = () => {
      newInfoWindows.forEach(infoWindowData => {
        if (
          infoWindowData.markerId &&
          this.markerHolders.has(infoWindowData.markerId)
        ) {
          const holder = this.markerHolders.get(infoWindowData.markerId);
          const marker = holder?.item;
          if (marker) {
            // TODO add to listener list and clear old one
            marker.addListener('click', async () => {
              const content =
                typeof infoWindowData.content === 'function'
                  ? await infoWindowData.content()
                  : infoWindowData.content;
              this.openInfoWindow(content, holder.wrapper.point);
            });
          }
        }
      });
    };
    this.addOrRunReadyCallback(callback);
  };

  public addMapListener = (eventName: string, cb: () => void) => {
    this.addOrRunReadyCallback(() => {
      const listener = this.map?.addListener(eventName, _debounce(cb, 500));
      this.mapListenerHolders.set(
        eventName,
        listener as google.maps.MapsEventListener
      );
    });
  };

  public clearMapListener = (eventName: string) => {
    this.addOrRunReadyCallback(() => {
      this.mapListenerHolders.get(eventName)?.remove();
      this.mapListenerHolders.delete(eventName);
    });
  };

  public clearMap = () => {
    // clear callbacks
    this.readyCallbacks.length = 0;
    this.addOrRunReadyCallback(() => {
      this.polygonHolders.forEach(h => h.item.setMap(null));
      this.markerHolders.forEach(h => h.item.setMap(null));
      this.arrowHolders.forEach(h => h.item.setMap(null));
      this.clustererHolders.forEach(h => h.item.setMap(null));
      this.mapListenerHolders.forEach(h => h.remove());
      this.polygonHolders.clear();
      this.markerHolders.clear();
      this.arrowHolders.clear();
      this.clustererHolders.clear();
      this.mapListenerHolders.clear();
    });
  };

  private static clearDrawingTypeHelper(
    drawingType: string | DrawingType,
    holders: Map<
      string,
      GoogleMapItemHolder<
        google.maps.Polygon | google.maps.Marker | google.maps.Polyline,
        unknown
      >
    >
  ) {
    holders.forEach(h => {
      if (h.type === drawingType) {
        h.item.setMap(null);
        holders.delete(h.id);
      }
    });
  }

  public clearDrawingType = (drawingType: string | DrawingType) => {
    // cannot empty the callback list, as there might be other type of drawing in it
    this.addOrRunReadyCallback(() => {
      MapClass.clearDrawingTypeHelper(drawingType, this.polygonHolders);
      MapClass.clearDrawingTypeHelper(drawingType, this.markerHolders);
      MapClass.clearDrawingTypeHelper(drawingType, this.arrowHolders);
      this.removeMarkerClustererOnDrawingType(drawingType);
    });
  };

  private static clearOtherThanDrawingTypesHelper(
    keepDrawingTypes: Array<string | DrawingType>,
    holders: Map<
      string,
      GoogleMapItemHolder<
        google.maps.Polygon | google.maps.Marker | google.maps.Polyline,
        unknown
      >
    >
  ) {
    holders.forEach(h => {
      if (keepDrawingTypes.find(dt => dt === h.type)) {
        h.item.setMap(null);
        holders.delete(h.id);
      }
    });
  }

  // not used currently, remove?
  public clearOtherThanDrawingTypes = (
    keepDrawingTypes: Array<string | DrawingType>
  ) => {
    // cannot empty the callback list, as there might be other type of drawing in it
    this.addOrRunReadyCallback(() => {
      MapClass.clearOtherThanDrawingTypesHelper(
        keepDrawingTypes,
        this.polygonHolders
      );
      MapClass.clearOtherThanDrawingTypesHelper(
        keepDrawingTypes,
        this.markerHolders
      );
      MapClass.clearOtherThanDrawingTypesHelper(
        keepDrawingTypes,
        this.arrowHolders
      );
    });
  };
}

const MapService: MapServiceFactory = () => {
  let loadJSOnGoing = false;
  let loadedJS = false;
  const mapWrappers: Map<MapId, MapClass> = new Map<MapId, MapClass>();

  const get = (mapId: MapId) => {
    const mapWrapper = mapWrappers.get(mapId);
    if (!mapWrapper) {
      // eslint-disable-next-line no-console
      console.error(
        "Erreur lors de l'affichage de la carte, carte référencée avant initialisation"
      );
      return undefined;
    }
    if (loadedJS && mapWrapper.shouldBeInitialized()) {
      mapWrapper.init();
    }
    return {
      polygons: {
        add: mapWrapper.addPolygons,
        get: mapWrapper.getPolygons,
        setVisibility: mapWrapper.setPolygonsVisibility,
        setCanBeEdited: mapWrapper.setPolygonsCanBeEdited,
      },
      markers: {
        add: mapWrapper.addMarkers,
        addClusterer: mapWrapper.addMarkerClusterer,
        removeClustererOnDrawingType:
          mapWrapper.removeMarkerClustererOnDrawingType,
      },
      deckGlArrows: {
        set: mapWrapper.setDeckGlArrows,
      },
      circles: {
        set: mapWrapper.setCircles,
      },
      icons: {
        set: mapWrapper.setIcons,
      },
      arrows: {
        add: mapWrapper.addArrows,
      },
      infoWindows: {
        add: mapWrapper.addInfoWindows,
      },
      center: {
        set: mapWrapper.setCenter,
        get: mapWrapper.getCenter,
      },
      zoom: {
        set: mapWrapper.setZoom,
        get: mapWrapper.getZoom,
      },
      viewPort: {
        get: mapWrapper.getViewPort,
      },
      mapListener: {
        add: mapWrapper.addMapListener,
        clear: mapWrapper.clearMapListener,
      },
      clear: {
        map: mapWrapper.clearMap,
        drawingType: mapWrapper.clearDrawingType,
        otherThanDrawingTypes: mapWrapper.clearOtherThanDrawingTypes,
      },
      getMap: () => mapWrapper.map,
    };
  };

  const free = (mapId: MapId) => {
    const mapWrapper = mapWrappers.get(mapId);
    if (!mapWrapper) {
      return;
    }
    mapWrapper.clearMap();
    mapWrappers.delete(mapId);
  };

  const initMaps = (): void => {
    loadedJS = true;
    mapWrappers.forEach(wrapper => wrapper.init());
  };

  const init = (
    mapId: MapId,
    config: Config,
    mapReference: MutableRefObject<HTMLDivElement>
  ): MapWrapperInterface => {
    const mapWrapperOptional = mapWrappers.get(mapId);
    if (
      mapWrapperOptional === undefined ||
      mapWrapperOptional.refMap !== mapReference
    ) {
      free(mapId);
      mapWrappers.set(mapId, new MapClass(mapId, config, mapReference));
    }
    const mapWrapper = mapWrappers.get(mapId) as MapClass; // cast because it can't be undefined now
    if (loadedJS) {
      // happens when we left the page and went back
      // or simply to another page that has a map
      mapWrapper?.init();
    } else if (!loadJSOnGoing) {
      window.initMap = initMaps;
      loadJSOnGoing = true;
      void signGoogleMapUrl(
        `${BASE_GOOGLE_MAPS_URL}/maps/api/js?callback=initMap&libraries=geometry,marker`
      ).then((signedUrl: UrlDTO) => {
        const script = window.document.createElement('script');
        script.src = BASE_GOOGLE_MAPS_URL + signedUrl.url;
        script.async = true;
        window.document.body.appendChild(script);
      });
    }
    return get(mapId) as MapWrapperInterface;
  };

  return {
    init,
    get,
    free,
  };
};

export default MapService;
