import React from 'react';

import { BRD_GREY, TXT_BLACK } from 'theme';

const SeparatorWithTitle = ({
  style,
  title,
  color,
  titleSize,
  className,
  textColor,
}: {
  style?: Record<string, any>;
  title: string | React.ReactElement<any>;
  color?: string;
  titleSize?: number;
  className?: string;
  textColor?: string;
}) => {
  const lineColor = color || BRD_GREY;
  const fontSize = titleSize || 12;

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', ...style }}
      className={className}
    >
      <div
        style={{
          height: 1,
          flex: 1,
          backgroundColor: lineColor,
          margin: '0 20px',
        }}
      />
      <div
        style={{
          fontFamily: 'Roboto',
          color: textColor || TXT_BLACK,
          fontSize,
          fontWeight: 'bold',
        }}
      >
        {title}
      </div>
      <div
        style={{
          height: 1,
          flex: 1,
          backgroundColor: lineColor,
          margin: '0 20px',
        }}
      />
    </div>
  );
};

export default SeparatorWithTitle;
