import React, { CSSProperties } from 'react';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import {
  ICON_STYLE,
  LABEL_STYLE,
  STYLE_TITLE_BORDER,
  WIDTH_STYLE,
} from 'theme';

import { STYLE_BLOCK_TITLE } from '../theme';
import { CheckboxOption } from '../types';

const STYLE_BLOCK: CSSProperties = {
  marginTop: 20,
  fontWeight: 'bold',
};

const STYLE_FILTER_BLOCK: CSSProperties = {
  fontSize: 13,
  margin: '2px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

type Props = {
  title?: string;
  id: string;
  options: Array<CheckboxOption>;
  selected: string | null | undefined;
  onChange: (id: string, selected: string | null | undefined) => void;
  labelStyle?: any | null | undefined;
  iconStyle?: any | null | undefined;
  style?: any | null | undefined;
};

class RadioButtons extends React.Component<Props> {
  onChange = (event: React.FormEvent, value: string) => {
    const { id, onChange } = this.props;
    onChange(id, value);
  };

  render() {
    const {
      title,
      options,
      selected,
      id,
      style,
      labelStyle,
      iconStyle,
    } = this.props;
    return (
      <div style={STYLE_BLOCK}>
        {title && (
          <div style={STYLE_BLOCK_TITLE}>
            {title}
            <hr style={STYLE_TITLE_BORDER} />
          </div>
        )}
        <RadioButtonGroup
          style={{ ...STYLE_FILTER_BLOCK, ...style }}
          valueSelected={selected}
          name={id}
          onChange={this.onChange}
        >
          {options.map(option => (
            <RadioButton
              key={option.value}
              style={WIDTH_STYLE}
              iconStyle={{ ...ICON_STYLE, ...iconStyle }}
              labelStyle={{ ...LABEL_STYLE, ...labelStyle }}
              value={option.value}
              label={option.label}
            />
          ))}
        </RadioButtonGroup>
      </div>
    );
  }
}

export default RadioButtons;
