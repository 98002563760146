import React, { CSSProperties, LegacyRef, useState } from 'react';
import SelectField from 'material-ui/SelectField';
import { MenuItem, TextField } from 'material-ui';

import { ParkingMeterDTO } from 'api/parkingMeter/type';
import { formatDate } from 'commons/Utils/dateUtil';
import SimpleButton from 'commons/SimpleButton';
import SimpleDialog from 'commons/SimpleDialog';
import DatetimePickerCustom from 'commons/FormComponent/Fields/DatetimePickerCustom';
import { ZoneMap } from 'api/pricing/types';
import FormComponent from 'commons/FormComponent';
import { BKG_LIGHT_BLUE } from 'theme';

import { IParkingMeterPatchObject } from '../types';
import FakeInputBlock from 'commons/FakeInputBlock';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_LIGHT_BLUE,
  color: BKG_LIGHT_BLUE,
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
};

const STYLE_FIELD: CSSProperties = {
  width: '100%',
};

type ParkingMeterInfoWindowProps = {
  zones: Array<ZoneMap>;
  parkingMeter: ParkingMeterDTO;
  deleteParkingMeter: () => void;
  editParkingMeter: (
    number: string,
    updates: IParkingMeterPatchObject[]
  ) => void;
};

const ParkingMeterInfoWindow = React.forwardRef(
  (
    props: ParkingMeterInfoWindowProps,
    ref: LegacyRef<HTMLDivElement> | undefined
  ) => {
    const { zones, parkingMeter, deleteParkingMeter, editParkingMeter } = props;
    const [isEditing, setIsEditing] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [patches, setPatches] = useState<
      Record<string, IParkingMeterPatchObject>
    >({});
    const [selectedZoneValue, setSelectedZoneValue] = useState(
      parkingMeter.codeZone
    );
    const handleDeleteParkingMeter = () => {
      deleteParkingMeter();
      setOpenDeleteDialog(false);
    };

    const handleEditParkingMeter = () => {
      editParkingMeter(parkingMeter.number, Object.values(patches));
      setOpenEditDialog(false);
      setIsEditing(false);
    };

    const handleChange = (path: string, value: any) => {
      let newValue = value;
      if (path === 'pricingCategories' || path === 'tariffCodes') {
        newValue = Array.from(
          new Set(
            value
              .split(',')
              .map((v: string) => v.trim())
              .filter((i: any) => i)
          )
        );
      }

      setPatches(prevPatches => ({
        ...prevPatches,
        [path]: { op: 'replace', path: `/${path}`, value: newValue },
      }));
    };

    const handleZoneChange = (value: any) => {
      setSelectedZoneValue(value);
      const selectedZone = zones.find(zone => zone.id === value);

      setPatches(prevPatches => ({
        ...prevPatches,
        '/zone': {
          op: 'replace',
          path: '/zone',
          value: selectedZone?.name || '',
        },
        '/codeZone': { op: 'replace', path: '/codeZone', value },
      }));
    };

    const handleEditChange = () => {
      if (isEditing) {
        setOpenEditDialog(true);
      } else {
        setIsEditing(true);
      }
    };

    return (
      <div ref={ref}>
        {parkingMeter && (
          <div>
            {!isEditing ? (
              <>
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.number')}
                  value={parkingMeter.number}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.id')}
                  value={parkingMeter.id}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.title')}
                  value={parkingMeter.title}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.type.name')}
                  value={
                    parkingMeter.type === 'VIRTUAL'
                      ? _t('naming.parkingMeter.type.virtual')
                      : _t('naming.parkingMeter.type.physical')
                  }
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.installationDate')}
                  value={formatDate(new Date(parkingMeter.installationDate))}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.warrantyDate')}
                  value={formatDate(new Date(parkingMeter.warrantyDate))}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.serialNumber')}
                  value={parkingMeter.serialNumber.toString()}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.providerName')}
                  value={parkingMeter.providerName}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.model')}
                  value={parkingMeter.model}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t(
                    'naming.parkingMeter.currentLocationInstallationDate'
                  )}
                  value={formatDate(
                    new Date(parkingMeter.currentLocationInstallationDate)
                  )}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.street')}
                  value={parkingMeter.street}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.city')}
                  value={parkingMeter.city}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.zipCode')}
                  value={parkingMeter.zipCode}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.zone')}
                  value={parkingMeter.zone}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.codeZone')}
                  value={parkingMeter.codeZone}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.parkingSpaces')}
                  value={parkingMeter.parkingSpaces.toString()}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.creationDatetime')}
                  value={formatDate(new Date(parkingMeter.creationDatetime))}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.lastModificationDate')}
                  value={formatDate(
                    new Date(parkingMeter.lastModificationDate)
                  )}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.pricingCategories')}
                  value={parkingMeter.pricingCategories.join(', ')}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.tariffCodes')}
                  value={parkingMeter.tariffCodes.join(', ')}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal' }}
                  title={_t('naming.parkingMeter.vehicleTypes')}
                  value={parkingMeter.vehicleTypes.join(', ')}
                />
                <FakeInputBlock
                  inputStyle={{ wordBreak: 'normal', maxWidth: 300 }}
                  title={_t('naming.parkingMeter.comment')}
                  value={parkingMeter.comment}
                />
              </>
            ) : (
              <FormComponent style={STYLE_CONTENT_WRAPPER}>
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={parkingMeter.number}
                  floatingLabelText={_t('naming.parkingMeter.number')}
                  disabled
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={parkingMeter.id}
                  floatingLabelText={_t('naming.parkingMeter.id')}
                  disabled
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  defaultValue={parkingMeter.title}
                  floatingLabelText={_t('naming.parkingMeter.title')}
                  onChange={(e: any, value: string) =>
                    handleChange('title', value)
                  }
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={
                    parkingMeter.type === 'VIRTUAL'
                      ? _t('naming.parkingMeter.type.virtual')
                      : _t('naming.parkingMeter.type.physical')
                  }
                  floatingLabelText={_t('naming.parkingMeter.type.name')}
                  disabled
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={formatDate(new Date(parkingMeter.installationDate))}
                  floatingLabelText={_t('naming.parkingMeter.installationDate')}
                  disabled
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={formatDate(new Date(parkingMeter.warrantyDate))}
                  floatingLabelText={_t('naming.parkingMeter.warrantyDate')}
                  disabled
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={parkingMeter.serialNumber.toString()}
                  floatingLabelText={_t('naming.parkingMeter.serialNumber')}
                  disabled
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={parkingMeter.providerName}
                  floatingLabelText={_t('naming.parkingMeter.providerName')}
                  disabled
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={parkingMeter.model}
                  floatingLabelText={_t('naming.parkingMeter.model')}
                  disabled
                />
                <DatetimePickerCustom
                  hintDate={_t(
                    'naming.parkingMeter.currentLocationInstallationDate'
                  )}
                  name="datepicker"
                  defaultValue={
                    new Date(parkingMeter.currentLocationInstallationDate)
                  }
                  hideTime
                  onChange={v =>
                    handleChange('currentLocationInstallationDate', v)
                  }
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  defaultValue={parkingMeter.street}
                  floatingLabelText={_t('naming.parkingMeter.street')}
                  onChange={(e: any, value: string) =>
                    handleChange('street', value)
                  }
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  defaultValue={parkingMeter.city}
                  floatingLabelText={_t('naming.parkingMeter.city')}
                  onChange={(e: any, value: string) =>
                    handleChange('city', value)
                  }
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  defaultValue={parkingMeter.zipCode}
                  floatingLabelText={_t('naming.parkingMeter.zipCode')}
                  onChange={(e: any, value: string) =>
                    handleChange('zipCode', value)
                  }
                />
                <SelectField
                  floatingLabelText={_t('naming.parkingMeter.zone')}
                  floatingLabelStyle={{ color: '#aaa' }}
                  value={selectedZoneValue}
                  onChange={(event: any, index: number, value: any) =>
                    handleZoneChange(value)
                  }
                >
                  {zones.map(({ id, name }) => (
                    <MenuItem key={id} value={id} primaryText={name} />
                  ))}
                </SelectField>
                <SelectField
                  floatingLabelText={_t('naming.parkingMeter.codeZone')}
                  floatingLabelStyle={{ color: '#aaa' }}
                  value={selectedZoneValue}
                  onChange={(event: any, index: number, value: any) =>
                    handleZoneChange(value)
                  }
                >
                  {zones.map(({ id }) => (
                    <MenuItem key={id} value={id} primaryText={id} />
                  ))}
                </SelectField>
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  defaultValue={parkingMeter.parkingSpaces.toString()}
                  floatingLabelText={_t('naming.parkingMeter.parkingSpaces')}
                  onChange={(e: any, value: string) =>
                    handleChange('parkingSpaces', value)
                  }
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={formatDate(new Date(parkingMeter.creationDatetime))}
                  floatingLabelText={_t('naming.parkingMeter.creationDatetime')}
                  disabled
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  value={formatDate(
                    new Date(parkingMeter.lastModificationDate)
                  )}
                  floatingLabelText={_t(
                    'naming.parkingMeter.lastModificationDate'
                  )}
                  disabled
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  defaultValue={parkingMeter.pricingCategories.join(', ')}
                  floatingLabelText={_t(
                    'naming.parkingMeter.pricingCategories'
                  )}
                  onChange={(e: any, value: string) =>
                    handleChange('pricingCategories', value)
                  }
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  defaultValue={parkingMeter.tariffCodes.join(', ')}
                  floatingLabelText={_t('naming.parkingMeter.tariffCodes')}
                  onChange={(e: any, value: string) =>
                    handleChange('tariffCodes', value)
                  }
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  defaultValue={parkingMeter.vehicleTypes.join(', ')}
                  floatingLabelText={_t('naming.parkingMeter.vehicleTypes')}
                  onChange={(e: any, value: string) =>
                    handleChange('vehicleTypes', value)
                  }
                  disabled
                />
                <TextField
                  style={STYLE_FIELD}
                  underlineFocusStyle={STYLE_INPUTS}
                  floatingLabelFocusStyle={STYLE_INPUTS}
                  defaultValue={parkingMeter.comment}
                  floatingLabelText={_t('naming.parkingMeter.comment')}
                  onChange={(e: any, value: string) =>
                    handleChange('comment', value)
                  }
                />
              </FormComponent>
            )}
            <div style={{ textAlign: 'right', width: '100%', marginTop: 20 }}>
              <SimpleButton
                id={`delete-${parkingMeter.id}`}
                className={`delete-${parkingMeter.id}`}
                title={_tg('action.delete')}
                onClick={_e => setOpenDeleteDialog(true)}
                secondary
              />
              <SimpleButton
                key="dialog-editer"
                title={isEditing ? _tg('action.save_1') : _tg('action.edit')}
                onClick={handleEditChange}
              />
              ,
            </div>
            <SimpleDialog
              open={openDeleteDialog}
              title={_t('dialog.title')}
              actions={[
                <SimpleButton
                  key="dialog-cancel"
                  title={_tg('action.cancel')}
                  onClick={_e => setOpenDeleteDialog(false)}
                  secondary
                />,
                <SimpleButton
                  key="dialog-delete"
                  title={_tg('action.delete')}
                  onClick={handleDeleteParkingMeter}
                />,
              ]}
            />
            <SimpleDialog
              open={openEditDialog}
              title={_t('dialog.titleEdit')}
              actions={[
                <SimpleButton
                  key="dialog-cancel"
                  title={_tg('action.cancel')}
                  onClick={_e => setOpenEditDialog(false)}
                  secondary
                />,
                <SimpleButton
                  key="dialog-edit"
                  title={_tg('action.edit')}
                  onClick={handleEditParkingMeter}
                />,
              ]}
            />
          </div>
        )}
      </div>
    );
  }
);

export default ParkingMeterInfoWindow;
