import React from 'react';

type Props = {
  mediaUrn: string;
  name: string;
};

function PictureLink({ mediaUrn, name }: Props): JSX.Element {
  return (
    <a href={mediaUrn} target="_blank" rel="noopener noreferrer">
      <img
        style={{ maxWidth: 200, maxHeight: 200 }}
        src={mediaUrn}
        alt={name}
      />
    </a>
  );
}

export default PictureLink;
