import { SnackbarActions, SnackbarState } from './types';

const INITIAL_STATE: SnackbarState = {
  isOpen: false,
  message: '',
};

export const snackbarReducer = (
  state: SnackbarState = INITIAL_STATE,
  action: SnackbarActions
) => {
  switch (action.type) {
    case 'SNACKBAR_SEND_MESSAGE':
      return {
        ...state,
        isOpen: true,
        message: action.payload,
      };
    case 'SNACKBAR_CLOSE':
      return {
        ...state,
        isOpen: false,
        message: '',
      };
    default:
      return state;
  }
};
