import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';

import { ComplementRequest } from '../../tefps/RecoursesV2/pages/Detail/ComplementRequest/types';
import { FileCheckbox } from '../../tefps/RecoursesV2/utils/recourseFilesUtils';

export interface ComplementRequestServiceFactory {
  (): ComplementRequestServiceInterface;
}

export interface ComplementRequestServiceInterface {
  getComplementRequest: () => ComplementRequest;
  setComplementRequest: (complementRequest: ComplementRequest) => void;
  watchComplementRequest: WatchFunctionType<ComplementRequest>;
  getOtherFile: () => FileCheckbox;
  setOtherFile: (file: FileCheckbox) => void;
  watchOtherFile: WatchFunctionType<FileCheckbox>;
}

const ComplementRequestService: ComplementRequestServiceFactory = () => {
  const {
    getValue: getComplementRequest,
    setValue: setComplementRequest,
    watchValue: watchComplementRequest,
  } = Watcher<ComplementRequest>({ files: [], complementInfo: '' });

  const {
    getValue: getOtherFile,
    setValue: setOtherFile,
    watchValue: watchOtherFile,
  } = Watcher<FileCheckbox>({ name: '', checked: false });

  return {
    getComplementRequest,
    setComplementRequest,
    watchComplementRequest,
    getOtherFile,
    setOtherFile,
    watchOtherFile,
  };
};

export default ComplementRequestService;
