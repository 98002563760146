import React from 'react';

import { Flex, Text } from '@cvfm-front/commons-ui';
import './ThirdPartyValidatedElement.css';

const { _tg } = window.loadTranslations(__filename);
type Props = {
  title: string;
};
const ThirdPartyValidatedElement = ({ title }: Props): JSX.Element => {
  return (
    <Flex className="fc-validated-element">
      <img
        src="static/verified_blue.svg"
        alt={_tg('commons.thirdParty.alt')}
        title={_tg('commons.thirdParty.alt')}
      />
      <Text className="fc-validated-element__text">{title}</Text>
    </Flex>
  );
};

export default ThirdPartyValidatedElement;
