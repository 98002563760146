import { WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import { EsCityParkingSpaceDTO } from '@cvfm-front/tefps-types';

import { MapId } from '../MapService';

import { ParkingSpaceServiceApiInterface } from './ParkingSpaceApiService';
import { ParkingSpaceServiceMapInterface } from './ParkingSpaceMapService';

export interface ParkingSpaceEditionServiceFactory {
  (
    parkingSpaceApi: ParkingSpaceServiceApiInterface,
    parkingSpaceMap: ParkingSpaceServiceMapInterface
  ): ParkingSpaceEditionServiceInterface;
}

export enum EditionType {
  CREATION,
  MODIFICATION,
  VERSION_EDITION,
  DUPLICATION,
}

export interface ParkingSpaceEditionServiceInterface {
  watchEditedParkingSpace: WatchFunctionType<EsCityParkingSpaceDTO | null>;
  watchEditionType: WatchFunctionType<EditionType>;
  startEdition: (
    parkingSpace: EsCityParkingSpaceDTO,
    editionType: EditionType
  ) => void;
  saveEdition: (changed: EsCityParkingSpaceDTO) => Promise<void>;
  reset: () => void;
}

const ParkingSpaceEditionService: ParkingSpaceEditionServiceFactory = (
  parkingSpaceApi,
  parkingSpaceMap
) => {
  const {
    watchValue: watchEditedParkingSpace,
    setValue: setEditedParkingSpace,
  } = Watcher<EsCityParkingSpaceDTO | null>(null);

  const {
    watchValue: watchEditionType,
    setValue: setEditionType,
    getValue: getEditionType,
  } = Watcher<EditionType>(EditionType.CREATION);

  const startEdition: ParkingSpaceEditionServiceInterface['startEdition'] = (
    parkingSpaceToEdit,
    editionType
  ) => {
    setEditedParkingSpace(parkingSpaceToEdit);
    setEditionType(editionType);
  };

  const reset = () => {
    setEditedParkingSpace(null);
  };

  const saveEdition = async (parkingSpaceToSave: EsCityParkingSpaceDTO) => {
    const editionType = getEditionType();
    switch (editionType) {
      case EditionType.MODIFICATION:
      case EditionType.VERSION_EDITION:
        await parkingSpaceApi.updateParkingSpace(parkingSpaceToSave);
        break;
      case EditionType.CREATION:
      default:
        await parkingSpaceApi.addParkingSpace(parkingSpaceToSave);
        break;
    }

    reset();

    // Ajout d'un délais pour attendre l'indexation de l'emprise
    setTimeout(() => {
      void (async () => {
        await parkingSpaceMap.refresh(MapId.PARKING_SPACE);
        await parkingSpaceMap.setSelectedId(
          MapId.PARKING_SPACE,
          parkingSpaceToSave.parkingSpaceId,
          true
        );
      })();
    }, 1000);
  };

  return {
    watchEditedParkingSpace,
    watchEditionType,
    startEdition,
    saveEdition,
    reset,
  };
};

export default ParkingSpaceEditionService;
