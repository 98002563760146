import { unreachable } from 'commons/Enums';
import { ErrorType } from 'commons/types/error';

const { _tg } = window.loadTranslations(__filename);

const translateError = (errorType: ErrorType): string => {
  switch (errorType) {
    case 'OrderPlateUsed':
      return _tg('errors.orderPlateUsed');
    case 'OrderRestrictionViolation':
      return _tg('errors.orderRestriction');
    case 'OrderRequirementNotFulfilled':
      return _tg('errors.orderRequirement');
    case 'OrderPlateRequirementNotFulfilled':
      return _tg('errors.OrderPlateRequirementNotFulfilled');
    case 'OrderPlateMaxLengthExceeded':
      return _tg('errors.OrderPlateRequirementNotFulfilled');
    case 'OrderPlateNoVehicle':
      return _tg('errors.OrderPlateNoVehicle');
    case 'OrderIncompatible':
      return _tg('errors.orderIncompatible');
    case 'OrderActiveRightNotFulfilled':
      return _tg('errors.orderActiveRightNotFulfilled');
    case 'ActiveRightDoesNotCoverStartingDateOfOrders':
      return _tg('errors.activeRightDoesNotCoverStartingDateOfOrders');
    case 'MissingActiveRightUsed':
      return _tg('errors.missingActiveRightUsed');
    case 'CannotCreateOrderWithMoreThanOneSubscriber':
      return _tg('errors.cannotCreateOrderWithMoreThanOneSubscriber');
    case 'ActiveRightMandatory':
      return _tg('errors.activeRightMandatory');
    case 'AtLeastOneZoneIsMandatory':
      return _tg('errors.atLeastOneZoneIsMandatory');
    case 'SubscriberDoesNotResideInRequiredZones':
      return _tg('errors.subscriberDoesNotResideInRequiredZones');
    case 'ValidityPeriodDurationExceedsOptionDuration':
      return _tg('errors.validityPeriodDurationExceedsOptionDuration');
    case 'ValidityPeriodStartDateEqualsEndDate':
      return _tg('errors.validityPeriodStartDateEqualsEndDate');
    case 'ValidityPeriodDoesNotStartOnOneOfRequiredWeekDay':
      return _tg('errors.validityPeriodDoesNotStartOnOneOfRequiredWeekDay');
    case 'NoOrderWithThisIdInTheCity':
      return _tg('errors.noOrderWithThisIdInTheCity');
    case 'UsedOrderNotFound':
      return _tg('errors.usedOrderNotFound');
    case 'OrderPaymentNotAllowed':
      return _tg('errors.orderPaymentNotAllowed');
    case 'OrderEvidenceNotExpected':
      return _tg('errors.orderEvidenceNotExpected');
    case 'OrderEvidenceMissing':
      return _tg('errors.orderEvidenceMissing');
    case 'OrderAlreadyExists':
      return _tg('errors.orderAlreadyExists');
    case 'SubscriberAlreadyInOrder':
      return _tg('errors.subscriberAlreadyInOrder');
    case 'SubscriberProfileMissing':
      return _tg('errors.SubscriberProfileMissing');
    case 'SubscriberProfileIdMissing':
      return _tg('errors.SubscriberProfileIdMissing');
    case 'SubscriberProfileResidenceMissing':
      return _tg('errors.SubscriberProfileResidenceMissing');
    case 'SubscriberProfileCompanyMissing':
      return _tg('errors.SubscriberProfileCompanyMissing');
    case 'OrderDecisionAlreadyExists':
      return _tg('errors.orderDecisionAlreadyExists');
    case 'OrderDecisionNotExpected':
      return _tg('errors.orderDecisionNotExpected');
    case 'EvidenceNoLongerAvailable':
      return _tg('errors.evidenceNoLongerAvailable');
    case 'EvidenceNeedsToBeUploaded':
      return _tg('errors.evidenceNeedsToBeUploaded');
    case 'ProductSharingDisabled':
      return _tg('errors.productSharingDisabled');
    case 'OrderEvidenceNotFound':
      return _tg('errors.orderEvidenceNotFound');
    case 'OrderPeriodStartAfterEnd':
      return _tg('errors.OrderPeriodStartAfterEnd');
    case 'OrderPeriodStartOutsideEligibilityPeriod':
      return _tg('errors.OrderPeriodStartOutsideEligibilityPeriod');
    case 'OrderPeriodEndOutsideEligibilityPeriodAndAnyFallbackEligibility':
      return _tg(
        'errors.OrderPeriodEndOutsideEligibilityPeriodAndAnyFallbackEligibility'
      );
    case 'OrderPeriodStartsDuringABlockedPeriod':
      return _tg('errors.OrderPeriodStartsDuringABlockedPeriod');
    case 'OrderActiveRightDoesNotMatchAnyRequiredProduct':
      return _tg('errors.OrderActiveRightDoesNotMatchAnyRequiredProduct');
    case 'OrderActiveRightDoesNotCoverEveryPlate':
      return _tg('errors.OrderActiveRightDoesNotCoverEveryPlate');
    case 'OrderIncompatibleWithAnotherOrder':
      return _tg('errors.OrderIncompatibleWithAnotherOrder');
    case 'OrderIncompatibleWithWaitingToPayOrder':
      return _tg('errors.OrderIncompatibleWithWaitingToPayOrder');
    case 'OrderMaximumNumberOfOrdersExceeded':
      return _tg('errors.OrderMaximumNumberOfOrdersExceeded');
    case 'OrderCreationNotAvailable':
      return _tg('errors.OrderCreationNotAvailable');
    case 'OrderCreationSubscriberNeedAZone':
      return _tg('errors.OrderCreationSubscriberNeedAZone');
    case 'AgentNotFound':
      return _tg('errors.AgentNotFound');
    case 'AgentEmailNotFound':
      return _tg('errors.AgentEmailNotFound');
    case 'OrderMaximumNumberOfPlatesExceeded':
      return _tg('errors.OrderMaximumNumberOfPlatesExceeded');
    case 'OrderPendingPlateChangeRequest':
      return _tg('errors.OrderPendingPlateChangeRequest');
    case 'OrderPeriodViolation':
      return _tg('errors.OrderPeriodViolation');
    case 'OrderStartNotChargedDay':
      return _tg('errors.OrderStartNotChargedDay');
    case 'OrderNotFound':
      return _tg('errors.OrderNotFound');
    case 'DeletingLastPlate':
      return _tg('errors.DeletingLastPlate');
    case 'OrderAlreadyStarted':
      return _tg('errors.OrderAlreadyStarted');
    case 'OrderIsNotCancelable':
      return _tg('errors.OrderIsNotCancelable');
    case 'OrderIsNotCancelableDueToPaymentNotif':
      return _tg('errors.OrderIsNotCancelableDueToPaymentNotif');
    case 'VehicleAlreadyExists':
      return _tg('errors.VehicleAlreadyExists');
    case 'VehicleCritairForbidden':
      return _tg('errors.VehicleCritairForbidden');
    case 'SubscriberStillHasValidOrders':
      return _tg('errors.SubscriberStillHasValidOrders');
    case 'RequestedDurationUnreachable':
      return _tg('errors.RequestedDurationUnreachable');
    case 'InvalidEmailException':
      return _tg('errors.InvalidEmailException');
    case 'TemporaryPasswordExpired':
      return _tg('errors.TemporaryPasswordExpired');
    case 'FcAccountMissMatch':
      return _tg('errors.FcAccountMissMatch');
    default:
      return unreachable(errorType);
  }
};

export default translateError;
