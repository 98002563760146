import services from 'commons/services';
import useWatcher from 'commons/hooks/useWatcher';
import {
  initialFilters,
  RecourseSearchCriterias,
} from 'tefps/RecoursesV2/utils/recourseUtils';

const useRecourseFilters = (): [
  RecourseSearchCriterias,
  (arg0: RecourseSearchCriterias) => void
] => {
  return [
    useWatcher(
      services.recourseListService.watchRecourseFilters,
      initialFilters()
    ),
    services.recourseListService.updateFilters,
  ];
};

export default useRecourseFilters;
