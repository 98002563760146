import querystring from 'querystring';
import moment from 'moment';

import { Base64 } from 'commons/helpers/Base64';

// querystring doesn't remove the first '?' of the query -_-'
function removeFirstQuestionMark(str: string) {
  if (str && str.startsWith('?')) {
    return str.substring(1);
  }
  return str;
}

export default function loadQueryParamFilters(
  search: string,
  updateFilter: (filters: {
    plate?: string;
    fpsStatementDate?: Date;
  }) => void | Promise<void>
): void {
  const params = querystring.parse(removeFirstQuestionMark(search));
  let plate;
  let fpsStatementDate;
  if (params.fpsStatementDate) {
    fpsStatementDate = moment(params.fpsStatementDate, 'DD-MM-YYYY').toDate();
  }
  if (params.plate) {
    const stringFilters = Base64.decode(params.plate as string);
    plate = JSON.parse(stringFilters) as string;
  }

  void updateFilter({ plate, fpsStatementDate });
}
