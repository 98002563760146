import moment from 'moment';

import {
  FilterDate,
  FormattedFilterDate,
} from 'commons/SidebarV2/Components/Dates';

export const formatFilterDate = (date: FilterDate): FormattedFilterDate => {
  return {
    from: date.from ? moment(date.from).toISOString() : null,
    to: date.to ? moment(date.to).toISOString() : null,
  };
};
