import Backend from 'i18next-http-backend/cjs';
import React from 'react';
import i18next, { StringMap, TOptions } from 'i18next';
import moment from 'moment';
import urljoin from 'url-join';
import { initReactI18next, Trans, TransProps } from 'react-i18next';

const DEFAULT_NAMESPACE = 'DEFAULT_NAMESPACE';

export type LoadTranslations = (
  filename?: string
) => {
  _t: (key: string, arg?: TOptions<StringMap> | string) => string;
  _tg: (key: string, arg?: TOptions<StringMap> | string) => string;
  Trans: (props: any) => JSX.Element;
};

/**
 * initialize a i18next instance and add it in window namespace
 */
export const initI18next = async (): Promise<void> => {
  await i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
      debug: process.env.NODE_ENV !== 'production',
      fallbackLng: 'fr',
      interpolation: {
        escapeValue: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        format: (value, format, lng) => {
          if (typeof value === 'number') return value.toLocaleString();
          return value as string;
        },
      },
      load: 'languageOnly',
      react: {
        wait: true, // Wait for namespace to be loaded before displaying child component
        useSuspense: false,
      },
      backend: {
        // Required because the frontend might not be exposed at the domain root
        loadPath: urljoin('/static/locales/{{lng}}/{{ns}}.json'),
        // Append the git hash so that the URL is saw as new on each release
        queryStringParams: { v: __BUILD_SHA__ },
      },
    });

  moment.locale(i18next.language);
  i18next.on('languageChanged', lng => {
    moment.locale(lng);
  });

  window.i18next = i18next;

  window.loadTranslations = (filename?: string) => {
    const namespace = filename?.split('.')?.[0] || DEFAULT_NAMESPACE;
    const traductionKey = namespace.substring(4).replace(/\//g, '.');
    // we actually use namespace as prefix here
    return {
      _t: (key: string, arg?: TOptions<StringMap> | string): string => {
        return i18next.t(`${traductionKey}.${key}`, arg);
      },
      _tg: (key: string, arg?: TOptions<StringMap> | string): string => {
        return i18next.t(`${key}`, arg);
      },
      Trans: (props: TransProps<string>) => (
        // eslint-disable-next-line react/destructuring-assignment, react/jsx-props-no-spreading
        <Trans {...props} i18nKey={`${traductionKey}.${props.i18nKey || ''}`} />
      ),
    };
  };
};
