import React, { CSSProperties } from 'react';
import Coordinate from 'jsts/org/locationtech/jts/geom/Coordinate';
import GeometryFactory from 'jsts/org/locationtech/jts/geom/GeometryFactory';
import Dropzone from 'react-dropzone';
import _cloneDeep from 'lodash.clonedeep';
import Checkbox from 'material-ui/Checkbox';
import Dialog from 'material-ui/Dialog';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import _uniqueId from 'lodash.uniqueid';

import BoButton from 'facade/BoButton';
import ErrorBlock from 'commons/ErrorBlock';
import { BKG_LIGHT_BLUE, STYLE_ERROR_WRAPPER } from 'theme';
import { PolygonForMap } from 'api/pricing/types';
import { validateMandatoryInput } from 'commons/Validators';
import { ZoningDTO } from '@cvfm-front/tefps-types';

const { _t, _tg } = window.loadTranslations(__filename);

const ROAD_WIDTH = 6; // en mètres
const METERS_PER_DEGREE = 111132.954;
const ROAD_WIDTH_IN_DEGREE = ROAD_WIDTH / METERS_PER_DEGREE;

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: 'white',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  width: '100%',
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_LIGHT_BLUE,
  color: BKG_LIGHT_BLUE,
};

function getZoneIds(polygon: PolygonForMap, zoneIdProperty: string) {
  if (polygon.properties) {
    const pZoneIds = polygon.properties[zoneIdProperty];
    return Array.isArray(pZoneIds) ? pZoneIds : [pZoneIds];
  }
  return [];
}

type Props = {
  zoning: ZoningDTO;
  addPolygons: (polygons: Array<PolygonForMap>) => void;
};

type State = {
  openModal: boolean;
  polygons: Array<PolygonForMap>;
  property: string;
  propertyList: Array<{ key: string; value: any }>;
  useFieldForZones: boolean;
  zoneId: string | null | undefined;
  error: string | null | undefined;
  errors: {
    [key: string]: string | null | undefined;
  };
};

class ImportPolygons extends React.Component<Props, State> {
  state: State = {
    openModal: false,
    polygons: [],
    zoneId: null,
    useFieldForZones: true,
    property: '',
    propertyList: [],
    error: null,
    errors: {},
  };

  onDrop = (files: Array<File>) => {
    if (files.length === 0) {
      this.setState({
        error: _t('feedback.error.incorrectFileFormat'),
        openModal: true,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const fileAsBinaryString: string = (reader.result as any) as string;

      try {
        const geoJSON = JSON.parse(fileAsBinaryString);
        const polygons: Array<PolygonForMap> = [];

        geoJSON.features.forEach((feature: any) => {
          if (feature.geometry && feature.geometry.type === 'Polygon') {
            const points = feature.geometry.coordinates[0].map(
              (p: [number, number]) => ({
                lat: p[1],
                lon: p[0],
              })
            );
            polygons.push({
              id: _uniqueId(Date.now().toString()),
              name: '',
              zoneIds: [],
              points,
              properties: feature.properties,
            });
          } else if (
            feature.geometry &&
            feature.geometry.type === 'LineString'
          ) {
            // Les lignes brisées sont converties en polygones
            const geoInput = feature.geometry.coordinates.map(
              (point: [number, number]) => new Coordinate(point[1], point[0])
            );
            const geometryFactory = new GeometryFactory();
            const shell = geometryFactory.createLineString(geoInput);
            const polygon = shell.buffer(ROAD_WIDTH_IN_DEGREE);
            const points = polygon
              .getCoordinates()
              .map((coord: jsts.geom.Coordinate) => ({
                lat: coord.x,
                lon: coord.y,
              }));
            points.pop();
            polygons.push({
              id: _uniqueId(Date.now().toString()),
              name: '',
              zoneIds: [],
              points,
              properties: feature.properties,
            });
          }
        });
        const polygonProperties =
          polygons[0] && polygons[0].properties ? polygons[0].properties : null;
        const propertyList = polygonProperties
          ? Object.entries(polygonProperties).map(([key, value]) => ({
              key,
              value: value || '',
            }))
          : [];

        this.setState({ polygons, propertyList });
      } catch (error) {
        this.setState({
          error: _t('feedback.error.fileReading_error'),
          openModal: true,
        });
      }
    };
    reader.onabort = () =>
      this.setState({
        error: _t('feedback.warning.fileReadingCanceled'),
        openModal: true,
      });
    reader.onerror = () =>
      this.setState({
        error: _t('feedback.error.fileReading'),
        openModal: true,
      });

    reader.readAsText(files[0]);

    this.setState({ openModal: true });
  };

  onCheck = (e: React.MouseEvent<HTMLElement>, isInputChecked: boolean) => {
    this.setState({
      useFieldForZones: isInputChecked,
      zoneId: null,
      errors: {},
    });
  };

  handleClose = () => {
    this.setState({
      openModal: false,
      polygons: [],
      error: null,
    });
  };

  changeName = (event: any, index: number, property: string) =>
    this.setState({ property });

  changeZone = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    zoneId: string
  ) => this.setState({ zoneId });

  validateImport = () => {
    const { property, zoneId, useFieldForZones } = this.state;
    const errors = {
      property: validateMandatoryInput(property),
      zoneId: validateMandatoryInput(zoneId),
    };
    if (errors.property || errors.zoneId) {
      this.setState({ errors });
      return;
    }
    const polygons: PolygonForMap[] = _cloneDeep(this.state.polygons);

    const unknownZones = new Set();
    const zoneRoutingObject = this.props.zoning.zones.reduce(
      (accumulator, { id, name }) => {
        return { ...accumulator, [id]: id, [name]: id };
      },
      {}
    );

    polygons.forEach((polygon: PolygonForMap) => {
      polygon.name =
        polygon.properties && polygon.properties[property]
          ? polygon.properties[property].toString()
          : '';
      // Si les zones sont déterminées par la valeur d'un champ,
      // On récupère cette valeur et on l'affecte à l'attribut zoneIds des polygones
      if (useFieldForZones) {
        const pZoneIds = getZoneIds(polygon, zoneId as string);
        const validIds = new Set<string>();
        pZoneIds.forEach(pZoneId => {
          const normalizedZoneId = zoneRoutingObject[pZoneId];
          if (normalizedZoneId) {
            validIds.add(normalizedZoneId);
          } else {
            // Si la zone n'existe pas, on renverra une erreur
            unknownZones.add(pZoneId);
          }
        });
        if (unknownZones.size === 0) polygon.zoneIds = [...validIds];
      } else {
        // Cas où tous les polygones sont affectés à la même zone
        polygon.zoneIds = [zoneId as string];
      }
    });

    if (unknownZones.size > 0) {
      const nonExistentZones = _t('element.nonExistentZones', {
        zones:
          unknownZones.size === 1
            ? [...unknownZones].join(', ')
            : [...unknownZones].join(', ').slice(0, -2),
      });
      this.setState({ errors: { nonExistentZones } });
      return;
    }

    this.props.addPolygons(polygons);
    this.handleClose();
  };

  render() {
    const {
      openModal,
      error,
      errors,
      zoneId,
      property,
      propertyList,
      useFieldForZones,
    } = this.state;
    const { zoning } = this.props;

    const propertyMenuItems = propertyList.map(({ key, value }) => (
      <MenuItem key={key} value={key} primaryText={`${key}: ${value}`} />
    ));

    const actions = [
      <BoButton
        style={{ marginRight: 10 }}
        key={1}
        label={_tg('action.cancel')}
        onClick={this.handleClose}
      />,
      <BoButton
        key={2}
        label={_tg('action.validate')}
        primary
        keyboardFocused
        onClick={this.validateImport}
      />,
    ];

    return (
      <div style={{ marginTop: 10 }}>
        <Dropzone
          accept="application/vnd.geo+json, .geojson"
          multiple={false}
          onDrop={this.onDrop}
          style={{ width: 'auto', height: 'auto', border: 'none' }}
        >
          <BoButton
            label={_t('element.importPolygonButton.label')}
            fullWidth
            primary
          />
        </Dropzone>

        <Dialog
          actions={actions}
          title={_t('element.dialog.title')}
          open={openModal}
          titleStyle={STYLE_TITLE}
        >
          {!error && (
            <div style={STYLE_CONTENT_WRAPPER}>
              <div style={STYLE_CONTENT_WRAPPER}>
                <SelectField
                  underlineFocusStyle={
                    STYLE_INPUTS
                  } /* floatingLabelFocusStyle={STYLE_INPUTS} */
                  value={property}
                  onChange={this.changeName}
                  floatingLabelText={_t(
                    'element.dialog.selectPolygons.floatingLabelText'
                  )}
                  errorText={errors.property}
                >
                  {propertyMenuItems}
                </SelectField>
              </div>
              <div
                style={{ ...STYLE_CONTENT_WRAPPER, flexDirection: 'column' }}
              >
                <Checkbox
                  onCheck={this.onCheck}
                  checked={useFieldForZones}
                  label={_t('element.dialog.useFieldForZones.label')}
                />
                {!useFieldForZones && (
                  <div style={{ color: '#A00', fontSize: '0.85em' }}>
                    {_t('element.dialog.useFieldForZones.message')}
                  </div>
                )}
                <SelectField
                  value={zoneId}
                  onChange={this.changeZone}
                  style={{ width: '100%' }}
                  floatingLabelText={_t(
                    'element.dialog.selectZones. floatingLabelText'
                  )}
                  errorText={errors.zoneId || errors.nonExistentZones}
                >
                  {useFieldForZones
                    ? propertyMenuItems
                    : zoning.zones.map(zone => (
                        <MenuItem
                          key={zone.id}
                          value={zone.id}
                          primaryText={zone.name}
                        />
                      ))}
                </SelectField>
              </div>
              <br />
              <br />
            </div>
          )}
          {error && (
            <div style={STYLE_ERROR_WRAPPER}>
              <ErrorBlock error={{ message: error }} />
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}

export default ImportPolygons;
