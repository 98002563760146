import React, { CSSProperties, FocusEvent } from 'react';
import moment from 'moment';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';

import BoButton from 'facade/BoButton';

const { _t, _tg } = window.loadTranslations(__filename);

type ChronoFields = 'years' | 'months' | 'days' | 'hours' | 'minutes';
type ErrorType = { [key in ChronoFields]?: string };
type PeriodType = {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

type PeriodPickerProps = {
  value?: string | null;
  idx?: number;
  width?: number | string;
  errorText?: string;
  onChange: (event: React.FormEvent<any> | undefined, period: string) => void;
  disabled?: boolean;
  style?: CSSProperties;
  hint?: string;
  periodRender?: (value?: string | null) => string;
  withTime?: boolean;
};

type PeriodPickerState = {
  isOpen: boolean;
  period: PeriodType;
  errors: ErrorType;
};

function initState({
  value,
  withTime = false,
}: PeriodPickerProps): PeriodPickerState {
  const defaultIsoPeriod = 'P0Y0M0DT0H0M0S';
  const period = value
    ? moment.duration(value)
    : moment.duration(defaultIsoPeriod);
  return {
    isOpen: false,
    period: {
      years: period.years(),
      months: period.months(),
      days: period.days(),
      hours: period.hours(),
      minutes: period.minutes(),
      seconds: period.seconds(),
    },
    errors: {},
  };
}

/**
 * Period.days() et period.months() de moment.duration transforme la valeur de _days.
 * Au lieu d'avoir 60 jours, l'utilisateur se retrouvera alors avec 1 mois et 29 jours.
 * On perd donc de l'information car 1 mois peut à la fois être 28, 29, 30 et 31 jours pour le calendrier grégorien.
 *
 * On utilise la valeur brute de _days pour les jours et on retourne l'année et le mois à partir de _months
 */
export function localPeriodRenderer(value?: string | null): string | undefined {
  if (!value) return '';
  const duration = moment.duration(value);
  const days = duration.days();
  const months = duration.months();
  const years = duration.years();
  const displayedMonths = months % 12;

  const result = [];
  if (years) {
    result.push(
      `${years} ${_tg('field.date.year', { count: years }).toLowerCase()}`
    );
  }
  if (displayedMonths) {
    result.push(
      `${displayedMonths} ${_tg('field.date.month', {
        count: displayedMonths,
      }).toLowerCase()}`
    );
  }
  if (days) {
    result.push(
      `${days} ${_tg('field.date.day', { count: days }).toLowerCase()}`
    );
  }
  const last = result.pop();
  return result.length
    ? `${result.join(', ')} ${_t('field.and')} ${last}`
    : last;
}

class PeriodPicker extends React.Component<
  PeriodPickerProps,
  PeriodPickerState
> {
  state: PeriodPickerState = initState(this.props);

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: PeriodPickerProps) {
    if (nextProps.value !== this.props.value) {
      this.setState(initState(nextProps));
    }
  }

  onChangePeriod = (key: ChronoFields, value: string) => {
    const { period: previousPeriod } = this.state;
    const period = {
      ...previousPeriod,
      [key]: value ? Math.abs(Math.round(parseInt(value, 10))) : value, // allow empty space
    };
    this.setState({ period });
  };

  onChangeYears = (e: React.FormEvent, value: string): void => {
    this.onChangePeriod('years', value);
  };

  onChangeMonths = (e: React.FormEvent, value: string): void => {
    this.onChangePeriod('months', value);
  };

  onChangeDays = (e: React.FormEvent, value: string): void => {
    this.onChangePeriod('days', value);
  };

  onChangeHours = (e: React.FormEvent, value: string): void => {
    this.onChangePeriod('hours', value);
  };

  onChangeMinutes = (e: React.FormEvent, value: string): void => {
    this.onChangePeriod('minutes', value);
  };

  onFocus = ({ currentTarget }: FocusEvent<HTMLElement>) => {
    this.setState({ isOpen: true });
    currentTarget.blur();
  };

  openEditor = () => {
    this.setState({ isOpen: true });
  };

  closeEditor = () => {
    this.setState({ isOpen: false });
  };

  submit = () => {
    const { period } = this.state;

    const errors = Object.entries(period).reduce((prev, [key, value]) => {
      if (!(Number(value) >= 0)) {
        prev[key] = _t('element.invalidField');
      }
      return prev;
    }, {});

    if (Object.keys(errors).length) {
      this.setState(errors);
    } else {
      const { onChange } = this.props;
      onChange(undefined, moment.duration(period).toISOString()); // return month and days (ex: P1Y3M4D)
      this.closeEditor();
    }
  };

  renderInput = () => {
    const { period, errors, isOpen } = this.state;
    const { withTime } = this.props;
    const periodValue = moment.duration(period);
    const actions = [
      <BoButton
        key="annuler"
        style={{ marginRight: 10 }}
        label={_tg('action.cancel')}
        onClick={this.closeEditor}
      />,
      <BoButton
        key="modifier"
        style={{ marginRight: 10 }}
        label={_tg('action.modify')}
        primary
        disabled={!(periodValue.asDays() > 0)}
        onClick={this.submit}
      />,
    ];

    return (
      <Dialog
        title={_t('element.dialog.title')}
        actions={actions}
        modal
        autoScrollBodyContent
        open={isOpen}
      >
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <TextField
            style={{ width: 170 }}
            type="number"
            floatingLabelText={_tg('field.date.year_plural')}
            value={period.years}
            onChange={this.onChangeYears}
            errorText={errors.years}
          />
          <TextField
            style={{ width: 170 }}
            type="number"
            floatingLabelText={_tg('field.date.month')}
            value={period.months}
            onChange={this.onChangeMonths}
            errorText={errors.months}
          />
          <TextField
            style={{ width: 170 }}
            type="number"
            floatingLabelText={_tg('field.date.day_plural')}
            value={period.days}
            onChange={this.onChangeDays}
            errorText={errors.days}
          />
          {withTime && (
            <>
              <TextField
                style={{ width: 170 }}
                type="number"
                floatingLabelText={_tg('field.date.hours')}
                value={period.hours}
                onChange={this.onChangeHours}
                errorText={errors.hours}
              />
              <TextField
                style={{ width: 170 }}
                type="number"
                floatingLabelText={_tg('field.date.min_plural')}
                value={period.minutes}
                onChange={this.onChangeMinutes}
                errorText={errors.minutes}
              />
            </>
          )}
        </div>
      </Dialog>
    );
  };

  render() {
    const {
      disabled,
      periodRender,
      hint,
      value,
      style,
      onChange,
      width,
      errorText,
    } = this.props;

    return (
      <div style={style}>
        <TextField
          onChange={onChange}
          value={
            periodRender ? periodRender(value) : localPeriodRenderer(value)
          }
          style={{ width } || {}}
          floatingLabelText={hint || _t('element.changeText.floatingLabelText')}
          disabled={disabled}
          // eslint-disable-next-line
          // @ts-ignore
          onClick={this.openEditor}
          onFocus={this.onFocus}
          errorText={errorText}
        />
        {this.renderInput()}
      </div>
    );
  }
}

export default PeriodPicker;
