import services from 'commons/services';
import { CityRecourseReason } from 'api/recourse/types';

import useWatcher from './useWatcher';

const useReason = (): [
  CityRecourseReason | null,
  (arg0: CityRecourseReason | null) => void
] => {
  return [
    useWatcher(services.proposal.watchReason, null),
    services.proposal.setReason,
  ];
};

export default useReason;
