import services from 'commons/services';

import useWatcher from './useWatcher';

const useNewPrice = (): [number | null, (arg0: number | null) => void] => {
  return [
    useWatcher(services.decisionPrice.watchNewPrice, null),
    services.decisionPrice.setNewPrice,
  ];
};

export default useNewPrice;
