import * as React from 'react';
import { connect } from 'react-redux';
import MaterialSnackbar from 'material-ui/Snackbar';

import { mapSnackbarStateToProps } from './ducks/mapper';

type SnackbarProps = {
  // Redux props from Snackbar
  isOpen: boolean;
  message: string;
};

const Snackbar = ({ isOpen, message }: SnackbarProps) => {
  return (
    <MaterialSnackbar open={isOpen} message={message} autoHideDuration={5000} />
  );
};

export default connect(mapSnackbarStateToProps, null)(Snackbar);
