import React from 'react';
import DatePicker from 'material-ui/DatePicker';
import IntlPolyfill from 'intl';
import Reset from 'material-ui/svg-icons/action/restore';

const { _tg } = window.loadTranslations();

type SimpleDateFilterProps = {
  styleTextField: any;
  styleInput: any;
  colorResetButton: string;
  onChange: (date: Date | null | undefined) => void;
  date: Date | undefined;
};

const SimpleDateFilter = ({
  styleTextField,
  styleInput,
  colorResetButton,
  date,
  onChange,
}: SimpleDateFilterProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
      }}
    >
      <DatePicker
        name={_tg('field.date.date').toLowerCase()}
        DateTimeFormat={IntlPolyfill.DateTimeFormat}
        okLabel={_tg('action.ok')}
        cancelLabel={_tg('action.cancel')}
        locale={window.i18next.language}
        style={{ flex: 1 }}
        textFieldStyle={styleTextField}
        underlineStyle={{ borderColor: 'transparent' }}
        inputStyle={styleInput}
        onChange={(event, newDate) => onChange(newDate)}
        value={date}
      />
      <Reset
        style={{
          color: colorResetButton,
          marginLeft: 10,
          width: 30,
          height: 30,
          cursor: 'pointer',
        }}
        onClick={() => onChange(null)}
      />
    </div>
  );
};

export default SimpleDateFilter;
