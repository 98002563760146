import { PatrolZoneDTO } from '@cvfm-front/tefps-types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const usePatrolZones = (): Array<PatrolZoneDTO> | null => {
  return useWatcher(
    services.patrolZoneService.watchPatrolZones,
    services.patrolZoneService.getPatrolZones()
  );
};

export default usePatrolZones;
