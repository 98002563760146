import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { Checkbox } from 'material-ui';

import { BKG_LIGHT_BLUE } from 'theme';
import { patchRecourse } from 'api/recourse';
import { RecourseDTO, RecourseUser } from 'api/recourse/types';
import FormComponent from 'commons/FormComponent';
import { CcspRecourseDTO } from 'api/ccsp/types';
import { patchCcspRecourse } from 'api/ccsp';

import Form from './Form';

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: '#ffffff',
  fontWeight: 'bold',
};

const MODAL_TITLE = {
  user: "Modifier les informations de l'usager",
  transferUser: 'Modifier les informations du nouveau propriétaire',
  representative: 'Modifier les informations du mandataire',
};

const PATCH_PATH = {
  user: '/user',
  transferUser: '/transferUser',
  representative: '/representative',
};

// Hack to prevent editors of losing syntax color
type fnOnClickProps = (type: string | null | undefined) => void;

type ModalProps<T> = {
  type: string | null | undefined;
  onChangeEditUserType: fnOnClickProps;
  recourse: T;
  applyChanges: (recourse: T) => void;
  showMessage: (message: string) => void;
};

type ModalState = {
  user: RecourseUser;
  representative: RecourseUser | null;
};

class Modal extends React.Component<
  ModalProps<RecourseDTO | CcspRecourseDTO>,
  ModalState
> {
  userEditForm: FormComponent | null = null;
  addRepresentativeForm: FormComponent | null = null;

  constructor(props: ModalProps<RecourseDTO | CcspRecourseDTO>) {
    super(props);

    this.state = {
      user: props.recourse.user,
      representative: null,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(
    nextProps: ModalProps<RecourseDTO | CcspRecourseDTO>
  ): void {
    if (nextProps.type) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.setState({ user: nextProps.recourse[nextProps.type] });
    }
  }

  onCheckRepresentative = (
    e: React.MouseEvent<HTMLInputElement>,
    hasRepresentative: boolean
  ): void => {
    this.setState({
      representative: hasRepresentative ? ({} as RecourseUser) : null,
    });
  };

  onChangeUser = (user: RecourseUser): void => this.setState({ user });

  onChangeRepresentative = (representative: RecourseUser): void =>
    this.setState({ representative });

  onCancel = (): void => {
    const { onChangeEditUserType, type, recourse } = this.props;
    if (type) {
      this.onChangeUser(recourse[type]);
    }
    onChangeEditUserType(null);
    this.setState({
      representative: null,
    });
  };

  onSave = async (): Promise<void> => {
    const { recourse, type, applyChanges, showMessage } = this.props;
    const { representative } = this.state;
    const form = this.userEditForm;
    const representativeForm = this.addRepresentativeForm;
    if (
      form &&
      form.isValid() &&
      type &&
      (!representativeForm || representativeForm.isValid())
    ) {
      const value: any = form.getFormEntries() as any;
      const user = { ...value, ...value.address } as RecourseUser;
      const patch = [
        {
          path: PATCH_PATH[type] as string,
          op: 'replace',
          value: user,
        },
      ];
      if (representative && representativeForm) {
        const representativeValue: any = representativeForm.getFormEntries() as any;
        const representativeUser = {
          ...representativeValue,
          ...representativeValue.address,
        } as RecourseUser;
        patch.push({
          path: PATCH_PATH.representative,
          op: 'replace',
          value: representativeUser,
        });
      }

      try {
        const updatedRecourse =
          'rapoId' in recourse
            ? await patchRecourse(recourse.rapoId, patch)
            : await patchCcspRecourse(recourse.id, patch);
        applyChanges(updatedRecourse);
        this.onCancel();
        showMessage('Modifications enregistrées');
      } catch (e) {
        showMessage(
          "Une erreur s'est produite pendant la modification des informations"
        );
      }
    }
  };

  attachRef = (node: FormComponent): void => {
    this.userEditForm = node;
  };
  attachRepresentativeRef = (node: FormComponent): void => {
    this.addRepresentativeForm = node;
  };

  render(): React.ReactNode {
    const { type, recourse } = this.props;
    const { user, representative } = this.state;
    return (
      <Dialog
        title={type ? (MODAL_TITLE[type] as string) : ''}
        open={type !== null}
        titleStyle={STYLE_TITLE}
        modal
        autoScrollBodyContent
        actions={[
          <FlatButton label="Annuler" onClick={this.onCancel} />,
          <FlatButton label="Enregistrer" primary onClick={this.onSave} />,
        ]}
      >
        <FormComponent ref={this.attachRef}>
          {type && (
            <Form
              type={type}
              user={user}
              setUser={this.onChangeUser}
              createdByAgent={
                'createdByAgent' in recourse ? recourse.createdByAgent : true
              }
              mandatory={'createdByAgent' in recourse}
            />
          )}
        </FormComponent>
        <FormComponent ref={this.attachRepresentativeRef}>
          {type === 'user' &&
            'representative' in recourse &&
            !recourse.representative && (
              <Checkbox
                label="Ajouter un représentant légal ou un tiers"
                onCheck={this.onCheckRepresentative}
                style={{ marginTop: '1.7em' }}
              />
            )}
          {representative && (
            <Form
              type="representative"
              user={representative}
              setUser={this.onChangeRepresentative}
              createdByAgent={
                'createdByAgent' in recourse ? recourse.createdByAgent : true
              }
              mandatory={'createdByAgent' in recourse}
            />
          )}
        </FormComponent>
      </Dialog>
    );
  }
}

export default Modal;
