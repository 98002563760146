import services from 'commons/services';

import useWatcher from './useWatcher';

const useRecoursesError = (): Error | null | undefined => {
  return useWatcher(
    services.recourseListService.watchError,
    services.recourseListService.getError()
  );
};

export default useRecoursesError;
