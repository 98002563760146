import { FpsCaseDTO } from 'api/fps/types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useFps = (): FpsCaseDTO | null => {
  return useWatcher(services.fps.watchFps, services.fps.getFps());
};

export default useFps;
