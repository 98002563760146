import services from 'commons/services';

import useWatcher from './useWatcher';

const useProposalComment = (): [string, (arg0: string) => void] => {
  return [
    useWatcher(services.proposal.watchProposalComment, ''),
    services.proposal.setProposalComment,
  ];
};

export default useProposalComment;
