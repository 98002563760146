import React, { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { BKG_CYAN_SELECTED, TEXT_COLOR_LIGHTER } from 'theme';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import { AgentRight } from '@cvfm-front/tefps-types';

const TITLE: CSSProperties = {
  textAlign: 'center',
};

const WRAPPER: CSSProperties = {
  height: 50,
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-around',
  padding: 16,
};

const CONTAINER: CSSProperties = {
  height: 50,
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-around',
};

const CONTENT: CSSProperties = {
  border: `1px solid ${TEXT_COLOR_LIGHTER}`,
  flex: '1 1 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: -1,
  color: TEXT_COLOR_LIGHTER,
  textTransform: 'uppercase',
  fontFamily: 'Roboto',
  width: 170,
};

const LEFT_CONTENT: CSSProperties = {
  ...CONTENT,
  borderRadius: '8px 0 0 8px',
};

const RIGHT_CONTENT: CSSProperties = {
  ...CONTENT,
  borderRadius: '0 8px 8px 0',
};

const MIDDLE_CONTENT: CSSProperties = {
  ...CONTENT,
  borderRadius: 8,
};

const ACTIVE_STYLE: CSSProperties = {
  borderColor: BKG_CYAN_SELECTED,
  color: BKG_CYAN_SELECTED,
  zIndex: 1,
};

export type Nav = {
  to: string;
  label: string;
  right: string;
};

type NavigationProps = {
  style?: Record<string, any>;
  userInfo: InternalAgent;
  title: string;
  navs: Nav[];
};

function getStyle(index: number, length: number) {
  if (length === 1) {
    return MIDDLE_CONTENT;
  }

  if (index === 0) {
    return LEFT_CONTENT;
  }
  if (index === length - 1) {
    return RIGHT_CONTENT;
  }
  return CONTENT;
}

const Navigation = ({ style, userInfo, title, navs }: NavigationProps) => {
  const navsFiltered = navs.filter(link =>
    userInfo.rights.includes(link.right as AgentRight)
  );

  return (
    <div style={{ ...style, ...WRAPPER }}>
      {navsFiltered.length > 0 && (
        <span>
          <div style={TITLE}>{title}</div>
          <div style={CONTAINER}>
            {navsFiltered.map((link, index) => (
              <NavLink
                key={index}
                to={link.to}
                style={getStyle(index, navsFiltered.length)}
                activeStyle={ACTIVE_STYLE}
              >
                <span>{link.label}</span>
              </NavLink>
            ))}
          </div>
        </span>
      )}
    </div>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(Navigation);
