import React, { CSSProperties } from 'react';

const { _t, _tg, Trans } = window.loadTranslations(__filename);

const rapoTemplateFieldsCol1 = () => [
  { field: 'civilite', legend: _tg('field.human.gender') },
  {
    field: 'date_rapo',
    legend: _t('col1.date_rapo'),
  },
  {
    field: 'date_demande_complement',
    legend: _t('col1.date_demande_complement'),
  },
  { field: 'motif_rapo', legend: _t('col1.motif_rapo') },
  {
    field: 'raison_reponse',
    legend: _t('col1.raison_reponse'),
  },
  {
    field: 'prenom_nom_validateur',
    legend: _t('col1.prenom_nom_validateur'),
  },
  {
    field: 'nom_prenom_utilisateur_createur',
    legend: _t('col1.nom_prenom_utilisateur_createur'),
  },
  { field: 'matricule_validateur', legend: _t('col1.matricule_validateur') },
  {
    field: 'immatriculation',
    legend: _tg('field.vehicle.license'),
  },
  { field: 'numero_rapo', legend: _t('col1.numero_rapo') },
];

const rapoTemplateFieldsCol2 = () => [
  { field: 'numero_fps', legend: _t('col2.numero_fps') },
  {
    field: 'prix_fps',
    legend: _t('col2.prix_fps'),
  },
  { field: 'date_controle', legend: _tg('field.date.fpsDate') },
  {
    field: 'heure_controle',
    legend: _t('col2.heure_controle'),
  },
  {
    field: 'numero_adresse_controle',
    legend: _t('col2.numero_adresse_controle'),
  },
  {
    field: 'type_rue_adresse_controle',
    legend: _tg('field.address.streetType_2'),
  },
  {
    field: 'nom_rue_adresse_controle',
    legend: _tg('field.address.streetName'),
  },
  {
    field: 'lieu_dit_adresse_controle',
    legend: _t('col2.lieu_dit_adresse_controle'),
  },
  { field: 'nom_ville', legend: _t('col2.nom_ville') },
  { field: 'nom_prenom_redacteur', legend: _t('col2.nom_prenom_redacteur') },
  {
    field: 'posting_date',
    legend: _t('col2.posting_date'),
  },
];
export const RAPO_TEMPLATE_FIELDS = [
  rapoTemplateFieldsCol1(),
  rapoTemplateFieldsCol2(),
];

export const RAPO_TEMPLATE_FIELDS_LIST: Array<{
  field: string;
  legend: string | null | undefined;
}> = rapoTemplateFieldsCol1().concat(rapoTemplateFieldsCol2());

const STYLE_BLOCK: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#EEE',
  color: 'black',
  borderTop: '1px solid #666',
  borderBottom: '2px solid #666',
};

const STYLE_TEMPLATE_LIST: CSSProperties = {
  fontSize: 14,
  display: 'flex',
  justifyContent: 'space-around',
};

const STYLE_TEMPLATE_EXPLANATION: CSSProperties = {
  marginBottom: 12,
  fontSize: 15,
  marginTop: 8,
};

const STYLE_TEMPLATE_FIELD: CSSProperties = {
  fontWeight: 'bold',
};

const STYLE_EXAMPLE_BLOCK: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-around',
  borderTop: '1px dashed #999',
  margin: '8px 0px',
  paddingTop: 8,
};

const STYLE_EXAMPLE: CSSProperties = {
  borderLeft: '3px solid #555',
  fontSize: 14,
  padding: 8,
};

const STYLE_CENTER: CSSProperties = { textAlign: 'center' };

export const RapoTemplatingInfo = ({
  withNoteAboutReplacement,
}: {
  withNoteAboutReplacement?: boolean;
}): JSX.Element => (
  <div style={STYLE_BLOCK}>
    <div style={{ padding: 8 }}>
      <div style={STYLE_TEMPLATE_EXPLANATION}>
        {_t('element.explanation')}
        {withNoteAboutReplacement && (
          <div style={{ fontStyle: 'italic', marginTop: 6 }}>
            {_t('element.withNoteAboutReplacement')}
          </div>
        )}
      </div>
      <div style={STYLE_TEMPLATE_LIST}>
        {RAPO_TEMPLATE_FIELDS.map((column, id) => (
          <table key={id} style={{ width: '50%' }}>
            <tbody>
              {column.map(template => (
                <tr key={template.field}>
                  <td style={STYLE_TEMPLATE_FIELD}>{`{${template.field}}`}</td>
                  <td>{template.legend}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
    </div>
    <div style={STYLE_EXAMPLE_BLOCK}>
      <div style={{ width: '50%', marginLeft: 8 }}>
        <div style={STYLE_CENTER}>{_t('element.mailType.title')}</div>
        <div style={STYLE_EXAMPLE}>
          <Trans
            i18nKey="element.mailType.mail"
            components={[<div style={{ marginBottom: 8 }} />, <br />]}
          />
        </div>
      </div>
      <div style={{ width: '50%', marginRight: 8 }}>
        <div style={STYLE_CENTER}>{_t('element.result.title')}</div>
        <div style={STYLE_EXAMPLE}>
          <Trans
            i18nKey="element.result.mail"
            components={[<div style={{ marginBottom: 8 }} />, <br />]}
          />
        </div>
      </div>
    </div>
  </div>
);
