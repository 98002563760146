import React, { CSSProperties } from 'react';

import { BKG_PINK, TXT_BLACK } from 'theme';

const STYLE_TITLE: CSSProperties = {
  color: TXT_BLACK,
  fontFamily: 'Roboto',
  fontSize: 14,
  marginBottom: 10,
  textAlign: 'center',
  marginTop: 30,
};

const STYLE_SECTION_HEADER: CSSProperties = {
  display: 'flex',
  margin: '10px 0',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
};

const STYLE_SECTION_TITLE: CSSProperties = {
  ...STYLE_TITLE,
  fontSize: 24,
  marginBottom: 0,
  marginTop: 0,
  margin: '0 20px',
};

const STYLE_BORDER: CSSProperties = {
  height: 3,
  backgroundColor: BKG_PINK,
  flex: 1,
};

const SectionTitle = ({
  title,
  styleLeft,
}: {
  title: string | React.ReactElement<any>;
  styleLeft?: any;
}) => (
  <div style={STYLE_SECTION_HEADER}>
    <div style={{ ...STYLE_BORDER, ...styleLeft }}>&nbsp;</div>
    <div style={STYLE_SECTION_TITLE}>{title}</div>
    <div style={STYLE_BORDER}>&nbsp;</div>
  </div>
);

export default SectionTitle;
