import React, { CSSProperties } from 'react';
import { AutoSizer } from 'react-virtualized';
import { Scrollbars } from 'react-custom-scrollbars';

import { BKG_BLUE, TXT_GREY } from 'theme';

const STYLE_SIDEBAR: CSSProperties = {
  width: 365,
  height: '100%',
  backgroundColor: BKG_BLUE,
  justifyContent: 'center',
  fontFamily: 'Roboto, sans-serif',
  color: TXT_GREY,
};

const STYLE_SIDEBAR_CONTENT: CSSProperties = {
  padding: '80px 30px 20px',
  overflowX: 'hidden',
};

type Props = {
  children?: any;
};

const Sidebar = ({ children }: Props) => (
  <div style={STYLE_SIDEBAR} className="sidebarV2">
    <AutoSizer disableWidth>
      {({ height, width }) => (
        <Scrollbars style={{ width, height }}>
          <div style={STYLE_SIDEBAR_CONTENT}>{children}</div>
        </Scrollbars>
      )}
    </AutoSizer>
  </div>
);

export default Sidebar;
