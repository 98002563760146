import { WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import { searchParkingRightsOrDryRunControl } from 'api/tickets';
import { ParkingRightSearchResultDTO } from 'api/tickets/types';

export interface ParkingRightsServiceFactory {
  (): ParkingRightsServiceInterface;
}

export interface ParkingRightsServiceInterface {
  init: (plate: string, statementDatetime: string) => Promise<void>;
  getParkingRights: () => ParkingRightSearchResultDTO | null;
  watchParkingRights: WatchFunctionType<ParkingRightSearchResultDTO | null>;
}

const ParkingRightsService: ParkingRightsServiceFactory = () => {
  const {
    getValue: getParkingRights,
    setValue: setParkingRights,
    watchValue: watchParkingRights,
  } = Watcher<ParkingRightSearchResultDTO | null>(null);

  const init = async (
    plate: string,
    statementDatetime: string
  ): Promise<void> => {
    const datetime = new Date(statementDatetime);
    datetime.setDate(datetime.getDate() - 7);
    const fromEndDatetime = datetime.toISOString();
    datetime.setDate(datetime.getDate() + 14);
    const toStartDatetime = datetime.toISOString();
    const filterParam = {
      plate,
      toStartDatetime,
      fromEndDatetime,
    };
    try {
      const parkingRights = await searchParkingRightsOrDryRunControl(
        filterParam
      );
      setParkingRights(parkingRights);
    } catch (e) {
      // NO OP
    }
  };

  return {
    init,
    getParkingRights,
    watchParkingRights,
  };
};

export default ParkingRightsService;
