import {
  Getter,
  PatchObject,
  Setter,
  WatchFunctionType,
} from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import {
  Address,
  EsCityParkingSpaceDTO,
  FnmsLocation,
  PatrolZoneDTO,
} from '@cvfm-front/tefps-types';
import { Coordinates } from 'api/commonTypes';
import { fetchControl, patchControl } from 'api/control';
import { ControlDTO } from 'api/control/types';
import { apiGet, apiPatch } from 'api/helpers';
import { LapiReviewDTO } from 'api/lapiReview/types';
import { Marker, Position } from 'commons/ClusterMap/types';
import ControlMapService from 'tefps/LapiReview/Geolocalisation/components/ControlMapService';

export interface LapiReviewServiceFactory {
  (): LapiReviewServiceInterface;
}

export interface LapiReviewServiceInterface {
  setCurrentLapiReview: Setter<LapiReviewDTO>;
  getCurrentLapiReview: Getter<LapiReviewDTO>;
  watchCurrentLapiReview: WatchFunctionType<LapiReviewDTO>;
  setCurrentControlDTO: Setter<ControlDTO>;
  getCurrentControlDTO: Getter<ControlDTO>;
  watchCurrentControlDTO: WatchFunctionType<ControlDTO>;
  patchLocationAddressZone: (
    location: FnmsLocation,
    address: Address,
    zoneId: string,
    parkingSpace: EsCityParkingSpaceDTO | null
  ) => Promise<LapiReviewDTO>;
  computeMarkersAndCenter: (
    newAddressCoordinate?: Coordinates | null
  ) => [Marker[], Position];
  registerNewPatrolZoneControl: (
    apiReview: LapiReviewDTO,
    newPatrolZone: string | null
  ) => Promise<void>;
  findPatrolZoneWithLatLon: (
    lat: number,
    lon: number
  ) => Promise<PatrolZoneDTO>;
}

const LapiReviewService: LapiReviewServiceFactory = () => {
  const {
    watchValue: watchCurrentLapiReview,
    setValue: setCurrentLapiReview,
    getValue: getCurrentLapiReview,
  } = Watcher<LapiReviewDTO>({} as LapiReviewDTO);
  const {
    watchValue: watchCurrentControlDTO,
    setValue: setCurrentControlDTO,
    getValue: getCurrentControlDTO,
  } = Watcher<ControlDTO>({} as ControlDTO);

  const setCurrentLapiReviewAndControlDTO = (newValue: LapiReviewDTO) => {
    setCurrentLapiReview(newValue);
    void fetchControl(newValue.controlId).then(controlDTO => {
      // in case we are on a new control for some reason
      if (getCurrentLapiReview().controlId === controlDTO.controlId) {
        setCurrentControlDTO(controlDTO);
      }
    });
  };

  async function patch(patchList: PatchObject<unknown>[]) {
    const lapiReview = getCurrentLapiReview();
    const newLapiReview = await apiPatch<LapiReviewDTO>(
      `/api/proxy/lapi-review/api/cities/{cityId}/lapi-reviews/${lapiReview.controlId}`,
      patchList
    );
    setCurrentLapiReviewAndControlDTO(newLapiReview);
    return newLapiReview;
  }

  async function registerNewPatrolZoneControl(
    lapiReview: LapiReviewDTO,
    newPatrolZone: string | null
  ): Promise<void> {
    await patchControl(lapiReview.controlId, [
      {
        op: 'add',
        path: '/patrolZoneId',
        value: newPatrolZone ?? '',
      },
    ]);
  }

  return {
    setCurrentLapiReview: setCurrentLapiReviewAndControlDTO,
    watchCurrentLapiReview,
    getCurrentLapiReview,
    setCurrentControlDTO,
    watchCurrentControlDTO,
    getCurrentControlDTO,
    patchLocationAddressZone: async (
      location,
      address,
      zoneId,
      parkingSpace
    ) => {
      const patches: PatchObject<unknown>[] = [];
      patches.push({
        op: 'add',
        path: '/addressLocation',
        value: location,
      });

      patches.push({
        op: 'add',
        path: '/statementAddress',
        value: address,
      });

      patches.push({
        op: 'add',
        path: '/zoneId',
        value: zoneId,
      });

      if (parkingSpace != null) {
        patches.push({
          op: 'add',
          path: '/parkingSpace',
          value: parkingSpace,
        });
      }

      return patch(patches);
    },
    computeMarkersAndCenter: (newAddressCoordinate?: Coordinates) => {
      const lapiReview = getCurrentLapiReview();
      const addressCoordinate = {
        latitude: lapiReview.addressLatitude,
        longitude: lapiReview.addressLongitude,
      };
      const controlCoordinate = {
        latitude: lapiReview.controlLatitude,
        longitude: lapiReview.controlLongitude,
      };
      const markers = ControlMapService.computeReviewMarkers(
        addressCoordinate,
        controlCoordinate,
        newAddressCoordinate
      );

      const coords = [addressCoordinate, controlCoordinate];
      if (newAddressCoordinate) {
        coords.push(newAddressCoordinate);
      }

      const center = ControlMapService.computeCenterCoordinates(coords);
      return [markers, center];
    },
    registerNewPatrolZoneControl,
    findPatrolZoneWithLatLon: (lat, lon) => {
      return apiGet<PatrolZoneDTO>(
        `/api/proxy/lapi-review/api/cities/{cityId}/lapi-reviews/findPatrolZone?lat=${lat}&lon=${lon}`
      );
    },
  };
};

export default LapiReviewService;
