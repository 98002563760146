import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { RecourseServiceInterface } from 'commons/services/RecourseService';
import {
  CityRecourseReason,
  RecourseProposalWrapperDTO,
  RecourseUpdateStatus,
} from 'api/recourse/types';

export interface ProposalServiceFactory {
  (recourseService: RecourseServiceInterface): ProposalServiceInterface;
}

export interface ProposalServiceInterface {
  init: () => void;
  getCurrentProposal: () => RecourseProposalWrapperDTO | null;
  setCurrentProposal: (
    currentProposal: RecourseProposalWrapperDTO | null
  ) => void;
  watchCurrentProposal: WatchFunctionType<RecourseProposalWrapperDTO | null>;
  getDecision: () => RecourseUpdateStatus | null;
  setDecision: (decision: RecourseUpdateStatus | null) => void;
  watchDecision: WatchFunctionType<RecourseUpdateStatus | null>;
  getReason: () => CityRecourseReason | null;
  setReason: (reason: CityRecourseReason | null) => void;
  watchReason: WatchFunctionType<CityRecourseReason | null>;
  getProposalComment: () => string;
  setProposalComment: (proposalComment: string) => void;
  watchProposalComment: WatchFunctionType<string>;
}

const ProposalService: ProposalServiceFactory = recourseService => {
  const {
    getValue: getCurrentProposal,
    setValue: setCurrentProposal,
    watchValue: watchCurrentProposal,
  } = Watcher<RecourseProposalWrapperDTO | null>(null);

  const {
    getValue: getDecision,
    setValue: setDecision,
    watchValue: watchDecision,
  } = Watcher<RecourseUpdateStatus | null>(null);

  const {
    getValue: getReason,
    setValue: setReason,
    watchValue: watchReason,
  } = Watcher<CityRecourseReason | null>(null);

  const {
    getValue: getProposalComment,
    setValue: setProposalComment,
    watchValue: watchProposalComment,
  } = Watcher<string>('');

  const init = (): void => {
    const recourse = recourseService.getRecourse();

    // Get last proposal for recourse
    const currentProposal =
      recourse?.recourse.proposals[recourse.recourse.proposals.length - 1];
    // Check if current proposal has not already been rejected and is not on hold
    if (
      currentProposal &&
      (!currentProposal.decision || currentProposal.decision.accepted) &&
      currentProposal.proposal.status !== 'IS_WAITING'
    ) {
      setCurrentProposal(currentProposal);
      setDecision(currentProposal.proposal.status);
      setReason(currentProposal.proposal.cityReason);
      setProposalComment(currentProposal.proposal.agentComment ?? '');
    } else {
      setCurrentProposal(null);
      setDecision(null);
      setReason(null);
      setProposalComment('');
    }
  };

  return {
    init,
    getCurrentProposal,
    setCurrentProposal,
    watchCurrentProposal,
    getDecision,
    setDecision,
    watchDecision,
    getReason,
    setReason,
    watchReason,
    getProposalComment,
    setProposalComment,
    watchProposalComment,
  };
};

export default ProposalService;
