import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { fetchRapoConfiguration } from 'api/rapoConfiguration/index';
import { RapoConfigurationDTO } from '@cvfm-front/tefps-types';

export interface RecourseConfigurationServiceFactory {
  (): RecourseConfigurationServiceInterface;
}

export interface RecourseConfigurationServiceInterface {
  init: () => Promise<void>;
  getRecourseConfiguration: () => RapoConfigurationDTO | null;
  watchRecourseConfiguration: WatchFunctionType<RapoConfigurationDTO | null>;
}

const RecourseConfigurationService: RecourseConfigurationServiceFactory = () => {
  const {
    getValue: getRecourseConfiguration,
    setValue: setRecourseConfiguration,
    watchValue: watchRecourseConfiguration,
  } = Watcher<null | RapoConfigurationDTO>(null);

  const init = async (): Promise<void> => {
    const recourseConfiguration = await fetchRapoConfiguration();
    setRecourseConfiguration(recourseConfiguration);
  };

  return {
    init,
    getRecourseConfiguration,
    watchRecourseConfiguration,
  };
};

export default RecourseConfigurationService;
