import { WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import { OrderEsSearchResultDTO, ProductType } from '@cvfm-front/tefps-types';
import { searchEsOrderScroll } from 'api/cvfm-core-subscription/order';
import { filtersToScrollRequest } from 'tefps/Subscription/helpers';

export interface EligibilityServiceFactory {
  (): EligibilityServiceInterface;
}

export interface EligibilityServiceInterface {
  init: (plate: string) => Promise<void>;
  getEligibilities: () => OrderEsSearchResultDTO | null;
  watchEligibilities: WatchFunctionType<OrderEsSearchResultDTO | null>;
  applyEligibilitiesChanges: (eligibility: OrderEsSearchResultDTO) => void;
}

const EligibilityService: EligibilityServiceFactory = () => {
  const {
    getValue: getEligibilities,
    setValue: setEligibilities,
    watchValue: watchEligibilities,
  } = Watcher<OrderEsSearchResultDTO | null>(null);

  const init = async (plate: string): Promise<void> => {
    const filters = {
      plates: [plate],
      productTypes: [ProductType.ELIGIBILITY],
    };
    const filterParam = filtersToScrollRequest(filters);
    const eligibilities = await searchEsOrderScroll(filterParam);
    setEligibilities(eligibilities);
  };

  const applyEligibilitiesChanges = (
    eligibilities: OrderEsSearchResultDTO
  ): void => {
    setEligibilities(eligibilities);
  };

  return {
    init,
    getEligibilities,
    watchEligibilities,
    applyEligibilitiesChanges,
  };
};

export default EligibilityService;
