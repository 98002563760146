import React, { CSSProperties } from 'react';

import TextFieldCustom from 'commons/FormComponent/Fields/TextFieldCustom';
import SelectFieldCustom from 'commons/FormComponent/Fields/SelectFieldCustom';
import { validateEmail } from 'commons/Validators';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { Gender, RecourseUser } from 'api/recourse/types';
import { BKG_DARK_BLUE, BKG_PINK } from 'theme';
import AddressAutocomplete from 'commons/AddressAutocomplete';
import { Address } from '@cvfm-front/tefps-types';
import { fieldKeyRecourseUser } from 'commons/types/fieldKeyRecourseUser';

const STYLE_FIELD: CSSProperties = {
  marginLeft: 20,
  flexGrow: 1,
};

const STYLE_BLOCK: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
};

export const STYLE_ADDRESS_TITLE: CSSProperties = {
  height: '100%',
  width: '100%',
  margin: '40px 0px 30px 0px',
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_DARK_BLUE,
  color: BKG_DARK_BLUE,
};

const MALE = { id: 'MALE', name: 'Monsieur' };
const FEMALE = { id: 'FEMALE', name: 'Madame' };
const NOT_GIVEN = { id: 'NOT_GIVEN', name: 'Madame, Monsieur' };
const ORGA = { id: 'ORGA', name: 'Personne morale' };

const USER_GENDERS = [MALE, FEMALE, NOT_GIVEN, ORGA];
const TRANSFER_USER_GENDERS = [MALE, FEMALE, ORGA];

type FormProps = {
  type: string;
  user: RecourseUser;
  setUser: (user: RecourseUser) => void;
  createdByAgent: boolean;
  mandatory: boolean;
};

// Handle the gender change in form state to display (without getting FormComponent values) some fields depending on gender
// The onChange props of FormComponent Fields is called in the onValueChange of attachToForm to get the value outside FormComponent
type FormState = {
  gender: Gender;
  isIllegibleAddress: boolean;
  isForeignAddress: boolean;
};

class Form extends React.Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props);
    this.state = {
      gender: props.user.gender,
      isIllegibleAddress: props.user.illegibleAddress,
      isForeignAddress:
        !!props.user.addressCountry && props.user.addressCountry !== 'FR',
    };
  }

  onChangeGender = (gender: Gender): void => this.setState({ gender });

  onChangeForeignAddress = (isForeignAddress: boolean): void =>
    this.setState({ isForeignAddress });

  onChangeIllegibleAddress = (isIllegibleAddress: boolean): void =>
    this.setState({ isIllegibleAddress });

  onAutocomplete = (newAddress: Address): void => {
    const { user, setUser } = this.props;
    const newUser = {
      ...user,
      addressStreetType: newAddress.streetType,
      addressStreetName: newAddress.streetName,
      addressStreetNumber: newAddress.streetNumber,
      addressStreetNumberBis: newAddress.streetNumberBis ?? '',
      addressPostalCode: newAddress.postalCode,
      addressComplement: newAddress.specialPlace ?? '',
      addressLocality: newAddress.addressLocality,
      addressCountry: newAddress.addressCountry,
    };
    setUser(newUser);
  };

  render(): React.ReactNode {
    const { type, user, createdByAgent, mandatory } = this.props;
    const { gender, isForeignAddress, isIllegibleAddress } = this.state;

    const required = mandatory && !isIllegibleAddress;
    const validate = !isForeignAddress && !isIllegibleAddress;

    return (
      <div>
        <div style={STYLE_BLOCK}>
          <SelectFieldCustom
            name="gender"
            style={STYLE_FIELD}
            hint="Civilité"
            items={
              type === 'transferUser' ? TRANSFER_USER_GENDERS : USER_GENDERS
            }
            defaultValue={user.gender}
            mandatory={required}
            onChange={this.onChangeGender}
          />
        </div>
        <div style={STYLE_BLOCK}>
          <TextFieldCustom
            name="lastName"
            hint="Nom"
            style={STYLE_FIELD}
            mandatory={required}
            value={user.lastName}
          />
          {gender !== 'ORGA' && (
            <TextFieldCustom
              name="firstName"
              hint="Prénom"
              style={STYLE_FIELD}
              mandatory={required}
              value={user.firstName}
            />
          )}
        </div>
        <div style={STYLE_BLOCK}>
          <TextFieldCustom
            name="email"
            hint="Email"
            value={user.email}
            style={STYLE_FIELD}
            validators={validate ? [validateEmail] : []}
            mandatory={type !== 'transferUser' && !createdByAgent && required}
          />
          <TextFieldCustom
            name="phoneNumber"
            hint="Téléphone"
            value={user.phoneNumber}
            style={STYLE_FIELD}
          />
        </div>
        <SeparatorWithTitle
          style={STYLE_ADDRESS_TITLE}
          title="Adresse"
          color={BKG_PINK}
          titleSize={20}
        />
        <AddressAutocomplete
          field="address"
          fieldKeys={fieldKeyRecourseUser}
          mandatoryAddress={false}
          illegibleOption={isIllegibleAddress}
          isForeignAddressAllow={false}
          styleInputs={STYLE_INPUTS}
          address={{
            streetNumber: user.addressStreetNumber,
            streetNumberBis: user.addressStreetNumberBis,
            streetType: user.addressStreetType,
            streetName: user.addressStreetName,
            postalCode: user.addressPostalCode,
            specialPlace: user.addressComplement,
            addressLocality: user.addressLocality,
            addressSubRegion: '',
            addressRegion: '',
            addressCountry: user.addressCountry,
          }}
          onAutoCompleteCallback={this.onAutocomplete}
        />
      </div>
    );
  }
}

export default Form;
