import { LapiReviewDTO } from 'api/lapiReview/types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useCurrentLapiReview = (): LapiReviewDTO => {
  return useWatcher(
    services.lapiReview.watchCurrentLapiReview,
    services.lapiReview.getCurrentLapiReview()
  );
};

export default useCurrentLapiReview;
