import React, { CSSProperties } from 'react';

import Sort from './Sort';

type Props = {
  cols: Array<{
    label?: string;
    width: number;
    grow?: number;
    style?: Record<string, any>;
    headerStyle?: Record<string, any>;
    onSort?: boolean;
    sortId?: string;
  }>;
  index: number;
  cells: Array<any>;
  value?: any;
  onRowClick?: (value: any) => void | null | undefined;
  style?: Record<string, any>;
  hoverable: boolean;
  header?: boolean;
  onSort?: Function;
  colSorted?: number;
  sortOrder?: boolean;
};

type State = {
  hover: boolean;
};

const ROW_STYLE: CSSProperties = {
  display: 'flex',
  flex: 1,
  flexFlow: 'row nowrap',
  justifyContent: 'space-evenly',
};

const CELL_STYLE: CSSProperties = {
  padding: 3,
  alignSelf: 'center',
  overflow: 'hidden',
  flexDirection: 'row',
  alignItems: 'center',
};

const STYLE_BUTTON_SORT: CSSProperties = {
  cursor: 'pointer',
  height: 25,
  width: 25,
};

const DEFAULT_COL_DEFINITION = {
  width: 100,
  grow: 0,
  style: {},
  headerStyle: {},
};

const computeBackgroundColor = (index: number, hover: boolean) => {
  if (hover) return 'rgba(150, 150, 150, 0.5)';
  return index % 2 ? 'rgba(150, 150, 150, 0.14902)' : '#FFF';
};

function getStyle(header?: boolean, col?: any) {
  const colDefinition = col || DEFAULT_COL_DEFINITION;
  const flex = `${colDefinition.grow || 1} ${colDefinition.shrink || 1} ${
    colDefinition.width
  }px`;
  const cellStyle = { ...CELL_STYLE, flex, ...colDefinition.style };
  if (header) {
    return {
      ...cellStyle,
      display: 'flex',
      ...colDefinition.headerStyle,
    };
  }
  return {
    ...cellStyle,
    justifyContent: 'center',
  };
}

const computeCursor = (
  fn: ((value: any) => void | null | undefined) | undefined
) => {
  if (fn) {
    return 'pointer';
  }
  return undefined;
};

class SimpleTableRow extends React.Component<Props, State> {
  state: State = {
    hover: false,
  };

  onMouseEnter = () => this.setState({ hover: true });
  onMouseLeave = () => this.setState({ hover: false });
  onRowClick = () => {
    const { onRowClick, value } = this.props;
    if (onRowClick && value) {
      onRowClick(value);
    }
  };

  render() {
    const {
      cols,
      index,
      hoverable,
      style,
      cells,
      header,
      onSort,
      colSorted,
      sortOrder,
      onRowClick,
    } = this.props;
    const { hover } = this.state;
    return (
      <div
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onRowClick}
        style={{
          ...ROW_STYLE,
          ...style,
          cursor: computeCursor(onRowClick),
          backgroundColor: computeBackgroundColor(index, hover && hoverable),
        }}
      >
        {cells.map((cell, col) => {
          const styleCell = getStyle(header, cols[col]);
          return (
            <div key={col} style={styleCell}>
              <span>{cell}</span>
              {header && cols[col].onSort && (
                <Sort
                  style={STYLE_BUTTON_SORT}
                  colNumber={col}
                  onSort={onSort}
                  sorted={colSorted === col}
                  sortOrder={colSorted === col ? sortOrder : null}
                  sortId={cols[col].sortId}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default SimpleTableRow;
