import { EsCityParkingSpaceDTO } from '@cvfm-front/tefps-types';

import { ParkingSpaceServiceApiInterface } from './ParkingSpaceApiService';

export interface ParkingSpaceDeletionServiceFactory {
  (
    parkingSpaceApi: ParkingSpaceServiceApiInterface
  ): ParkingSpaceDeletionServiceInterface;
}

export interface ParkingSpaceDeletionServiceInterface {
  deleteParkingSpace: (
    parkingSpaceId: EsCityParkingSpaceDTO['parkingSpaceId']
  ) => Promise<void>;
}

const ParkingSpaceDeletionService: ParkingSpaceDeletionServiceFactory = parkingSpaceApi => {
  const deleteParkingSpace = async (
    parkingSpaceId: EsCityParkingSpaceDTO['parkingSpaceId']
  ) => {
    await parkingSpaceApi.deleteOneParkingSpace(parkingSpaceId);
  };

  return {
    deleteParkingSpace,
  };
};

export default ParkingSpaceDeletionService;
