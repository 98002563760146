import _debounce from 'lodash.debounce';

import { Watcher } from '@cvfm-front/commons-utils';
import { Setter, WatchFunctionType } from '@cvfm-front/commons-types';
import { fetchControlFilteredList } from 'api/control';
import { ControlSearchResultDTO } from 'api/control/types';
import { SortParameters } from 'api/commonTypes';
import { ControlSearchCriterias } from 'tefps/Control/List/types';
import { filtersToRequest } from 'tefps/Control/List/utils';
import { initialFilters } from 'tefps/Control/List/duck';
import { ControlFiltersParam, Pager } from '@cvfm-front/tefps-types';

export interface ControlListServiceFactory {
  (): ControlListServiceInterface;
}

export interface ControlListServiceInterface {
  init: () => Promise<void>;

  getControls: (startIndex?: number) => ControlSearchResultDTO | null;
  watchControls: WatchFunctionType<ControlSearchResultDTO | null>;

  loadMoreRows: () => Promise<void>;

  updateSortParameter: (sortField: number) => Promise<void>;
  getSortParameter: () => SortParameters;
  watchSortParameter: WatchFunctionType<SortParameters>;

  getFormattedFilters: () => ControlFiltersParam;

  getFilters: () => ControlSearchCriterias;
  setFilters: Setter<ControlSearchCriterias>;
  watchFilters: WatchFunctionType<ControlSearchCriterias>;

  updateFilter: (filters: ControlSearchCriterias) => Promise<void>;

  isLoading: () => boolean;
  watchLoading: WatchFunctionType<boolean>;
}

const MAX_RECORDS = 20;
const INITIAL_PAGER = 0;
const DEFAULT_SORT = false;

const ControlListService: ControlListServiceFactory = () => {
  const {
    getValue: getControls,
    setValue: setControls,
    watchValue: watchControls,
  } = Watcher<ControlSearchResultDTO | null>(null);

  const {
    getValue: getFilters,
    setValue: setFilters,
    watchValue: watchFilters,
  } = Watcher<ControlSearchCriterias>(initialFilters());

  const {
    getValue: getSortParameter,
    setValue: setSortParameter,
    watchValue: watchSortParameter,
  } = Watcher<SortParameters>({
    sortField: 1,
    increasingOrder: DEFAULT_SORT,
  });

  const {
    getValue: isLoading,
    setValue: setLoading,
    watchValue: watchLoading,
  } = Watcher<boolean>(false);

  let currentPage = INITIAL_PAGER;
  let pager: Pager = {
    page: currentPage,
    maxRecords: MAX_RECORDS,
  };

  const fetchControls = async () => {
    setLoading(true);
    const controls = await fetchControlFilteredList(
      filtersToRequest(getFilters(), getSortParameter(), pager)
    );
    setControls(controls);
    setLoading(false);
  };

  // Debounce fonction pour les changements de filtres
  const debounceFetchControl = _debounce(fetchControls, 1000);

  const updateFilter = async (
    filters: ControlSearchCriterias
  ): Promise<void> => {
    setFilters(filters);
    currentPage = INITIAL_PAGER;
    pager = { ...pager, page: currentPage };
    await debounceFetchControl();
  };

  const getFormattedFilters = (): ControlFiltersParam => {
    return filtersToRequest(getFilters(), getSortParameter(), pager);
  };

  const loadMoreRows = async (): Promise<void> => {
    const previousControls = getControls();
    if (
      !!previousControls &&
      currentPage + 1 > previousControls.totalHits / MAX_RECORDS
    ) {
      return;
    }
    setLoading(true);
    currentPage += 1;
    pager = { ...pager, page: currentPage };
    const newControls = await fetchControlFilteredList(
      filtersToRequest(getFilters(), getSortParameter(), pager)
    );
    if (previousControls && previousControls.controls && newControls.controls) {
      const newControlsResult = previousControls.controls.concat(
        newControls.controls
      );
      setControls({
        ...previousControls,
        controls: newControlsResult,
      });
    } else {
      setControls(newControls);
    }
    setLoading(false);
  };

  const updateSortParameter = async (sortField: number): Promise<void> => {
    setSortParameter({
      sortField,
      increasingOrder:
        // si le tri est sur le même champ, on inverse, sinon on inverse le nouveau
        sortField === getSortParameter().sortField
          ? !getSortParameter().increasingOrder
          : !DEFAULT_SORT,
    });
    currentPage = INITIAL_PAGER;
    pager.page = INITIAL_PAGER;
    await debounceFetchControl();
  };

  const init = async (): Promise<void> => {
    currentPage = INITIAL_PAGER;
    pager.page = INITIAL_PAGER;

    await fetchControls();
  };

  return {
    init,
    getControls,
    watchControls,
    loadMoreRows,
    updateSortParameter,
    getSortParameter,
    watchSortParameter,
    getFormattedFilters,
    getFilters,
    setFilters,
    watchFilters,
    updateFilter,
    isLoading,
    watchLoading,
  };
};

export default ControlListService;
