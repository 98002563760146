import { EsCityParkingSpaceDTO } from '@cvfm-front/tefps-types';

export interface ParkingSpaceCreationServiceFactory {
  (): ParkingSpaceCreationServiceInterface;
}

export interface ParkingSpaceCreationServiceInterface {
  addParkingSpace: (
    parkingSpace: EsCityParkingSpaceDTO,
    isDuplicated?: boolean
  ) => void;
}

const ParkingSpaceCreationService: ParkingSpaceCreationServiceFactory = () => {
  return {
    addParkingSpace: (
      parkingSpace: EsCityParkingSpaceDTO,
      isDuplicated?: boolean
    ) => {
      if (!isDuplicated) {
        const { latitude, longitude } = parkingSpace.latLong;
        parkingSpace.geometry.coordinates.push([
          [longitude - 0.0001, latitude - 0.0001],
          [longitude + 0.0001, latitude - 0.0001],
          [longitude + 0.0001, latitude + 0.0001],
          [longitude - 0.0001, latitude + 0.0001],
        ]);
      }
    },
  };
};

export default ParkingSpaceCreationService;
