import React, { CSSProperties } from 'react';
import Chip from 'material-ui/Chip';

type Props = {
  tag: string;
  onDelete?: (arg0: string) => any;
  backgroundColor?: string;
  labelColor?: string;
  onClick?: (arg0: string) => void;
  disabled?: boolean;
};

const STYLE_TAG_DISABLED: CSSProperties = {
  pointerEvents: 'auto',
  cursor: 'not-allowed',
};

class Tag extends React.Component<Props> {
  handleClick = () => {
    const { onClick, tag } = this.props;
    if (onClick) {
      onClick(tag);
    }
  };

  handleDelete = () => {
    const { onDelete, tag } = this.props;
    if (onDelete) {
      onDelete(tag);
    }
  };

  render() {
    const { tag, onDelete, backgroundColor, labelColor, disabled } = this.props;

    return (
      <Chip
        key={tag}
        style={{ margin: 5 } && disabled ? STYLE_TAG_DISABLED : {}}
        onRequestDelete={onDelete && !disabled ? this.handleDelete : undefined}
        labelColor={labelColor}
        backgroundColor={backgroundColor}
        onClick={disabled ? undefined : this.handleClick}
      >
        {tag}
      </Chip>
    );
  }
}

export default Tag;
