import services from 'commons/services';

import useWatcher from './useWatcher';

const useNewReducedPrice = (): [
  number | null,
  (arg0: number | null) => void
] => {
  return [
    useWatcher(services.decisionPrice.watchNewReducedPrice, null),
    services.decisionPrice.setNewReducedPrice,
  ];
};

export default useNewReducedPrice;
