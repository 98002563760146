import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { fetchRapoChoiceReasonListFormatted } from 'api/rapo-choice-reason';
import { RapoChoiceReasonList } from 'api/rapo-choice-reason/types';
import { RecourseServiceInterface } from 'commons/services/RecourseService';

export interface RecourseReasonsServiceFactory {
  (recourseService: RecourseServiceInterface): RecourseReasonsServiceInterface;
}

export interface RecourseReasonsServiceInterface {
  init: () => Promise<void>;
  getRecourseReasons: () => RapoChoiceReasonList | null;
  watchRecourseReasons: WatchFunctionType<RapoChoiceReasonList | null>;
}

const RecourseReasonsService: RecourseReasonsServiceFactory = recourseService => {
  const {
    getValue: getRecourseReasons,
    setValue: setRecourseReasons,
    watchValue: watchRecourseReasons,
  } = Watcher<RapoChoiceReasonList | null>(null);

  const init = async (): Promise<void> => {
    const recourse = recourseService.getRecourse();
    if (recourse) {
      const recourseChoiceReasons = await fetchRapoChoiceReasonListFormatted(
        recourse.recourse.rapoId,
        recourse.fps.fineLegalId
      );
      setRecourseReasons(recourseChoiceReasons);
    }
  };

  return {
    init,
    getRecourseReasons,
    watchRecourseReasons,
  };
};

export default RecourseReasonsService;
