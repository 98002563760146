import _debounce from 'lodash.debounce';

import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { CcspSearchResultDTO } from 'api/ccsp/types';
import { Pager } from '@cvfm-front/tefps-types';
import { fetchCcspRecourseFilteredList } from 'api/ccsp';
import {
  filtersToRequest,
  initialFilters,
} from 'tefps/CcspRecourses/List/utils';
import { CcspSearchCriterias } from 'tefps/CcspRecourses/List/types';
import { SortParameters } from 'api/commonTypes';

export interface CcspListServiceFactory {
  (): CcspListServiceInterface;
}

export interface CcspListServiceInterface {
  init: () => Promise<void>;

  isLoading: () => boolean;
  watchLoading: WatchFunctionType<boolean>;

  getCcspRecourses: () => CcspSearchResultDTO | null;
  watchCcspRecourses: WatchFunctionType<CcspSearchResultDTO | null>;

  getError: () => Error | null;
  watchError: WatchFunctionType<Error | null>;

  getSortParameter: () => SortParameters;
  watchSortParameter: WatchFunctionType<SortParameters>;
  updateSortParameter: (sortField: number) => Promise<void>;

  getFilters: () => CcspSearchCriterias;
  watchFilters: WatchFunctionType<CcspSearchCriterias>;
  updateFilters: (filters: CcspSearchCriterias) => Promise<void>;

  loadMoreRows: () => Promise<void>;
}

const MAX_RECORDS = 20;
const INITIAL_PAGE = 0;
const DEFAULT_SORT = false;
export const DEFAULT_PAGER: Pager = {
  page: INITIAL_PAGE,
  maxRecords: MAX_RECORDS,
};

const CcspListService: CcspListServiceFactory = () => {
  const {
    getValue: isLoading,
    setValue: setLoading,
    watchValue: watchLoading,
  } = Watcher<boolean>(false);

  const {
    getValue: getCcspRecourses,
    setValue: setCcspRecourses,
    watchValue: watchCcspRecourses,
  } = Watcher<CcspSearchResultDTO | null>(null);

  const {
    getValue: getFilters,
    setValue: setFilters,
    watchValue: watchFilters,
  } = Watcher<CcspSearchCriterias>(initialFilters());

  const {
    getValue: getError,
    setValue: setError,
    watchValue: watchError,
  } = Watcher<Error | null>(null);

  const {
    getValue: getSortParameter,
    setValue: setSortParameter,
    watchValue: watchSortParameter,
  } = Watcher<SortParameters>({
    sortField: 2,
    increasingOrder: DEFAULT_SORT,
  });

  let currentPage = INITIAL_PAGE;
  let pager: Pager = {
    page: currentPage,
    maxRecords: MAX_RECORDS,
  };

  /**
   * Charge une page de recours
   */
  const fetchCcspRecourseList = async () => {
    setError(null);
    setLoading(true);

    try {
      const res = await fetchCcspRecourseFilteredList(
        filtersToRequest(getFilters(), pager, getSortParameter())
      );
      setCcspRecourses(res);
      setError(null);
    } catch (err) {
      setError(err);
      setCcspRecourses(null);
    }
    setLoading(false);
  };

  const debounceLoad = _debounce(fetchCcspRecourseList, 1000);

  /**
   * Charge la prochaine page de recours
   */
  const loadMoreRows = async () => {
    // Early return because of concurency issue with the method componentWillReceiveProps
    // loadMoreRows can be called even if the initial fetch is not done
    const currentRecourses = getCcspRecourses();
    if (!currentRecourses) {
      return;
    }

    // Check si nous devons envoyer la requête ou si nous sommes au bout
    if (
      !!currentRecourses &&
      currentPage + 1 > currentRecourses.totalHits / MAX_RECORDS
    ) {
      return;
    }

    setLoading(true);
    currentPage += 1;
    pager = { ...pager, page: currentPage };
    try {
      const res = await fetchCcspRecourseFilteredList(
        filtersToRequest(getFilters(), pager, getSortParameter())
      );

      if (currentRecourses) {
        const newCcspRecourseList = res.ccspRecourses.concat(res.ccspRecourses);

        setCcspRecourses({
          ...res,
          ccspRecourses: newCcspRecourseList,
        });
      } else {
        setCcspRecourses(res);
      }
    } catch (err) {
      // do nothing
    }
    setLoading(false);
  };

  /**
   * Maj les filtrers et recharge la liste avec les nouveaux filtres
   */
  const updateFilters = async (filters: CcspSearchCriterias) => {
    setFilters(filters);
    currentPage = INITIAL_PAGE;
    pager = { page: INITIAL_PAGE, maxRecords: MAX_RECORDS };

    await debounceLoad();
  };

  /**
   * Change le sens de tri d'un champ
   */
  const updateSortParameter = async (sortField: number): Promise<void> => {
    setSortParameter({
      sortField,
      increasingOrder:
        // si le tri est sur le même champ, on inverse, sinon on inverse le nouveau
        sortField === getSortParameter().sortField
          ? !getSortParameter().increasingOrder
          : !DEFAULT_SORT,
    });
    currentPage = INITIAL_PAGE;
    pager.page = INITIAL_PAGE;

    await debounceLoad();
  };

  const init = async () => {
    currentPage = INITIAL_PAGE;
    pager = { page: INITIAL_PAGE, maxRecords: MAX_RECORDS };

    await fetchCcspRecourseList();
  };

  return {
    init,
    isLoading,
    watchLoading,
    getCcspRecourses,
    watchCcspRecourses,
    getSortParameter,
    watchSortParameter,
    updateSortParameter,
    getError,
    watchError,
    getFilters,
    watchFilters,
    updateFilters,
    loadMoreRows,
  };
};

export default CcspListService;
