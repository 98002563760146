import React from 'react';
import Dialog from 'material-ui/Dialog';
import { FlatButton } from 'material-ui';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  open: boolean;
  onQuit: () => void;
  onSave: () => Promise<void>;
  onCancel: () => void;
};

const SaveAndContinueModal = ({ open, onQuit, onSave, onCancel }: Props) => {
  const actions = [
    <FlatButton label={_tg('action.cancel')} onClick={onCancel} />,
    <FlatButton
      label={_t('element.continueWithoutSave.label')}
      secondary
      onClick={onQuit}
    />,
    <FlatButton
      label={_t('element.continueAndSave.label')}
      primary
      onClick={() => {
        onSave();
        onQuit();
      }}
    />,
  ];

  return (
    <Dialog
      title={_t('element.dialog.title')}
      actions={actions}
      modal
      open={open}
    />
  );
};

export default SaveAndContinueModal;
