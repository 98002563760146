import services from 'commons/services';
import { RecourseUpdateStatus } from 'api/recourse/types';

import useWatcher from './useWatcher';

const useDecision = (): [
  RecourseUpdateStatus | null,
  (arg0: RecourseUpdateStatus | null) => void
] => {
  return [
    useWatcher(services.proposal.watchDecision, null),
    services.proposal.setDecision,
  ];
};

export default useDecision;
