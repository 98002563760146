import { RaisedButton } from 'material-ui';
import React from 'react';

interface Props {
  // Used props
  fullWidth?: boolean;
  primary?: boolean;
  label?: React.ReactNode;
  onClick?: React.MouseEventHandler<{}>;
  disabled?: boolean;
  style?: React.CSSProperties;
  keyboardFocused?: boolean;

  // Props defined my mui, but not necessarily used
  backgroundColor?: string;
  buttonStyle?: React.CSSProperties;
  centerRipple?: boolean;
  className?: string;
  containerElement?: React.ReactNode | string;
  disableFocusRipple?: boolean;
  disableKeyboardFocus?: boolean;
  disableTouchRipple?: boolean;
  disabledBackgroundColor?: string;
  disabledLabelColor?: string;
  focusRippleColor?: string;
  focusRippleOpacity?: number;
  href?: string;
  icon?: React.ReactNode;
  labelColor?: string;
  labelPosition?: 'before' | 'after';
  labelStyle?: React.CSSProperties;
  linkButton?: boolean;
  onBlur?: React.FocusEventHandler<{}>;
  onFocus?: React.FocusEventHandler<{}>;
  onKeyDown?: React.KeyboardEventHandler<{}>;
  onKeyUp?: React.KeyboardEventHandler<{}>;
  onKeyboardFocus?(e: React.FocusEvent<{}>, isKeyboardFocused: boolean): void;
  onMouseDown?: React.MouseEventHandler<{}>;
  onMouseEnter?: React.MouseEventHandler<{}>;
  onMouseLeave?: React.MouseEventHandler<{}>;
  onMouseUp?: React.MouseEventHandler<{}>;
  onTouchEnd?: React.TouchEventHandler<{}>;
  onTouchStart?: React.TouchEventHandler<{}>;
  overlayStyle?: React.CSSProperties;
  rippleStyle?: React.CSSProperties;
  secondary?: boolean;
  tabIndex?: number;
  target?: string;
  touchRippleColor?: string;
  touchRippleOpacity?: number;
  type?: string;
}

const BoButton = (props: Props): JSX.Element => {
  return <RaisedButton {...props} />;
};

export default BoButton;
