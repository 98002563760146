import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { fetchControl } from 'api/control';
import { ControlDTO, ControlOverviewDTO } from 'api/control/types';

import services from '.';

export interface ControlServiceFactory {
  (): ControlServiceInterface;
}

export interface ControlServiceInterface {
  init: (controlId: string) => Promise<void>;
  getControl: () => ControlDTO | null;
  watchControl: WatchFunctionType<ControlDTO | null>;
  applyControlChanges: () => void;
  hasNextControlToGo: () => boolean;
  getNextControlToGo: () => Promise<ControlOverviewDTO | null>;
  getPreviousControlToGo: () => ControlOverviewDTO | null;
}

const ControlService: ControlServiceFactory = () => {
  const {
    getValue: getControl,
    setValue: setControl,
    watchValue: watchControl,
  } = Watcher<ControlDTO | null>(null);

  const init = async (controlId: string): Promise<void> => {
    const control = await fetchControl(controlId);
    setControl(control);
  };

  const applyControlChanges = (): void => {
    const controlDetails = getControl();
    if (controlDetails) {
      setControl(controlDetails);
    }
  };

  const hasNextControlToGo = (): boolean => {
    const controlList = services.controls.getControls()?.controls;
    const totalHits = services.controls.getControls()?.totalHits;
    const currentControl = getControl();

    if (!controlList || !currentControl) {
      return false;
    }

    const currentIndex = controlList.findIndex(
      item => item.controlId === currentControl.controlId
    );

    if (currentIndex + 1 === totalHits) {
      return false;
    }

    return true;
  };

  const getNextControlToGo = async (): Promise<ControlOverviewDTO | null> => {
    if (!hasNextControlToGo()) {
      return null;
    }

    const controlList = services.controls.getControls()?.controls;
    const currentControl = getControl();

    if (!controlList || !currentControl) {
      return null;
    }

    const currentIndex = controlList.findIndex(
      item => item.controlId === currentControl.controlId
    );

    if (currentIndex === controlList.length - 1) {
      await services.controls.loadMoreRows();
      const newControlList = services.controls.getControls()?.controls;

      if (newControlList && currentIndex < newControlList.length - 1) {
        return newControlList[currentIndex + 1];
      }

      return null;
    }

    return controlList[currentIndex + 1] || null;
  };

  const getPreviousControlToGo = (): ControlOverviewDTO | null => {
    const controlList = services.controls.getControls()?.controls;
    const currentControl = getControl();

    if (!controlList || !currentControl) {
      return null;
    }

    const currentIndex = controlList.findIndex(
      item => item.controlId === currentControl.controlId
    );

    if (currentIndex > 0) {
      return controlList[currentIndex - 1];
    }

    return null;
  };

  return {
    init,
    getControl,
    watchControl,
    applyControlChanges,
    hasNextControlToGo,
    getNextControlToGo,
    getPreviousControlToGo,
  };
};

export default ControlService;
