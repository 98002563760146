import React from 'react';
import range from 'lodash.range';
import moment, { Moment } from 'moment';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

const { _tg } = window.loadTranslations();

type Props = {
  date: Moment;
  changeDate: (date: Moment) => void;
};

const yearList = range(2014, moment().year() + 1);

class MonthYearPicker extends React.Component<Props> {
  changeMonth = (event: any, index: number, value: number) => {
    const { changeDate, date } = this.props;
    const newDate = moment(date);
    newDate.month(value);
    changeDate(newDate);
  };
  changeYear = (event: any, index: number, value: number) => {
    const { changeDate, date } = this.props;
    const newDate = moment(date);
    newDate.year(value);
    changeDate(newDate);
  };

  render() {
    const { date } = this.props;

    return (
      <div style={{ display: 'flex', marginBottom: 23 }}>
        <SelectField
          floatingLabelText={_tg('field.date.month')}
          value={date.month()}
          onChange={this.changeMonth}
          style={{ width: 140, marginRight: 20 }}
        >
          {moment.months().map((monthName, i) => (
            <MenuItem key={i} value={i} primaryText={monthName} />
          ))}
        </SelectField>
        <SelectField
          floatingLabelText={_tg('field.date.year')}
          value={date.year()}
          onChange={this.changeYear}
          style={{ width: 100, marginRight: 20 }}
        >
          {yearList.map((yearV, i) => (
            <MenuItem key={i} value={yearV} primaryText={yearV} />
          ))}
        </SelectField>
      </div>
    );
  }
}

export default MonthYearPicker;
