// based on PlateUtils.java

import { buildDisplayingPlate } from 'tefps/Tickets/List/utils';

// Ancien modèle de plaque standard avant le changement pour le modèle SIV. Exemple: 9123 ABC 976
// Les parenthèses sont requises pour la capture
const PATTERN_FNI = /^([1-9]\d{0,3})\s([A-Z]{1,3})\s(\d\d|2A|2B|971|972|973|974|975|976)$/;

// Nouveau systeme de plaque standard. Exemple: AA-999-AA
const PATTERN_SIV = /^[A-Z]{2}-[0-9]{3}-[A-Z]{2}$/;

// Plaque standart pour les cyclomoteur. Exemple: AA999A
const PATTERN_CYCLOMOTEUR = /^[A-Z]{1,2}\s[0-9]{2,3}\s[A-Z]$/;

// Plaque domaine (Service publique de l'Etat). Exemple: 99N9999A
const PATTERN_DOMAIN = /^((\d{2,3})|2A|2B)[DRNE]?[1-9][0-9]{3}[A-Z]$/;

// Plaque de garage provisoire, nouveau système. Exemple: W-999-AA
const PATTERN_GARAGE = /^W{1,2}-[0-9]{3}-[A-Z]{2}$/;

// Plaque de garage provisoire, ancien système. Exemple: 4242WWZ42
const PATTERN_GARAGE_OLD = /^[1-9]\d{0,3}(W|WW[A-Z]?)(\d\d|2A|2B|MC|971|972|973|974|975|976)$/;

// Plaque de vehicule de tourisme acheté neuf en France et destiné à l'exportation. Exemple: 999TAA99
const PATTERN_TRANSIT = /^([1-9]\d{0,2}(T[A-Z]{2})(\d{2}|2A|2B|971|972|973|974|975|976))|(([1-9]\d{4})(TTQ|TTW))$/;

// Plaque de véhicule militaire. Exemple: 27777777
const PATTERN_MILITARY = /^[26789][0-9]{7}$/;

// Plaque de véhicule consulaire. Exemple: S 1234 C 4321
const PATTERN_CONSULAR = /^(([ESU]\s)?[0-9]{1,4}\s[C]\s[0-9]{1,4}(\s[X|Z])?(\s[0-8][0-9]|9[0-5])?)$/;

// Plaque de véhicule diplomatique. Exemple: 999 CD 9999
const PATTERN_DIPLOMATIC = /^(([ESU]\s)?[0-9]{1,4}\s[K]\s[0-9]{1,4}(\s[XZ])?)|(([ESU]\s)?[0-9]{1,4}\s[C][mM]?[D]\s[0-9]{1,4}(\s[XZ])?)$/;

function isPlateMilitary(plate: string) {
  return PATTERN_MILITARY.test(plate);
}

function isPlateDiplomatic(plate: string) {
  return PATTERN_CONSULAR.test(plate) || PATTERN_DIPLOMATIC.test(plate);
}

function isPlateRegular(plate: string) {
  return (
    PATTERN_FNI.test(plate) ||
    PATTERN_SIV.test(plate) ||
    PATTERN_CYCLOMOTEUR.test(plate)
  );
}

function isPlateDomain(plate: string) {
  return PATTERN_DOMAIN.test(plate);
}

function isPlateGarage(plate: string) {
  return PATTERN_GARAGE.test(plate) || PATTERN_GARAGE_OLD.test(plate);
}

function isPlateTansit(plate: string) {
  return PATTERN_TRANSIT.test(plate);
}

export function isPlateValid(plate: string): boolean {
  return (
    isPlateRegular(plate) ||
    isPlateGarage(plate) ||
    isPlateTansit(plate) ||
    isPlateDomain(plate) ||
    isPlateDiplomatic(plate) ||
    isPlateMilitary(plate)
  );
}

export const renderPlates = (
  hashedPlates: string[] | undefined,
  maxDisplay?: number
): string => {
  if (!hashedPlates) {
    return '';
  }

  if (maxDisplay && hashedPlates.length > maxDisplay) {
    const total = hashedPlates.length;
    return `${hashedPlates
      .slice(0, maxDisplay)
      .map(p => buildDisplayingPlate(p))
      // eslint-disable-next-line no-useless-escape
      .join(', ')},... \[${total}\]`;
  }

  return hashedPlates.map(p => buildDisplayingPlate(p)).join(', ');
};
