import React from 'react';
import WarningIcon from 'material-ui/svg-icons/alert/error';

import { ICON_RED } from 'theme';

type Props = {
  style: any;
};

const WarningSymbol = ({ style }: Props) => (
  <div style={style}>
    <svg height="30" width="30">
      <circle cx="15" cy="15" r="15" style={{ fill: 'white' }} />
      <WarningIcon style={{ color: ICON_RED }} />
    </svg>
  </div>
);

export default WarningSymbol;
