import _ from 'lodash';

import { EsCityParkingSpaceDTO } from '@cvfm-front/tefps-types';

import { ParkingSpaceCreationServiceInterface } from './ParkingSpaceCreationService';

export interface ParkingSpaceDuplicationServiceFactory {
  (
    parkingSpaceCreation: ParkingSpaceCreationServiceInterface
  ): ParkingSpaceDuplicationServiceInterface;
}

export interface ParkingSpaceDuplicationServiceInterface {
  duplicateParkingSpace: (
    parkingSpace: EsCityParkingSpaceDTO
  ) => EsCityParkingSpaceDTO;
}

const ParkingSpaceDuplicationService: ParkingSpaceDuplicationServiceFactory = parkingSpaceCreation => {
  return {
    duplicateParkingSpace: (parkingSpace: EsCityParkingSpaceDTO) => {
      const duplicateParkingSpace = _.cloneDeep(parkingSpace);
      duplicateParkingSpace.geometry.coordinates = duplicateParkingSpace.geometry.coordinates.map(
        polygon => polygon.map(coord => coord.map(value => value + 0.00002))
      );
      duplicateParkingSpace.latLong.latitude += 0.00002; // Décalage vers le Nord
      duplicateParkingSpace.latLong.longitude += 0.00002; // Décalage vers l'est
      duplicateParkingSpace.versions = duplicateParkingSpace.versions.slice(-1); // Garder uniquement la dernière version de l'emprise

      parkingSpaceCreation.addParkingSpace(duplicateParkingSpace, true);
      return duplicateParkingSpace;
    },
  };
};

export default ParkingSpaceDuplicationService;
