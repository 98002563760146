import services from 'commons/services';
import { EligibilitySearchResultDTO } from 'api/eligibility/type';

import useWatcher from './useWatcher';

const useEligibilityRights = (): EligibilitySearchResultDTO | null => {
  return useWatcher(
    services.eligibilityRights.watchEligibilityRights,
    services.eligibilityRights.getEligibilityRights()
  );
};

export default useEligibilityRights;
