import * as React from 'react';

import { STYLE_TITLE_BORDER } from 'theme';

import { STYLE_BLOCK_TITLE } from '../theme';

const { _t } = window.loadTranslations(__filename);

type Props = {
  hits: number;
};

const HitsCounter = ({ hits }: Props) => (
  <div style={{ marginTop: 20 }}>
    <div style={STYLE_BLOCK_TITLE}>
      {_t('element.elementFound', {
        count: hits,
        formattedCount: hits.toLocaleString(),
      })}
      <hr style={STYLE_TITLE_BORDER} />
    </div>
  </div>
);

export default HitsCounter;
