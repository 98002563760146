import React, { CSSProperties } from 'react';
import PingIcon from 'material-ui/svg-icons/action/room';

import BoButton from 'facade/BoButton';
import { ICON_RED } from 'theme';

const buttonStyle: CSSProperties = { width: '350px' };

type Props = {
  label: string;
  onClick: () => void;
};

const MapLinkButton = ({ label, onClick }: Props) => (
  <BoButton
    label={label}
    style={buttonStyle}
    icon={<PingIcon color={ICON_RED} />}
    onClick={onClick}
  />
);

export default MapLinkButton;
