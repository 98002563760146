import React, { CSSProperties } from 'react';
import Checkbox from 'material-ui/Checkbox';

import {
  ICON_STYLE,
  LABEL_STYLE,
  STYLE_TITLE_BORDER,
  FLEX_WIDTH_STYLE,
} from 'theme';
import { CheckboxOption } from 'commons/SidebarV2/types';

import { STYLE_BLOCK_TITLE } from '../theme';

const STYLE_BLOCK: CSSProperties = {
  marginTop: 20,
};

const STYLE_FILTER_BLOCK: CSSProperties = {
  fontSize: 13,
  margin: '2px 0',
  display: 'flex',
  alignItems: 'center',
};

type CheckboxProps = {
  title?: string;
  id: string;
  filter: boolean | undefined;
  options: CheckboxOption | undefined;
  onChange: (id: string, filters: any) => void;
};

class CheckboxV1 extends React.Component<CheckboxProps> {
  onCheck = (event: never, isInputChecked: boolean): void => {
    const { id, onChange } = this.props;
    onChange(id, isInputChecked);
  };

  render(): React.ReactNode {
    const { title, filter, options } = this.props;
    return (
      <div style={STYLE_BLOCK}>
        {title && (
          <div style={STYLE_BLOCK_TITLE}>
            {title}
            <hr style={STYLE_TITLE_BORDER} />
          </div>
        )}
        <div style={STYLE_FILTER_BLOCK}>
          <Checkbox
            label={options && options.label}
            iconStyle={ICON_STYLE}
            labelStyle={LABEL_STYLE}
            style={FLEX_WIDTH_STYLE}
            checked={filter}
            onCheck={this.onCheck}
          />
        </div>
      </div>
    );
  }
}

export default CheckboxV1;
