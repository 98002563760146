import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';

import config from 'config/duck';
import tokenAlert from 'tokenAlert/duck';
import controlList from 'tefps/Control/List/duck';

import universe from './UniverseHandler/duck';
import api from './api/duck';
import dashboard from './Dashboard/duck';
import admin from './Administration/duck';
import topbar from './Topbar/Download/duck';
import documentLibrary from './DocumentLibrary/ducks';
import documentLibraryModals from './DocumentLibrary/Modals/ducks';
import snackbar from './commons/Snackbar/ducks';
import parkingRightDefinitionsSearchCriteria from './tefps/Beneficiaries/ParkingRightDefinitions/duck';
import parkingRightRequestsSearchCriteria from './tefps/Beneficiaries/ParkingRightRequests/duck';
import subscriptionPlansSearchCriteria from './tefps/Beneficiaries/SubscriptionPlans/duck';
import subscriptionApplicationsSearchCriteria from './tefps/Beneficiaries/SubscriptionApplications/duck';
import subscriptionsSearchCriteria from './tefps/Beneficiaries/Subscriptions/duck';
import subscriberList from './tefps/Beneficiaries/Subscribers/duck';
import renewalReminders from './tefps/Beneficiaries/SubscriptionPlans/AddSubscriptionPlan/Components/ducks/reducer';
import mifList from './tepv/Mif/List/duck';
import pingFilters from './tefps/Planner/Pings/duck';
import alertFilters from './tefps/Planner/Alerts/AlertFilter/ducks/reducer';
import eligibilitiesList from './tefps/Eligibility/List/duck';

const reducers = combineReducers<unknown>({
  api,
  config,
  tokenAlert,
  controlList,
  documentLibrary,
  documentLibraryModals,
  snackbar,
  dashboard,
  admin,
  universe,
  topbar,
  subscriberList,
  parkingRightDefinitionsSearchCriteria,
  parkingRightRequestsSearchCriteria,
  subscriptionPlansSearchCriteria,
  subscriptionApplicationsSearchCriteria,
  subscriptionsSearchCriteria,
  mifList,
  pingFilters,
  renewalReminders,
  alertFilters,
  eligibilitiesList,
});

export default function createFpsBackofficeStore(): Store<unknown> {
  const __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ =
    '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__';
  const composeEnhancers =
    (window[__REDUX_DEVTOOLS_EXTENSION_COMPOSE__] as typeof compose) || compose;
  return createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
}
