import React, { ReactElement } from 'react';
import TextField from 'material-ui/TextField';
import Reset from 'material-ui/svg-icons/action/restore';

import { INLINE_TEXT_FIELD_STYLE, STYLE_INPUT_TEXT } from '../theme';

const { _t } = window.loadTranslations(__filename);

const getDays = (mins: number | undefined): number | undefined => {
  if (mins !== undefined) {
    return Math.floor(mins / 60 / 24);
  }
  return undefined;
};

const getHours = (mins: number | undefined): number | undefined => {
  if (mins !== undefined) {
    return Math.floor(mins / 60) % 24;
  }
  return undefined;
};

const getMinutes = (mins: number | undefined): number | undefined => {
  if (mins !== undefined) {
    return mins % 60;
  }
  return undefined;
};

type DurationProps = {
  id: string;
  initialTotalMinutes: number | undefined;
  changeDuration: (id: string, durationMinutes: number) => void;
  clearDuration: (id: string) => void;
};

type DurationState = {
  days: number | undefined;
  hours: number | undefined;
  minutes: number | undefined;
};

class Duration extends React.Component<DurationProps, DurationState> {
  constructor(props: DurationProps) {
    super(props);
    const { initialTotalMinutes } = this.props;
    if (initialTotalMinutes === 0) {
      this.state = {
        days: undefined,
        hours: undefined,
        minutes: 0,
      };
    } else {
      this.state = this.computeTime(initialTotalMinutes);
    }
  }

  computeTime = (totalMinutes: number | undefined): DurationState => {
    return {
      days: getDays(totalMinutes),
      hours: getHours(totalMinutes),
      minutes: getMinutes(totalMinutes),
    };
  };

  // the new values might be any integer, or undefined (when the field is empty)
  changeDuration = (
    newDays: number | undefined,
    newHours: number | undefined,
    newMinutes: number | undefined
  ): void => {
    const { id, changeDuration } = this.props;
    // if all the values are undefined, then it means that all fields are empty
    if (
      newDays === undefined &&
      newHours === undefined &&
      newMinutes === undefined
    ) {
      // clear the filter
      this.clearDuration();
    } else {
      // at least a field is not empty
      // this means that the filter should be applied
      // Compute the duration (undefined is interpreted as 0)
      const newDuration =
        (newDays === undefined ? 0 : newDays) * 24 * 60 +
        (newHours === undefined ? 0 : newHours) * 60 +
        (newMinutes === undefined ? 0 : newMinutes);
      if (newDuration >= 0) {
        changeDuration(id, newDuration);
        // now compute what should go into the fields (61 minutes will change to 1 hour and 1 minute)
        const { days, hours, minutes } = this.computeTime(newDuration);
        // use the computed value if the user entered something (not '') or if the value is > 0. Use undefined otherwise
        // that way, the user can type 0, can delete what they entered, and (24h) => (1d, 0h)
        this.setState({
          days: newDays !== undefined || days ? days : undefined,
          hours: newHours !== undefined || hours ? hours : undefined,
          minutes: newMinutes !== undefined || minutes ? minutes : undefined,
        });
      } else {
        changeDuration(id, 0);
        this.setState({
          days: undefined,
          hours: undefined,
          minutes: 0,
        });
      }
    }
  };

  changeDays = (_event: React.MouseEvent<any>, value: string): void => {
    const { hours, minutes } = this.state;
    const newDays = Math.ceil(Number(value));
    this.changeDuration(value === '' ? undefined : newDays, hours, minutes);
  };

  changeHours = (_event: React.MouseEvent<any>, value: string): void => {
    const { days, minutes } = this.state;
    const newHours = Math.ceil(Number(value));
    this.changeDuration(days, value === '' ? undefined : newHours, minutes);
  };

  changeMinutes = (_event: React.MouseEvent<any>, value: string): void => {
    const { days, hours } = this.state;
    const newMinutes = Math.ceil(Number(value));
    this.changeDuration(days, hours, value === '' ? undefined : newMinutes);
  };

  // fired when the 3 fields are empty or when the reset button is clicked
  clearDuration = (): void => {
    const { id, clearDuration } = this.props;
    clearDuration(id);
    this.setState({ days: undefined, hours: undefined, minutes: undefined });
  };

  render(): ReactElement {
    const { days, hours, minutes } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 5,
        }}
      >
        <TextField
          name="days"
          value={days !== undefined ? days : ''}
          onChange={this.changeDays}
          type="number"
          inputStyle={STYLE_INPUT_TEXT}
          style={{ ...INLINE_TEXT_FIELD_STYLE, marginTop: 0 }}
          underlineShow={false}
        />
        <span style={{ ...STYLE_INPUT_TEXT, marginLeft: 6, marginRight: 6 }}>
          {_t('element.units.days')}
        </span>
        <TextField
          name="hours"
          value={hours !== undefined ? hours : ''}
          onChange={this.changeHours}
          type="number"
          inputStyle={STYLE_INPUT_TEXT}
          style={{ ...INLINE_TEXT_FIELD_STYLE, marginTop: 0 }}
          underlineShow={false}
        />
        <span style={{ ...STYLE_INPUT_TEXT, marginLeft: 6, marginRight: 6 }}>
          {_t('element.units.hours')}
        </span>
        <TextField
          name="min"
          value={minutes !== undefined ? minutes : ''}
          onChange={this.changeMinutes}
          type="number"
          inputStyle={STYLE_INPUT_TEXT}
          style={{ ...INLINE_TEXT_FIELD_STYLE, marginTop: 0 }}
          underlineShow={false}
        />
        <span style={{ ...STYLE_INPUT_TEXT, marginLeft: 6, marginRight: 6 }}>
          {_t('element.units.minutes')}
        </span>
        <Reset
          style={{
            color: 'white',
            width: 30,
            minWidth: 20,
            height: 30,
            cursor: 'pointer',
          }}
          onClick={this.clearDuration}
        />
      </div>
    );
  }
}

export default Duration;
