import React from 'react';
import { Route } from 'react-router-dom';

import FlexCenter from './FlexCenter';
import ErrorBlock from './ErrorBlock';

const { _t } = window.loadTranslations(__filename);

type Props = {
  location: {
    pathname: string; // eslint-disable-line react/no-unused-prop-types
  };
};

const NoMatch = ({ location }: Props) => (
  <FlexCenter>
    <ErrorBlock
      level="WARN"
      message={_t('element.errorBlock.message')}
      error={{
        message: _t('element.errorBlock.error.message', {
          pathname: location.pathname,
        }),
      }}
    />
  </FlexCenter>
);

const MissNotFound = () => <Route component={NoMatch} />;

export default MissNotFound;
