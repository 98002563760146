import { OrderEsSearchResultDTO } from '@cvfm-front/tefps-types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useEligibilities = (): OrderEsSearchResultDTO | null => {
  return useWatcher(
    services.eligibilities.watchEligibilities,
    services.eligibilities.getEligibilities()
  );
};

export default useEligibilities;
