import React from 'react';
import DeleteIcon from 'material-ui/svg-icons/content/clear';

const { _tg } = window.loadTranslations(__filename);

type DeleteProps = { deleteFunc: (event: any) => void; index?: number };
type DeleteBlockProps = {
  deleteFunc: (event: any) => void;
  index?: number;
  children?: any;
};

const DeleteButton = ({ deleteFunc, index }: DeleteProps) => (
  <span title={_tg('action.delete').toLowerCase()} key={index}>
    <DeleteIcon
      style={{ cursor: 'pointer', color: '#222' }}
      data-idx={index}
      onClick={deleteFunc}
    />
  </span>
);

const RemovableCriteria = ({
  deleteFunc,
  index,
  children,
}: DeleteBlockProps) => (
  <table style={{ width: '100%' }}>
    <tbody>
      <tr style={{ textAlign: 'center' }}>
        <td
          // eslint-disable-next-line
          // @ts-ignore
          width="10%"
        >
          <DeleteButton deleteFunc={deleteFunc} index={index} />
        </td>
        <td style={{ textAlign: 'center', verticalAlign: 'center' }}>
          {children}
        </td>
      </tr>
    </tbody>
  </table>
);

export default RemovableCriteria;
