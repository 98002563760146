import React, { CSSProperties } from 'react';

import { BRD_GREY } from 'theme';

const STYLE_SEPARATOR: CSSProperties = {
  width: 1,
  backgroundColor: BRD_GREY,
  margin: '0px 25px',
};

type Props = {
  style?: CSSProperties;
};

const Separator = ({ style }: Props) => (
  <div style={{ ...STYLE_SEPARATOR, ...style }} />
);

export default Separator;
