import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';
import _cloneDeep from 'lodash.clonedeep';

import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import { fetchZoning, saveZoning } from 'api/pricing';
import { PolygonForMap } from 'api/pricing/types';
import { GeocoderConfiguration, getConfigState } from 'config/duck';
import { computeNaming, toZoneforMap } from 'commons/ZoningComponents/helpers';
import { getApiState, InternalApiState } from 'api/duck';
import ZoningMap from 'commons/ZoningComponents/ZoningMap';
import ZoningSidebar from 'commons/ZoningComponents/ZoningSidebar';
import {
  ZoningState,
  ZoningFetchConfig,
  ZoningNames,
  IParkingMeterPatchObject,
} from 'commons/ZoningComponents/types';
import {
  deleteCityParkingMeters,
  getCityParkingMeters,
  deleteParkingMeterByNumber,
  updateParkingMeter,
} from 'api/parkingMeter';
import {
  PointDTO,
  PricingConfiguration,
  ZoningDTO,
} from '@cvfm-front/tefps-types';
import { HorizontalTabs } from '@cvfm-front/tefps-ui';
import { SidebarStyle } from 'styles/SidebarStyle';
import ParkingSpaceMap from 'tefps/ParkingSpace/ParkingSpaceMap';
import ParkingSpaceFilterBar from 'tefps/ParkingSpace/ParkingSpaceFilterBar';

const { _tg } = window.loadTranslations();

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  width: '100%',
  height: '100%',
};

const STYLE_LIST_WRAPPER: CSSProperties = {
  backgroundColor: 'white',
  width: '100%',
  margin: '0 auto',
};

const STYLE_MAP: CSSProperties = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};

export type ZoningProps = {
  location: {
    latitude: number;
    longitude: number;
  };
  pricingConfiguration: PricingConfiguration;
  fetchConfigs: ZoningFetchConfig;
  enableParkingMeter?: boolean;
  organizationFilterEnabled?: boolean;
  geocoderConfiguration: GeocoderConfiguration;
  canAccessParkingSpace: boolean;
};

const INITIAL_STATE: ZoningState = {
  zoning: undefined,
  selectedPolygonId: undefined,
  selectedParkingMeterNumber: undefined,
  hiddenZones: new Set(),
  error: null,
  hasChanged: false,
  parkingMeters: null,
  canShowParkingMeter: true,
  hasCityParkingMeters: false,
  tab: 'zoning',
};

/**
 * /!\ L'état de cette classe est modifié par le composant enfant ZoningMap via les callbacks onChange
 */
class Zoning extends React.Component<ZoningProps, ZoningState> {
  map: ZoningMap | null = null;
  fetchConfigs: ZoningFetchConfig;
  naming: ZoningNames;
  enableParkingMeter: boolean;
  showParkingMeter: boolean;

  constructor(props: ZoningProps) {
    super(props);
    this.fetchConfigs = this.props.fetchConfigs;
    this.naming = computeNaming(this.fetchConfigs.zoningType);
    this.enableParkingMeter = !!this.props.enableParkingMeter;
  }
  state: ZoningState = INITIAL_STATE;

  componentDidMount() {
    this.fetchZoning(this.fetchConfigs);
    if (this.enableParkingMeter) this.fetchParkingMeter();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    this.fetchZoning(this.fetchConfigs);
    if (this.enableParkingMeter) this.fetchParkingMeter();
  }

  onChange = () => this.setState({ hasChanged: true });

  fetchZoning = async (fetchConfigs: ZoningFetchConfig) => {
    try {
      const zoning = await fetchZoning(fetchConfigs);
      this.setState({ error: null, zoning });
    } catch (error) {
      this.setState({ error });
    }
  };

  fetchParkingMeter = async () => {
    try {
      const parkingMeters = await getCityParkingMeters();
      this.setState({ hasCityParkingMeters: !!parkingMeters.length });
      this.setState({ error: null, parkingMeters });
    } catch (error) {
      this.setState({ error });
    }
  };

  save = async (zoningSidebar: ZoningDTO) => {
    zoningSidebar.zoningType = this.fetchConfigs.zoningType;
    await this.saveZoning(zoningSidebar);
    this.setState({ hasChanged: false });
  };

  updateZoningFromSidebar = (zoning: ZoningDTO) => {
    const newZoning = this.mergeSidebarAndMapData(zoning);
    this.setState({ zoning: newZoning, hasChanged: true });
    if (this.map) this.map.redraw(toZoneforMap(newZoning));
  };

  updateZoningFromMap = () => {
    if (!this.state.zoning) return;
    this.setState({ zoning: this.mergeSidebarAndMapData(this.state.zoning) });
  };

  saveZoning = async (zoning: ZoningDTO) => {
    try {
      await saveZoning(this.mergeSidebarAndMapData(zoning));
      this.fetchZoning(this.fetchConfigs);
    } catch (error) {
      this.setState({ error });
    }
  };

  mergeSidebarAndMapData = (zoningP: ZoningDTO) => {
    const zoning = _cloneDeep(zoningP);
    if (this.map) {
      const polygons = this.map.saveMap();
      polygons.forEach(p =>
        zoning.polygons.forEach(poly => {
          if (poly.id === p.id) {
            poly.points = p.points;
          }
        })
      );
    }
    return zoning;
  };

  showZone = (zoneId: string) => {
    const hiddenZones = new Set(this.state.hiddenZones);
    const { pricingConfiguration } = this.props;
    hiddenZones.delete(zoneId);
    const tariffZone = pricingConfiguration.tariffZones.find(
      tz => tz.tariffZoneId === zoneId
    );
    if (tariffZone) {
      tariffZone.zoneIds.forEach((zone: string) => hiddenZones.delete(zone));
    }
    this.setState({ hiddenZones });
  };

  hideZone = (zoneId: string) => {
    const hiddenZones = new Set(this.state.hiddenZones);
    const { pricingConfiguration } = this.props;
    hiddenZones.add(zoneId);
    const tariffZone = pricingConfiguration.tariffZones.find(
      tz => tz.tariffZoneId === zoneId
    );
    if (tariffZone) {
      tariffZone.zoneIds.forEach((zone: string) => hiddenZones.add(zone));
    }
    this.setState({ hiddenZones });
  };

  changeParkingMeterVisibility = () => {
    if (this.map) this.map.handleHideParkingMeters();
    this.setState({ canShowParkingMeter: !this.state.canShowParkingMeter });
  };

  newPolygon = (id: string, name: string, zoneIds: Array<string>) => {
    if (this.map) this.map.createPolygon(id, name, zoneIds);
    this.setState({ selectedPolygonId: id });
  };

  highlightPolygon = (selectedPolygonId: string) =>
    this.setState({ selectedPolygonId });

  selectParkingMeterNumber = (selectedParkingMeterNumber: string) => {
    this.setState({ selectedParkingMeterNumber });
  };

  deletePolygonById = (id: string) =>
    this.map && this.map.deletePolygonBydId(id);

  addPolygons = (polygons: Array<PolygonForMap>) => {
    if (!this.state.zoning) return;
    const zoning = _cloneDeep(this.state.zoning);
    polygons.forEach(polygon => {
      zoning.zones
        .filter(z => polygon.zoneIds.includes(z.id))
        .forEach(z => z.polygonIds.push(polygon.id));
      zoning.polygons.push(polygon);
    });
    const newZoning = this.mergeSidebarAndMapData(zoning);
    this.setState({ zoning: newZoning, hasChanged: true });
    if (this.map) this.map.redraw(toZoneforMap(newZoning));
  };

  deleteCityParkingMeters = async () => {
    try {
      if (await deleteCityParkingMeters()) {
        this.setState({ hasCityParkingMeters: false });
        if (this.map) this.map.removeAllMarkers();
      }
    } catch (error) {
      this.setState({ error });
    }
  };

  deleteParkingMeter = async (number: string) => {
    const { parkingMeters } = this.state;
    try {
      if (await deleteParkingMeterByNumber(number)) {
        this.setState({
          parkingMeters: parkingMeters
            ? parkingMeters.filter(pm => pm.number !== number)
            : null,
        });
      }
    } catch (error) {
      this.setState({ error });
    }
  };

  editParkingMeter = async (
    number: string,
    updates: IParkingMeterPatchObject[]
  ) => {
    try {
      await updateParkingMeter(number, updates);
      await this.fetchParkingMeter();
    } catch (error) {
      this.setState({ error });
    }
  };

  detectImportParkingMeters = () => {
    this.fetchParkingMeter().then(this.map?.drawParkingMeters);
  };

  addPolygon = (
    id: string,
    name: string,
    zoneIds: Array<string>,
    points: Array<PointDTO>
  ) => {
    this.addPolygons([{ id, name, zoneIds, points, properties: null }]);
  };

  render() {
    const {
      location,
      organizationFilterEnabled,
      geocoderConfiguration,
      canAccessParkingSpace,
    } = this.props;
    const {
      hasChanged,
      zoning,
      selectedPolygonId,
      selectedParkingMeterNumber,
      hiddenZones,
      error,
      tab,
    } = this.state;

    if (error || !location) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_tg('feedback.error.fetchZoning')}
              error={
                error || { message: _tg('feedback.error.cityHasNoCoordinate') }
              }
            />
          </FlexCenter>
        </Content>
      );
    }

    const { latitude: lat, longitude: lng } = location;

    if (!zoning) {
      return (
        <Content>
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        </Content>
      );
    }
    const tabs = [
      {
        id: 'zoning',
        label: 'Zones',
        content: (
          <ZoningSidebar
            naming={this.naming}
            showZone={this.showZone}
            newPolygon={this.newPolygon}
            hiddenZones={hiddenZones}
            hideZone={this.hideZone}
            zoning={zoning}
            selectedPolygonId={selectedPolygonId}
            selectParkingMeterNumber={this.selectParkingMeterNumber}
            onSave={this.save}
            hasChanged={hasChanged}
            highlightPolygon={this.highlightPolygon}
            deletePolygon={this.deletePolygonById}
            updateZoningFromSidebar={this.updateZoningFromSidebar}
            addPolygons={this.addPolygons}
            enableParkingMeter={this.enableParkingMeter}
            canShowParkingMeter={this.state.canShowParkingMeter}
            hasCityParkingMeters={this.state.hasCityParkingMeters}
            changeParkingMeterVisibility={this.changeParkingMeterVisibility}
            deleteCityParkingMeters={this.deleteCityParkingMeters}
            detectImportParkingMeters={this.detectImportParkingMeters}
            organizationFilterEnabled={organizationFilterEnabled}
            parkingMeters={this.state.parkingMeters}
          />
        ),
      },
    ];

    if (geocoderConfiguration.parkingSpaceEnabled && canAccessParkingSpace) {
      tabs.push({
        id: 'parking-space',
        label: _tg('parkingSpace'),
        content: <ParkingSpaceFilterBar />,
      });
    }

    return (
      <div style={STYLE_CONTENT}>
        <div style={SidebarStyle}>
          <HorizontalTabs
            onActiveTabChange={newTab => this.setState({ tab: newTab.id })}
            tabs={tabs}
          />
        </div>
        <div style={STYLE_LIST_WRAPPER}>
          {tab === 'zoning' && (
            <ZoningMap
              ref={ref => {
                this.map = ref;
              }}
              style={STYLE_MAP}
              center={{ lat, lng }}
              zoning={toZoneforMap(zoning)}
              selectedPolygonId={selectedPolygonId}
              selectedParkingMeterNumber={selectedParkingMeterNumber}
              selectParkingMeterNumber={this.selectParkingMeterNumber}
              hiddenZones={hiddenZones}
              onChange={this.onChange}
              addPolygon={this.addPolygon}
              selectPolygon={this.highlightPolygon}
              enableParkingMeter={this.enableParkingMeter}
              canShowParkingMeter={this.state.canShowParkingMeter}
              parkingMeters={this.state.parkingMeters}
              deleteParkingMeter={this.deleteParkingMeter}
              editParkingMeter={this.editParkingMeter}
            />
          )}
          {tab === 'parking-space' && <ParkingSpaceMap />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: InternalApiState) => {
  const {
    location,
    pricingConfiguration,
    geocoderConfiguration,
  } = getConfigState(state);
  const { userInfo } = getApiState(state);
  return {
    location,
    pricingConfiguration,
    geocoderConfiguration,
    canAccessParkingSpace:
      userInfo &&
      (userInfo.rights.includes('PARKING_SPACE_WRITE') ||
        userInfo.rights.includes('PARKING_SPACE_READ')),
  };
};

export default connect(mapStateToProps)(Zoning);
