import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { fetchFps } from 'api/fps';
import { FpsCaseDTO } from 'api/fps/types';

export interface FpsServiceFactory {
  (): FpsServiceInterface;
}

export interface FpsServiceInterface {
  init: (id: string) => Promise<void>;
  getFps: () => FpsCaseDTO | null;
  watchFps: WatchFunctionType<FpsCaseDTO | null>;
}

const FpsService: FpsServiceFactory = () => {
  const {
    getValue: getFps,
    setValue: setFps,
    watchValue: watchFps,
  } = Watcher<FpsCaseDTO | null>(null);

  const init = async (id: string): Promise<void> => {
    try {
      const fps = await fetchFps(id);
      setFps(fps);
    } catch (e) {
      // NO OP
    }
  };

  return {
    init,
    getFps,
    watchFps,
  };
};

export default FpsService;
