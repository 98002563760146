import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';

export interface CommentServiceFactory {
  (): CommentServiceInterface;
}

export interface CommentServiceInterface {
  getComment: () => string;
  setComment: (comment: string) => void;
  watchComment: WatchFunctionType<string>;
}

const CommentService: CommentServiceFactory = () => {
  const {
    getValue: getComment,
    setValue: setComment,
    watchValue: watchComment,
  } = Watcher<string>('');

  return {
    getComment,
    setComment,
    watchComment,
  };
};

export default CommentService;
