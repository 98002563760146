import { RapoConfigurationDTO } from '@cvfm-front/tefps-types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useRecourseConfiguration = (): RapoConfigurationDTO | null => {
  return useWatcher(
    services.recourseConfiguration.watchRecourseConfiguration,
    services.recourseConfiguration.getRecourseConfiguration()
  );
};

export default useRecourseConfiguration;
