import { Setter, WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import { FnmsParkingDetailResultV1DTO } from '@cvfm-front/tefps-types';
import { fetchParkingDetail } from 'api/pricing';

interface ParkingDetailServiceFactory {
  (): ParkingDetailServiceInterface;
}

export interface ParkingDetailServiceInterface {
  fetchStepsForProduct: (
    productId: string,
    plate: string,
    startDatetime: string,
    zoneId: string
  ) => Promise<FnmsParkingDetailResultV1DTO>;
  getParkingDetail: Setter<FnmsParkingDetailResultV1DTO | null>;
  watchParkingDetail: WatchFunctionType<FnmsParkingDetailResultV1DTO | null>;
}

const ParkingDetailService: ParkingDetailServiceFactory = () => {
  const {
    watchValue: watchParkingDetail,
    setValue: setParkingDetail,
    getValue: getParkingDetail,
  } = Watcher<FnmsParkingDetailResultV1DTO | null>(null);

  const fetchStepsForProduct = async (
    productId: string,
    plate: string,
    startDatetime: string,
    zoneId: string
  ): Promise<FnmsParkingDetailResultV1DTO> => {
    const response = await fetchParkingDetail(
      productId,
      plate,
      startDatetime,
      zoneId
    );

    setParkingDetail(response);
    return response;
  };

  return { fetchStepsForProduct, getParkingDetail, watchParkingDetail };
};

export default ParkingDetailService;
