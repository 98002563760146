import { ControlSearchResultDTO } from 'api/control/types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useControls = (): ControlSearchResultDTO | null => {
  return useWatcher(
    services.controls.watchControls,
    services.controls.getControls()
  );
};
export default useControls;
