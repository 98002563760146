import React, { CSSProperties } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { DropDownMenuProps } from 'material-ui';

import { STYLE_BLOCK_TITLE, STYLE_SELECT_TEXT } from '../theme';

const STYLE_BLOCK: CSSProperties = {
  marginTop: 20,
};

export type SelectOption = {
  key: string | undefined;
  label: string;
};

type Props = {
  id: string;
  title?: string;
  floatingLabelText?: string;
  options: Array<SelectOption>;
  multiple?: boolean;
  disabled?: boolean;
  selected: (string | null | undefined) | Array<string>;
  onChange: (
    id: string,
    value: (string | null | undefined) | Array<string>
  ) => void;
  textStyle?: React.CSSProperties;
  underlineStyle?: React.CSSProperties;
  optionStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  dropDownMenuProps?: DropDownMenuProps;
};

class Select extends React.Component<Props> {
  onChange = (
    event: any,
    key: any,
    value: (string | null | undefined) | Array<string>
  ) => {
    const { onChange, id } = this.props;
    onChange(id, value);
  };

  render() {
    const {
      title,
      selected,
      multiple,
      floatingLabelText,
      options,
      disabled,
      textStyle,
      underlineStyle,
      optionStyle,
      iconStyle,
      dropDownMenuProps,
    } = this.props;

    return (
      <div style={STYLE_BLOCK}>
        {title && <div style={STYLE_BLOCK_TITLE}>{title}</div>}
        <SelectField
          multiple={multiple}
          floatingLabelText={floatingLabelText}
          disabled={disabled}
          labelStyle={{ ...STYLE_SELECT_TEXT, ...textStyle }}
          value={selected || ''}
          onChange={this.onChange}
          style={{ width: '100%' }}
          underlineStyle={underlineStyle}
          iconStyle={iconStyle}
          dropDownMenuProps={dropDownMenuProps}
        >
          {options.map(option => (
            <MenuItem
              key={option.key}
              insetChildren={multiple}
              checked={
                !!(
                  multiple &&
                  Array.isArray(selected) &&
                  option.key &&
                  selected.includes(option.key)
                )
              }
              value={option.key}
              primaryText={option.label}
              style={optionStyle}
            />
          ))}
        </SelectField>
      </div>
    );
  }
}

export default Select;
