import TextField from 'material-ui/TextField';
import React, { CSSProperties } from 'react';

import { BKG_GREYBLUE } from 'theme';

import { STYLE_BLOCK_TITLE } from '../theme';

const STYLE_BLOCK: CSSProperties = {
  marginTop: 20,
};

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  color: 'white',
  fontFamily: 'Roboto',
  marginLeft: 5,
};

const STYLE_FIELD: CSSProperties = {
  backgroundColor: BKG_GREYBLUE,
  height: 27,
  lineHeight: '22px',
  marginTop: 5,
  paddingRight: 10,
  width: '100%',
};

const STYLE_UNDERLINE_TEXT: CSSProperties = {
  color: BKG_GREYBLUE,
  marginTop: 3,
};

type Props = {
  id: string;
  title: string;
  placeholder: string;
  value: string;
  onChange: (id: string, value: string) => void;
  underlineText?: string;
};

class Input extends React.Component<Props> {
  onChange = (event: any, value: string) => {
    const { id, onChange } = this.props;
    onChange(id, value);
  };

  render() {
    const { placeholder, value, title, underlineText = '' } = this.props;
    return (
      <div style={STYLE_BLOCK}>
        <div style={STYLE_BLOCK_TITLE}>{title}</div>
        <TextField
          name={placeholder}
          underlineShow={false}
          errorText={underlineText}
          errorStyle={STYLE_UNDERLINE_TEXT}
          inputStyle={STYLE_TEXT}
          textareaStyle={STYLE_TEXT}
          value={value}
          onChange={this.onChange}
          style={STYLE_FIELD}
        />
      </div>
    );
  }
}

export default Input;
