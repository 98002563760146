import services from 'commons/services';

import useWatcher from './useWatcher';

const useHiddenPatrolZoneIds = (): Array<string> => {
  return useWatcher(
    services.patrolZoneService.watchHiddenPatrolZoneIds,
    services.patrolZoneService.getHiddenPatrolZoneIds()
  );
};

export default useHiddenPatrolZoneIds;
