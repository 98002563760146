import { ControlSearchCriterias } from 'tefps/Control/List/types';
import services from 'commons/services';
import { initialFilters } from 'tefps/Control/List/duck';

import useWatcher from './useWatcher';

const useControlSearchCriterias = (): ControlSearchCriterias => {
  return useWatcher(services.controls.watchFilters, initialFilters());
};

export default useControlSearchCriterias;
