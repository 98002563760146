import React, { CSSProperties } from 'react';

import DateFilter, { DatepickerClass } from 'commons/DateFilter';
import { STYLE_TITLE_BORDER } from 'theme';

import { STYLE_BLOCK_TITLE } from '../theme';

require('intl/locale-data/jsonp/fr');

const STYLE_BLOCK: CSSProperties = {
  marginTop: 20,
};

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  marginRight: 10,
};

export interface FilterDate {
  from: Date | undefined;
  to: Date | undefined;
}

export interface ExtendedFilterDate extends FilterDate {
  dateRangeAbortedAt?: string;
}

export type FormattedFilterDate = {
  from: string | null | undefined;
  to: string | null | undefined;
};

type Props = {
  id: string;
  title: string;
  onChange: (id: string, filters: FilterDate) => void;
  dates: FilterDate;
  periodSelectionEnabled?: boolean | null | undefined;
  secondaryColor?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  direction?: 'row' | 'column';
  datePickerClass?: DatepickerClass;
};

class Dates extends React.Component<Props> {
  getDefaultProps() {
    return {
      secondaryColor: false,
    };
  }

  onChange = (filters: FilterDate) => {
    const { id, onChange } = this.props;
    onChange(id, filters);
  };

  render(): React.ReactNode {
    const {
      title,
      dates,
      periodSelectionEnabled,
      secondaryColor,
      minDate,
      maxDate,
      direction = 'row',
      datePickerClass = DatepickerClass.sidebar,
    } = this.props;

    return (
      <div style={STYLE_BLOCK}>
        <div style={STYLE_BLOCK_TITLE}>
          {title}
          <hr style={STYLE_TITLE_BORDER} />
        </div>
        <DateFilter
          styleText={STYLE_TEXT}
          dateDirection={direction}
          datepickerClass={datePickerClass}
          colorResetButton={secondaryColor ? 'black' : 'white'}
          dates={dates}
          periodSelectionEnabled={periodSelectionEnabled}
          onChange={this.onChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    );
  }
}

export default Dates;
