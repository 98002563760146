import React from 'react';
import DatePicker from 'material-ui/DatePicker';

interface DatePickerProps {
  // <TextField/> is the element that get the 'other' properties
  DateTimeFormat?: typeof Intl.DateTimeFormat;
  autoOk?: boolean;
  cancelLabel?: React.ReactNode;
  container?: 'dialog' | 'inline';
  defaultDate?: Date;
  dialogContainerStyle?: React.CSSProperties;
  disableYearSelection?: boolean;
  disabled?: boolean;
  firstDayOfWeek?: number;
  formatDate?(date: Date): string;
  locale?: string;
  maxDate?: Date;
  minDate?: Date;
  mode?: 'portrait' | 'landscape';
  okLabel?: React.ReactNode;
  onChange?(e: any, date: Date): void; // e is always null
  onDismiss?(): void;
  onFocus?: React.FocusEventHandler<{}>;
  onShow?(): void;
  onClick?: React.TouchEventHandler<{}>;
  shouldDisableDate?(day: Date): boolean;
  style?: React.CSSProperties;
  textFieldStyle?: React.CSSProperties;
  value?: Date;

  // From <TextField />
  className?: string;
  defaultValue?: string;
  errorStyle?: React.CSSProperties;
  errorText?: React.ReactNode;
  floatingLabelStyle?: React.CSSProperties;
  floatingLabelText?: React.ReactNode;
  fullWidth?: boolean;
  hideCalendarDate?: boolean;
  hintStyle?: React.CSSProperties;
  hintText?: React.ReactNode;
  id?: string;
  inputStyle?: React.CSSProperties;
  onBlur?: React.FocusEventHandler<{}>;
  onKeyDown?: React.KeyboardEventHandler<{}>;
  openToYearSelection?: boolean;
  rows?: number;
  rowsMax?: number;
  name?: string;
  type?: string;
  underlineDisabledStyle?: React.CSSProperties;
  underlineFocusStyle?: React.CSSProperties;
  underlineShow?: boolean;
  underlineStyle?: React.CSSProperties;
}

const BoDatePicker = (props: DatePickerProps): JSX.Element => {
  return <DatePicker {...props} />;
};

export default BoDatePicker;
