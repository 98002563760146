import { ClientAppLightDTO } from 'api/clientapp/types';

export const buildClientAppOptions = (
  clientApps: Array<ClientAppLightDTO>
): Array<{ value: string; label: string }> => {
  return clientApps.map(clientApp => ({
    value: clientApp.id,
    label: clientApp.name,
  }));
};
