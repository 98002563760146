import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { fetchRecourse } from 'api/recourse';
import { RapoDetailDTO, RecourseDTO } from 'api/recourse/types';

export interface RecourseServiceFactory {
  (): RecourseServiceInterface;
}

export interface RecourseServiceInterface {
  init: (id: string) => Promise<void>;
  getRecourse: () => RapoDetailDTO | null;
  watchRecourse: WatchFunctionType<RapoDetailDTO | null>;
  applyRecourseChanges: (recourse: RecourseDTO) => void;
}

const RecourseService: RecourseServiceFactory = () => {
  const {
    getValue: getRecourse,
    setValue: setRecourse,
    watchValue: watchRecourse,
  } = Watcher<null | RapoDetailDTO>(null);

  const init = async (id: string): Promise<void> => {
    const recourse = await fetchRecourse(id);
    setRecourse(recourse);
  };

  const applyRecourseChanges = (recourse: RecourseDTO): void => {
    const recourseDetails = getRecourse();
    if (recourseDetails) {
      recourseDetails.recourse = {
        ...recourseDetails.recourse,
        recourse,
      } as RecourseDTO;
      setRecourse(recourseDetails);
    }
  };

  return {
    init,
    getRecourse,
    watchRecourse,
    applyRecourseChanges,
  };
};

export default RecourseService;
