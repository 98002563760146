import React from 'react';

import BoButton from 'facade/BoButton';

const { _tg } = window.loadTranslations();

export function getActions(
  onSubmit: () => Promise<void> | void,
  onClose: () => void,
  disabled?: boolean
) {
  return [
    <BoButton
      style={{ marginRight: 10 }}
      key={1}
      label={_tg('action.cancel')}
      onClick={onClose}
    />,
    <BoButton
      key={2}
      label={_tg('action.validate')}
      primary
      disabled={disabled}
      onClick={onSubmit}
    />,
  ];
}
