import services from 'commons/services';
import { PaymentDTO } from 'tefps/Fps/Detail/helpers';

import useWatcher from './useWatcher';

const useFpsPayments = (): PaymentDTO[] => {
  return useWatcher(
    services.fpsPayments.watchFpsPayments,
    services.fpsPayments.getFpsPayments()
  );
};

export default useFpsPayments;
