export type FieldKeyAddress = {
  streetNumber: 'streetNumber';
  streetNumberBis: 'streetNumberBis';
  streetType: 'streetType';
  streetName: 'streetName';
  specialPlace: 'complement';
  postalCode: 'postalCode';
  addressLocality: 'locality';
  addressCountry: 'addressCountry';
};

export const fieldKeyAddress: FieldKeyAddress = {
  streetNumber: 'streetNumber',
  streetNumberBis: 'streetNumberBis',
  streetType: 'streetType',
  streetName: 'streetName',
  specialPlace: 'complement',
  postalCode: 'postalCode',
  addressLocality: 'locality',
  addressCountry: 'addressCountry',
};
