import React, { useEffect, useState } from 'react';

import './css/UpDownArrow.css';

const { _tg } = window.loadTranslations(__filename);

type UpDownArrowProps = {
  isCollapsed: boolean;
  setIsCollapsed: (isUp: boolean) => void;
  className?: string;
  color?: string;
  size?: string;
  isUpAndDownIcon?: boolean;
  title?: string;
};

export const UpDownArrow = ({
  isCollapsed,
  setIsCollapsed,
  className,
  isUpAndDownIcon,
  color = 'white',
  size = '15px',
  title,
}: UpDownArrowProps) => {
  const [customTitle, setCustomTitle] = useState<string>(title || '');

  useEffect(() => {
    if (!title && isUpAndDownIcon !== undefined) {
      setCustomTitle(
        isCollapsed ? _tg('action.collapseAll') : _tg('action.expandAll')
      );
    } else if (!title) {
      setCustomTitle(
        isCollapsed ? _tg('action.collapse') : _tg('action.expand')
      );
    }
  }, [isCollapsed]);

  const onChange = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getIcon = () => {
    const downArrow =
      'M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3L86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z';
    const upArrow =
      'M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7L86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z';

    if (isUpAndDownIcon !== undefined) {
      const h = 140;
      return (
        <>
          <path
            fill={color}
            stroke={color}
            d={isCollapsed ? upArrow : downArrow}
            transform={`translate(0, -${h})`}
          />
          <path
            fill={color}
            stroke={color}
            transform={`translate(0, ${h})`}
            d={isCollapsed ? downArrow : upArrow}
          />
        </>
      );
    }
    return (
      <path fill={color} stroke={color} d={isCollapsed ? upArrow : downArrow} />
    );
  };

  return (
    <span title={customTitle}>
      <svg
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        height={size}
        width={size}
        onClick={onChange}
        className={className}
        style={{ cursor: 'pointer', minWidth: size, minHeight: size }}
      >
        {getIcon()}
      </svg>
    </span>
  );
};
