import services from 'commons/services';
import { RecourseSearchResultDTO } from 'api/recourse/types';

import useWatcher from './useWatcher';

const useRecourseSearchResult = (): RecourseSearchResultDTO | null => {
  return useWatcher(
    services.recourseListService.watchRecourseSearchResult,
    services.recourseListService.getRecourseSearchResult()
  );
};

export default useRecourseSearchResult;
