import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';
import { Scrollbars } from 'react-custom-scrollbars';
import { AutoSizer } from 'react-virtualized';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import Edit from 'material-ui/svg-icons/editor/mode-edit';
import Add from 'material-ui/svg-icons/content/add-box';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import DeleteForeverIcon from 'material-ui/svg-icons/action/delete-forever';
import _cloneDeep from 'lodash.clonedeep';
import Dialog from 'material-ui/Dialog';

import BoButton from 'facade/BoButton';
import { BKG_BLUE, STYLE_TITLE_BORDER, TXT_GREY } from 'theme';
import ConfirmAction from 'commons/ConfirmAction';
import { PolygonForMap } from 'api/pricing/types';
import {
  PricingDTO,
  ProductPrivateDTO,
  ProductSearchQueryDTO,
  ZoneDTO,
  ZoningDTO,
} from '@cvfm-front/tefps-types';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import {
  exportParkingMeter,
  getParkingMetersSummaryPdfUrl,
} from 'api/parkingMeter';
import { openNewAuthentifiedTab } from 'api/helpers';
import { ParkingMeterDTO } from 'api/parkingMeter/type';
import downloadFile from 'commons/DownloadFile';
import { searchProducts } from 'api/cvfm-core-subscription/product';
import { fetchPricingByZoneId } from 'api/pricing';
import { NotificationService } from '@cvfm-front/commons-services';

import Autocomplete from '../SidebarV2/Components/Autocomplete';

import ShowParkingMeter from './ParkingMeters/ShowParkingMeter';
import ZoneItem from './ZoneItem';
import AddZone from './AddZone';
import AddPolygon from './AddPolygon';
import ImportPolygons from './ImportPolygons';
import { PolygonMetadata, ZoningNames } from './types';
import { exportZoning } from './helpers';
import ImportParkingMeter from './ParkingMeters/ImportParkingMeter';
import { RemoveZoneError } from './RemoveZoneError';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_SIDEBAR: CSSProperties = {
  width: 365,
  height: '100%',
  backgroundColor: BKG_BLUE,
  display: 'flex',
  fontFamily: 'Roboto, sans-serif',
  color: TXT_GREY,
};

const STYLE_BOTTOM_BUTTON: CSSProperties = {
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  height: '96%',
};

const STYLE_CENTER: CSSProperties = {
  display: 'flex',
  margin: 'auto',
  alignItems: 'center',
  alignContent: 'center',
};

const STYLE_BLOCK_TITLE: CSSProperties = {
  fontSize: 15,
  marginBottom: '20px',
  marginTop: '8px',
  fontWeight: 'bold',
  display: 'flex',
  color: TXT_GREY,
};

const STYLE_CONTENT: CSSProperties = {
  width: '100%',
};

const STYLE_EDIT_WRAPPER: CSSProperties = {
  flex: 1,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  marginBottom: 15,
};
const STYLE_EDIT: CSSProperties = {
  width: 20,
  height: 20,
  cursor: 'pointer',
  color: '#ddd',
};

const STYLE_SEPARATOR: CSSProperties = {
  margin: '7px',
};

const computeHiddenPolygonIds = (
  zones: Array<ZoneDTO>,
  hiddenZones: Set<string>
) => {
  const hiddenPolygons = new Set();
  zones
    .filter(zone => hiddenZones.has(zone.id))
    .forEach(z => z.polygonIds.forEach(pId => hiddenPolygons.add(pId)));

  // On ne masque pas les polygones qui appartiennent aussi à des zones visibles
  zones
    .filter(zone => !hiddenZones.has(zone.id))
    .forEach(z => z.polygonIds.forEach(pId => hiddenPolygons.delete(pId)));

  return hiddenPolygons;
};

type ZoningSidebarProps = {
  hasChanged: boolean;
  zoning: ZoningDTO;
  selectedPolygonId: string | null | undefined;
  selectParkingMeterNumber: (number: string) => void;
  hiddenZones: Set<string>; // liste des id des zones masquées pour ne pas proposer leurs polygones dans la liste déroulante
  userInfo: InternalAgent;
  onSave: (zoning: ZoningDTO) => Promise<void>;
  newPolygon: (id: string, name: string, zoneIds: Array<string>) => void;
  showZone: (id: string) => void;
  hideZone: (id: string) => void;
  highlightPolygon: (id: string) => void;
  deletePolygon: (id: string) => void;
  updateZoningFromSidebar: (zoningDTO: ZoningDTO) => void;
  addPolygons: (polygons: Array<PolygonForMap>) => void;
  naming: ZoningNames;
  hasCityParkingMeters: boolean;
  enableParkingMeter: boolean;
  canShowParkingMeter: boolean;
  changeParkingMeterVisibility: () => void;
  deleteCityParkingMeters: () => void;
  detectImportParkingMeters: () => void;
  organizationFilterEnabled?: boolean;
  parkingMeters: Array<ParkingMeterDTO> | null;
};

type ZoningSidebarState = {
  addZone: number;
  editPolygon: number; // 1 si création, 2 si édition, 0 sinon
  existingZone: ZoneDTO | null | undefined;
  message: string | null | undefined;
  polygonList: Array<{ id: string; name: string }>;
  parkingMeterSearchList: Array<{ id: string; name: string }> | undefined;
  canWrite: boolean;
  canReadParkingMeters: boolean;
  canWriteParkingMeters: boolean;
  openDeleteMenuId: string | null | undefined;
  openPolygonsDeletionModal: boolean;
  productsUsingZone: Array<ProductPrivateDTO>;
  pricingUsingZone: Array<PricingDTO>;
  showRemoveZoneError: boolean;
};

class ZoningSidebar extends React.Component<
  ZoningSidebarProps,
  ZoningSidebarState
> {
  constructor(props: ZoningSidebarProps) {
    super(props);
    this.state = {
      addZone: 0,
      editPolygon: 0,
      existingZone: null,
      message: null,
      polygonList: this.computePolygonList(props.zoning, props.hiddenZones),
      parkingMeterSearchList: this.initializeParkingMeterSearchList(
        props.parkingMeters
      ),

      canWrite: props.userInfo.rights.includes('ZONING_WRITE'),
      canReadParkingMeters: props.userInfo.rights.includes(
        'PARKING_METER_READ'
      ),
      canWriteParkingMeters: props.userInfo.rights.includes(
        'PARKING_METER_WRITE'
      ),
      openDeleteMenuId: null,
      openPolygonsDeletionModal: false,
      productsUsingZone: [],
      pricingUsingZone: [],
      showRemoveZoneError: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: ZoningSidebarProps) {
    const { zoning, hiddenZones } = nextProps;
    const polygonList = this.computePolygonList(zoning, hiddenZones);
    this.setState({ polygonList });
  }

  onClickHideZone = (zoneId: string) => {
    const { hideZone } = this.props;
    hideZone(zoneId);
  };

  onClickShowZone = (zoneId: string) => this.props.showZone(zoneId);

  computePolygonList = (zoning: ZoningDTO, hiddenZones: Set<string>) => {
    const hiddenPolygonIds = computeHiddenPolygonIds(zoning.zones, hiddenZones);
    return zoning.polygons
      .filter(polygon => !hiddenPolygonIds.has(polygon.id))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ id, name }) => ({ id, name }));
  };

  initializeParkingMeterSearchList = (
    parkingMeters: Array<ParkingMeterDTO> | null
  ) => {
    return parkingMeters?.map(pm => ({ id: pm.number, name: pm.number }));
  };

  computeParkingMeterSearchList = (
    parkingMeters: Array<ParkingMeterDTO> | null
  ) => {
    return this.setState(oldState => {
      return {
        ...oldState,
        parkingMeterSearchList: parkingMeters?.map(pm => ({
          id: pm.number,
          name: pm.number,
        })),
      };
    });
  };

  close = () =>
    this.setState({
      addZone: 0,
      existingZone: null,
      editPolygon: 0,
      message: null,
    });
  newZone = () => this.setState({ addZone: 1, existingZone: null });
  editZone = (zone: ZoneDTO) => {
    this.setState({ addZone: 2, existingZone: zone });
  };

  selectPolygon = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    value: string
  ) => this.props.highlightPolygon(value);

  searchParkingMeter = (number: string, search: string | null | undefined) => {
    return this.props.selectParkingMeterNumber(search || '');
  };
  canDeleteZone = async (zoneId: string): Promise<void> => {
    const searchQueryDTO = {
      activeOnly: true,
      deletingZone: true,
      zoneIdCheck: zoneId,
      professionalZoneIdCheck: zoneId,
    } as ProductSearchQueryDTO;
    const products = await searchProducts(searchQueryDTO);
    const pricings = await fetchPricingByZoneId(zoneId);
    this.setState({ productsUsingZone: products, pricingUsingZone: pricings });
    if (
      this.state.productsUsingZone.length > 0 ||
      this.state.pricingUsingZone.length > 0
    )
      this.setState({ showRemoveZoneError: true });
  };

  closeRemoveZoneError = () => this.setState({ showRemoveZoneError: false });

  deleteZone = async (zoneId: string, deletePolygonsOfTheZone: boolean) => {
    this.setState({ openDeleteMenuId: null });
    try {
      await this.canDeleteZone(zoneId);
    } catch (e) {
      NotificationService.pushNotification({
        id: 'deleteZoneError',
        message: _tg('field.error'),
        type: 'error',
      });
    }
    if (
      this.state.productsUsingZone.length === 0 &&
      this.state.pricingUsingZone.length === 0
    ) {
      const { updateZoningFromSidebar } = this.props;
      const zoning = _cloneDeep(this.props.zoning);

      const nextZones = zoning.zones.filter(z => z.id !== zoneId);
      if (deletePolygonsOfTheZone) {
        const currentZone = zoning.zones.find(z => z.id === zoneId);
        if (currentZone) {
          // On supprime tous les polygones de la zone supprimée
          const polygonIdsToRemove = new Set(currentZone.polygonIds);
          // sauf ceux qui appartiennent à d'autres zones
          nextZones.forEach(({ polygonIds }) =>
            polygonIds.forEach(id => polygonIdsToRemove.delete(id))
          );
          zoning.polygons = zoning.polygons.filter(
            p => !polygonIdsToRemove.has(p.id)
          );
        }
      }
      zoning.zones = nextZones;
      updateZoningFromSidebar(zoning);
    }
  };

  saveZone = (zone: ZoneDTO) => {
    const zoning = _cloneDeep(this.props.zoning);
    const { updateZoningFromSidebar } = this.props;
    const {
      id,
      name,
      color,
      secondaryColor,
      virtual,
      alias,
      organizationId,
    } = zone;
    const foundZone = zoning.zones.find(z => z.id === id);
    if (foundZone) {
      foundZone.name = name;
      foundZone.color = color;
      foundZone.secondaryColor = secondaryColor;
      foundZone.alias = alias;
      foundZone.virtual = virtual;
      foundZone.organizationId = organizationId;
    } else {
      zoning.zones.push({
        id,
        name,
        color,
        secondaryColor,
        polygonIds: [],
        alias,
        virtual,
        lastModified: Date.now().toString(),
        organizationId,
      });
    }
    this.setState({ addZone: 0 });
    updateZoningFromSidebar(zoning);
  };

  createPolygon = () => this.setState({ editPolygon: 1, message: null });
  addPolygonOnMap = (p: PolygonMetadata) => {
    const { newPolygon } = this.props;
    newPolygon(p.id, p.name, p.zoneIds);
    this.setState({
      editPolygon: 0,
      message: _t('field.helpMessage'),
    });
  };

  editPolygon = () => {
    if (this.props.selectedPolygonId) {
      this.setState({ editPolygon: 2, message: null });
    }
  };

  changePolygon = (p: PolygonMetadata) => {
    const zoning = _cloneDeep(this.props.zoning);
    // updates list of polygons in zones
    zoning.zones.forEach(z => {
      // si le polygon est n'est pas dans la zone alors qu'il le devrait, on l'ajoute
      const present = z.polygonIds.includes(p.id);
      if (!present && p.zoneIds.includes(z.id)) {
        z.polygonIds.push(p.id);
      } else if (present && !p.zoneIds.includes(z.id)) {
        // sinon, il ne devrait pas être dans la zone
        z.polygonIds = z.polygonIds.filter(pId => pId !== p.id);
      }
    });
    zoning.polygons = zoning.polygons.map(poly =>
      poly.id === p.id
        ? {
            id: p.id,
            name: p.name,
            zoneIds: p.zoneIds,
            points: [],
          }
        : poly
    );
    this.setState({ editPolygon: 0, message: null });
    this.props.updateZoningFromSidebar(zoning);
  };

  deletePolygon = () => {
    const {
      selectedPolygonId,
      deletePolygon,
      updateZoningFromSidebar,
    } = this.props;
    const zoning = _cloneDeep(this.props.zoning);
    if (selectedPolygonId) {
      // delete the polygon
      deletePolygon(selectedPolygonId);
      zoning.polygons = zoning.polygons.filter(p => p.id !== selectedPolygonId);
      // delete reference to this polygon in zones
      zoning.zones
        .filter(z => z.polygonIds.includes(selectedPolygonId))
        .forEach(z => {
          z.polygonIds = z.polygonIds.filter(pId => pId !== selectedPolygonId);
        });
      updateZoningFromSidebar(zoning);
    }
  };

  deleteAllPolygons = () => {
    const zoning = _cloneDeep(this.props.zoning);
    zoning.polygons = [];
    this.props.updateZoningFromSidebar(zoning);
    this.setState({ openPolygonsDeletionModal: false });
  };

  confirmPolygonsDeletionModal = () => {
    this.setState({ openPolygonsDeletionModal: true });
  };

  save = async () => {
    await this.props.onSave(this.props.zoning);
    this.setState({ message: null });
  };

  exportZoningToGeoJson = () => {
    const fileName = `exportZones_${new Date().getTime().toString()}.geojson`;
    const file = new File(
      [JSON.stringify(exportZoning(this.props.zoning), undefined, 2)],
      fileName,
      { type: 'application/json;charset=utf-8' }
    );
    FileSaver.saveAs(file, fileName);
  };

  updateOpenDeleteMenuId = (openDeleteMenuId: string | null | undefined) =>
    this.setState({ openDeleteMenuId });

  // eslint-disable-next-line @typescript-eslint/require-await
  updateMatchingParkingMeters = async (parkingMeterNumber: string) => {
    const { parkingMeters } = this.props;
    let filteredParkingMeters: Array<ParkingMeterDTO> = [];
    if (parkingMeterNumber) {
      filteredParkingMeters = (parkingMeters || []).filter(
        pm => pm.number && pm.number.startsWith(parkingMeterNumber)
      );
    }
    this.computeParkingMeterSearchList(filteredParkingMeters);
  };

  render() {
    const {
      zoning,
      addPolygons,
      selectedPolygonId,
      hasChanged,
      naming,
      enableParkingMeter,
      canShowParkingMeter,
      changeParkingMeterVisibility,
      deleteCityParkingMeters,
      detectImportParkingMeters,
      hasCityParkingMeters,
      organizationFilterEnabled,
      hiddenZones,
    } = this.props;
    const {
      addZone,
      editPolygon,
      existingZone,
      message,
      polygonList,
      parkingMeterSearchList,
      canWrite,
      canReadParkingMeters,
      canWriteParkingMeters,
      openDeleteMenuId,
      openPolygonsDeletionModal,
      productsUsingZone,
      pricingUsingZone,
      showRemoveZoneError,
    } = this.state;

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{ ...STYLE_CENTER, ...STYLE_BOTTOM_BUTTON }}>
          <div style={STYLE_CONTENT}>
            {enableParkingMeter &&
              hasCityParkingMeters &&
              canReadParkingMeters && (
                <>
                  <ShowParkingMeter
                    canShowParkingMeter={canShowParkingMeter}
                    changeParkingMeterVisibility={changeParkingMeterVisibility}
                    canDeleteParkingMeters={canWriteParkingMeters}
                    deleteCityParkingMeters={deleteCityParkingMeters}
                  />
                  <Autocomplete
                    id="seletedParkingMeterNumber"
                    title={_t('element.searchParkingMeter')}
                    options={
                      parkingMeterSearchList !== undefined
                        ? parkingMeterSearchList
                        : []
                    }
                    onChange={this.searchParkingMeter}
                    onAutocomplete={this.updateMatchingParkingMeters}
                    search=""
                  />
                </>
              )}
            <div style={STYLE_BLOCK_TITLE}>
              {_t('element.zoneManagement.title', {
                name: naming.name.toLowerCase(),
              })}
              <hr style={STYLE_TITLE_BORDER} />
            </div>
            <div style={{ marginBottom: 10 }}>
              {zoning.zones.map(zone => (
                <ZoneItem
                  key={zone.id}
                  hidden={hiddenZones.has(zone.id)}
                  menuOpen={openDeleteMenuId === zone.id}
                  notifyDeleteMenuOpen={this.updateOpenDeleteMenuId}
                  zone={zone}
                  show={this.onClickShowZone}
                  hide={this.onClickHideZone}
                  edit={this.editZone}
                  deleteZone={this.deleteZone}
                  canWrite={canWrite}
                  naming={naming}
                />
              ))}
            </div>
            {canWrite && (
              <div style={{ marginBottom: 10 }}>
                <span
                  title={_t('element.zoneManagement.canWrite.title', {
                    withArticler: naming.withArticle,
                  })}
                  style={{ right: 0 }}
                >
                  <Add
                    style={{
                      color: 'white',
                      marginLeft: 0,
                      width: 60,
                      height: 30,
                      cursor: 'pointer',
                    }}
                    onClick={this.newZone}
                  />
                </span>
                <AddZone
                  isOpen={addZone}
                  close={this.close}
                  existingZone={existingZone}
                  save={this.saveZone}
                  naming={naming}
                  organizationFilterEnabled={organizationFilterEnabled}
                />
                <RemoveZoneError
                  isOpen={showRemoveZoneError}
                  close={this.closeRemoveZoneError}
                  products={productsUsingZone}
                  pricings={pricingUsingZone}
                />
              </div>
            )}

            <span style={STYLE_BLOCK_TITLE}>
              {_t('element.polygonManagement.title')}
              <hr style={STYLE_TITLE_BORDER} />
            </span>
            <div style={{ display: 'flex' }}>
              <SelectField
                value={selectedPolygonId}
                style={{ width: '70%' }}
                onChange={this.selectPolygon}
                floatingLabelText={_t(
                  'element.polygonManagement.select.floatingLabelText'
                )}
                floatingLabelStyle={{ color: '#aaa' }}
                labelStyle={{ color: '#fff', fontSize: 14 }}
              >
                {polygonList.map(({ id, name }) => (
                  <MenuItem key={id} value={id} primaryText={name || id} />
                ))}
              </SelectField>
              {canWrite && (
                <div
                  title={_tg('action.modify').toLowerCase()}
                  style={STYLE_EDIT_WRAPPER}
                >
                  <Edit style={STYLE_EDIT} onClick={this.editPolygon} />
                </div>
              )}
              {canWrite && (
                <ConfirmAction
                  action={this.deletePolygon}
                  styleWrapper={{
                    ...STYLE_EDIT_WRAPPER,
                    marginBottom: 12,
                  }}
                  message={_t('element.polygonManagement.confirm.message')}
                  enabled={selectedPolygonId != null}
                >
                  <DeleteIcon style={{ ...STYLE_EDIT, color: '#aaa' }} />
                </ConfirmAction>
              )}
            </div>
            {canWrite && (
              <div>
                <div style={{ display: 'flex' }}>
                  <div title={_t('element.addPolygon.title')}>
                    <Add
                      style={{
                        color: 'white',
                        marginLeft: 0,
                        height: 30,
                        width: 30,
                        cursor: 'pointer',
                      }}
                      onClick={this.createPolygon}
                    />
                  </div>
                  {zoning.polygons.length !== 0 && (
                    <div title={_t('element.deleteAllPolygons.title')}>
                      <DeleteForeverIcon
                        style={{
                          color: 'white',
                          marginLeft: 0,
                          width: 60,
                          height: 30,
                          cursor: 'pointer',
                        }}
                        onClick={this.confirmPolygonsDeletionModal}
                      />
                    </div>
                  )}
                </div>
                <AddPolygon
                  message={message}
                  isOpen={editPolygon}
                  zoning={zoning}
                  close={this.close}
                  save={this.addPolygonOnMap}
                  edit={this.changePolygon}
                  selectedPolygonId={selectedPolygonId}
                  naming={naming}
                />
                <br />
                <ImportPolygons addPolygons={addPolygons} zoning={zoning} />
              </div>
            )}
            <div style={{ ...STYLE_CENTER, marginTop: 12 }}>
              <BoButton
                key={3}
                fullWidth
                primary
                label={_t('element.exportGeoJson.label')}
                onClick={this.exportZoningToGeoJson}
              />
            </div>
            {canWrite && (
              <div>
                <br />
                <br />
                <hr style={STYLE_TITLE_BORDER} />
                <div style={STYLE_CENTER}>
                  <BoButton
                    key={2}
                    label={_tg('action.save_1')}
                    primary
                    fullWidth
                    onClick={this.save}
                    disabled={!hasChanged}
                  />
                </div>
              </div>
            )}
            {canWriteParkingMeters && enableParkingMeter && (
              <>
                <br style={STYLE_SEPARATOR} />
                <ImportParkingMeter
                  detectImportParkingMeters={detectImportParkingMeters}
                />
              </>
            )}
            {enableParkingMeter && hasCityParkingMeters && (
              <>
                <br style={STYLE_SEPARATOR} />
                <BoButton
                  key="export-parking-meter"
                  fullWidth
                  primary
                  label={_tg('tefps.pricing.zoning.exportParkingMeters')}
                  onClick={async _ => {
                    const exportResponse = await exportParkingMeter();
                    const content = await exportResponse.blob();
                    downloadFile(new File([content], 'export_horodateur.csv'));
                  }}
                />
                <br style={STYLE_SEPARATOR} />
              </>
            )}
            {enableParkingMeter && hasCityParkingMeters && (
              <>
                <br style={STYLE_SEPARATOR} />
                <BoButton
                  key={4}
                  fullWidth
                  primary
                  label={_t('element.parkingMetersSummary.label')}
                  onClick={_e =>
                    openNewAuthentifiedTab(getParkingMetersSummaryPdfUrl())
                  }
                />
              </>
            )}
          </div>
        </div>
        <Dialog
          actions={[
            <BoButton
              style={{ marginRight: 10 }}
              key={1}
              label={_tg('action.cancel')}
              onClick={() => {
                this.setState({ openPolygonsDeletionModal: false });
              }}
            />,
            <BoButton
              key={2}
              label={_tg('action.confirm')}
              onClick={this.deleteAllPolygons}
              primary
            />,
          ]}
          title={_t('element.deleteAllPolygons.confirmationModal.header')}
          open={openPolygonsDeletionModal}
          onRequestClose={() => {
            this.setState({ openPolygonsDeletionModal: false });
          }}
        />
      </div>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(ZoningSidebar);
