import moment from 'moment';

import { MailGroup } from 'api/mail/type';
import {
  MailType,
  MailTypeOrder,
  MailTypeRAPO,
  MailTypeRefund,
  MailTypeSubscriber,
  MailTypeSubscriberMassAction,
} from '@cvfm-front/tefps-types';
import { unreachable } from 'commons/Enums';
import { Config } from 'config/duck';

const { _t, _tg } = window.loadTranslations(__filename);

type Hint = {
  field: string;
  legend: string | null | undefined;
  required?: boolean;
};

export type HintList = Array<Hint>;

export const getHintList = (
  city: Config,
  type: MailType
): Array<{
  field: string;
  legend: string;
  required?: boolean;
  testValue?: string;
}> => {
  switch (type) {
    case MailTypeRefund.REFUND:
    case MailTypeRefund.REFUND_NOTIFICATION:
      return [
        {
          field: 'civilite',
          legend: _tg('field.human.gender'),
        },
        {
          field: 'prenom_nom_gestionnaire',
          legend: _t('hintList.prenom_nom_gestionnaire.text'),
        },
        { field: 'immatriculation', legend: _tg('field.vehicle.license') },
        {
          field: 'numero_fps',
          legend: _t('hintList.numero_fps.text'),
        },
        { field: 'prix_fps', legend: _t('hintList.prix_fps.text') },
        {
          field: 'montant_a_rembourser',
          legend: _t('hintList.montant_a_rembourser.text'),
        },
        {
          field: 'date_controle',
          legend: _tg('field.date.fpsDate'),
        },
        {
          field: 'heure_controle',
          legend: _t('hintList.heure_controle.text'),
        },
        {
          field: 'numero_adresse_controle',
          legend: _t('hintList.numero_adresse_controle.text'),
        },
        {
          field: 'type_rue_adresse_controle',
          legend: _tg('field.address.streetType_2'),
        },
        {
          field: 'nom_rue_adresse_controle',
          legend: _tg('field.address.streetName'),
        },
        {
          field: 'lieu_dit_adresse_controle',
          legend: _t('hintList.lieu_dit_adresse_controle.text'),
        },
        { field: 'nom_ville', legend: _t('hintList.nom_ville.text') },
      ];
    case MailTypeRAPO.RAPO_COMPLEMENT:
    case MailTypeRAPO.RAPO_COMPLEMENT_NOTIFICATION:
      return [
        { field: 'civilite', legend: _tg('field.human.gender') },
        {
          field: 'date_rapo',
          legend: _t('hintList.date_rapo.text'),
        },
        { field: 'motif_rapo', legend: _t('hintList.motif_rapo.text') },
        {
          field: 'prenom_nom_agent',
          legend: _t('hintList.prenom_nom_agent.text'),
        },
        {
          field: 'nom_prenom_utilisateur_createur',
          legend: _t('hintList.nom_prenom_utilisateur_createur.text'),
        },
        {
          field: 'matricule_agent',
          legend: _t('hintList.matricule_agent.text'),
        },
        { field: 'immatriculation', legend: _tg('field.vehicle.license') },
        {
          field: 'numero_rapo',
          legend: _t('hintList.numero_rapo.text'),
        },
        { field: 'fpsId', legend: _t('hintList.numero_fps.text') },
        {
          field: 'prix_fps',
          legend: _t('hintList.prix_fps.text'),
        },
        { field: 'date_controle', legend: _tg('field.date.fpsDate') },
        {
          field: 'heure_controle',
          legend: _t('hintList.heure_controle.text'),
        },
        {
          field: 'numero_adresse_controle',
          legend: _t('hintList.numero_adresse_controle.text'),
        },
        {
          field: 'type_rue_adresse_controle',
          legend: _tg('field.address.streetType_2'),
        },
        {
          field: 'nom_rue_adresse_controle',
          legend: _tg('field.address.streetName'),
        },
        {
          field: 'lieu_dit_adresse_controle',
          legend: _t('hintList.lieu_dit_adresse_controle.text'),
        },
        { field: 'nom_ville', legend: _t('hintList.nom_ville.text') },
        {
          field: 'date_création',
          legend: _t('hintList.date_création.text'),
        },
        {
          field: 'date_demande_complement',
          legend: _t('hintList.date_demande_complement.text'),
        },
        {
          field: 'date_limite_complément',
          legend: _t('hintList.date_limite_complément.text'),
        },
        {
          field: 'liste_de_compléments',
          legend: _t('hintList.liste_de_compléments.text'),
          required: type === MailTypeRAPO.RAPO_COMPLEMENT,
        },
        {
          field: 'information_complémentaire',
          legend: _t('hintList.information_complémentaire.text'),
        },
        {
          field: 'posting_date',
          legend: _t('hintList.posting_date.text'),
        },
        {
          field: 'url_front_office',
          legend: _t('hintList.url_front_office.text'),
        },
        {
          field: 'nom_prenom_redacteur',
          legend: _t('hintList.nom_prenom_redacteur.text'),
        },
      ];
    case MailTypeOrder.ORDER_ACCEPTED:
    case MailTypeOrder.ORDER_AWAITING_PAYMENT:
    case MailTypeOrder.ORDER_AWAITING_PAYMENT_REMINDER:
    case MailTypeOrder.ORDER_CREATION:
    case MailTypeOrder.ORDER_DISCARDED:
    case MailTypeOrder.ORDER_POST_BE_RENEWED:
    case MailTypeOrder.ORDER_TO_BE_RENEWED:
    case MailTypeOrder.ORDER_REJECTED:
    case MailTypeOrder.ORDER_EDIT_PLATE:
    case MailTypeOrder.ORDER_EDIT_PLATE_REJECTED:
    case MailTypeOrder.ORDER_EVIDENCE_REQUEST:
    case MailTypeOrder.ORDER_CLAIM_EVIDENCE_REQUEST:
    case MailTypeOrder.FPS_MAJORATION:
    case MailTypeOrder.ORDER_AGENT_MODIFICATION:
    case MailTypeOrder.ORDER_MAKE_CLAIM:
    case MailTypeOrder.ORDER_CLAIM_ACCEPTED:
    case MailTypeOrder.ORDER_CLAIM_REJECTED:
    case MailTypeSubscriber.SUBSCRIBERS_ACCOUNT_CREATION:
    case MailTypeSubscriber.SUBSCRIBERS_EMAIL_UPDATE:
    case MailTypeSubscriber.SUBSCRIBERS_PASSWORD_UPDATE:
    case MailTypeSubscriberMassAction.SUBSCRIBERS_COM_1:
    case MailTypeSubscriberMassAction.SUBSCRIBERS_COM_2:
      return [
        {
          field: 'subscriber_title',
          legend: _t('hintList.subscriber_title.text'),
          testValue: _t('hintList.subscriber_title.testValue'),
        },
        {
          field: 'product_name',
          legend: _t('hintList.product_name.text'),
          testValue: _t('hintList.product_name.testValue'),
        },
        {
          field: 'creation_datetime',
          legend: _tg('field.date.creation'),
          testValue: moment().format(_tg('field.date.format_date')),
        },
        {
          field: 'purge_datetime',
          legend: _tg('field.date.purge'),
          testValue: moment()
            .add(1, 'month')
            .format(_tg('field.date.format_date')),
        },
        {
          field: 'order_dates',
          legend: _t('hintList.order_dates.text'),
          testValue: _t('hintList.order_dates.testValue', {
            startDate: moment().format(_tg('field.date.format_datetime')),
            endDate: moment()
              .add(1, 'year')
              .format(_tg('field.date.format_datetime')),
          }),
        },
        {
          field: 'plates',
          legend: _t('hintList.plates.text'),
          testValue: _t('hintList.plates.testValue'),
        },
        {
          field: 'start_of_validity',
          legend: _tg('field.date.startValidity'),
          testValue: moment().format(_tg('field.date.format_date')),
        },
        {
          field: 'end_of_validity',
          legend: _tg('field.date.endValidity'),
          testValue: moment()
            .add(1, 'year')
            .format(_tg('field.date.format_date')),
        },
        {
          field: 'remaining_days',
          legend: _t('hintList.remaining_days.text'),
          testValue: _t('hintList.remaining_days.testValue'),
        },
        {
          field: 'remaining_months',
          legend: _t('hintList.remaining_months.text'),
          testValue: _t('hintList.remaining_months.testValue'),
        },
        {
          field: 'zones',
          legend: _t('hintList.zones.text'),
          testValue: _t('hintList.zones.testValue'),
        },
        {
          field: 'link',
          legend: _t('hintList.link.text'),
          testValue: _t('hintList.link.testValue'),
        },
        {
          field: 'link_pmr',
          legend: _t('hintList.link_pmr.text'),
          testValue: _t('hintList.link_pmr.testValue'),
        },
        {
          field: 'front_url',
          legend: _t('hintList.front_url.text'),
          testValue: city.frontSubsUrl,
        },
        {
          field: 'front_url_pmr',
          legend: _t('hintList.front_url_pmr.text'),
          testValue: `${city.frontPmrUrl}/pmr`,
        },
        {
          field: 'city',
          legend: _t('hintList.city.text'),
          testValue: city.name,
        },
        {
          field: 'message',
          legend: _t('hintList.message.text'),
          testValue: _t('hintList.message.testValue'),
        },
        {
          field: 'fpsId',
          legend: _t('hintList.fpsId.text'),
          testValue: _t('hintList.fpsId.testValue'),
        },
        {
          field: 'fpsPlate',
          legend: _t('hintList.fpsPlate.text'),
          testValue: _t('hintList.fpsPlate.testValue'),
        },
        {
          field: 'fpsSendDatetime',
          legend: _t('hintList.fpsSendDatetime.text'),
          testValue: _t('hintList.fpsSendDatetime.testValue'),
        },
        {
          field: 'fpsMajorationDatetime',
          legend: _t('hintList.fpsMajorationDatetime.text'),
          testValue: _t('hintList.fpsMajorationDatetime.testValue'),
        },
        {
          field: 'vehicle_type',
          legend: _t('hintList.vehicle_type.text'),
          testValue: _t('hintList.vehicle_type.testValue'),
        },
        {
          field: 'action_type',
          legend: _t('hintList.action_type.text'),
          testValue: _t('hintList.action_type.testValue'),
        },
        {
          field: 'action_link',
          legend: _t('hintList.action_link.text'),
          testValue: _t('hintList.action_link.testValue'),
        },
        {
          field: 'logo',
          legend: _t('hintList.logo.text'),
          testValue: _t('hintList.logo.testValue'),
        },
      ];
    default:
      return unreachable(type);
  }
};

const getRequiredAnchorList = (hintList: HintList): Array<string> => {
  return hintList
    .filter((hint: Hint) => hint.required)
    .map((hint: Hint) => hint.field);
};

export const getUnusedAnchorList = (
  mailContent: string | null | undefined,
  hintList: HintList
): Array<string> => {
  const requiredAnchorList = getRequiredAnchorList(hintList);
  const unusedAnchorList: string[] = [];
  if (mailContent && !!requiredAnchorList.length) {
    requiredAnchorList.forEach((anchor: string) => {
      if (!mailContent.includes(`{${anchor}}`)) {
        unusedAnchorList.push(anchor);
      }
    });
  }
  return unusedAnchorList;
};

export function translateMailType(type: MailType): string {
  switch (type) {
    case MailTypeRefund.REFUND:
      return _t('mails.refund.REFUND');
    case MailTypeRAPO.RAPO_COMPLEMENT:
      return _t('mails.rapo.RAPO_COMPLEMENT');
    case MailTypeOrder.ORDER_CREATION:
      return _t('mails.order.ORDER_CREATION');
    case MailTypeOrder.ORDER_AWAITING_PAYMENT:
      return _t('mails.order.ORDER_AWAITING_PAYMENT');
    case MailTypeOrder.ORDER_AWAITING_PAYMENT_REMINDER:
      return _t('mails.order.ORDER_AWAITING_PAYMENT_REMINDER');
    case MailTypeOrder.ORDER_ACCEPTED:
      return _t('mails.order.ORDER_ACCEPTED');
    case MailTypeOrder.ORDER_DISCARDED:
      return _t('mails.order.ORDER_DISCARDED');
    case MailTypeOrder.ORDER_POST_BE_RENEWED:
      return _t('mails.order.ORDER_POST_BE_RENEWED');
    case MailTypeOrder.ORDER_REJECTED:
      return _t('mails.order.ORDER_REJECTED');
    case MailTypeOrder.ORDER_TO_BE_RENEWED:
      return _t('mails.order.ORDER_TO_BE_RENEWED');
    case MailTypeOrder.ORDER_EDIT_PLATE:
      return _t('mails.order.ORDER_EDIT_PLATE');
    case MailTypeOrder.ORDER_EDIT_PLATE_REJECTED:
      return _t('mails.order.ORDER_EDIT_PLATE_REJECTED');
    case MailTypeOrder.ORDER_EVIDENCE_REQUEST:
      return _t('mails.order.ORDER_EVIDENCE_REQUEST');
    case MailTypeOrder.ORDER_CLAIM_EVIDENCE_REQUEST:
      return _t('mails.order.ORDER_CLAIM_EVIDENCE_REQUEST');
    case MailTypeOrder.FPS_MAJORATION:
      return _t('mails.order.FPS_MAJORATION');
    case MailTypeOrder.ORDER_MAKE_CLAIM:
      return _t('mails.order.ORDER_MAKE_CLAIM');
    case MailTypeOrder.ORDER_CLAIM_ACCEPTED:
      return _t('mails.order.ORDER_CLAIM_ACCEPTED');
    case MailTypeOrder.ORDER_CLAIM_REJECTED:
      return _t('mails.order.ORDER_CLAIM_REJECTED');
    case MailTypeRefund.REFUND_NOTIFICATION:
      return _t('mails.refund.REFUND_NOTIFICATION');
    case MailTypeRAPO.RAPO_COMPLEMENT_NOTIFICATION:
      return _t('mails.rapo.RAPO_COMPLEMENT_NOTIFICATION');
    case MailTypeOrder.ORDER_AGENT_MODIFICATION:
      return _t('mails.order.ORDER_AGENT_MODIFICATION');
    case MailTypeSubscriber.SUBSCRIBERS_ACCOUNT_CREATION:
      return _t('mails.subscriber.SUBSCRIBERS_ACCOUNT_CREATION');
    case MailTypeSubscriber.SUBSCRIBERS_EMAIL_UPDATE:
      return _t('mails.subscriber.SUBSCRIBERS_EMAIL_UPDATE');
    case MailTypeSubscriber.SUBSCRIBERS_PASSWORD_UPDATE:
      return _t('mails.subscriber.SUBSCRIBERS_PASSWORD_UPDATE');
    case MailTypeSubscriberMassAction.SUBSCRIBERS_COM_1:
      return _t('mails.subscriberMassAction.SUBSCRIBERS_COM_1');
    case MailTypeSubscriberMassAction.SUBSCRIBERS_COM_2:
      return _t('mails.subscriberMassAction.SUBSCRIBERS_COM_2');
    default:
      return unreachable(type);
  }
}

export function translateMailGroup(group: MailGroup): string {
  switch (group) {
    case MailGroup.ORDER:
      return _t('mailGroups.ORDER');
    case MailGroup.RAPO:
      return _t('mailGroups.RAPO');
    case MailGroup.REFUND:
      return _t('mailGroups.REFUND');
    case MailGroup.SUBSCRIBER:
      return _t('mailGroups.SUBSCRIBER');
    case MailGroup.SUBSCRIBER_MASS_ACTION:
      return _t('mailGroups.SUBSCRIBER_MASS_ACTION');
    default:
      return unreachable(group);
  }
}
