import { InternalAgent } from 'api/auth/types';
import services from 'commons/services';

import useWatcher from './useWatcher';

const useUserInfo = (): InternalAgent | null => {
  return useWatcher(
    services.userInfo.watchUserInfo,
    services.userInfo.getUserInfo()
  );
};

export default useUserInfo;
