export type FieldKeyRecourseUser = {
  streetNumber: 'addressStreetNumber';
  streetNumberBis: 'addressStreetNumberBis';
  streetType: 'addressStreetType';
  streetName: 'addressStreetName';
  specialPlace: 'addressComplement';
  postalCode: 'addressPostalCode';
  addressLocality: 'addressLocality';
  addressCountry: 'addressCountry';
};

export const fieldKeyRecourseUser: FieldKeyRecourseUser = {
  streetNumber: 'addressStreetNumber',
  streetNumberBis: 'addressStreetNumberBis',
  streetType: 'addressStreetType',
  streetName: 'addressStreetName',
  specialPlace: 'addressComplement',
  postalCode: 'addressPostalCode',
  addressLocality: 'addressLocality',
  addressCountry: 'addressCountry',
};
