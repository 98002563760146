import { v4 as uuidv4 } from 'uuid';
import _isEqual from 'lodash.isequal';
import _cloneDeep from 'lodash.clonedeep';

import { WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import { PatrolZoneDTO } from '@cvfm-front/tefps-types';
import {
  fetchPatrolZones,
  upsertPatrolZone,
  deletePatrolZone as deletePatrolZoneApi,
} from 'api/planner';

import { DrawingType, MapId, PolygonWrapper } from './MapService';

import services from '.';

export interface PatrolZoneServiceFactory {
  (): PatrolZoneServiceInterface;
}

export interface PatrolZoneServiceInterface {
  init: () => Promise<void>;
  getPatrolZones: () => Array<PatrolZoneDTO> | null;
  watchPatrolZones: WatchFunctionType<Array<PatrolZoneDTO> | null>;
  getHiddenPatrolZoneIds: () => Array<string>;
  setPatrolZoneHidden: (
    mapId: MapId,
    patrolId: string,
    hidden: boolean
  ) => void;
  watchHiddenPatrolZoneIds: WatchFunctionType<Array<string>>;
  getError: () => string | null;
  watchError: WatchFunctionType<string | null>;
  clearError: () => void;
  createPatrolZone: () => void;
  changePatrolZone: (patrolZone: PatrolZoneDTO) => void;
  addPolygonToPatrolZone: (patrolZone: PatrolZoneDTO) => void;
  deletePatrolZone: (patrolZone: PatrolZoneDTO) => void;
  savePatrolZones: () => Promise<void>;
  hasAnyPatrolZoneChange: () => boolean;
  hasPatrolZoneChanges: (patrolZone: PatrolZoneDTO) => boolean;
  getPatrolZoneToEdit: () => PatrolZoneDTO | null;
  setPatrolZoneToEdit: (patrolZone: PatrolZoneDTO | null) => void;
  watchPatrolZoneToEdit: WatchFunctionType<PatrolZoneDTO | null>;
  getPatrolZoneNameFromId: (patrolZoneId: string) => string;
  drawPatrolZonesOnMap: (mapId: MapId) => void;
  clearPatrolZonesOnMap: (mapId: MapId) => void;
}

// logic :
// savedPatrolZones is read only, stores the state of the back
// patrolZones is read and write, stores the current (unsaved) state
// hiddenPatrolZoneIds is a list of patrolZoneIds (not ids) that are set as hidden in the front
// patrolZoneToEdit controls the modal to edit name, description and color
const PatrolZoneService: PatrolZoneServiceFactory = () => {
  const {
    getValue: getSavedPatrolZones,
    setValue: setSavedPatrolZones,
  } = Watcher<Array<PatrolZoneDTO> | null>(null);
  const {
    getValue: getPatrolZones,
    setValue: setPatrolZones,
    watchValue: watchPatrolZones,
  } = Watcher<Array<PatrolZoneDTO> | null>(null);
  const {
    getValue: getHiddenPatrolZoneIds,
    setValue: setHiddenPatrolZoneIds,
    watchValue: watchHiddenPatrolZoneIds,
  } = Watcher<Array<string>>([]);
  const {
    getValue: getError,
    setValue: setError,
    watchValue: watchError,
  } = Watcher<PatrolZoneDTO['patrolZoneId'] | null>(null);
  const {
    getValue: getPatrolZoneToEdit,
    setValue: setPatrolZoneToEdit,
    watchValue: watchPatrolZoneToEdit,
  } = Watcher<PatrolZoneDTO | null>(null);

  const hasPatrolZoneChanges = (patrolZone: PatrolZoneDTO): boolean => {
    const savedPatrolZone = (getSavedPatrolZones() || []).find(
      saved => saved.id === patrolZone.id
    );
    if (!savedPatrolZone) {
      return true; // new zone
    }
    return !_isEqual(savedPatrolZone, patrolZone);
  };

  const hasAnyPatrolZoneChange = (): boolean => {
    if (getPatrolZones()?.length !== getSavedPatrolZones()?.length) {
      return true; // typically if we deleted the last one
    }
    // if at least one has changes => return true
    // if getPatrolZones is empty, no changes (thanks to the if above)
    return !!getPatrolZones()?.some(p => hasPatrolZoneChanges(p));
  };

  const fetch = async (): Promise<void> => {
    setError(null);
    try {
      const zones = await fetchPatrolZones();
      setSavedPatrolZones(_cloneDeep(zones));
      setPatrolZones(zones);
    } catch (e) {
      setError('Error !');
    }
  };

  const savePatrolZones = async (): Promise<void> => {
    setError(null);
    try {
      // save the new or modified zones
      await Promise.all(
        (getPatrolZones() || [])
          .filter(hasPatrolZoneChanges)
          .map(patrolZone =>
            upsertPatrolZone(patrolZone.patrolZoneId, patrolZone)
          )
      );
      // delete the old zones that aren't in the new tab
      await Promise.all(
        (getSavedPatrolZones() || [])
          .filter(spz => !getPatrolZones()?.some(pz => pz.id === spz.id))
          .map(spz => deletePatrolZoneApi(spz.patrolZoneId))
      );
      await fetch();
    } catch (e) {
      setError('Error !');
    }
  };

  const init = async (): Promise<void> => {
    await fetch();
  };

  const clearError = (): void => {
    setError(null);
  };

  const createPatrolZone = (): void => {
    const city = services.cityConfig.getCityConfig();
    if (!city) {
      return;
    }
    const newPatrolZoneId = uuidv4();
    const { location } = city;
    const newPatrolZones = [
      ...(getPatrolZones() || []),
      {
        id: `${city?.cityId || ''}/${newPatrolZoneId}`,
        cityId: city?.cityId || '',
        patrolZoneId: newPatrolZoneId,
        name: 'Nouvelle zone',
        description: '',
        color: '#ff0000',
        points: [
          [
            {
              latitude: location.latitude + 0.01,
              longitude: location.longitude + 0.01,
            },
            {
              latitude: location.latitude - 0.01,
              longitude: location.longitude + 0.01,
            },
            {
              latitude: location.latitude - 0.01,
              longitude: location.longitude - 0.01,
            },
          ],
        ],
        agentId: '', // is replaced in the back when saving
      },
    ];
    setPatrolZones(newPatrolZones);
  };

  const changePatrolZone = (patrolZone: PatrolZoneDTO): void => {
    const newPatrolZones = [...(getPatrolZones() || [])].map(p => {
      if (p.id === patrolZone.id) {
        return patrolZone;
      }
      return p;
    });
    setPatrolZones(newPatrolZones);
  };

  const deletePatrolZone = (patrolZone: PatrolZoneDTO): void => {
    const newPatrolZones = [...(getPatrolZones() || [])].filter(
      p => p.id !== patrolZone.id
    );
    setPatrolZones(newPatrolZones);
  };

  const addPolygonToPatrolZone = (patrolZone: PatrolZoneDTO): void => {
    const city = services.cityConfig.getCityConfig();
    if (!city) {
      return;
    }
    const { location } = city;
    patrolZone.points.push([
      {
        latitude: location.latitude + 0.01,
        longitude: location.longitude + 0.01,
      },
      {
        latitude: location.latitude - 0.01,
        longitude: location.longitude + 0.01,
      },
      {
        latitude: location.latitude - 0.01,
        longitude: location.longitude - 0.01,
      },
    ]);
    changePatrolZone(patrolZone);
  };

  const setPatrolZoneHidden = (
    mapId: MapId,
    patrolZoneId: string,
    hidden: boolean
  ): void => {
    const newHiddenPatrolZoneIds = [...(getHiddenPatrolZoneIds() || [])].filter(
      id => id !== patrolZoneId
    );
    if (hidden) {
      newHiddenPatrolZoneIds.push(patrolZoneId);
    }
    setHiddenPatrolZoneIds(newHiddenPatrolZoneIds);
    services.mapService
      .get(mapId)
      ?.polygons?.setVisibility([patrolZoneId], !hidden);
  };

  const getPatrolZoneNameFromId = (patrolZoneId: string): string => {
    const patrolZones = getPatrolZones();
    return (
      patrolZones?.find(z => z.patrolZoneId === patrolZoneId)?.name ||
      patrolZoneId
    );
  };

  const drawPatrolZonesOnMapHelper = (mapId: MapId) => {
    // here patrolZones should not be null
    const patrolZones = getPatrolZones();
    if (patrolZones) {
      services.mapService.get(mapId)?.polygons?.add(
        patrolZones.reduce(
          (acc, p) =>
            acc.concat(
              p.points.map((points, index) => ({
                id: `${p.patrolZoneId}/${index}`,
                name: p.name,
                type: DrawingType.PATROL_ZONE,
                points,
                color: p.color,
                zIndex: acc.length + index,
                visible: true,
                editable: false,
                canBeEdited: false,
              }))
            ),
          [] as Array<PolygonWrapper>
        )
      );
    }
  };

  const drawPatrolZonesOnMap = (mapId: MapId) => {
    if (getPatrolZones() === null) {
      void fetch().then(() => drawPatrolZonesOnMapHelper(mapId));
    } else {
      drawPatrolZonesOnMapHelper(mapId);
    }
  };

  const clearPatrolZonesOnMap = (mapId: MapId) => {
    services.mapService.get(mapId)?.clear?.drawingType(DrawingType.PATROL_ZONE);
  };

  return {
    init,
    getPatrolZones,
    watchPatrolZones,
    getHiddenPatrolZoneIds,
    setPatrolZoneHidden,
    watchHiddenPatrolZoneIds,
    getError,
    watchError,
    clearError,
    createPatrolZone,
    changePatrolZone,
    addPolygonToPatrolZone,
    deletePatrolZone,
    savePatrolZones,
    hasPatrolZoneChanges,
    hasAnyPatrolZoneChange,
    getPatrolZoneToEdit,
    setPatrolZoneToEdit,
    watchPatrolZoneToEdit,
    getPatrolZoneNameFromId,
    drawPatrolZonesOnMap,
    clearPatrolZonesOnMap,
  };
};

export default PatrolZoneService;
