import React, { CSSProperties } from 'react';
import MaterialToggle from 'material-ui/Toggle';

import { LABEL_STYLE } from 'theme';

const STYLE_BLOCK: CSSProperties = { marginTop: 20 };
const STYLE_TOGGLE: CSSProperties = { marginTop: 5, width: '100%' };

type Props = {
  id: string;
  title: string;
  value: boolean | undefined;
  onChange: (id: string, value: boolean) => void;
  styleBlock?: CSSProperties;
};

class Toggle extends React.Component<Props> {
  onChange = (_e: unknown, checked: boolean): void => {
    const { id, onChange } = this.props;
    onChange(id, checked);
  };

  render(): JSX.Element {
    const { value, title, styleBlock } = this.props;
    return (
      <div style={styleBlock ? { ...STYLE_BLOCK, ...styleBlock } : STYLE_BLOCK}>
        <MaterialToggle
          label={title}
          labelPosition="right"
          labelStyle={LABEL_STYLE}
          toggled={value}
          onToggle={this.onChange}
          style={STYLE_TOGGLE}
        />
      </div>
    );
  }
}

export default Toggle;
